import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SectionHeader from "../../Common/SectionHeader";
import { MdAddModerator } from "react-icons/md";
import CustomTextField from "../../Common/CustomTextField";
import UserSelection from "./UserSelection";
import PermissionSelection from "./PermissionSelection";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../../redux/slices/app";
import { useAddPermissionMutation } from "../../../redux/slices/api/permission/permissionApi";
import { useGetAllUsersQuery } from "../../../redux/slices/api/user/userApi";
import { images } from "../../Assets";
import { useTranslation } from "react-i18next";

const AddPermissionGroup = ({ isSmallScreen, setOpenAddPermission }) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const dispatch = useDispatch();
	const [formData, setFormData] = useState({
		permissionName: "",
		description: "",
	});

	const { data, isLoading, isError } = useGetAllUsersQuery({}, { refetchOnMountOrArgChange: true });

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [usersToAdd, setUsersToAdd] = useState([]);

	const [userList, setUserList] = useState(null);
	const [originalRows, setOriginalRows] = useState(null);

	const [permissionsToAdd, setPermissionsToAdd] = useState({});

	const [openUserSelection, setOpenUserSelection] = useState(false);

	const [hasError, setHasError] = useState(false);

	useEffect(() => {
		if (isError) {
			// dispatch(setSnackbar({ open: true, message: "Error fetching data", severity: "error" }));
			return;
		}
		if (data && !isError && !isLoading) {
			const usersToAddSet = new Set(usersToAdd.map((addUser) => addUser.id));

			const filteredRows = data?.data.filter((rowUser) => !usersToAddSet.has(rowUser.id));

			setUserList(filteredRows);
			setOriginalRows(filteredRows);
		}
	}, [data, isLoading, isError, usersToAdd]);

	const handleInput = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const [addPermissionGroup] = useAddPermissionMutation();

	const handleSavePermissionGroup = async (e) => {
		e.preventDefault();
		setIsSubmitting(true);
		if (formData.permissionName === "") {
			setHasError(true);
			setIsSubmitting(false);
			return;
		}
		if (usersToAdd.length < 1) {
			dispatch(setSnackbar({ open: true, message: t("UserMinimum"), severity: "error" }));
			setIsSubmitting(false);
			return;
		}

		// Filter out keys with empty array values
		const filteredPermissions = Object.fromEntries(
			Object.entries(permissionsToAdd)
				.filter(([key, value]) => value.length > 0)
				.map(([key, value]) => [
					key === "case_file"
						? "caseFile"
						: key === "email_account"
						? "emailAccount"
						: key === "email_template"
						? "emailTemplate"
						: key === "email_message"
						? "emailMessage"
						: key,
					value,
				])
		);

		const formattedUsersToAdd = usersToAdd.map((user) => user.id);

		const payloadWithDescription = {
			name: formData.permissionName,
			description: formData.description,
			users: formattedUsersToAdd,
			permissions: filteredPermissions,
		};

		const payload = {
			name: formData.permissionName,
			users: formattedUsersToAdd,
			permissions: filteredPermissions,
		};

		try {
			await addPermissionGroup(formData.description === "" ? payload : payloadWithDescription).unwrap();
			dispatch(setSnackbar({ open: true, message: t("PermissionGroupCreatedSuccessfully"), severity: "success" }));
			setIsSubmitting(false);
			setOpenAddPermission(false);
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setIsSubmitting(false);
		}
	};

	return (
		<>
			<Box
				width={"100%"}
				height={"max-content"}
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
				gap={4}
				sx={{ overflowY: "auto", overflowX: "hidden" }}
				p={4}
			>
				{/* Add the AddPermissionGroup component here */}
				<Box
					width={isSmallScreen ? "100%" : "90%"}
					height={"auto"}
					p={2}
					borderRadius={1.5}
					bgcolor={"#fff"}
					sx={{
						boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
					}}
				>
					<SectionHeader icon={<MdAddModerator color="#164c68" size={18} />} title={`${t("NewPermissionGroup")}`} type={"dialog"} />
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<Stack justifyContent={"flex-start"} alignItems={"center"} spacing={2} p={4} mb={2}>
						{/* Add the form here */}
						<CustomTextField
							type={"text"}
							label={`${t("PermissionName")}: `}
							value={formData.permissionName}
							resetValue={() => setFormData({ ...formData, permissionName: "" })}
							name={"permissionName"}
							handleInputChange={handleInput}
							error={hasError}
							errorText={"Permission Group Name is required"}
							required={true}
						/>
						<CustomTextField
							type={"text"}
							label={`${t("PermissionGroupDesc")}:`}
							value={formData.description}
							resetValue={() => setFormData({ ...formData, description: "" })}
							name={"description"}
							handleInputChange={handleInput}
							required={false}
							multiline={true}
						/>
					</Stack>
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={2} p={1} width={"100%"}>
						<Box width={"100%"}>
							<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ mb: 2 }}>
								{t("Users")}:
							</Typography>
							{usersToAdd.length > 0 && (
								<Grid container spacing={2}>
									{usersToAdd.map((user) => (
										<Grid item xs={12} sm={3} key={user.id}>
											<Box
												width={"100%"}
												p={2}
												height={"100%"}
												borderRadius={1.5}
												display={"flex"}
												justifyContent={"flex-start"}
												alignItems={"start"}
												flexDirection={"column"}
												gap={2}
												bgcolor={"#f3f7ff"}
												sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;" }}
											>
												<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={1}>
													<img src={images.id} alt="id" width={23} height={23} />
													<Typography
														variant="h5"
														color={"#164c68"}
														fontWeight={700}
														sx={{ mx: `${isRtl ? "8px" : "0px"} !important` }}
													>
														{t("FirstName")}:
													</Typography>
													<Typography variant="h5" color={"#164c68"} fontWeight={500}>
														{user?.firstName}
													</Typography>
												</Stack>
												<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={1}>
													<img src={images.id} alt="id" width={23} height={23} />
													<Typography
														variant="h5"
														color={"#164c68"}
														fontWeight={700}
														sx={{ mx: `${isRtl ? "8px" : "0px"} !important` }}
													>
														{t("LastName")}:
													</Typography>
													<Typography variant="h5" color={"#164c68"} fontWeight={500}>
														{user?.lastName}
													</Typography>
												</Stack>

												<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={1}>
													<img src={images.role} alt="role" width={23} height={23} />
													<Typography
														variant="h5"
														color={"#164c68"}
														fontWeight={700}
														sx={{ mx: `${isRtl ? "8px" : "0px"} !important` }}
													>
														{t("Role")}:
													</Typography>
													<Typography variant="h5" color={"#164c68"} fontWeight={500}>
														{user?.role}
													</Typography>
												</Stack>
											</Box>
										</Grid>
									))}
								</Grid>
							)}
						</Box>
						<Button
							onClick={() => setOpenUserSelection(true)}
							sx={{
								bgcolor: "#164c68",
								color: "#fff",
								"&:hover": {
									bgcolor: "#00a3b1",
									color: "#fff",
								},
								transition: "all 1s ease",
							}}
						>
							{t("ManageUsers")}
						</Button>
					</Stack>
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<Typography variant={"h5"} fontWeight={600} color={"#164c68"}>
						{t("ManagePermissions")}:
					</Typography>
					<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={2} p={1}>
						<PermissionSelection isSmallScreen={isSmallScreen} permissions={permissionsToAdd} setPermissions={setPermissionsToAdd} />
					</Stack>
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<Stack direction="row" justifyContent={"flex-end"} alignItems={"center"} spacing={2} p={1}>
						<Button
							onClick={handleSavePermissionGroup}
							sx={{
								bgcolor: "#164c68",
								color: "#fff",
								"&:hover": {
									bgcolor: "#00a3b1",
									color: "#fff",
								},
								transition: "all 1s ease",
								mr: 1,
							}}
						>
							{t("Save")}
						</Button>
						<Button
							onClick={() => setOpenAddPermission(false)}
							sx={{
								color: "red",
								"&:hover": {
									color: "#fff",
									backgroundColor: "red",
								},
								transition: "all 1s ease",
								mr: `${isRtl ? "8px" : "0px"} !important`,
							}}
						>
							{t("Cancel")}
						</Button>
					</Stack>
				</Box>
			</Box>
			{openUserSelection && (
				<UserSelection
					open={openUserSelection}
					handleClose={() => setOpenUserSelection(false)}
					usersToAdd={usersToAdd}
					setUsersToAdd={setUsersToAdd}
					rows={userList}
					setRows={setUserList}
					originalRows={originalRows}
				/>
			)}
		</>
	);
};

export default AddPermissionGroup;
