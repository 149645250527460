import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomConstraintSelect from "../../Common/CustomConstraintSelect";
import CustomTextField from "../../Common/CustomTextField";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IoAddCircle } from "react-icons/io5";

const CaseDetails = ({
	classification,
	isSmallScreen,
	caseNumber,
	setCaseNumber,
	caseYear,
	setCaseYear,
	caseStage,
	setCaseStage,
	caseType,
	setCaseType,
	court,
	setCourt,
	setAddConstraint,
	setConstraintReference,
}) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const [error, setError] = useState({
		caseNumber: false,
		caseYear: false,
	});
	const [errorText, setErrorText] = useState({
		caseNumber: "Case number cannot be less than 0.",
		caseYear: "Case year cannot be less than 0.",
	});
	return (
		<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={4} p={1} width={"100%"}>
			<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
					<Typography variant={"h5"} fontWeight={500} color={"#164c68"} sx={{ ml: `${isRtl ? "8px" : "0px"} !important` }}>
						{t("CaseNumber")}:
					</Typography>
					<CustomTextField
						type={"number"}
						name={"caseNumber"}
						value={caseNumber}
						resetValue={() => setCaseNumber("")}
						handleInputChange={(e) => {
							if (e.target.value < 0) {
								setError({ ...error, caseNumber: true });
								return;
							}
							setCaseNumber(e.target.value);
						}}
						disable={classification === "consultation" ? true : false}
						error={error.caseNumber}
						errorText={errorText.caseNumber}
						required={classification === "litigation" ? true : false}
					/>
				</Stack>
				<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1} width={"100%"}>
					<Typography variant={"h5"} fontWeight={500} color={"#164c68"} sx={{ ml: `${isRtl ? "8px" : "0px"} !important` }}>
						{t("CaseYear")}:
					</Typography>
					<CustomTextField
						type={"number"}
						name={"caseYear"}
						value={caseYear}
						resetValue={() => setCaseYear("")}
						handleInputChange={(e) => {
							if (e.target.value < 0) {
								setError({ ...error, caseYear: true });
								return;
							}
							setCaseYear(e.target.value);
						}}
						disable={classification === "consultation" ? true : false}
						error={error.caseYear}
						errorText={errorText.caseYear}
						required={classification === "litigation" ? true : false}
					/>
				</Stack>
			</Stack>
			<Stack
				direction={isSmallScreen ? "column" : "row"}
				justifyContent={isSmallScreen ? "flex-start" : "space-between"}
				alignItems={isSmallScreen ? "start" : "center"}
				width={"100%"}
				px={isSmallScreen ? 0 : 4}
				spacing={2}
			>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
					<Typography variant="body1" color={"#164c68"} fontWeight={500} sx={{ mb: 1, ml: `${isRtl ? "8px" : "0px"} !important` }}>
						{t("CaseStage")}:
					</Typography>
					<CustomConstraintSelect reference={"caseStage"} dataSet={caseStage} setData={setCaseStage} classification={classification} />
					<Tooltip title={`${t("Add")}  ${t("CaseStage")}`}>
						<IconButton
							onClick={() => {
								setAddConstraint(true);
								setConstraintReference("caseStage");
							}}
						>
							<IoAddCircle color={"#164c68"} />
						</IconButton>
					</Tooltip>
				</Stack>
				<Stack direction={"row"} justifyContent={isSmallScreen ? "flex-start" : "center"} alignItems={"center"} spacing={1} width={"100%"}>
					<Typography variant="body1" color={"#164c68"} fontWeight={500} sx={{ mb: 1, ml: `${isRtl ? "8px" : "0px"} !important` }}>
						{t("CaseType")}:
					</Typography>
					<CustomConstraintSelect reference={"caseType"} dataSet={caseType} setData={setCaseType} classification={classification} />
					<Tooltip title={`${t("Add")}  ${t("CaseType")}`}>
						<IconButton
							onClick={() => {
								setAddConstraint(true);
								setConstraintReference("caseType");
							}}
						>
							<IoAddCircle color={"#164c68"} />
						</IconButton>
					</Tooltip>
				</Stack>
				<Stack direction={"row"} justifyContent={isSmallScreen ? "flex-start" : "flex-end"} alignItems={"center"} spacing={1} width={"100%"}>
					<Typography variant="body1" color={"#164c68"} fontWeight={500} sx={{ mb: 1, ml: `${isRtl ? "8px" : "0px"} !important` }}>
						{t("Court")}:
					</Typography>
					<CustomConstraintSelect reference={"court"} dataSet={court} setData={setCourt} classification={classification} />
					<Tooltip title={`${t("Add")}  ${t("Court")}`}>
						<IconButton
							onClick={() => {
								setAddConstraint(true);
								setConstraintReference("court");
							}}
						>
							<IoAddCircle color={"#164c68"} />
						</IconButton>
					</Tooltip>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default CaseDetails;
