import React, { useEffect } from "react";
import { useGetConstraintsQuery } from "../../../redux/slices/api/constraints/constraintsApi";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

const PartyConstraint = ({ reference, constraintId, caseRoleName, setCaseRoleName }) => {
	const { data, isError } = useGetConstraintsQuery({ reference }, { refetchOnMountOrArgChange: true });
	const isRtl = useSelector((state) => state.app.isRtl);

	useEffect(() => {
		if (isError) return;

		if (data) {
			const constraint = data?.data.find((constraint) => constraint.id === constraintId);
			setCaseRoleName(isRtl ? constraint.nameAr : constraint.nameEn ? constraint.nameEn : constraint.nameAr);
		}
	}, [data, constraintId]);

	return (
		<Typography variant={"h5"} fontWeight={500} color={"#595858"}>
			{caseRoleName}
		</Typography>
	);
};

export default PartyConstraint;
