import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { images } from "../../../Assets";

const UserCard = ({ user }) => {
	const { t } = useTranslation();

	return (
		<Box
			display={"flex"}
			justifyContent={"center"}
			alignItems={"center"}
			bgcolor={"#f3f7ff"}
			width={"100%"}
			p={2}
			height={"100%"}
			borderRadius={1.5}
			sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;" }}
		>
			<Stack justifyContent={"flex-start"} width={"100%"} spacing={2}>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={0.5}>
					<img src={images.id} alt="id" width={20} height={20} />
					<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mx: ` 8px  !important` }}>
						{t("FirstName")}: {user?.user?.firstName || user.firstName}
					</Typography>
				</Stack>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={0.5}>
					<img src={images.id} alt="id" width={20} height={20} />
					<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mx: `8px  !important` }}>
						{t("LastName")}: {user?.user?.lastName || user.lastName}
					</Typography>
				</Stack>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={0.5}>
					<img src={images.role} alt="position" width={20} height={20} />
					<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mx: `8px  !important` }}>
						{t("Position")}: {user.position ? user.position : "N/A"}
					</Typography>
				</Stack>
			</Stack>
		</Box>
	);
};

export default UserCard;
