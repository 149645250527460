// DroppableSection.js
import { useDroppable } from "@dnd-kit/core";

const DroppableSection = ({ status, children }) => {
	const { setNodeRef, isOver, isOverCurrent } = useDroppable({
		id: status.id,
		data: { status },
	});
	const style = isOverCurrent
		? { background: "lightblue" }
		: { width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" };

	return (
		<div ref={setNodeRef} style={style}>
			{children}
		</div>
	);
};

export default DroppableSection;
