import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import Animate from "../../Common/Animate";
import moment from "moment";
import { images } from "../../Assets";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCurrentMeeting } from "../../../redux/slices/meetings";
import { useTranslation } from "react-i18next";
import { setCurrentCase } from "../../../redux/slices/case";

const ListCard = ({ data, setDeleteMeeting }) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	return (
		<Animate type="fade" delay={0.5}>
			<Box
				width={"100%"}
				height={"max-content"}
				p={2}
				borderRadius={1.5}
				bgcolor={"#fff"}
				sx={{
					boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
				}}
			>
				<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2} width={"100%"}>
						<Box
							width={"120px"}
							p={4}
							borderRadius={2}
							bgcolor={"#e3e3e3"}
							display={"flex"}
							justifyContent={"center"}
							alignItems={"center"}
							flexDirection={"column"}
							sx={{
								ml: `${isRtl ? "8px" : "0px"} !important`,
							}}
						>
							<Typography
								variant={"h4"}
								fontWeight={700}
								color={"#164c68"}
								sx={{
									fontFamily: '"Montserrat", "sans-serif"',
								}}
							>
								{moment(data?.date).format("DD")}
							</Typography>
							<Typography variant={"h4"} fontWeight={700} color={"#164c68"}>
								{moment(data?.date).format("MMM")}
							</Typography>
						</Box>
						<Stack justifyContent={"flex-start"} alignItems={"flex-start"} spacing={1.5}>
							<Stack justifyContent={"flex-start"} alignItems={"flex-start"} spacing={0.5}>
								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
									<Typography variant={"h5"} fontWeight={700} color={"#164c68"} sx={{ ml: `${isRtl ? "8px" : "0px"} !important` }}>
										{t("meetingCode")}:
									</Typography>
									<Typography variant={"h5"} fontWeight={500} color={"#164c68"}>
										{data?.code ? data?.code : "N/A"}
									</Typography>
								</Stack>
								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
									<Typography variant={"h5"} fontWeight={700} color={"#164c68"} sx={{ ml: `${isRtl ? "8px" : "0px"} !important` }}>
										{t("CaseFileNumber")}:
									</Typography>
									<Typography
										variant={"h5"}
										fontWeight={500}
										color={"#164c68"}
										sx={{
											fontFamily: '"Montserrat", "sans-serif"',
										}}
									>
										{data?.caseFile?.fileNumber}
									</Typography>
								</Stack>
								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
									<Typography variant={"h5"} fontWeight={700} color={"#164c68"} sx={{ ml: `${isRtl ? "8px" : "0px"} !important` }}>
										{t("MeetingTime")}:
									</Typography>
									<Typography
										variant={"h5"}
										fontWeight={500}
										color={"#164c68"}
										sx={{
											fontFamily: '"Montserrat", "sans-serif"',
										}}
									>
										{moment(data?.date).format("hh:mm a")} - {moment(data?.endDate).format("hh:mm a")}
									</Typography>
								</Stack>
							</Stack>
							<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
									<img src={images.team} alt="Team" width={15} height={15} />
									<Typography
										variant={"h6"}
										fontWeight={700}
										color={"#164c68"}
										sx={{ mr: `${isRtl ? "8px" : "0px"} !important`, fontFamily: '"Montserrat", "sans-serif"' }}
									>
										{data?.users?.length} {t("Users")}
									</Typography>
								</Stack>
								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
									<img src={images.contacts} alt="Contacts" width={15} height={15} />
									<Typography
										variant={"h6"}
										fontWeight={700}
										color={"#164c68"}
										sx={{ mr: `${isRtl ? "8px" : "0px"} !important`, fontFamily: '"Montserrat", "sans-serif"' }}
									>
										{data?.contacts?.length} {t("Contacts")}
									</Typography>
								</Stack>
								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
									<img src={images.place} alt="Place" width={15} height={15} />
									<Typography variant={"h6"} fontWeight={700} color={"#164c68"} sx={{ mr: `${isRtl ? "8px" : "0px"} !important` }}>
										{data?.place ? data?.place : "N/A"}
									</Typography>
								</Stack>
							</Stack>
						</Stack>
					</Stack>
					<Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"} gap={1}>
						<Button
							sx={{
								bgcolor: "#164c68",
								color: "#fff",
								"&:hover": {
									bgcolor: "#00a3b1",
								},
								transition: "all 1s ease",
							}}
							onClick={() => {
								dispatch(setCurrentMeeting(data));
								dispatch(setCurrentCase(data?.caseFile));
								navigate(`/dashboard/meeting-detail`);
							}}
						>
							{t("View")}
						</Button>
						<Button
							sx={{
								color: "#fff",
								backgroundColor: "#fc6739",
								"&:hover": {
									color: "#fff",
									backgroundColor: "red",
								},
								transition: "all 1s ease",
							}}
							onClick={() => {
								dispatch(setCurrentMeeting(data));
								setDeleteMeeting(true);
							}}
						>
							{t("Delete")}
						</Button>
					</Box>
				</Stack>
			</Box>
		</Animate>
	);
};

export default ListCard;
