import { apiSlice } from "../apiSlice";

export const emailApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getEmailAccount: builder.query({
			query: () => ({
				url: "/email/account",
				method: "GET",
			}),
			providesTags: ["Email"],
		}),
		createEmailAccount: builder.mutation({
			query: (data) => ({
				url: "/email/account",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["Email"],
		}),
		deleteEmailAccount: builder.mutation({
			query: () => ({
				url: `/email/account`,
				method: "DELETE",
			}),
			invalidatesTags: ["Email"],
		}),
	}),
});

export const { useGetEmailAccountQuery, useCreateEmailAccountMutation, useDeleteEmailAccountMutation } = emailApi;
