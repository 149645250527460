import React, { useEffect, useState } from "react";
import Animate from "../../components/Common/Animate";
import { Box, Button, CircularProgress, FormControl, Grid, MenuItem, Select as MuiSelect, Stack, Typography } from "@mui/material";
import CustomTextField from "../../components/Common/CustomTextField";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
import { IoMdAdd } from "react-icons/io";
import ContactCard from "../../components/Contacts/ContactCard";
import NewContact from "../../components/Contacts/NewContact";
import { images } from "../../components/Assets/index";
import CustomBreadcrumbs from "../../components/Common/Breadcrumbs";
import { useGetAllContactsQuery, useGetByCriteriaQuery } from "../../redux/slices/api/contacts/contactApi";
import { useDispatch, useSelector } from "react-redux";
import ContactMenu from "../../components/Common/Menu/ContactMenu";
import { setIsAddingContact, setIsEditingContact } from "../../redux/slices/contact";
import { useTranslation } from "react-i18next";
import { setSnackbar } from "../../redux/slices/app";

const Select = styled(MuiSelect)({
	color: "#164c68", // Change this to your desired color
	"& .MuiSelect-outlined": {
		// Change this to your desired color
		borderRadius: 5,
	},
	"&:active": {
		"& .MuiSelect-outlined": {
			boxShadow: "0 0 5px rgba(0,0,0,0.3)",
			// Example border color when focused
		},
	},
});

const Contacts = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	const isRtl = useSelector((state) => state.app.isRtl);
	const userDetails = useSelector((state) => state.app.userDetails);

	const { data, isLoading, isError } = useGetAllContactsQuery({}, { refetchOnMountOrArgChange: true });

	const [rows, setRows] = useState(data?.data);
	const [originalRows, setOriginalRows] = useState(data?.data);
	const [searchValue, setSearchValue] = useState("");
	const [isSearching, setIsSearching] = useState(false);
	const [isSortingByType, setIsSortingByType] = useState(false);
	const [isSortingByCategory, setIsSortingByCategory] = useState(false);
	const [sortTypeValue, setSortTypeValue] = useState("none");
	const [sortCategoryValue, setSortCategoryValue] = useState("none");
	const [openAddContact, setOpenAddContact] = useState(false);
	const [selectedContact, setSelectedContact] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		if (isError) {
			return;
		}
		if (data) {
			setRows(data?.data);
			setOriginalRows(data?.data);
		}
	}, [data]);

	const nameQuery = searchValue?.length >= 3 && searchValue !== "" ? { search: searchValue } : {};

	const searchByName = useGetByCriteriaQuery(nameQuery, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (searchValue?.length > 2 && searchValue !== "") {
			setIsSearching(true);
			setRows(searchByName?.data?.data);
		} else {
			setIsSearching(false);
			setRows(originalRows);
		}
	}, [searchValue]);

	const searchByTypeQuery = isSortingByType && sortTypeValue !== "none" ? { type: sortTypeValue } : {};
	const searchByCategoryQuery = isSortingByCategory && sortCategoryValue !== "none" ? { "category[0]": sortCategoryValue } : {};

	const searchByType = useGetByCriteriaQuery(searchByTypeQuery, { refetchOnMountOrArgChange: true });
	const searchByCategory = useGetByCriteriaQuery(searchByCategoryQuery, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		let filteredData = originalRows;

		if (sortTypeValue === "individual" || sortTypeValue === "company") {
			setIsSortingByType(true);
			if (isSortingByCategory) {
				filteredData = filteredData.filter((contact) => contact.type === sortTypeValue);
			} else {
				filteredData = searchByType?.data?.data;
			}
			filteredData = searchByType?.data?.data;
		} else {
			setIsSortingByType(false);
		}

		if (sortCategoryValue === "client" || sortCategoryValue === "litigant" || sortCategoryValue === "lawyer" || sortCategoryValue === "expertOffice") {
			setIsSortingByCategory(true);
			if (isSortingByType) {
				filteredData = filteredData.filter((contact) => contact.category === sortCategoryValue);
			} else {
				filteredData = searchByCategory?.data?.data;
			}
		} else {
			setIsSortingByCategory(false);
		}

		setRows(filteredData);
	}, [sortTypeValue, sortCategoryValue, searchByType, searchByCategory, isSortingByType, isSortingByCategory]);

	const handleSearchInput = (e) => {
		setSearchValue(e.target.value);
		if (e.target.value === "") {
			setIsSearching(false);
		}
	};

	const clearSearch = () => {
		setSearchValue("");
		setIsSearching(false);
		setRows(originalRows);
	};

	const closeContactDialog = () => {
		setOpenAddContact(false);
	};

	return (
		<Animate type="fade" delay={0.5}>
			<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={2} px={6} pt={4}>
				<CustomBreadcrumbs section={t("Contacts")} />
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<img src={images.contacts} alt={"contacts"} width={35} height={35} />
					<Typography variant={"h2"} fontWeight={600} color={"#164c68"}>
						{t("My")} {t("Contacts")}
					</Typography>
				</Stack>
			</Stack>
			<Box
				width={"100%"}
				height={"max-content"}
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
				gap={4}
				sx={{ overflowY: "auto", overflowX: "hidden" }}
				px={6}
				py={3}
			>
				<Stack
					direction={isSmallScreen ? "column" : "row"}
					justifyContent={isSmallScreen ? "flex-start" : "space-between"}
					alignItems={isSmallScreen ? "flex-start" : "center"}
					width={"100%"}
					spacing={2}
				>
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={isSmallScreen ? "100%" : "30%"}>
						<CustomTextField
							label={t("Search")}
							name={"searchValue"}
							value={searchValue}
							type={"text"}
							handleInputChange={handleSearchInput}
							isSearching={isSearching}
							clearSearch={clearSearch}
							required={false}
						/>
					</Stack>
					<Stack
						direction={isSmallScreen ? "column" : "row"}
						justifyContent={isSmallScreen ? "flex-start" : "flex-end"}
						alignItems={isSmallScreen ? "flex-start" : "center"}
						spacing={2}
						width={"100%"}
					>
						<Typography variant="body1" color={"#164c68"} fontWeight={500} sx={{ mb: 1 }}>
							{t("FilterBy")} {t("Type")}:
						</Typography>
						<FormControl size="small">
							<Select
								id="select-category"
								value={sortTypeValue}
								onChange={(e) => {
									if (e.target.value === "none") {
										setSortCategoryValue("none");
										setIsSortingByType(false);
										setIsSortingByCategory(false);
										setRows(originalRows);
									}
									setSortTypeValue(e.target.value);
								}}
								sx={{ color: "#164c68" }}
							>
								<MenuItem value={"none"}>{t("All")}</MenuItem>
								<MenuItem value={"individual"}>{t("Individual")}</MenuItem>
								<MenuItem value={"company"}>{t("Company")}</MenuItem>
							</Select>
						</FormControl>

						<Typography variant="body1" color={"#164c68"} fontWeight={500} sx={{ mb: 1 }}>
							{t("FilterBy")} {t("Category")}:
						</Typography>
						<FormControl size="small">
							<Select
								id="select-category"
								value={sortCategoryValue}
								onChange={(e) => {
									if (sortTypeValue === "none") {
										dispatch(setSnackbar({ open: true, message: "Please select a type first", severity: "error" }));
										return;
									}
									setSortCategoryValue(e.target.value);
								}}
								sx={{ color: "#164c68" }}
							>
								<MenuItem value={"none"}>{t("All")}</MenuItem>
								<MenuItem value={"client"}>{t("Client")}</MenuItem>
								<MenuItem value={"litigant"}>{t("Litigant")}</MenuItem>
								<MenuItem value={"lawyer"}>{t("Lawyer")}</MenuItem>
								<MenuItem value={"expertOffice"}>{t("ExpertOffice")}</MenuItem>
							</Select>
						</FormControl>

						{userDetails?.role !== "Employee" && (
							<Button
								variant="contained"
								startIcon={!isRtl && <IoMdAdd />}
								size="medium"
								sx={{
									bgcolor: "#164c68",
									color: "#fff",
									"&:hover": {
										bgcolor: "#00a3b1",
									},
									transition: "all 0.5s ease",
									px: 3,
									py: 0.98,
								}}
								onClick={() => {
									dispatch(setIsEditingContact(false));
									dispatch(setIsAddingContact(true));
									setOpenAddContact(true);
								}}
							>
								{t("Create")}
							</Button>
						)}
					</Stack>
				</Stack>
				{isLoading || searchByName.isLoading || searchByCategory.isLoading || searchByType.isLoading ? (
					<Box width={"100%"} height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} mt={10}>
						<CircularProgress />
					</Box>
				) : (
					<Grid container spacing={2}>
						{rows?.map((contact) => (
							<Grid item xs={12} sm={4} key={contact.id}>
								<ContactCard
									contact={contact}
									open={open}
									setAnchorEl={setAnchorEl}
									setSelectedContact={setSelectedContact}
									searchValue={searchValue}
									isSearching={isSearching}
								/>
							</Grid>
						))}
					</Grid>
				)}

				{(isSearching || isSortingByType || isSortingByCategory) && rows?.length === 0 && (
					<Box width={"100%"} height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} mt={10}>
						<img src={images.notFound} alt="Not Found" width={200} height={200} />
						<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ my: 2 }}>
							{t("NoResultsFound")}
						</Typography>
						<Typography variant={"subtitle1"} fontWeight={500} color={"#51505099"}>
							{t("AddContacts")}
						</Typography>
					</Box>
				)}
			</Box>
			{open && <ContactMenu open={open} anchorEl={anchorEl} handleClose={handleClose} contact={selectedContact} />}
			{openAddContact && <NewContact open={openAddContact} handleClose={closeContactDialog} />}
		</Animate>
	);
};

export default Contacts;
