import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import FirstSection from "./Sections/FirstSection";
import SecondSection from "./Sections/SecondSection";
import ThirdSection from "./Sections/ThirdSection";
import Animate from "../../components/Common/Animate";
import CustomBreadcrumbs from "../../components/Common/Breadcrumbs";
import { images } from "../../components/Assets";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
	const { t } = useTranslation();
	return (
		<Animate type="fade" delay={0.5}>
			<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={2} px={6} pt={4}>
				<CustomBreadcrumbs section={"dashboard"} />
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<img src={images.dashboard} alt={"dashboard"} width={40} height={40} />
					<Typography variant={"h2"} fontWeight={600} color={"#164c68"}>
						{t("My")} {t("Dashboard")}
					</Typography>
				</Stack>
			</Stack>

			<Box
				width={"100%"}
				height={"100%"}
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
				gap={4}
				sx={{ overflow: "hidden" }}
				px={6}
			>
				<FirstSection />
				<SecondSection />
				<ThirdSection />
			</Box>
		</Animate>
	);
};

export default Dashboard;
