import { Box, Stack } from '@mui/material';
import React from 'react';
import LoginForm from '../../components/Auth/LoginForm';
import { images } from '../../components/Assets';
import Animate from '../../components/Common/Animate';

const Login = () => {
	return (
		<Box
			width={'100%'}
			height={'100%'}
			display={'flex'}
			justifyContent={'center'}
			alignItems={'center'}
			flexDirection={'column'}
			gap={2}
			sx={{ backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: `url(${images.loginBg})` }}
		>
			<Animate type="fade" delay={1}>
				<Stack direction={'row'} width={'100%'} justifyContent={'center'} alignItems={'center'}>
					<img src={images.logo} alt="logo" width={100} height={100} />
				</Stack>
			</Animate>
			<Animate>
				<LoginForm />
			</Animate>
		</Box>
	);
};

export default Login;
