import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { useSelector } from "react-redux";
import { fetchMedia } from "../../Common/utils";
import { images } from "../../Assets";

const UserCard = ({ user, open, setAnchorEl, setSelectedUser }) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const userDetails = useSelector((state) => state.app.userDetails);

	const [avatar, setAvatar] = useState(null);
	const [isLoadingAvatar, setIsLoadingAvatar] = useState(false);

	useEffect(() => {
		if (user.avatar) {
			const fetchData = async () => {
				setIsLoadingAvatar(true);
				const data = await fetchMedia(user.avatar);
				setAvatar(data?.dataUrl);
				setIsLoadingAvatar(false);
			};
			fetchData();
		}
	}, [user]);
	return (
		<>
			<Box
				width={"100%"}
				p={2}
				height={"100%"}
				borderRadius={1.5}
				sx={{ boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;" }}
				bgcolor={"#fff"}
			>
				<Stack width={"100%"} direction={"row"} justifyContent={"space-between"} alignItems={"center"} mb={2}>
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
						{avatar ? (
							<Box
								width={"50px"}
								height={"50px"}
								p={0}
								borderRadius={"50%"}
								bgcolor={"#bdbdbd"}
								display={"flex"}
								justifyContent={"center"}
								alignItems={"center"}
								border={"3px solid #164c68"}
								sx={{
									marginLeft: `${isRtl ? "8px" : "0px"} !important`,
								}}
							>
								<img
									src={avatar ? avatar : images.avatar}
									alt="avatar"
									width={avatar ? "100%" : "80%"}
									height={avatar ? "100%" : "80%"}
									style={{ objectFit: "cover", borderRadius: "50%" }}
								/>
							</Box>
						) : (
							<Box
								width={"50px"}
								p={1}
								borderRadius={"50%"}
								bgcolor={"#bdbdbd"}
								display={"flex"}
								justifyContent={"center"}
								alignItems={"center"}
								sx={{
									ml: `${isRtl ? "8px" : "0px"} !important`,
								}}
							>
								<Typography variant={"h3"} fontWeight={700} color={"#fff"}>
									{user?.firstName?.charAt(0).toUpperCase()}
								</Typography>
							</Box>
						)}

						<Typography variant={"h4"} fontWeight={700} color={"#595858"}>
							{user.firstName} {user.lastName}
						</Typography>
					</Stack>

					{userDetails.role === "Owner" && (
						<IconButton
							sx={{ p: 0 }}
							onClick={(e) => {
								setSelectedUser(user);
								setAnchorEl(e.currentTarget);
							}}
							id="user-button"
							aria-controls={open ? "user-menu" : undefined}
							aria-haspopup="true"
							aria-expanded={open ? "true" : undefined}
						>
							<HiOutlineDotsVertical color="#164c68" size={18} />
						</IconButton>
					)}
				</Stack>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"} mb={2}>
					<a href={"mailto:" + user.email} style={{ textDecoration: "none" }}>
						<Typography variant={"h6"} fontWeight={600} color={"blue"} sx={{ "&:hover": { cursor: "pointer", textDecoration: "underline" } }}>
							{user.email}
						</Typography>
					</a>
				</Stack>
				<Stack direction={"row"} justifyContent={"space-between"} alignItems={"end"} spacing={1} width={"100%"}>
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2} width={"100%"}>
						<Stack justifyContent={"flex-start"} alignItems={"flex-start"} spacing={1}>
							<Typography variant={"h6"} fontWeight={500} color={"#595858"} sx={{ mb: 1 }}>
								{t("Role")}:
							</Typography>
							<Box
								width={"max-content"}
								display={"flex"}
								justifyContent={"center"}
								alignItems={"center"}
								p={1}
								borderRadius={1.5}
								sx={{ boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;" }}
							>
								<Typography variant={"h6"} fontWeight={600} color={"#595858"}>
									{t(user.role)}
								</Typography>
							</Box>
						</Stack>
						<Stack justifyContent={"flex-start"} alignItems={"flex-start"} spacing={1}>
							<Typography variant={"h6"} fontWeight={500} color={"#595858"} sx={{ mb: 1 }}>
								{t("Position")}:
							</Typography>
							<Box
								width={"max-content"}
								display={"flex"}
								justifyContent={"center"}
								alignItems={"center"}
								p={1}
								borderRadius={1.5}
								minWidth={60}
								sx={{ boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;" }}
							>
								<Typography variant={"h6"} fontWeight={600} color={"#595858"}>
									{user.position ? t(user.position) : "N/A"}
								</Typography>
							</Box>
						</Stack>
					</Stack>
					<Stack justifyContent={"flex-end"} alignItems={"end"} spacing={2} width={"100%"}>
						<Box
							width={"max-content"}
							display={"flex"}
							justifyContent={"center"}
							alignItems={"center"}
							p={1}
							borderRadius={1.5}
							bgcolor={user.isActive ? "#164c68" : "#f44336"}
							sx={{ boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;" }}
						>
							<Typography variant={"h6"} fontWeight={600} color={"#fff"}>
								{user.isActive ? t("Active") : t("Inactive")}
							</Typography>
						</Box>
					</Stack>
				</Stack>
			</Box>
		</>
	);
};

export default UserCard;
