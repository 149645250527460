import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import SectionHeader from "../../Common/SectionHeader";
import { FaTasks } from "react-icons/fa";
import { Divider, FormControl, MenuItem, Select, Stack, Typography } from "@mui/material";
import moment from "moment";
import CustomTextField from "../../Common/CustomTextField";
import CustomDatePicker from "../../Common/CustomDatePicker";
import { useUpdateTaskMutation } from "../../../redux/slices/api/tasks/tasksApi";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../../redux/slices/app";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../utils/axios";
import { setSelectedTask } from "../../../redux/slices/task";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const UpdateTaskDialog = ({ open, handleClose, currentTask, newStatus, loading, setLoading }) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [dueDate, setDueDate] = useState(moment().add(1, "days"));
	const [taskNote, setTaskNote] = useState("");
	const [taskStatus, setTaskStatus] = useState(currentTask.status);
	const selectedTask = useSelector((state) => state.task.selectedTask);

	const [error, setError] = useState({
		dueDate: false,
		taskNote: false,
	});

	const [errorText, setErrorText] = useState({
		dueDate: "",
		taskNote: "",
	});

	const [updateTask] = useUpdateTaskMutation();

	const handleUpdateTask = async () => {
		setLoading(true);

		if (!dueDate) {
			setErrorText({ ...errorText, dueDate: t("TaskDueDateRequired") });
			setError({ ...error, dueDate: true });
			setLoading(false);
			return;
		}
		if (!taskNote) {
			setErrorText({ ...errorText, taskNote: t("StatusNoteRequired") });
			setError({ ...error, taskNote: true });
			setLoading(false);
			return;
		}

		let taskUpdate = {
			status: newStatus ? newStatus : taskStatus,
			dueDate: dueDate,
			statusNote: taskNote,
		};

		try {
			await updateTask({ taskId: currentTask.id, data: taskUpdate }).unwrap();
			if (selectedTask) {
				const newTaskData = await axiosInstance.get(`/task/${currentTask.id}`);
				dispatch(setSelectedTask(newTaskData.data.data));
				navigate("/dashboard/task/details");
			}
			dispatch(setSnackbar({ open: true, message: t("TaskUpdatedSuccessfully"), severity: "success" }));
			setTimeout(() => {
				setLoading(false);
			}, 2000);
			handleClose();
			return;
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setLoading(false);
		}
	};
	return (
		<Dialog open={open} fullWidth TransitionComponent={Transition} keepMounted aria-describedby="update-task-dialog" sx={{ height: "100%" }}>
			<DialogTitle sx={{ pb: 2, borderBottom: "1px solid #aaaaaa6b", backgroundColor: "#fff" }}>
				<SectionHeader
					icon={<FaTasks color="#164c68" size={18} />}
					title={
						`${t("UpdatingTask")}: ` +
						(currentTask?.taskTitle
							? isRtl
								? currentTask?.taskTitle?.nameAr
								: currentTask?.taskTitle?.nameEn
								? currentTask?.taskTitle?.nameEn
								: currentTask?.taskTitle?.nameAr
							: currentTask?.title)
					}
					type={"dialog"}
				/>
			</DialogTitle>
			<DialogContent sx={{ backgroundColor: "#fff" }}>
				<DialogContentText id="add-user-dialog-description" sx={{ p: 4 }}>
					{newStatus && (
						<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ mb: 2 }}>
							{t("UpdatingTaskDesc")} {currentTask.status === "In Progress" ? t("InProgress") : t(currentTask.status)} {t("To")} {t(newStatus)}
						</Typography>
					)}
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<Stack justifyContent={"flex-start"} alignItems={"flex-start"} spacing={3} width={"100%"} sx={{ mb: 2 }}>
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
							<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ mb: 2, width: "30%" }}>
								{t("DueDate")}:
							</Typography>
							<Stack justifyContent={"flex-start"} alignItems={"flex-start"} spacing={2} width={"100%"}>
								<CustomDatePicker
									title={t("Due")}
									setInvalidDate={setError.dueDate}
									setErrorText={setErrorText.dueDate}
									value={dueDate}
									setDate={setDueDate}
								/>
							</Stack>
						</Stack>
						{!newStatus && (
							<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
								<Typography variant="h5" color={"#164c68"} fontWeight={600} sx={{ mb: 1, ml: `${isRtl ? "8px" : "0px"} !important` }}>
									{t("Status")}:
								</Typography>
								<FormControl sx={{ m: 1, minWidth: 100 }} size="small">
									<Select
										id="select-classification"
										value={taskStatus}
										onChange={(e) => setTaskStatus(e.target.value)}
										sx={{ color: "#164c68" }}
									>
										<MenuItem value={"New"}>{t("New")}</MenuItem>
										<MenuItem value={"In Progress"}> {t("InProgress")}</MenuItem>
										<MenuItem value={"Completed"}>{t("Completed")}</MenuItem>
										<MenuItem value={"Canceled"}>{t("Canceled")}</MenuItem>
									</Select>
								</FormControl>
							</Stack>
						)}
						<Stack justifyContent={"flex-start"} alignItems={"flex-start"} spacing={2} width={"100%"}>
							<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ mb: 2 }}>
								{t("StatusNote")}
							</Typography>
							<CustomTextField
								type={"text"}
								label={t("StatusNote")}
								value={taskNote}
								resetValue={() => setTaskNote("")}
								name={"taskNote"}
								handleInputChange={(e) => setTaskNote(e.target.value)}
								error={false}
								errorText={""}
								required={true}
								multiline={true}
							/>
						</Stack>
					</Stack>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: "#fff" }}>
				<Button
					disabled={loading}
					onClick={handleClose}
					sx={{
						color: "red",
						"&:hover": {
							color: "#fff",
							backgroundColor: "red",
						},
						transition: "all 1s ease",
					}}
				>
					{t("Cancel")}
				</Button>
				<Button
					disabled={loading}
					variant="contained"
					size="medium"
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						mr: `${isRtl ? "8px" : "0px"} !important`,
					}}
					onClick={handleUpdateTask}
				>
					{t("Update")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default UpdateTaskDialog;
