import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Animate from "../../Common/Animate";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	CircularProgress,
	Divider,
	Fade,
	FormControl,
	Grid,
	MenuItem,
	Select,
	Stack,
	Typography,
} from "@mui/material";
import CustomBreadcrumbs from "../../Common/Breadcrumbs";
import { images } from "../../Assets";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FaBell } from "react-icons/fa6";
import { useAddReminderMutation, useGetReminderMetadataQuery } from "../../../redux/slices/api/reminders/reminderApi";
import { useGetByCriteriaEmailTemplatesQuery } from "../../../redux/slices/api/email/templateApi";
import TemplateCard from "../Email/Templates/TemplateCard";
import { IoCaretDownOutline } from "react-icons/io5";
import UsersSection from "../../Tasks/TaskForm/UsersSection";
import UserSelection from "../../Tasks/TaskForm/UserSelection";
import CancellingDialog from "../../Common/CancellingDialog";
import { useNavigate } from "react-router-dom";
import { setSnackbar } from "../../../redux/slices/app";
import CustomTextField from "../../Common/CustomTextField";

const ReminderSettings = () => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [modelRef, setModelRef] = useState("CaseFile");
	const [actionTypeSelect, setActionTypeSelect] = useState([]);
	const [actionType, setActionType] = useState("");
	const [expanded, setExpanded] = useState(false);
	const [emailTemplates, setEmailTemplates] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [assigningUsers, setAssigningUsers] = useState(false);
	const [reminderUsers, setReminderUsers] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [cancelling, setCancelling] = useState(false);

	const reminderMetadata = useGetReminderMetadataQuery({}, { refetchOnMountOrArgChange: true });
	const getEmailTemplates = useGetByCriteriaEmailTemplatesQuery({}, { refetchOnMountOrArgChange: true });

	const [modelRefSelect, setModelRefSelect] = useState(reminderMetadata.data?.data?.MODEL_REF || []);
	const [reminderDayDurationType, setReminderDayDurationType] = useState("Before");
	const [reminderHourDurationType, setReminderHourDurationType] = useState("Before");
	const [reminderDay, setReminderDay] = useState(0);
	const [reminderHour, setReminderHour] = useState(0);
	const [reminderDurationError, setReminderDurationError] = useState({
		day: false,
		hour: false,
	});

	useEffect(() => {
		if (reminderMetadata.isError) return;

		if (reminderMetadata.data?.data || !reminderMetadata.isLoading || !reminderMetadata.isError) {
			setModelRefSelect(reminderMetadata.data?.data?.MODEL_REF);
		}
	}, []);

	useEffect(() => {
		if (reminderMetadata.isError) return;
		if (reminderMetadata.data?.data || !reminderMetadata.isLoading || !reminderMetadata.isError) {
			if (modelRef === "CaseFile" && modelRef !== "") {
				setActionTypeSelect(reminderMetadata.data?.data?.TYPE_CASE_FILE);
			} else if (modelRef === "Fee" && modelRef !== "") {
				setActionTypeSelect(reminderMetadata.data?.data?.TYPE_FEE);
			} else if (modelRef === "Hearing" && modelRef !== "") {
				setActionTypeSelect(reminderMetadata.data?.data?.TYPE_HEARING);
			} else if (modelRef === "Task" && modelRef !== "") {
				setActionTypeSelect(reminderMetadata.data?.data?.TYPE_TASK);
			} else if (modelRef === "Memo" && modelRef !== "") {
				setActionTypeSelect(reminderMetadata.data?.data?.TYPE_MEMO);
			} else if (modelRef === "Meeting" && modelRef !== "") {
				setActionTypeSelect(reminderMetadata.data?.data?.TYPE_MEETING);
			} else {
				setActionTypeSelect("");
			}
		}
	}, [modelRef]);

	useEffect(() => {
		if (getEmailTemplates.isError) {
			console.log(getEmailTemplates.error);
			return;
		}
		if (getEmailTemplates.data) {
			setEmailTemplates(getEmailTemplates.data?.data);
		}
	}, [getEmailTemplates]);

	const handleExpansion = () => {
		setExpanded((prevExpanded) => !prevExpanded);
	};

	const [addReminder] = useAddReminderMutation();

	const handleAddReminder = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		const userIds = reminderUsers.map((user) => user.id);

		if (!selectedTemplate) {
			dispatch(setSnackbar({ open: true, message: t("SelectEmailTemplate"), severity: "error" }));
			setIsLoading(false);
			return;
		}

		let reminderData = {
			active: true,
			action: {
				modelRef: modelRef,
				type: actionType,
			},
			email: {
				template: selectedTemplate,
				users: userIds,
				relatedUsers: true,
				relatedClients: true,
			},
		};

		if (reminderDayDurationType === "Before") {
			const formattedReminderDay = reminderDay * -1;
			reminderData = {
				...reminderData,
				email: {
					...reminderData.email,
					days: formattedReminderDay,
				},
			};
		} else {
			const formattedReminderDay = reminderDay * 1;
			reminderData = {
				...reminderData,
				email: {
					...reminderData.email,
					days: formattedReminderDay,
				},
			};
		}

		if (reminderHourDurationType === "Before") {
			const formattedReminderHour = reminderHour * -1;
			reminderData = {
				...reminderData,
				email: {
					...reminderData.email,
					hours: formattedReminderHour,
				},
			};
		} else {
			const formattedReminderHour = reminderHour * 1;
			reminderData = {
				...reminderData,
				email: {
					...reminderData.email,
					hours: formattedReminderHour,
				},
			};
		}

		console.log(reminderData);

		try {
			await addReminder(reminderData).unwrap();
			dispatch(setSnackbar({ open: true, message: t("ReminderAdded"), severity: "success" }));
			setIsLoading(false);
			navigate("/dashboard");
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setIsLoading(false);
		}
	};

	return (
		<Animate type="fade" delay={0.5}>
			<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={2} px={6} pt={4}>
				<CustomBreadcrumbs section={t("ReminderSettings")} />
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<img src={images.reminder} alt={"reminder"} width={40} height={40} />
					<Typography variant={"h2"} fontWeight={600} color={"#164c68"} sx={{ mr: `${isRtl ? "8px" : "0px"} !important` }}>
						{t("ReminderSettings")}
					</Typography>
				</Stack>
			</Stack>

			<Box
				width={"100%"}
				height={"max-content"}
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
				gap={4}
				sx={{ overflowY: "auto", overflowX: "hidden" }}
				p={4}
			>
				<Box
					width={isSmallScreen ? "100%" : "70%"}
					height={"auto"}
					p={2}
					bgcolor={"#fff"}
					borderRadius={1.5}
					sx={{
						boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
					}}
				>
					<Stack direction={"row"} width={"100%"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
						<Box p={1} display={"flex"} justifyContent={"center"} alignItems={"center"} bgcolor={"#e9e7e770"} borderRadius={1}>
							<FaBell color="#164c68" size={18} />
						</Box>
						<Typography variant={"h4"} fontWeight={700} color={"#595858"}>
							{t("CreateReminder")}
						</Typography>
					</Stack>
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={4} width={"100%"} p={2}>
						<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} spacing={4} width={"100%"}>
							<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
								<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1, ml: `${isRtl ? "8px" : "0px"} !important` }}>
									{t("Reference")}:
								</Typography>
								<FormControl sx={{ m: 1, minWidth: 100 }} size="small">
									<Select id="select-modelRef" value={modelRef} onChange={(e) => setModelRef(e.target.value)} sx={{ color: "#164c68" }}>
										{modelRefSelect?.map((item, index) => {
											return (
												<MenuItem key={index} value={item}>
													{t(item)}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</Stack>

							<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1} width={"100%"}>
								<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1, ml: `${isRtl ? "8px" : "0px"} !important` }}>
									{t("Type")}:
								</Typography>
								<FormControl sx={{ m: 1, minWidth: 100 }} size="small">
									<Select id="select-actionType" value={actionType} onChange={(e) => setActionType(e.target.value)} sx={{ color: "#164c68" }}>
										{actionTypeSelect?.map((item, index) => {
											return (
												<MenuItem key={index} value={item}>
													{t(item)}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</Stack>
						</Stack>
						{/* <Stack justifyContent={"flex-start"} alignItems={"start"} spacing={1} width={"100%"}> */}
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
							<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1, ml: `${isRtl ? "8px" : "0px"} !important` }}>
								{t("ReminderDay")}:
							</Typography>
							<CustomTextField
								id={"reminderDay-textfield"}
								type={"number"}
								name={"reminderDay"}
								value={reminderDay}
								resetValue={() => setReminderDay(0)}
								handleInputChange={(e) => {
									if (e.target.value < 0) {
										setReminderDurationError({ ...reminderDurationError, day: true });
										return;
									}
									setReminderDay(e.target.value);
								}}
								error={reminderDurationError.day}
								errorText={t("DayError")}
								required={true}
							/>
							<FormControl sx={{ m: 1, minWidth: 100 }} size="small">
								<Select
									id="select-reminderDayDurationType"
									value={reminderDayDurationType}
									onChange={(e) => setReminderDayDurationType(e.target.value)}
									sx={{ color: "#164c68" }}
								>
									<MenuItem value={"Before"}>{t("Before")}</MenuItem>
									<MenuItem value={"After"}>{t("After")}</MenuItem>
								</Select>
							</FormControl>
						</Stack>
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
							<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1, ml: `${isRtl ? "8px" : "0px"} !important` }}>
								{t("ReminderHour")}:
							</Typography>
							<CustomTextField
								id={"reminderHour-textfield"}
								type={"number"}
								name={"reminderHour"}
								value={reminderHour}
								resetValue={() => setReminderHour(0)}
								handleInputChange={(e) => {
									if (e.target.value < 0) {
										setReminderDurationError({ ...reminderDurationError, hour: true });
										return;
									}
									setReminderHour(e.target.value);
								}}
								error={reminderDurationError.hour}
								errorText={t("HourError")}
								required={true}
							/>
							<FormControl sx={{ m: 1, minWidth: 100 }} size="small">
								<Select
									id="select-reminderHourDurationType"
									value={reminderHourDurationType}
									onChange={(e) => setReminderHourDurationType(e.target.value)}
									sx={{ color: "#164c68" }}
								>
									<MenuItem value={"Before"}>{t("Before")}</MenuItem>
									<MenuItem value={"After"}>{t("After")}</MenuItem>
								</Select>
							</FormControl>
						</Stack>
						{/* </Stack> */}

						<Accordion
							expanded={expanded}
							onChange={handleExpansion}
							slots={{ transition: Fade }}
							slotProps={{ transition: { timeout: 1500 } }}
							sx={{
								bgcolor: "#fff",
								boxShadow: "none",
								"& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
								"& .MuiAccordionDetails-root": { display: expanded ? "block" : "none" },
								width: "100%",
							}}
						>
							<AccordionSummary expandIcon={<IoCaretDownOutline size={20} color={"#164c68"} />} aria-controls="panel-content" id="panel-header">
								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
									<Box p={1} display={"flex"} justifyContent={"center"} alignItems={"center"} bgcolor={"#e9e7e770"} borderRadius={1}>
										<img src={images.template} alt="Email Template" width={23} height={23} />
									</Box>

									<Typography variant={"h3"} fontWeight={500} color={"#164c68"} sx={{ mr: `${isRtl ? "8px" : "0px"} !important` }}>
										{t("EmailTemplate")}:
									</Typography>
								</Stack>
							</AccordionSummary>
							<AccordionDetails>
								<Divider sx={{ mb: 2, bgcolor: "#164c6866" }} />

								{getEmailTemplates.isLoading && !getEmailTemplates.isError ? (
									<CircularProgress />
								) : emailTemplates.length >= 1 ? (
									<Grid container spacing={2}>
										{emailTemplates.map((template) => (
											<Grid item xs={12} sm={4} key={template.id}>
												<TemplateCard
													template={template}
													section={"reminder"}
													selectedTemplate={selectedTemplate}
													setSelectedTemplate={setSelectedTemplate}
												/>
											</Grid>
										))}
									</Grid>
								) : (
									<>
										<img src={images.notFound} alt="Not Found" width={100} height={100} />
										<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ my: 1 }}>
											{t("NoEmailTemplate")}!
										</Typography>
										<Typography variant={"subtitle1"} fontWeight={500} color={"#51505099"}>
											{t("AddEmailTemplate")}!
										</Typography>
									</>
								)}
							</AccordionDetails>
						</Accordion>
						<UsersSection isSmallScreen={isSmallScreen} assignedTo={reminderUsers} setAssigningUsers={setAssigningUsers} />
					</Stack>
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1.5}>
						<Button
							disabled={isLoading}
							variant="contained"
							startIcon={!isRtl && <FaBell />}
							size="medium"
							sx={{
								bgcolor: "#164c68",
								color: "#fff",
								ml: `${isRtl ? "8px" : "0px"} !important`,
							}}
							onClick={handleAddReminder}
						>
							{t("Save")}
						</Button>
						<Button
							onClick={() => setCancelling(true)}
							disabled={isLoading}
							sx={{
								color: "#fff",
								backgroundColor: "#fc6739",
								"&:hover": {
									color: "#fff",
									backgroundColor: "red",
								},
								transition: "all 1s ease",
							}}
						>
							{t("Cancel")}
						</Button>
					</Stack>
				</Box>
			</Box>
			{assigningUsers && (
				<UserSelection open={assigningUsers} handleClose={() => setAssigningUsers(false)} users={reminderUsers} setUsers={setReminderUsers} />
			)}
			{cancelling && <CancellingDialog open={cancelling} handleClose={() => setCancelling(false)} path={"dashboard"} />}
		</Animate>
	);
};

export default ReminderSettings;
