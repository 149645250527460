import { Box, Stack, Typography, Select as MuiSelect, FormControl, MenuItem, IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import CustomTextField from "../../Common/CustomTextField";
import CustomConstraintSelect from "../../Common/CustomConstraintSelect";
import { styled } from "@mui/material/styles";
import CustomDatePicker from "../../Common/CustomDatePicker";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IoAddCircle } from "react-icons/io5";
import { TiDelete } from "react-icons/ti";
import LinkedCaseSelect from "./LinkedCaseSelect";

const Select = styled(MuiSelect)({
	"& .MuiSvgIcon-root": { color: "#164c68" },
});

const CaseInformation = ({
	isSmallScreen,
	fileNumber,
	fileError,
	setError,
	setFileNumber,
	classification,
	setClassification,
	status,
	setStatus,
	subject,
	setSubject,
	note,
	setNote,
	openDate,
	setOpenDate,
	endDate,
	setEndDate,
	fileType,
	setFileType,
	closeReason,
	setCloseReason,
	linkedCase,
	setLinkedCase,
	setAddConstraint,
	setConstraintReference,
	expertAssignmentDate,
	setExpertAssignmentDate,
	commercialBroker,
	setCommercialBroker,
}) => {
	const { t } = useTranslation();

	const isRtl = useSelector((state) => state.app.isRtl);

	const [invalidOpenDate, setInvalidOpenDate] = useState(false);
	const [invalidExpertAssignmentDate, setInvalidExpertAssignmentDate] = useState(false);
	const [invalidEndDate, setInvalidEndDate] = useState(false);

	const [errorText, setErrorText] = useState("");

	return (
		<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={4} p={1} width={"100%"}>
			{isSmallScreen && (
				<>
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
						<Typography variant={"h5"} fontWeight={500} color={"#164c68"} sx={{ mb: 3, ml: `${isRtl ? "8px" : "0px"} !important` }}>
							{t("FileNumber")}:
						</Typography>
						<CustomTextField
							type={"number"}
							name={"fileNumber"}
							value={fileNumber}
							resetValue={() => setFileNumber("")}
							handleInputChange={(e) => {
								if (e.target.value < 0) {
									setError(true);
									return;
								}
								setFileNumber(e.target.value);
							}}
							error={fileError}
							errorText={"File Number cannot be less that 0."}
							required={true}
						/>
					</Stack>
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
						<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1, ml: `${isRtl ? "8px" : "0px"} !important` }}>
							{t("Classification")}:
						</Typography>
						<FormControl sx={{ m: 1, minWidth: 100 }} size="small">
							<Select
								id="select-classification"
								value={classification}
								onChange={(e) => setClassification(e.target.value)}
								sx={{ color: "#164c68" }}
								displayEmpty
							>
								<MenuItem value={"litigation"}>{t("Litigation")}</MenuItem>
								<MenuItem value={"consultation"}>{t("Consultation")}</MenuItem>
							</Select>
						</FormControl>
					</Stack>
				</>
			)}
			<Stack
				direction={isSmallScreen ? "column" : "row"}
				justifyContent={isSmallScreen ? "flex-start" : "space-between"}
				alignItems={"center"}
				spacing={2}
				width={"100%"}
			>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2} width={"100%"}>
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={0.5} width={"100%"}>
						<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1, ml: `${isRtl ? "8px" : "0px"} !important` }}>
							{t("FileType")}:
						</Typography>
						<CustomConstraintSelect reference={"fileType"} dataSet={fileType} setData={setFileType} />
						<Tooltip title={t("AddFileType")}>
							<IconButton
								onClick={() => {
									setAddConstraint(true);
									setConstraintReference("fileType");
								}}
							>
								<IoAddCircle color={"#164c68"} />
							</IconButton>
						</Tooltip>
					</Stack>
				</Stack>
				<Stack direction={"row"} justifyContent={isSmallScreen ? "flex-start" : "flex-end"} alignItems={"center"} spacing={1} width={"100%"}>
					<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1, ml: `${isRtl ? "8px" : "0px"} !important` }}>
						{t("LinkedCase")}:
					</Typography>
					<LinkedCaseSelect fileNumber={fileNumber} linkedCase={linkedCase} setLinkedCase={setLinkedCase} classification={classification} />
					<IconButton onClick={() => setLinkedCase(null)}>
						<TiDelete color={linkedCase ? "red" : "#a9a9a9"} />
					</IconButton>
				</Stack>
			</Stack>
			<Stack
				direction={isSmallScreen ? "column" : "row"}
				justifyContent={isSmallScreen ? "flex-start" : "space-between"}
				alignItems={"center"}
				spacing={2}
				width={"100%"}
			>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
					<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1, ml: `${isRtl ? "8px" : "0px"} !important` }}>
						{t("CaseStatus")}:
					</Typography>
					<FormControl sx={{ m: 1, minWidth: 100 }} size="small">
						<Select id="select-status" value={status} onChange={(e) => setStatus(e.target.value)} sx={{ color: "#164c68" }} displayEmpty>
							<MenuItem value={"ongoing"}>{t("Ongoing")}</MenuItem>
							<MenuItem value={"closed"}>{t("Closed")}</MenuItem>
						</Select>
					</FormControl>
				</Stack>
				<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={2} width={"100%"}>
					<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={0.5} width={"100%"}>
						<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1, ml: `${isRtl ? "8px" : "0px"} !important` }}>
							{t("CommercialBroker")}:
						</Typography>
						<CustomConstraintSelect reference={"commercialBroker"} dataSet={commercialBroker} setData={setCommercialBroker} />
						<Tooltip title={t("AddCommercialBroker")}>
							<IconButton
								onClick={() => {
									setAddConstraint(true);
									setConstraintReference("commercialBroker");
								}}
							>
								<IoAddCircle color={"#164c68"} />
							</IconButton>
						</Tooltip>
					</Stack>
				</Stack>
			</Stack>
			<Stack justifyContent={"flex-start"} alignItems={"start"} width={"100%"} spacing={2}>
				<Stack
					direction={isSmallScreen ? "column" : "row"}
					justifyContent={isSmallScreen ? "flex-start" : "space-between"}
					alignItems={isSmallScreen ? "flex-start" : "center"}
					spacing={1}
					width={"100%"}
				>
					<Stack justifyContent={"flex-start"} alignItems={"start"} width={"100%"} spacing={1}>
						<CustomDatePicker
							title={t("CaseFileOpenDate")}
							setInvalidDate={setInvalidOpenDate}
							setErrorText={setErrorText}
							value={openDate}
							setDate={setOpenDate}
						/>
						{invalidOpenDate && (
							<Typography variant={"title1"} color={"red"} fontWeight={500}>
								{errorText}
							</Typography>
						)}
					</Stack>
					<Stack justifyContent={"flex-end"} alignItems={"end"} width={"100%"} spacing={1}>
						<CustomDatePicker
							title={t("ExpertAssignment")}
							setInvalidDate={setInvalidExpertAssignmentDate}
							setErrorText={setErrorText}
							value={expertAssignmentDate}
							setDate={setExpertAssignmentDate}
						/>
						{invalidExpertAssignmentDate && (
							<Typography variant={"title1"} color={"red"} fontWeight={500}>
								{errorText}
							</Typography>
						)}
					</Stack>
				</Stack>
				{status === "closed" && (
					<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={3} width={"100%"}>
						<Stack justifyContent={"center"} alignItems={"start"} spacing={1}>
							<CustomDatePicker
								title={t("Close")}
								setInvalidDate={setInvalidOpenDate}
								setErrorText={setErrorText}
								value={endDate}
								setDate={setEndDate}
							/>
							{invalidEndDate && (
								<Typography variant={"title1"} color={"red"} fontWeight={500}>
									{errorText}
								</Typography>
							)}
						</Stack>
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={isSmallScreen ? "100%" : "50%"}>
							<Typography variant={"h5"} fontWeight={500} color={"#164c68"} sx={{ ml: `${isRtl ? "8px" : "0px"} !important` }}>
								{t("CloseReason")}:
							</Typography>
							<CustomConstraintSelect reference={"closeReason"} dataSet={closeReason} setData={setCloseReason} />
							<Tooltip title={`${t("Add")}  ${t("closeReason")}`}>
								<IconButton
									onClick={() => {
										setAddConstraint(true);
										setConstraintReference("closeReason");
									}}
								>
									<IoAddCircle color={"#164c68"} />
								</IconButton>
							</Tooltip>
						</Stack>
					</Stack>
				)}
			</Stack>

			<Stack direction={isSmallScreen ? "column" : "row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={2}>
				<Box width={isSmallScreen ? "100%" : "50%"}>
					<CustomTextField
						type={"text"}
						label={t("Subject")}
						value={subject}
						resetValue={() => setSubject("")}
						name={"subject"}
						handleInputChange={(e) => {
							setSubject(e.target.value);
						}}
						required={false}
						multiline={true}
					/>
				</Box>
				<Box width={isSmallScreen ? "100%" : "50%"} sx={{ mr: `${isRtl ? "8px" : "0px"} !important` }}>
					<CustomTextField
						type={"text"}
						label={t("Notes")}
						value={note}
						resetValue={() => setNote("")}
						name={"note"}
						handleInputChange={(e) => {
							setNote(e.target.value);
						}}
						required={false}
						multiline={true}
					/>
				</Box>
			</Stack>
		</Stack>
	);
};

export default CaseInformation;
