import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Animate from "../../Common/Animate";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Fade, Grid, Link, Stack, Typography } from "@mui/material";
import FirstSection from "../../Case/CaseDetails/FirstSection";
import FileSection from "../../Case/CaseDetails/FileSection";
import { images } from "../../Assets";
import { setIsCreatingMeeting, setIsEditingMeeting } from "../../../redux/slices/meetings";
import moment from "moment";
import { IoCaretDownOutline } from "react-icons/io5";
import Contact from "../Contact";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import { useTranslation } from "react-i18next";
import UserCard from "../../Case/CaseDetails/SecondSection/UserCard";

const MeetingDetails = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	const isRtl = useSelector((state) => state.app.isRtl);
	const currentMeeting = useSelector((state) => state.meetings.currentMeeting);

	const [loading, setLoading] = useState(false);
	const [contactExpanded, setContactExpanded] = useState(false);
	const [usersExpanded, setUsersExpanded] = useState(false);
	const [deleteMeeting, setDeleteMeeting] = useState(false);

	const handleContactExpansion = () => {
		setContactExpanded((prevExpanded) => !prevExpanded);
	};

	const handleUsersExpansion = () => {
		setUsersExpanded((prevExpanded) => !prevExpanded);
	};

	return (
		<Animate type="fade" delay={0.5}>
			<Box
				width={"100%"}
				height={"max-content"}
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
				sx={{ overflowY: "auto", overflowX: "hidden" }}
				px={isSmallScreen ? 6 : 15}
				py={2}
				gap={4}
			>
				<FirstSection category={"meeting"} />
				<Box
					width={"100%"}
					height={"max-content"}
					gap={6}
					bgcolor={"#fff"}
					sx={{
						overflowY: "auto",
						overflowX: "hidden",
						boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;",
					}}
					p={4}
					borderRadius={1.5}
				>
					<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} spacing={1} width={"100%"}>
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
							<img src={images.calendar} alt="calendar" width={25} height={25} />
							<Typography variant={"h3"} fontWeight={600} color={"#164c68"} sx={{ mr: `${isRtl ? "8px" : "0px"}` }}>
								{t("MeetingDetails")}
							</Typography>
						</Stack>
						<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1}>
							<Button
								disabled={loading}
								sx={{
									bgcolor: "#164c68",
									color: "#fff",
									"&:hover": {
										bgcolor: "#00a3b1",
									},
									transition: "all 1s ease",
									marginLeft: `${isRtl ? "8px" : "0px"} !important`,
								}}
								onClick={() => {
									dispatch(setIsEditingMeeting(true));
									dispatch(setIsCreatingMeeting(false));
									navigate("/dashboard/meeting");
								}}
							>
								{t("Update")}
							</Button>
							<Button
								onClick={() => setDeleteMeeting(true)}
								disabled={loading}
								sx={{
									color: "#fff",
									backgroundColor: "#fc6739",
									"&:hover": {
										color: "#fff",
										backgroundColor: "red",
									},
									transition: "all 1s ease",
								}}
							>
								{t("Delete")}
							</Button>
						</Stack>
					</Stack>
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<Stack justifyContent={"flex-start"} alignItems={"flex-start"} width={"100%"} spacing={5} height={"100%"}>
						<Stack direction={"row"} width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
							<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
								<img src={images.numbered} alt="code" width={20} height={20} />
								<Typography variant={"h5"} fontWeight={700} color={"#164c68"} sx={{ mx: "8px !important" }}>
									{t("MeetingCode")}:
								</Typography>
								<Typography variant={"h5"} fontWeight={500} color={"#164c68"}>
									{currentMeeting?.code}
								</Typography>
							</Stack>
							<Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={2} width={"100%"}>
								<img src={images.file} alt="code" width={20} height={20} />
								<Typography variant={"h5"} fontWeight={700} color={"#164c68"} sx={{ mx: "8px !important" }}>
									{t("MeetingType")}:
								</Typography>
								<Typography variant={"h5"} fontWeight={500} color={"#164c68"}>
									{currentMeeting?.type ? currentMeeting?.type : "N/A"}
								</Typography>
							</Stack>
							<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={2} width={"100%"}>
								<img src={images.place} alt="place" width={20} height={20} />
								<Typography variant={"h5"} fontWeight={700} color={"#164c68"} sx={{ mx: "8px !important" }}>
									{t("MeetingPlace")}:
								</Typography>
								<Typography variant={"h5"} fontWeight={500} color={"#164c68"}>
									{currentMeeting?.place ? currentMeeting?.place : "N/A"}
								</Typography>
							</Stack>
						</Stack>

						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
							<img src={images.case} alt="case" width={25} height={25} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
							<Typography Stage="h5" color={"#164c68"} fontWeight={700} sx={{ mb: 1, mr: `${isRtl ? "8px" : "0px"} !important` }}>
								{t("CaseFileFullName")}:
							</Typography>
							{currentMeeting.caseFile.classification === "litigation" ? (
								<Typography
									Stage={"h5"}
									color={"#164c68"}
									fontWeight={500}
									sx={{ mb: 1, mr: `${isRtl ? "8px" : "0px"} !important`, fontFamily: '"Montserrat", "sans-serif"' }}
								>
									{currentMeeting.caseFile.caseNumber} / {currentMeeting.caseFile.caseYear} -{" "}
									{isRtl
										? currentMeeting.caseFile.caseStage.nameAr
										: currentMeeting.caseFile.caseStage.nameEn
										? currentMeeting.caseFile.caseStage.nameEn
										: currentMeeting.caseFile.caseStage.nameAr}{" "}
									-{" "}
									{isRtl
										? currentMeeting.caseFile.caseType.nameAr
										: currentMeeting.caseFile.caseType.nameEn
										? currentMeeting.caseFile.caseType.nameEn
										: currentMeeting.caseFile.caseType.nameAr}
								</Typography>
							) : (
								<Typography Stage={"h5"} color={"#164c68"} fontWeight={500} sx={{ mb: 1, mr: `${isRtl ? "8px" : "0px"} !important` }}>
									N/A
								</Typography>
							)}
						</Stack>

						<Stack direction={"row"} width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
							<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
								<img src={images.calendar} alt="calendar" width={20} height={20} />
								<Typography variant={"h5"} fontWeight={700} color={"#164c68"} sx={{ mx: "8px !important" }}>
									{t("StartDate")}:
								</Typography>
								<Typography
									variant={"h5"}
									fontWeight={500}
									color={"#164c68"}
									sx={{
										fontFamily: '"Montserrat", "sans-serif"',
									}}
								>
									{moment(currentMeeting?.date).format("DD/MM/YYYY, h:mm:ss a")}
								</Typography>
							</Stack>
							<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={2} width={"100%"}>
								<img src={images.calendar} alt="calendar" width={20} height={20} />
								<Typography variant={"h5"} fontWeight={700} color={"#164c68"} sx={{ mx: "8px !important" }}>
									{t("EndDate")}:
								</Typography>
								<Typography
									variant={"h5"}
									fontWeight={500}
									color={"#164c68"}
									sx={{
										fontFamily: '"Montserrat", "sans-serif"',
									}}
								>
									{currentMeeting?.endDate ? moment(currentMeeting?.endDate).format("MMMM Do YYYY, h:mm:ss a") : "N/A"}
								</Typography>
							</Stack>
						</Stack>
						<Stack direction={"row"} width={"100%"} justifyContent={"flex-start"} alignItems={"center"}>
							<img src={images.website} alt="url" width={20} height={20} />
							<Typography variant={"h5"} fontWeight={700} color={"#164c68"} sx={{ mx: "8px !important" }}>
								{t("MeetingUrl")}:
							</Typography>
							{currentMeeting.url ? (
								<Link href={currentMeeting.url} target="_blank" rel="noopener noreferrer">
									{currentMeeting.url}
								</Link>
							) : (
								<Typography variant={"h5"} fontWeight={500} color={"#164c68"} sx={{ mx: "8px !important" }}>
									N/A
								</Typography>
							)}
						</Stack>
						<Accordion
							expanded={contactExpanded}
							onChange={handleContactExpansion}
							slots={{ transition: Fade }}
							slotProps={{ transition: { timeout: 1000 } }}
							sx={{
								width: "100%",
								bgcolor: "#fff",
								boxShadow: "none",
								"& .MuiAccordion-region": { height: contactExpanded ? "auto" : 0 },
								"& .MuiAccordionDetails-root": { display: contactExpanded ? "block" : "none" },
							}}
						>
							<AccordionSummary expandIcon={<IoCaretDownOutline size={20} color={"#164c68"} />} aria-controls="panel1-content" id="panel1-header">
								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2} width={"100%"}>
									<img src={images.contacts} alt="contacts" width={20} height={20} />
									<Typography variant={"h5"} fontWeight={700} color={"#164c68"} sx={{ mx: "8px !important" }}>
										{t("Contacts")}:
									</Typography>
								</Stack>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container spacing={2}>
									{currentMeeting?.contacts?.map((contact) => (
										<Grid item xs={12} sm={3} key={contact.id}>
											<Contact contact={contact} />
										</Grid>
									))}
								</Grid>
							</AccordionDetails>
						</Accordion>
						<Accordion
							expanded={usersExpanded}
							onChange={handleUsersExpansion}
							slots={{ transition: Fade }}
							slotProps={{ transition: { timeout: 1000 } }}
							sx={{
								width: "100%",
								bgcolor: "#fff",
								boxShadow: "none",
								"& .MuiAccordion-region": { height: usersExpanded ? "auto" : 0 },
								"& .MuiAccordionDetails-root": { display: usersExpanded ? "block" : "none" },
							}}
						>
							<AccordionSummary expandIcon={<IoCaretDownOutline size={20} color={"#164c68"} />} aria-controls="panel2-content" id="panel2-header">
								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2} width={"100%"}>
									<img src={images.team} alt="users" width={20} height={20} />
									<Typography variant={"h5"} fontWeight={700} color={"#164c68"} sx={{ mx: "8px !important" }}>
										{t("Users")}:
									</Typography>
								</Stack>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container spacing={2}>
									{currentMeeting?.users?.map((user) => (
										<Grid item xs={12} sm={3} key={user.id}>
											<UserCard user={user} />
										</Grid>
									))}
								</Grid>
							</AccordionDetails>
						</Accordion>
					</Stack>
				</Box>
				<FileSection criteria={"meeting"} criteriaId={currentMeeting?.id} />
			</Box>
			{deleteMeeting && <ConfirmDeleteDialog open={deleteMeeting} handleClose={() => setDeleteMeeting(false)} />}
		</Animate>
	);
};

export default MeetingDetails;
