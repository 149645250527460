import { apiSlice } from "../apiSlice";

export const emailMessageApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getEmails: builder.query({
			query: () => ({
				url: "/email/message",
				method: "GET",
			}),
			providesTags: ["Emails"],
		}),
		getEmailById: builder.query({
			query: (id) => ({
				url: `/email/message/${id}`,
				method: "GET",
			}),
		}),
		sendEmailMessage: builder.mutation({
			query: (data) => ({
				url: "/email/message",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["Emails"],
		}),
		deleteEmailMessage: builder.mutation({
			query: (id) => ({
				url: `/email/message/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Emails"],
		}),
	}),
});

export const { useGetEmailsQuery, useGetEmailByIdQuery, useSendEmailMessageMutation, useDeleteEmailMessageMutation } = emailMessageApi;
