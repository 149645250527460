import React, { useEffect, useState } from "react";
import Animate from "../../components/Common/Animate";
import { Box, Stack, Tab, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../components/Common/Breadcrumbs";
import { useGetAllMeetingsQuery } from "../../redux/slices/api/meetings/meetingsApi";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { CiCalendarDate, CiViewList } from "react-icons/ci";
import ListView from "../../components/Meetings/List/ListView";
import MyCalendar from "../../components/Common/Calendar";
import ConfirmDeleteDialog from "../../components/Meetings/Details/ConfirmDeleteDialog";
import { images } from "../../components/Assets";
import { useTranslation } from "react-i18next";

const Meetings = () => {
	const { t } = useTranslation();

	const [searchValue, setSearchValue] = useState("");
	const [isSearching, setIsSearching] = useState(false);
	const searchQuery = searchValue?.length > 2 && searchValue !== "" ? { search: searchValue } : {};

	const { data, error, isLoading } = useGetAllMeetingsQuery(searchQuery, { refetchOnMountOrArgChange: true });

	const [rows, setRows] = useState(data?.data || []);
	const [originalRows, setOriginalRows] = useState(data?.data || []);
	const [filteredRows, setFilteredRows] = useState([]);

	const [calendarData, setCalendarData] = useState([]);

	const [deleteMeeting, setDeleteMeeting] = useState(false);

	const [tabValue, setTabValue] = useState("list");

	useEffect(() => {
		if (error) return;
		if (data) {
			setRows(data?.data);
			setOriginalRows(data?.data);
			const formattedData = data?.data.map((meeting) => {
				return {
					...meeting,
					title: meeting.subject,
					start: new Date(meeting.date),
					end: meeting.endDate ? new Date(meeting.endDate) : new Date(meeting.date),
					allDay: false,
				};
			});
			setCalendarData(formattedData);
		}
	}, [data, tabValue, searchValue]);

	const handleSearchInput = (e) => {
		setSearchValue(e.target.value);
	};

	const clearSearch = () => {
		setSearchValue("");
		setIsSearching(false);
		setRows(originalRows);
	};

	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue);
	};

	return (
		<Animate type="fade" delay={0.5}>
			<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={2} px={6} pt={4}>
				<CustomBreadcrumbs section={t("Meetings")} />
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<img src={images.calendar} alt={"calendar"} width={40} height={40} />
					<Typography variant={"h2"} fontWeight={600} color={"#164c68"}>
						{t("MyMeetings")}
					</Typography>
				</Stack>
			</Stack>

			<Box
				width={"100%"}
				height={"max-content"}
				display={"flex"}
				justifyContent={"flex-start"}
				alignItems={"flex-start"}
				flexDirection={"column"}
				gap={2}
				sx={{ overflowY: "auto", overflowX: "hidden" }}
				px={6}
				py={3}
			>
				<TabContext value={tabValue}>
					<Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
						<TabList onChange={handleChangeTab} aria-label="lab API tabs example">
							<Tab
								icon={<CiViewList color={tabValue === "list" ? "#0d6666" : "#1f2021"} size={20} />}
								iconPosition="start"
								label={t("ListView")}
								value="list"
							/>
							<Tab
								icon={<CiCalendarDate color={tabValue === "calendar" ? "#0d6666" : "#1f2021"} size={20} />}
								iconPosition="start"
								label={t("CalendarView")}
								value="calendar"
							/>
						</TabList>
					</Box>
					<TabPanel value="list" sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
						<ListView
							rows={rows}
							filteredRows={filteredRows}
							searchValue={searchValue}
							handleSearchInput={handleSearchInput}
							isSearching={isSearching}
							clearSearch={clearSearch}
							isLoading={isLoading}
							isError={error}
							setDeleteMeeting={setDeleteMeeting}
						/>
					</TabPanel>
					<TabPanel value="calendar" sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
						<MyCalendar events={calendarData} />
					</TabPanel>
				</TabContext>
			</Box>
			{deleteMeeting && <ConfirmDeleteDialog open={deleteMeeting} handleClose={() => setDeleteMeeting(false)} />}
		</Animate>
	);
};

export default Meetings;
