import { Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { RxCaretRight } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { setActiveRoute } from "../../redux/slices/app";
import { useTranslation } from "react-i18next";

const CustomBreadcrumbs = ({ section }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	return (
		<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
			<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
				<Typography
					variant={"h5"}
					fontWeight={section !== "dashboard" ? 600 : 400}
					color={section === "dashboard" && "#164c68"}
					onClick={() => {
						if (section !== "dashboard") {
							dispatch(setActiveRoute("Dashboard"));
							navigate("/dashboard");
						} else {
							return;
						}
					}}
					sx={{ cursor: "pointer" }}
				>
					{t("Dashboard")}
				</Typography>
			</Stack>
			{section !== "dashboard" && <RxCaretRight size={20} />}
			{section !== "dashboard" && (
				<Typography variant={"h5"} fontWeight={400}>
					{section.charAt(0).toUpperCase() + section.slice(1)}
				</Typography>
			)}
		</Stack>
	);
};

export default CustomBreadcrumbs;
