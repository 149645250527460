import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import SectionHeader from "../../../Common/SectionHeader";
import { MdEmail } from "react-icons/md";
import CustomTextField from "../../../Common/CustomTextField";
import { Stack } from "@mui/material";
import Memo from "../../../Memo";
import { LoadingButton } from "@mui/lab";
import { IoIosSend } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSendEmailMessageMutation } from "../../../../redux/slices/api/email/emailMessageApi";
import { setSnackbar } from "../../../../redux/slices/app";
import { useNavigate } from "react-router-dom";
import { setIsUsingTemplate, setSelectedTemplate } from "../../../../redux/slices/email";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props) {
	return (
		<Draggable handle="#send-email-dialog" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}

const CreateEmail = ({ open, handleClose }) => {
	const { t } = useTranslation();

	const isRtl = useSelector((state) => state.app.isRtl);

	const isUsingTemplate = useSelector((state) => state.email.isUsingTemplate);
	const selectedTemplate = useSelector((state) => state.email.selectedTemplate);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const quillRef = useRef(null);
	const [to, setTo] = useState("");
	const [cc, setCc] = useState("");
	const [bcc, setBcc] = useState("");

	const [error, setError] = useState({
		to: false,
		cc: false,
		bcc: false,
	});

	const [errorText, setErrorText] = useState({
		to: "",
		cc: "",
		bcc: "",
	});

	const [isLoading, setIsLoading] = useState(false);

	const [subject, setSubject] = useState(isUsingTemplate && selectedTemplate ? selectedTemplate.subject : "");

	const [value, setValue] = useState(isUsingTemplate && selectedTemplate ? selectedTemplate.html : "");

	const [sendEmail] = useSendEmailMessageMutation();

	const handleSendEmail = async (e) => {
		e.preventDefault();
		setIsLoading(true);

		if (to === "") {
			setError({ ...error, to: true });
			setErrorText({ ...errorText, to: t("EmailToRequired") });
			setIsLoading(false);
			return;
		}

		if (!value || value === "") {
			dispatch(setSnackbar({ open: true, message: t("EmailBodyRequired"), severity: "error" }));
			setIsLoading(false);
			return;
		}

		const formattedTo = to.includes(",") ? to.split(",").map((email) => email.trim()) : to;
		const formattedCc = cc.includes(",") ? cc.split(",").map((email) => email.trim()) : cc;
		const formattedBcc = bcc.includes(",") ? bcc.split(",").map((email) => email.trim()) : bcc;

		let data = {
			destination: {
				to: formattedTo,
			},
			email: {
				html: value,
			},
		};

		if (subject && subject !== "") {
			data = {
				...data,
				email: {
					...data.email,
					subject: subject,
				},
			};
		}

		if (cc && cc !== "") {
			data = {
				...data,
				destination: {
					...data.destination,
					cc: formattedCc,
				},
			};
		}

		if (bcc && bcc !== "") {
			data = {
				...data,
				destination: {
					...data.destination,
					bcc: formattedBcc,
				},
			};
		}

		try {
			await sendEmail(data).unwrap();
			setIsLoading(false);
			dispatch(setSnackbar({ open: true, message: t("EmailSentSuccessfully"), severity: "success" }));
			dispatch(setIsUsingTemplate(false));
			dispatch(setSelectedTemplate(null));
			handleClose();
			return;
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setIsLoading(false);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullWidth
			TransitionComponent={Transition}
			PaperComponent={PaperComponent}
			maxWidth={"xl"}
			aria-describedby="send-email-dialog"
			sx={{ height: "100%" }}
		>
			<DialogTitle id="send-email-dialog" sx={{ pb: 2, borderBottom: "1px solid #aaaaaa6b", backgroundColor: "#fff", cursor: "move" }}>
				<SectionHeader icon={<MdEmail color="#164c68" size={18} />} title={t("NewEmail")} type={"dialog"} />
			</DialogTitle>
			<DialogContent sx={{ backgroundColor: "#fff" }}>
				<DialogContentText sx={{ p: 2 }}>
					<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={3} width={"100%"}>
						<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={1} width={"100%"}>
							<CustomTextField
								type={"email"}
								value={to}
								resetValue={() => setTo("")}
								name={"emailTo"}
								handleInputChange={(e) => setTo(e.target.value)}
								placeholder={"Eg: test@example.com, test@example.com "}
								error={error.to}
								errorText={errorText.to}
								required={true}
								multiline={true}
							/>
							<CustomTextField
								type={"email"}
								value={cc}
								resetValue={() => setCc("")}
								name={"emailCc"}
								handleInputChange={(e) => setCc(e.target.value)}
								placeholder={"Eg: test@example.com, test@example.com "}
								error={error.cc}
								errorText={errorText.cc}
								required={true}
								multiline={true}
							/>

							<CustomTextField
								type={"email"}
								value={bcc}
								resetValue={() => setBcc("")}
								name={"emailBcc"}
								handleInputChange={(e) => setBcc(e.target.value)}
								placeholder={"Eg: test@example.com, test@example.com "}
								error={error.bcc}
								errorText={errorText.bcc}
								required={true}
								multiline={true}
							/>

							<CustomTextField
								type={"text"}
								value={subject}
								resetValue={() => setSubject("")}
								name={"emailSubject"}
								handleInputChange={(e) => setSubject(e.target.value)}
								placeholder={"Subject"}
								error={""}
								errorText={""}
								multiline={true}
							/>

							<Memo
								ref={quillRef}
								value={value}
								handleChange={(content) => {
									setValue(content);
								}}
							/>
						</Stack>
					</Stack>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: "#fff" }}>
				<LoadingButton
					loading={isLoading}
					startIcon={!isRtl && <IoIosSend />}
					variant="contained"
					size="medium"
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
					}}
					onClick={handleSendEmail}
				>
					{t("SendEmail")}
				</LoadingButton>
				<Button
					onClick={handleClose}
					disabled={isLoading}
					sx={{
						color: "red",
						"&:hover": {
							color: "#fff",
							backgroundColor: "red",
						},
						transition: "all 1s ease",
					}}
				>
					{t("Cancel")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CreateEmail;
