import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const UserRoleCard = ({ role }) => {
	return (
		<Box
			display={"flex"}
			justifyContent={"center"}
			alignItems={"center"}
			p={1}
			gap={1}
			borderRadius={1.5}
			sx={{
				boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;",
				transition: "width 3s ease-out",
				width: "max-content", // Updated transition effect with a slower duration
			}}
		>
			<Typography variant={"h6"} fontWeight={600} color={"#595858"}>
				{role}
			</Typography>
		</Box>
	);
};

export default UserRoleCard;
