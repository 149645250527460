import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Animate from "../../components/Common/Animate";
import FirstSection from "../../components/Case/FirstSection";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CasePieChart from "../../components/Common/Charts/CasePieChart";
import SectionHeader from "../../components/Common/SectionHeader";
import { IoBriefcaseSharp } from "react-icons/io5";
import CustomBreadcrumbs from "../../components/Common/Breadcrumbs";
import CaseLineChart from "../../components/Common/Charts/LineChart";
import { ImStatsDots } from "react-icons/im";
import { images } from "../../components/Assets";
import { useGetCasesQuery } from "../../redux/slices/api/cases/caseApi";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Case = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const isLargeScreen = useMediaQuery(theme.breakpoints.down("xl"));
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	const isRtl = useSelector((state) => state.app.isRtl);

	const [caseData, setCaseData] = useState([]);
	const [totalRecords, setTotalRecords] = useState(0);

	const { data, isLoading, isError } = useGetCasesQuery({}, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (isError) return;

		if (data) {
			setTotalRecords(data?.totalRecords);
			setCaseData(data?.data);
		}
	}, [data]);

	return (
		<Animate type="fade" delay={0.5}>
			<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={2} px={6} pt={4}>
				<CustomBreadcrumbs section={t("Cases")} />
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<img src={images.onlineCase} alt={"onlineCase"} width={40} height={40} />
					<Typography variant={"h2"} fontWeight={600} color={"#164c68"}>
						{t("My")} {t("Cases")}
					</Typography>
				</Stack>
			</Stack>

			<Box
				width={"100%"}
				height={"max-content"}
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
				gap={4}
				sx={{ overflowY: "auto", overflowX: "hidden" }}
				px={6}
				py={3}
			>
				<Stack
					width={"100%"}
					height={"auto"}
					direction={isLargeScreen && isSmallScreen ? "column" : "row"}
					justifyContent={"center"}
					alignItems={"center"}
					spacing={2}
				>
					<Box
						width={isSmallScreen ? "100%" : "30%"}
						height={"350px"}
						px={2}
						py={4}
						borderRadius={2}
						bgcolor={"#fff"}
						sx={{
							boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;",
							marginLeft: `${isRtl && "24px"} !important`,
						}}
					>
						<SectionHeader icon={<IoBriefcaseSharp color="#164c68" size={18} />} title={t("CaseStatus")} />
						<Box height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
							{isLoading ? <CircularProgress /> : <CasePieChart />}
						</Box>
					</Box>
					<Box
						width={isSmallScreen ? "100%" : "70%"}
						height={"350px"}
						px={2}
						py={4}
						borderRadius={2}
						bgcolor={"#fff"}
						sx={{
							boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;",
							marginLeft: `${isRtl && 0} !important`,
						}}
					>
						<SectionHeader icon={<ImStatsDots color="#164c68" size={18} />} title={t("CaseStatistics")} />
						<Box height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"} width={"100%"}>
							{isLoading ? <CircularProgress /> : <CaseLineChart page={"dashboard"} cases={caseData} isSmallScreen={isSmallScreen} />}
						</Box>
					</Box>
				</Stack>
				<FirstSection caseData={totalRecords} />
			</Box>
		</Animate>
	);
};

export default Case;
