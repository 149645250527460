import React, { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import { useGetCasesQuery } from "../../../redux/slices/api/cases/caseApi";

const CasePieChart = () => {
	const [caseData, setCaseData] = useState([{}]);

	const ongoingCases = useGetCasesQuery({ status: "ongoing", data: false }, { refetchOnMountOrArgChange: true });
	const closedCases = useGetCasesQuery({ status: "closed", data: false }, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (ongoingCases.isError || closedCases.isError) {
			return;
			// dispatch(setSnackbar({ open: true, message: "Error! Please try again later", severity: "error" }));
		}

		if (ongoingCases && closedCases) {
			setCaseData([
				{ id: "Ongoing", label: "Ongoing", value: ongoingCases?.data?.totalRecords, color: "#edaf41" },
				{ id: "Closed", label: "Closed", value: closedCases?.data?.totalRecords, color: "#74c193" },
			]);
		}
	}, [ongoingCases, closedCases]);

	return (
		<ResponsivePie
			data={caseData}
			margin={{ top: 20, right: 80, bottom: 40, left: 80 }}
			innerRadius={0.5}
			colors={({ data }) => data.color}
			padAngle={0.7}
			cornerRadius={3}
			activeOuterRadiusOffset={8}
			borderWidth={1}
			borderColor={{
				from: "color",
				modifiers: [["darker", 0.2]],
			}}
			arcLinkLabelsSkipAngle={10}
			arcLinkLabelsTextColor="#333333"
			arcLinkLabelsThickness={2}
			arcLinkLabelsColor={{ from: "color" }}
			arcLabelsSkipAngle={10}
			arcLabelsTextColor={{
				from: "color",
				modifiers: [["darker", 2]],
			}}
			enableArcLabels={true}
		/>
	);
};

export default CasePieChart;
