import React, { useEffect, useState } from "react";
import Animate from "../../components/Common/Animate";
import { Box, Stack, Tab, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../components/Common/Breadcrumbs";
import { images } from "../../components/Assets";
import { useTranslation } from "react-i18next";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { FaBriefcase, FaCalendarAlt, FaTasks } from "react-icons/fa";
import { GiGavel } from "react-icons/gi";
import { CiMemoPad } from "react-icons/ci";
import { FaMoneyBill1Wave } from "react-icons/fa6";
import CasesReportSection from "../../components/Reports/cases";
import HearingReportSection from "../../components/Reports/hearing";
import MemoReportSection from "../../components/Reports/memo";
import TaskReportSection from "../../components/Reports/task";
import FeeReportSection from "../../components/Reports/fee";
import MeetingsReportSection from "../../components/Reports/meeting";
import { useDispatch } from "react-redux";
import { setCurrentCase, setCurrentHearing } from "../../redux/slices/case";
import { setSelectedTask } from "../../redux/slices/task";
import { setCurrentMeeting } from "../../redux/slices/meetings";

const Reports = () => {
	const { t } = useTranslation();
	const [tabValue, setTabValue] = useState("cases");

	const dispatch = useDispatch();

	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue);
	};

	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		if (!isMounted) {
			setIsMounted(true);
		} else {
			dispatch(setCurrentCase(null));
			dispatch(setSelectedTask(null));
			dispatch(setCurrentHearing(null));
			dispatch(setCurrentMeeting(null));
		}
	}, [isMounted]);
	return (
		<Animate type="fade" delay={0.5}>
			<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={2} px={6} pt={4}>
				<CustomBreadcrumbs section={t("Reports")} />
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<img src={images.reports} alt={"reports"} width={40} height={40} />
					<Typography variant={"h2"} fontWeight={600} color={"#164c68"}>
						{t("My")} {t("Reports")}
					</Typography>
				</Stack>
			</Stack>

			<Box
				width={"100%"}
				height={"100%"}
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
				gap={4}
				sx={{ overflowY: "auto", overflowX: "hidden" }}
				px={6}
				py={1}
			>
				<TabContext value={tabValue}>
					<Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
						<TabList onChange={handleChangeTab} aria-label="lab API tabs example">
							<Tab
								icon={<FaBriefcase color={tabValue === "cases" ? "#0d6666" : "#1f2021"} size={20} />}
								iconPosition="start"
								label={t("Cases")}
								value="cases"
							/>
							<Tab
								icon={<GiGavel color={tabValue === "hearing" ? "#0d6666" : "#1f2021"} size={20} />}
								iconPosition="start"
								label={t("Hearings")}
								value="hearing"
							/>
							<Tab
								icon={<CiMemoPad color={tabValue === "memo" ? "#0d6666" : "#1f2021"} size={20} />}
								iconPosition="start"
								label={t("Memos")}
								value="memo"
							/>

							<Tab
								icon={<FaTasks color={tabValue === "task" ? "#0d6666" : "#1f2021"} size={20} />}
								iconPosition="start"
								label={t("Tasks")}
								value="task"
							/>
							<Tab
								icon={<FaCalendarAlt color={tabValue === "meeting" ? "#0d6666" : "#1f2021"} size={20} />}
								iconPosition="start"
								label={t("Meetings")}
								value="meeting"
							/>
							<Tab
								icon={<FaMoneyBill1Wave color={tabValue === "fee" ? "#0d6666" : "#1f2021"} size={20} />}
								iconPosition="start"
								label={t("Fees")}
								value="fee"
							/>
						</TabList>
					</Box>
					<TabPanel
						value="cases"
						sx={{ width: "100%", display: tabValue === "cases" ? "flex" : "none", justifyContent: "center", alignItems: "center" }}
					>
						<CasesReportSection />
					</TabPanel>
					<TabPanel
						value="hearing"
						sx={{ width: "100%", display: tabValue === "hearing" ? "flex" : "none", justifyContent: "center", alignItems: "center" }}
					>
						<HearingReportSection />
					</TabPanel>
					<TabPanel
						value="memo"
						sx={{ width: "100%", display: tabValue === "memo" ? "flex" : "none", justifyContent: "center", alignItems: "center" }}
					>
						<MemoReportSection />
					</TabPanel>
					<TabPanel
						value="task"
						sx={{ width: "100%", display: tabValue === "task" ? "flex" : "none", justifyContent: "center", alignItems: "center" }}
					>
						<TaskReportSection />
					</TabPanel>
					<TabPanel
						value="meeting"
						sx={{ width: "100%", display: tabValue === "meeting" ? "flex" : "none", justifyContent: "center", alignItems: "center" }}
					>
						<MeetingsReportSection />
					</TabPanel>
					<TabPanel value="fee" sx={{ width: "100%", display: tabValue === "fee" ? "flex" : "none", justifyContent: "center", alignItems: "center" }}>
						<FeeReportSection />
					</TabPanel>
				</TabContext>
			</Box>
		</Animate>
	);
};

export default Reports;
