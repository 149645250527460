import React, { useEffect, useState } from "react";
import Animate from "../../Common/Animate";
import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomBreadcrumbs from "../../Common/Breadcrumbs";
import SearchSection from "./SearchSection";
import { useGetAllPermissionsQuery } from "../../../redux/slices/api/permission/permissionApi";
import { images } from "../../Assets";
import PermissionGroupList from "./PermissionGroupList";
import AddPermissionGroup from "./AddPermissionGroup";
import PermissionsMenu from "../../Common/Menu/PermissionsMenu";
import EditPermission from "./EditPermission";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const PermissionSettings = () => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const { data, isLoading, isError } = useGetAllPermissionsQuery({}, { refetchOnMountOrArgChange: true });
	const [categoryValue, setCategoryValue] = useState("");
	const [rows, setRows] = useState(data?.data);
	const [originalRows, setOriginalRows] = useState(data?.data);
	const [filteredRows, setFilteredRows] = useState([]);
	const [searchValue, setSearchValue] = useState("");
	const [isSearching, setIsSearching] = useState(false);
	const [openAddPermission, setOpenAddPermission] = useState(false);
	const [selectedPermission, setSelectedPermission] = useState(null);
	const [editPermission, setEditPermission] = useState(false);

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		if (data && !isError && !isLoading) {
			setRows(data?.data);
			setOriginalRows(data?.data);
		}
	}, [data]);

	useEffect(() => {
		if (searchValue?.length > 0 && searchValue !== "") {
			setIsSearching(true);
			const filtered = rows.filter((row) => {
				return Object.values(row).some((value) => value.toString().toLowerCase().includes(searchValue.toLowerCase()));
			});
			setFilteredRows(filtered);
		} else {
			setIsSearching(false);
			setRows(originalRows);
		}
	}, [searchValue]);

	const handleSearchInput = (e) => {
		setSearchValue(e.target.value);
		if (e.target.value === "") {
			setIsSearching(false);
		}
	};

	const clearSearch = () => {
		setSearchValue("");
		setIsSearching(false);
		setRows(originalRows);
	};

	return (
		<Animate type="fade" delay={0.5}>
			<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={2} px={6} py={4}>
				<CustomBreadcrumbs section={t("UserSettings")} />
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<img src={images.permission} alt={"permission"} width={40} height={40} />
					<Typography variant={"h2"} fontWeight={600} color={"#164c68"}>
						{t("PermissionSettings")}
					</Typography>
				</Stack>
			</Stack>
			{openAddPermission ? (
				<AddPermissionGroup isSmallScreen={isSmallScreen} setOpenAddPermission={setOpenAddPermission} />
			) : editPermission ? (
				<EditPermission isSmallScreen={isSmallScreen} handleClose={() => setEditPermission(false)} selected={selectedPermission} />
			) : (
				<Box width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"} gap={4} px={6}>
					<SearchSection
						isSmallScreen={isSmallScreen}
						searchValue={searchValue}
						handleSearchInput={handleSearchInput}
						clearSearch={clearSearch}
						isSearching={isSearching}
						categoryValue={categoryValue}
						setCategoryValue={setCategoryValue}
						setOpenAddPermission={setOpenAddPermission}
						data={rows}
					/>

					<PermissionGroupList
						isSmallScreen={isSmallScreen}
						data={rows}
						filteredRows={filteredRows}
						isSearching={isSearching}
						isLoading={isLoading}
						isError={isError}
						image={images.notFound}
						open={open}
						setAnchorEl={setAnchorEl}
						setSelectedPermission={setSelectedPermission}
					/>
				</Box>
			)}
			{open && (
				<PermissionsMenu
					open={open}
					anchorEl={anchorEl}
					setAnchorEl={setAnchorEl}
					handleClose={handleClose}
					permission={selectedPermission}
					setEditPermission={setEditPermission}
				/>
			)}
		</Animate>
	);
};

export default PermissionSettings;
