import { FormControl, MenuItem, Select, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomTextField from "../../Common/CustomTextField";
import CustomDatePicker from "../../Common/CustomDatePicker";
import ClassificationSelection from "./ClassificationSelection";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ClassificationDataSelection from "./ClassificationDataSelection";

const TaskInformation = ({
	isSmallScreen,
	taskDescription,
	setTaskDescription,
	taskPriority,
	setTaskPriority,
	taskStatus,
	setTaskStatus,
	taskDueDate,
	setTaskDueDate,
	statusNote,
	setStatusNote,
	setClassificationData,
	classificationData,
	classification,
	caseFile,
	setCaseFile,
}) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);
	const currentCase = useSelector((state) => state.case.currentCase);

	const [invalidDate, setInvalidDate] = useState(false);

	const [errorText, setErrorText] = useState("");
	return (
		<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={4} p={1}>
			<Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-start"} spacing={2} width={"100%"}>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
					<Typography variant="body1" color={"#164c68"} fontWeight={500} sx={{ mb: 1, ml: `${isRtl ? "8px" : "0px"} !important` }}>
						{t("Status")}:
					</Typography>
					<FormControl sx={{ m: 1, minWidth: 100 }} size="small">
						<Select id="select-classification" value={taskStatus} onChange={(e) => setTaskStatus(e.target.value)} sx={{ color: "#164c68" }}>
							<MenuItem value={"New"}>{t("New")}</MenuItem>
							<MenuItem value={"In Progress"}> {t("InProgress")}</MenuItem>
							<MenuItem value={"Completed"}>{t("Completed")}</MenuItem>
							<MenuItem value={"Canceled"}>{t("Canceled")}</MenuItem>
						</Select>
					</FormControl>
				</Stack>

				{classification !== "caseFile" && (
					<Stack direction={"row"} justifyContent={"flex-center"} alignItems={"center"} spacing={1} width={"100%"}>
						<Typography variant="body1" color={"#164c68"} fontWeight={500} sx={{ mb: 1, ml: `${isRtl ? "8px" : "0px"} !important` }}>
							{t("Priority")}:
						</Typography>
						<FormControl sx={{ m: 1, minWidth: 100 }} size="small">
							<Select id="select-classification" value={taskPriority} onChange={(e) => setTaskPriority(e.target.value)} sx={{ color: "#164c68" }}>
								<MenuItem value={"Low"}>{t("Low")}</MenuItem>
								<MenuItem value={"Medium"}>{t("Medium")}</MenuItem>
								<MenuItem value={"High"}>{t("High")}</MenuItem>
							</Select>
						</FormControl>
					</Stack>
				)}

				<Stack justifyContent={"flex-end"} alignItems={"center"} spacing={1} width={"100%"}>
					{!currentCase && classification !== "none" && (
						<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1} width={"100%"}>
							<Typography
								variant="body1"
								color={"#164c68"}
								fontWeight={500}
								sx={{ mb: 1, textTransform: "capitalize", ml: `${isRtl ? "8px" : "0px"} !important` }}
							>
								{t("Select")} {t("CaseFile")}:
							</Typography>
							<ClassificationSelection
								classification={classification}
								classificationData={classificationData}
								setClassificationData={setClassificationData}
								caseFileData={caseFile}
								setCaseFile={setCaseFile}
							/>
						</Stack>
					)}

					{classification !== "caseFile" && (
						<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1} width={"100%"}>
							<Typography
								variant="body1"
								color={"#164c68"}
								fontWeight={500}
								sx={{ mb: 1, textTransform: "capitalize", ml: `${isRtl ? "8px" : "0px"} !important` }}
							>
								{t("Select")} {t(classification)}:
							</Typography>
							<ClassificationDataSelection
								classification={classification}
								classificationData={classificationData}
								setClassificationData={setClassificationData}
								caseFileData={caseFile}
							/>
						</Stack>
					)}
				</Stack>
				{classification === "caseFile" && (
					<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1} width={"100%"}>
						<Typography variant="body1" color={"#164c68"} fontWeight={500} sx={{ mb: 1, ml: `${isRtl ? "8px" : "0px"} !important` }}>
							{t("Priority")}:
						</Typography>
						<FormControl sx={{ m: 1, minWidth: 100 }} size="small">
							<Select id="select-classification" value={taskPriority} onChange={(e) => setTaskPriority(e.target.value)} sx={{ color: "#164c68" }}>
								<MenuItem value={"Low"}>{t("Low")}</MenuItem>
								<MenuItem value={"Medium"}>{t("Medium")}</MenuItem>
								<MenuItem value={"High"}>{t("High")}</MenuItem>
							</Select>
						</FormControl>
					</Stack>
				)}
			</Stack>

			<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
				<Stack justifyContent={"flex-start"} alignItems={"flex-start"} spacing={2} width={isSmallScreen ? "100%" : "50%"}>
					<CustomTextField
						type={"text"}
						label={t("TaskNote")}
						value={statusNote}
						resetValue={() => setStatusNote("")}
						name={"taskNote"}
						handleInputChange={(e) => {
							setStatusNote(e.target.value);
						}}
						required={true}
						multiline={true}
					/>
				</Stack>
				<Stack justifyContent={"flex-end"} alignItems={"flex-start"} spacing={2} width={isSmallScreen ? "100%" : "50%"} sx={{ ml: "8px !important" }}>
					<CustomTextField
						type={"text"}
						label={t("TaskDescription")}
						value={taskDescription}
						resetValue={() => setTaskDescription("")}
						name={"taskDescription"}
						handleInputChange={(e) => {
							setTaskDescription(e.target.value);
						}}
						required={true}
						multiline={true}
					/>
				</Stack>
			</Stack>

			<Stack justifyContent={"flex-start"} alignItems={"start"} width={isSmallScreen ? "100%" : "50%"} spacing={1}>
				{/* <Typography variant={"h5"} fontWeight={500} color={"#164c68"}>
					{t("DueDate")}: {taskDueDate.format("MMMM Do YYYY")}
				</Typography> */}
				<CustomDatePicker title={t("Due")} setInvalidDate={setInvalidDate} setErrorText={setErrorText} value={taskDueDate} setDate={setTaskDueDate} />
				{invalidDate && (
					<Typography variant={"title1"} color={"red"} fontWeight={500}>
						{errorText}
					</Typography>
				)}
			</Stack>
		</Stack>
	);
};

export default TaskInformation;
