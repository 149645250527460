import React from "react";
import Animate from "../../Common/Animate";
import { Box, Stack, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../Common/Breadcrumbs";
import MyProfileSection from "./MyProfileSection";
import SecuritySection from "./SecuritySection";
import { images } from "../../Assets";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const MyAccountSettings = () => {
	const { t } = useTranslation();

	const userDetails = useSelector((state) => state.app.userDetails);
	return (
		<Animate type="fade" delay={0.5}>
			<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={2} px={6} py={4}>
				<CustomBreadcrumbs section={t("MyAccount")} />
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<img src={images.accountSettings} alt={"accountSettings"} width={35} height={35} />
					<Typography variant={"h2"} fontWeight={600} color={"#164c68"}>
						{t("AccountSettings")}
					</Typography>
				</Stack>
			</Stack>
			<Box
				width={"100%"}
				height={"max-content"}
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
				gap={4}
				sx={{ overflowY: "auto", overflowX: "hidden" }}
				p={4}
				mb={2}
			>
				<MyProfileSection />
				{userDetails.role !== "Owner" && <SecuritySection />}
			</Box>
		</Animate>
	);
};

export default MyAccountSettings;
