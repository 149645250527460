import { Box, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import CustomTextField from "./CustomTextField";
import { IconButton } from "@mui/material";
import { FaCheck, FaTimes } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { VscVerifiedFilled } from "react-icons/vsc";
import { PiSealWarningFill } from "react-icons/pi";
import { useUpdateMineMutation } from "../../redux/slices/api/user/userApi";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar, setUserDetails } from "../../redux/slices/app";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../utils/axios";

const EditableTextField = ({
	label,
	value,
	setValue,
	name,
	isEditing,
	setIsEditing,
	prevValue,
	showPassword,
	setShowPassword,
	setSubmittingPassword,
	prevData,
	setPrevData,
	verified,
	section,
	mode,
	setOpenVerification,
	setVerificationMethod,

}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const isRtl = useSelector((state) => state.app.isRtl);

	const [update] = useUpdateMineMutation();

	const handleUpdate = async () => {
		if (section === "account") {
			try {
				await update({ [name]: value }).unwrap();
				const updatedData = await axiosInstance.get("/user/mine");
				dispatch(setUserDetails(updatedData.data.data));
				if(name === "email" || name === "phone"){
					dispatch(setSnackbar({ open: true, message: `${t("VerificationCodeSent")}.`, severity: "info" }));
					setVerificationMethod();
					setOpenVerification();
				}
				dispatch(setSnackbar({ open: true, message: `${name} ${t("UpdatedSuccessfully")}.`, severity: "success" }));
			} catch (error) {
				// dispatch(setSnackbar({ open: true, message: isRtl ? error.data.ar : error.data.en, severity: "error" }));
				console.log(error);
			}
		} else if (section === "user") {
			setPrevData({ ...prevData, [name]: value });
			// handle user update
			setIsEditing(false);
		}
	};
	return (
		<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} mb={2} width={"100%"}>
			{isEditing ? (
				<Stack width={"100%"} direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<CustomTextField
						type={name === "password" ? "password" : "text"}
						label={label}
						value={value}
						name={name}
						handleInputChange={(e) => {
							setValue(e.target.value);
						}}
						showPassword={showPassword}
						setShowPassword={setShowPassword}
						required={true}
					/>
					{prevValue !== value && (
						<IconButton
							// disabled={prevValue === value}
							disableRipple
							onClick={() => {
								if (name === "password") {
									setSubmittingPassword(true);
									setShowPassword(false);
									return;
								} else if (name === "firstName" || name === "lastName" || name === "phone" || name === "email") {
									handleUpdate();
								}

								setIsEditing(false);
							}}
						>
							<FaCheck color={"#2abe5d"} size={22} />
						</IconButton>
					)}
					<IconButton
						disableRipple
						onClick={() => {
							if (name === "password") {
								setSubmittingPassword(false);
								setShowPassword(false);
							}
							setValue(prevValue);
							setIsEditing(false);
						}}
					>
						<FaTimes color="#164c68" size={22} />
					</IconButton>
				</Stack>
			) : (
				<Stack width={"100%"} direction={"row"} justifyContent={"flex-start"} alignItems={"end"}>
					<Stack width={"100%"} justifyContent={"flex-start"} alignItems={"start"} spacing={1}>
						<Typography variant={"h5"} fontWeight={500} color={"#164c68"} sx={{ mb: 3 }}>
							{label}:
						</Typography>
						<Box
							display={"flex"}
							justifyContent={name === "password" || name === "phone" || name === "email" ? "space-between" : "flex-start"}
							alignItems={"center"}
							p={1}
							borderRadius={1}
							border={"1px solid #164c68"}
							width={"100%"}
						>
							<Typography variant="h6" fontWeight={600} color={"#59585896"}>
								{name === "password" && !showPassword ? "*************" : name === "password" && showPassword ? value : value}
							</Typography>
							{(name === "phone" || name === "email") && section === "account" ? (
								<Tooltip title={verified ? t("Verified") : `${t("ClickToVerify")} ${name} ${t("Now")}.`} arrow placement={"top"}>
									<IconButton
										sx={{ p: 0 }}
										onClick={() => {
											if (verified) return;
										}}
									>
										{verified ? <VscVerifiedFilled color="#2098e5" /> : <PiSealWarningFill color="#f54d64" />}
									</IconButton>
								</Tooltip>
							) : null}
						</Box>
					</Stack>
					{mode !== "view" && (
						<Tooltip title={"Update"} arrow placement={"top"}>
							<IconButton
								disableRipple
								onClick={() => {
									setIsEditing(true);
								}}
							>
								<CiEdit color="#164c68" size={22} />
							</IconButton>
						</Tooltip>
					)}
				</Stack>
			)}
		</Stack>
	);
};

export default EditableTextField;
