import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	currentContact: null,
	isEditingContact: false,
	isAddingContact: false,
	isViewingContact: false,
};

export const contactSlice = createSlice({
	name: "contact",
	initialState,
	reducers: {
		setCurrentContact: (state, action) => {
			state.currentContact = action.payload;
		},
		setIsEditingContact: (state, action) => {
			state.isEditingContact = action.payload;
		},
		setIsAddingContact: (state, action) => {
			state.isAddingContact = action.payload;
		},
		setIsViewingContact: (state, action) => {
			state.isViewingContact = action.payload;
		},
	},
});

export const { setCurrentContact, setIsEditingContact, setIsAddingContact, setIsViewingContact } = contactSlice.actions;

export default contactSlice.reducer;
