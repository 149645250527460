import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Animate from "../../components/Common/Animate";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../components/Common/Breadcrumbs";
import TaskSection from "../../components/Tasks/TaskSection";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsCreatingTask, setIsEditingTask } from "../../redux/slices/task";
import { useGetTasksQuery } from "../../redux/slices/api/tasks/tasksApi";
import { images } from "../../components/Assets";
import { DndContext, PointerSensor, closestCorners, useSensor, useSensors } from "@dnd-kit/core";
import DroppableSection from "../../components/Tasks/DroppableSection";
import UpdateTaskDialog from "../../components/Tasks/TaskForm/UpdateTaskDialog";
import { useTranslation } from "react-i18next";
import { MdOutlineAdd } from "react-icons/md";
import CustomBackdrop from "../../components/Common/CustomBackdrop";

const Tasks = () => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

	const [rows, setRows] = useState([]);
	const [newStatus, setNewStatus] = useState(null);

	const [currentTask, setCurrentTask] = useState(null);

	const [loading, setLoading] = useState(false);

	const { data, isLoading, isError } = useGetTasksQuery({}, { refetchOnMountOrArgChange: true });

	const [isDragging, setIsDragging] = useState(false);
	useEffect(() => {
		if (isError) return;

		if (data) {
			setRows(data?.data);
		}
	}, [data]);

	const sensors = useSensors(useSensor(PointerSensor));

	const handleDragEnd = async (event) => {
		const { active, over } = event;

		const currentTask = active?.data?.current?.task;
		const prevStatus = currentTask?.status;
		const newStatus = over?.data?.current?.status.name;

		if (prevStatus === newStatus) {
			setIsDragging(false);
			return;
		}
		setCurrentTask(currentTask);
		setNewStatus(newStatus);
		setIsDragging(true);
	};

	const taskStatuses = [
		{
			id: 1,
			name: "New",
			imgSrc: images.newTask,
		},
		{
			id: 2,
			name: "In Progress",
			imgSrc: images.inProgress,
		},
		{
			id: 3,
			name: "Completed",
			imgSrc: images.complete,
		},

		{
			id: 4,
			name: "Canceled",
			imgSrc: images.cancelled,
		},
	];

	return (
		<Animate type="fade" delay={0.5}>
			<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={2} px={6} pt={4}>
				<CustomBreadcrumbs section={t("Tasks")} />
				<Stack direction={"row"} width={"100%"} justifyContent={"space-between"} alignItems={"start"}>
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
						<img src={images.task} alt={"task"} width={35} height={35} />
						<Typography variant={"h2"} fontWeight={600} color={"#164c68"}>
							{t("TasksManagement")}
						</Typography>
					</Stack>
					<Button
						startIcon={!isRtl && <MdOutlineAdd color="#fff" />}
						onClick={() => {
							dispatch(setIsCreatingTask(true));
							dispatch(setIsEditingTask(false));
							navigate("/dashboard/task");
						}}
						sx={{
							bgcolor: "#164c68",
							color: "#fff",
							padding: 1.5,
							"&:hover": {
								bgcolor: "#00a3b1",
							},
							transition: "all 0.5s ease",
						}}
					>
						{t("CreateNew")}
					</Button>
				</Stack>
			</Stack>

			<Box
				width={"100%"}
				height={"max-content"}
				display={"flex"}
				justifyContent={"center"}
				alignItems={"start"}
				flexDirection={isSmallScreen ? "column" : "row"}
				gap={1}
				sx={{ overflowY: "auto", overflowX: "hidden", px: "260px" }}
				py={3}
			>
				{rows?.length > 0 ? (
					<DndContext sensors={sensors} collisionDetection={closestCorners} onDragEnd={handleDragEnd}>
						{taskStatuses.map((status) => {
							// Use the useDroppable hook in your TaskSection component
							return (
								<DroppableSection key={status.id} status={status}>
									<TaskSection
										isSmallScreen={isSmallScreen}
										category={status.name}
										imgSrc={status.imgSrc}
										rows={rows}
										isLoading={isLoading}
										isError={isError}
										isDragging={isDragging}
									/>
								</DroppableSection>
							);
						})}
					</DndContext>
				) : rows.length < 1 && isLoading ? (
					<CircularProgress />
				) : rows.length < 1 && !isLoading ? (
					<Box
						width={"100%"}
						height={"100%"}
						display={"flex"}
						flexDirection={"column"}
						justifyContent={"center"}
						alignItems={"center"}
						m={"0 auto"}
						p={2}
					>
						<img src={images.notFound} alt="Not Found" width={200} height={200} />
						<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ my: 2 }}>
							{t("NoTaskAvailableList")}!
						</Typography>
						<Typography variant={"subtitle1"} fontWeight={500} color={"#51505099"}>
							{t("AddTask")}.
						</Typography>
					</Box>
				) : null}
			</Box>
			{isDragging && (
				<UpdateTaskDialog
					open={isDragging}
					handleClose={() => setIsDragging(false)}
					newStatus={newStatus}
					currentTask={currentTask}
					loading={loading}
					setLoading={setLoading}
				/>
			)}
			{loading && <CustomBackdrop open={loading} />}
		</Animate>
	);
};

export default Tasks;
