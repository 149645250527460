import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import SectionHeader from "../Common/SectionHeader";
import { BiSolidContact } from "react-icons/bi";
import { Stack, Typography } from "@mui/material";
import EditableTextField from "../Common/EditableTextField";
import UserRoleCard from "../Settings/Users/UserRoleCard";
import { useDeleteContactMutation, useUpdateContactMutation } from "../../redux/slices/api/contacts/contactApi";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../redux/slices/app";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ContactDialog = ({ open, handleClose, mode, contact }) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const [nameAr, setNameAr] = useState(contact.nameAr);
	const [nameEn, setNameEn] = useState(contact.nameEn);
	const [email, setEmail] = useState(contact.email);
	const [phone, setPhone] = useState(contact.phone);
	const [address, setAddress] = useState(contact.address);
	const [category, setCategory] = useState(contact.category);

	const [isEditingArName, setIsEditingArName] = useState(false);
	const [isEditingEnName, setIsEditingEnName] = useState(false);
	const [isEditingEmail, setIsEditingEmail] = useState(false);
	const [isEditingPhone, setIsEditingPhone] = useState(false);
	const [isEditingAddress, setIsEditingAddress] = useState(false);

	const [editContact] = useUpdateContactMutation();
	const [deleteContact] = useDeleteContactMutation();

	const isRtl = useSelector((state) => state.app.isRtl);

	const handleEditContact = async (e) => {
		e.preventDefault();
		try {
			const data = { nameAr: nameAr, nameEn: nameEn, email: email, phone: phone, address: address, category: category };
			await editContact({ contactId: contact.id, data: data });
			dispatch(setSnackbar({ open: true, message: t("ContactUpdatedSuccessfully"), severity: "success" }));
			handleClose();
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
		}
	};

	const handleDeleteContact = async (e) => {
		e.preventDefault();
		try {
			await deleteContact(contact.id);
			dispatch(setSnackbar({ open: true, message: t("ContactDeletedSuccessfully"), severity: "success" }));
			handleClose();
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
		}
	};

	return (
		<Dialog
			open={open}
			fullWidth
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			aria-describedby="contact-dialog"
			sx={{ height: "100%" }}
		>
			<DialogTitle sx={{ pb: 2, borderBottom: "1px solid #aaaaaa6b", backgroundColor: "#fff" }}>
				<SectionHeader icon={<BiSolidContact color="#164c68" size={18} />} title={`Contact: ${contact.nameEn} `} type={"dialog"} />
			</DialogTitle>
			<DialogContent sx={{ backgroundColor: "#fff" }}>
				<DialogContentText id="add-user-dialog-description" sx={{ p: 4 }}>
					<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={2} width={"100%"} mb={2}>
						<EditableTextField
							label={t("ArabicName")}
							value={nameAr}
							setValue={setNameAr}
							name={"nameAr"}
							isEditing={isEditingArName}
							setIsEditing={setIsEditingArName}
							prevValue={contact.nameAr}
							section={"contact"}
							mode={mode}
						/>
						<EditableTextField
							label={t("EnglishName")}
							value={nameEn}
							setValue={setNameEn}
							name={"nameEn"}
							isEditing={isEditingEnName}
							setIsEditing={setIsEditingEnName}
							prevValue={contact.nameEn}
							section={"contact"}
							mode={mode}
						/>
						<EditableTextField
							label={t("PhoneNumber")}
							value={phone}
							setValue={setPhone}
							name={"phone"}
							isEditing={isEditingPhone}
							setIsEditing={setIsEditingPhone}
							prevValue={contact.phone}
							section={"contact"}
							mode={mode}
						/>
						<EditableTextField
							label={t("EmailAddress")}
							value={email}
							setValue={setEmail}
							name={"email"}
							isEditing={isEditingEmail}
							setIsEditing={setIsEditingEmail}
							prevValue={contact.email}
							section={"contact"}
							mode={mode}
						/>
						<EditableTextField
							label={t("Address")}
							value={address}
							setValue={setAddress}
							name={"address"}
							isEditing={isEditingAddress}
							setIsEditing={setIsEditingAddress}
							prevValue={contact.address}
							section={"contact"}
							mode={mode}
						/>
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2} width={"100%"} mb={1}>
							<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={2}>
								<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ mb: 3 }}>
									{t("Category")}:
								</Typography>
								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"} mb={1}>
									<UserRoleCard role={contact.category} />
								</Stack>
							</Stack>
							<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={2}>
								<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ mb: 3 }}>
									{t("Type")}:
								</Typography>
								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"} mb={1}>
									<UserRoleCard role={contact.type} />
								</Stack>
							</Stack>
						</Stack>
					</Stack>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: "#fff" }}>
				{mode !== "view" && (
					<Button
						onClick={handleDeleteContact}
						sx={{
							color: "red",
							"&:hover": {
								color: "#fff",
								backgroundColor: "red",
							},
							transition: "all 1s ease",
						}}
					>
						{t("Delete")}
					</Button>
				)}
				<Button
					onClick={handleClose}
					sx={{
						color: "red",
						"&:hover": {
							color: "#fff",
							backgroundColor: "red",
						},
						transition: "all 1s ease",
					}}
				>
					{mode === "view" ? t("Close") : t("Cancel")}
				</Button>
				{mode !== "view" && (
					<Button
						onClick={handleEditContact}
						sx={{
							bgcolor: "#fff",
							color: "#00a3b1",
							"&:hover": {
								bgcolor: "#00a3b1",
								color: "#fff",
							},
							transition: "all 1s ease",
						}}
					>
						{t("Save")}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default ContactDialog;
