import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { useUpdateUserPasswordMutation } from "../../../redux/slices/api/user/userApi";
import SectionHeader from "../../Common/SectionHeader";
import { FaUserCheck } from "react-icons/fa";
import { LuCheckCircle } from "react-icons/lu";
import CustomTextField from "../../Common/CustomTextField";
import { setSnackbar } from "../../../redux/slices/app";

const UpdatePasswordDialog = ({ open, handleClose, user }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const isRtl = useSelector((state) => state.app.isRtl);

	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const [passwordRequirement, setPasswordRequirement] = useState({
		length: false,
		caseSensitive: false,
		specialChar: false,
		number: false,
	});
	const [isLoading, setIsLoading] = useState(false);

	const [updatePassword] = useUpdateUserPasswordMutation();

	const handleUpdatePassword = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		if (newPassword === "") {
			dispatch(setSnackbar({ open: true, message: t("PasswordEmptyError"), severity: "error" }));
			setIsLoading(false);
			return;
		}

		if (newPassword.length >= 1 && confirmPassword === "") {
			dispatch(setSnackbar({ open: true, message: t("ConfirmPasswordEmptyError"), severity: "error" }));
			setIsLoading(false);
			return;
		}

		if (newPassword !== confirmPassword) {
			dispatch(setSnackbar({ open: true, message: t("PasswordsDoNotMatch"), severity: "error" }));
			setIsLoading(false);
			return;
		}

		let data = {
			password: newPassword,
		};

		try {
			await updatePassword({ userId: user.id, credentials: data }).unwrap();
			dispatch(setSnackbar({ open: true, message: t("PasswordUpdated"), severity: "success" }));
			handleClose();
		} catch (error) {
			console.log("error", error);
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
		}
	};

	useEffect(() => {
		if (newPassword.length < 8) {
			setPasswordRequirement((prev) => ({ ...prev, length: false }));
		}
		if (!newPassword.match(/[a-z]/) || !newPassword.match(/[A-Z]/)) {
			setPasswordRequirement((prev) => ({ ...prev, caseSensitive: false }));
		}
		if (!newPassword.match(/[0-9]/)) {
			setPasswordRequirement((prev) => ({ ...prev, number: false }));
		}
		if (!newPassword.match(/[!@#$%^&*]/)) {
			setPasswordRequirement((prev) => ({ ...prev, specialChar: false }));
		}
	}, [newPassword]);

	return (
		<Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth={"md"} fullWidth>
			<DialogTitle sx={{ pb: 2, borderBottom: "1px solid #aaaaaa6b", backgroundColor: "#fff" }}>
				<SectionHeader icon={<FaUserCheck color="#164c68" size={18} />} title={`${t("UpdatePassword")}`} type={"dialog"} />
			</DialogTitle>

			<DialogContent sx={{ backgroundColor: "#fff" }}>
				<DialogContentText
					id="add-user-dialog-description"
					sx={{ p: 4, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 4 }}
				>
					<Stack spacing={3} width={"80%"} mb={2}>
						<CustomTextField
							type={"password"}
							name={"password"}
							value={newPassword}
							label={t("Password")}
							handleInputChange={(e) => {
								if (e.target.value.length >= 8) {
									setPasswordRequirement((prev) => ({ ...prev, length: true }));
								}
								if (e.target.value.match(/[a-z]/) && e.target.value.match(/[A-Z]/)) {
									setPasswordRequirement((prev) => ({ ...prev, caseSensitive: true }));
								}
								if (e.target.value.match(/[0-9]/)) {
									setPasswordRequirement((prev) => ({ ...prev, number: true }));
								}
								if (e.target.value.match(/[!@#$%^&*-]/)) {
									setPasswordRequirement((prev) => ({ ...prev, specialChar: true }));
								}

								setNewPassword(e.target.value);
							}}
							showPassword={showPassword}
							setShowPassword={setShowPassword}
							required={true}
						/>
						{(newPassword || newPassword.length >= 1) && (
							<CustomTextField
								type={"password"}
								name={"password"}
								value={confirmPassword}
								label={t("ConfirmPassword")}
								handleInputChange={(e) => {
									if (newPassword !== e.target.value) {
										setPasswordError(true);
									} else {
										setPasswordError(false);
									}
									setConfirmPassword(e.target.value);
								}}
								showPassword={showPassword}
								setShowPassword={setShowPassword}
								error={passwordError}
								errorText={t("PasswordsDoNotMatch")}
								required={true}
							/>
						)}
						<Typography variant={isRtl ? "title" : "body2"} fontWeight={700} color={"#51505099"}>
							{t("PasswordRequirements")}:
						</Typography>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={6}>
								<Stack direction="row" spacing={0.5} alignItems="center">
									<LuCheckCircle color={passwordRequirement.length ? "#00cb08" : "grey"} size={20} />
									<Typography variant="subtitle1" fontWeight="500" sx={{ color: passwordRequirement.length ? "#00cb08" : "grey" }}>
										{t("PasswordCharLengthReq")}
									</Typography>
								</Stack>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Stack direction="row" spacing={0.5} alignItems="center">
									<LuCheckCircle color={passwordRequirement.caseSensitive ? "#00cb08" : "grey"} size={20} />
									<Typography variant="subtitle1" fontWeight="500" sx={{ color: passwordRequirement.caseSensitive ? "#00cb08" : "grey" }}>
										{t("PasswordCaseSensitiveReq")}
									</Typography>
								</Stack>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Stack direction="row" spacing={0.5} alignItems="center">
									<LuCheckCircle color={passwordRequirement.specialChar ? "#00cb08" : "grey"} size={20} />
									<Typography variant="subtitle1" fontWeight="500" sx={{ color: passwordRequirement.specialChar ? "#00cb08" : "grey" }}>
										{t("PasswordSpecialCharReq")}
									</Typography>
								</Stack>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Stack direction="row" spacing={0.5} alignItems="center">
									<LuCheckCircle color={passwordRequirement.number ? "#00cb08" : "grey"} size={20} />
									<Typography variant="subtitle1" fontWeight="500" sx={{ color: passwordRequirement.number ? "#00cb08" : "grey" }}>
										{t("PasswordNumberReq")}
									</Typography>
								</Stack>
							</Grid>
						</Grid>
					</Stack>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: "#fff" }}>
				<Button
					onClick={handleClose}
					disabled={isLoading}
					sx={{
						color: "red",
						"&:hover": {
							color: "#fff",
							backgroundColor: "red",
						},
						transition: "all 1s ease",
					}}
				>
					{t("Cancel")}
				</Button>
				<LoadingButton
					disabled={
						!(passwordRequirement.length || passwordRequirement.caseSensitive || passwordRequirement.specialChar || passwordRequirement.number) ||
						passwordError ||
						newPassword !== confirmPassword ||
						newPassword.length === 0
					}
					loading={isLoading}
					variant="contained"
					size="medium"
					onClick={handleUpdatePassword}
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						mr: `${isRtl ? "8px" : "0px"} !important`,
					}}
				>
					{t("Update")}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default UpdatePasswordDialog;
