import { IoSettings } from "react-icons/io5";
import { FaTasks, FaUserCircle } from "react-icons/fa";
import { BsGraphUpArrow } from "react-icons/bs";
import { TiContacts } from "react-icons/ti";
import { FaBriefcase } from "react-icons/fa6";
import { CiCalendarDate } from "react-icons/ci";
import axiosInstance from "../../utils/axios";
import mime from "mime";
import { errorMessage } from "../../utils/errorCode";

export const appBarMenu = [
	// { id: 1, name: "Notifications", icon: <IoNotifications color="#164c68" size={25} />, path: "/notifications" },
	{
		id: 2,
		name: "Settings",
		icon: <IoSettings color="#164c68" size={25} />,
		path: "/settings",
	},
	{
		id: 3,
		name: "Profile",
		icon: <FaUserCircle color="#164c68" size={25} />,
		path: "/profile",
	},
];

export const navItems = [
	{
		id: 1,
		name: "Dashboard",
		path: "/",
		icon: <BsGraphUpArrow size={25} color="#164c68" />,
	},
	{
		id: 2,
		name: "Contacts",
		path: "/contacts",
		icon: <TiContacts size={25} color="#164c68" />,
	},
	// {
	// 	id: 3,
	// 	name: "Calendar",
	// 	path: "/calendar",
	// 	icon: <FaRegCalendarAlt size={25} color="#164c68" />,
	// },
	// {
	// 	id: 4,
	// 	name: "Reminders",
	// 	path: "/reminders",
	// 	icon: <FaBell size={25} color="#164c68" />,
	// },
	{
		id: 5,
		name: "Cases",
		path: "/case",
		icon: <FaBriefcase size={25} color="#164c68" />,
	},
	{
		id: 6,
		name: "Tasks",
		path: "/tasks",
		icon: <FaTasks size={25} color="#164c68" />,
	},
	{
		id: 7,
		name: "Meetings",
		path: "/meetings",
		icon: <CiCalendarDate size={25} color="#164c68" />,
	},
	{
		id: 8,
		name: "Emails",
		path: "/emails",
		icon: <CiCalendarDate size={25} color="#164c68" />,
	},
	{
		id: 9,
		name: "Reports",
		path: "/reports",
		icon: <CiCalendarDate size={25} color="#164c68" />,
	},
];

export const permissions = [
	{
		id: 1,
		name: "Owner",
	},
	{
		id: 2,
		name: "Admin",
	},
	{
		id: 3,
		name: "Employee",
	},
];

export const generateRandomString = (length) => {
	const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
	const numbers = "0123456789";
	const symbols = "!@#$%^&*()";

	let result = letters.charAt(Math.floor(Math.random() * letters.length)); // start with a letter

	for (let i = 1; i < length - 2; i++) {
		// leave room for a number and a symbol
		const characters = letters + numbers + symbols;
		result += characters.charAt(Math.floor(Math.random() * characters.length));
	}

	// add a number and a symbol at random positions
	const numberPosition = Math.floor(Math.random() * length);
	result = result.slice(0, numberPosition) + numbers.charAt(Math.floor(Math.random() * numbers.length)) + result.slice(numberPosition);

	const symbolPosition = Math.floor(Math.random() * length);
	result = result.slice(0, symbolPosition) + symbols.charAt(Math.floor(Math.random() * symbols.length)) + result.slice(symbolPosition);

	return result;
};

export const fetchMedia = async (mediaId) => {
	try {
		const response = await axiosInstance.get(`/static/${mediaId}`, {
			responseType: "arraybuffer",
		});
		let contentType = response.headers["content-type"];
		let mimeType = mime.getExtension(contentType);
		const blob = new Blob([response.data], { type: contentType });
		const dataUrl = URL.createObjectURL(blob);
		const sizeInMB = blob.size / 1024 / 1024;
		const formattedSize = sizeInMB.toFixed(2);

		return { dataUrl, formattedSize, mimeType, response };
	} catch (axiosError) {
		let errCode = axiosError.response?.data.code;

		return {
			error: { data: errorMessage[errCode] },
		};
	}
};

export const transformLineChartData = (data) => {
	const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
	const casesByMonth = {};

	// Initialize casesByMonth for all classifications
	const classifications = {};
	data.forEach((entry) => {
		if (!classifications[entry.classification]) {
			classifications[entry.classification] = {};
			months.forEach((month) => {
				classifications[entry.classification][month] = 0;
			});
		}
	});

	// Count cases by month for each classification
	data.forEach((entry) => {
		const openDate = new Date(entry.openDate);
		const month = months[openDate.getMonth()];
		classifications[entry.classification][month]++;
	});

	// Format data
	const formattedData = [];
	Object.keys(classifications).forEach((classification) => {
		const data = Object.keys(classifications[classification]).map((month) => ({
			x: month,
			y: classifications[classification][month],
		}));
		formattedData.push({ id: classification, data: data });
	});

	return formattedData;
};

export function decodeFileName(str) {
	// First, get the character codes
	const charCodes = [];
	for (let i = 0; i < str.length; i++) {
		charCodes.push(str.charCodeAt(i));
	}

	// Convert character codes to Uint8Array
	const byteArray = new Uint8Array(charCodes);

	// Use TextDecoder to decode the byteArray as UTF-8
	const decoder = new TextDecoder("utf-8");
	return decoder.decode(byteArray);
}

export function compareColumns(a, b) {
	if (a?.length !== b?.length) return false;
	else {
		// Comparing each element of your array
		for (var i = 0; i < a?.length; i++) {
			if (a[i] !== b[i]) {
				return false;
			}
		}
		return true;
	}
}
