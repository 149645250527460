import React, { useEffect } from "react";
import { useGetContactByIdQuery } from "../../../redux/slices/api/contacts/contactApi";
import { CircularProgress, Typography } from "@mui/material";

const PartyMember = ({ contactId, partyName, setPartyName }) => {
	const { data, isLoading, isError } = useGetContactByIdQuery(contactId, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (isError) return;
		if (data) {
			setPartyName(data?.data?.nameAr);
		}
	}, [data, contactId]);

	return (
		<>
			{isLoading ? (
				<CircularProgress />
			) : (
				<Typography variant={"h5"} fontWeight={500} color={"#595858"}>
					{partyName}
				</Typography>
			)}
		</>
	);
};

export default PartyMember;
