import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import SectionHeader from "../../Common/SectionHeader";
import { PiUsersFourFill } from "react-icons/pi";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import PartySelect from "./PartySelect";
import CustomConstraintSelect from "../../Common/CustomConstraintSelect";
import LawyerSelect from "./LawyerSelect";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../../redux/slices/app";
import { useTranslation } from "react-i18next";
import AddConstraintDialog from "./AddConstraintDialog";
import { IoAddCircle } from "react-icons/io5";
import { TiDelete } from "react-icons/ti";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const AddPartyDialog = ({ open, handleClose, classification, parties, setParties }) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const dispatch = useDispatch();
	const isEditingCase = useSelector((state) => state.case.isEditingCase);
	const [party, setParty] = useState(null);
	const [role, setRole] = useState(null);
	const [lawyer, setLawyer] = useState(null);

	const [addConstraint, setAddConstraint] = useState(false);
	const [constraintReference, setConstraintReference] = useState(null);
	const [addLawyer, setAddLawyer] = useState(false);

	const handleAddParty = () => {
		if (!party) {
			dispatch(setSnackbar({ open: true, message: t("PartyDialog"), severity: "error" }));
			return;
		}
		if (!lawyer) {
			setParties((prev) => [...prev, { party: party, caseRole: role }]);
			handleClose();
			return;
		}
		setParties((prev) => [...prev, { party: party, caseRole: role, lawyer: lawyer }]);
		handleClose();
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullWidth
			TransitionComponent={Transition}
			keepMounted
			aria-describedby="add-party-dialog"
			sx={{ height: "100%" }}
		>
			<DialogTitle sx={{ pb: 2, borderBottom: "1px solid #aaaaaa6b", backgroundColor: "#fff" }}>
				<SectionHeader icon={<PiUsersFourFill color="#164c68" size={18} />} title={"Add Party"} type={"dialog"} />
			</DialogTitle>
			<DialogContent sx={{ backgroundColor: "#fff" }}>
				<DialogContentText id="add-party-dialog-description" sx={{ p: 4 }}>
					<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={3} width={"100%"} sx={{ mb: 2 }}>
						<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={2} width={"100%"}>
							<Typography variant="h5" color={"#164c68"} fontWeight={500}>
								{t("PartyMember")}:
							</Typography>
							<PartySelect party={party} setParty={setParty} existingParties={parties} category={"client"} />
						</Stack>
					</Stack>
					{classification === "litigation" && (
						<>
							<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={3} width={"100%"} sx={{ mb: 2 }}>
								<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={2} width={"100%"}>
									<Typography variant="h5" color={"#164c68"} fontWeight={500}>
										{t("CaseRole")}:
									</Typography>
									<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2} width={"100%"}>
										<CustomConstraintSelect reference={"caseRole"} dataSet={role} setData={setRole} type={"party"} />
										<Tooltip title={`${t("Add")}  ${t("CaseRole")}`}>
											<IconButton
												onClick={() => {
													setAddConstraint(true);
													setConstraintReference("caseRole");
												}}
											>
												<IoAddCircle color={"#164c68"} size={30} />
											</IconButton>
										</Tooltip>
									</Stack>
								</Stack>
							</Stack>
							{addLawyer ? (
								<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={3} width={"100%"} sx={{ mb: 2 }}>
									<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={2} width={"100%"}>
										<Typography variant="h5" color={"#164c68"} fontWeight={500}>
											{t("Lawyer")} ({t("Optional")}):
										</Typography>
										<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2} width={"100%"}>
											<LawyerSelect lawyer={lawyer} setLawyer={setLawyer} existingParties={parties} category={"lawyer"} />
											<Tooltip title={`${t("Remove")} ${t("Lawyer")}`}>
												<IconButton
													onClick={() => {
														setLawyer(null);
														setAddLawyer(false);
													}}
												>
													<TiDelete color="red" size={30} />
												</IconButton>
											</Tooltip>
										</Stack>
									</Stack>
								</Stack>
							) : (
								<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={3} width={"100%"} sx={{ mb: 2 }}>
									<Button
										variant="contained"
										size="medium"
										sx={{
											bgcolor: "#164c68",
											color: "#fff",
											ml: `${isRtl ? "8px" : "0px"} !important`,
										}}
										onClick={() => setAddLawyer(true)}
									>
										Add Lawyer
									</Button>
								</Stack>
							)}
						</>
					)}
				</DialogContentText>
				<DialogActions sx={{ backgroundColor: "#fff" }}>
					<Button
						onClick={handleClose}
						sx={{
							color: "red",
							"&:hover": {
								color: "#fff",
								backgroundColor: "red",
							},
							transition: "all 1s ease",
						}}
					>
						{t("Cancel")}
					</Button>
					<Button
						variant="contained"
						size="medium"
						sx={{
							bgcolor: "#164c68",
							color: "#fff",
							mr: `${isRtl ? "8px" : "0px"} !important`,
						}}
						onClick={handleAddParty}
					>
						{t("Add")}
					</Button>
				</DialogActions>
			</DialogContent>
			{addConstraint && (
				<AddConstraintDialog open={addConstraint} handleClose={() => setAddConstraint(false)} reference={constraintReference} setRole={setRole} />
			)}
		</Dialog>
	);
};

export default AddPartyDialog;
