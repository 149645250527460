import { queryBuilder } from "../../../../utils/queryBuilder";
import { apiSlice } from "../apiSlice";

export const memoApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAllMemos: builder.query({
			query: (query) => ({
				url: `/memo?total=true&${queryBuilder(query)}`,
				method: "GET",
			}),
			providesTags: ["Memos"],
		}),
		getMemoById: builder.query({
			query: (memoId) => ({
				url: `/memo/${memoId}`,
				method: "GET",
			}),
		}),
		addMemo: builder.mutation({
			query: (data) => ({
				url: "/memo",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["Memos"],
		}),
		updateMemo: builder.mutation({
			query: ({ memoId, data }) => ({
				url: `/memo/${memoId}`,
				method: "PATCH",
				body: data,
			}),
			invalidatesTags: ["Memos"],
		}),
		deleteMemo: builder.mutation({
			query: (memoId) => ({
				url: `/memo/${memoId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Memos"],
		}),
	}),
});

export const { useGetAllMemosQuery, useGetMemoByIdQuery, useAddMemoMutation, useUpdateMemoMutation, useDeleteMemoMutation } = memoApi;
