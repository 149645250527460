import { MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import CreateNew from "../CreateNew";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "./MenuCommon";
import { useNavigate } from "react-router-dom";
import { setIsCreatingTask, setSelectedTask } from "../../../redux/slices/task";

const MemoMenu = ({ open, anchorEl, handleClose, section }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const currentSectionMenu = useSelector((state) => state.app.currentSectionMenu);
	const [createNewMemo, setCreateNewMemo] = useState(false);
	const [createMeeting, setCreateMeeting] = useState(false);

	const handleCreateOption = () => {
		if (currentSectionMenu === "Memos") {
			setCreateNewMemo(true);
		} else if (currentSectionMenu === "Meetings") {
			setCreateMeeting(true);
		} else if (section === "cases") {
			navigate("/dashboard/case/create");
		} else if (section === "hearing") {
			navigate("/dashboard/case/hearing");
		} else if (section === "task") {
			dispatch(setIsCreatingTask(true));
			dispatch(setSelectedTask(null));
			navigate("/dashboard/task");
		} else {
			handleClose();
		}
	};
	return (
		<>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "menu-button",
				}}
			>
				<MenuItem onClick={handleCreateOption}>
					<Typography variant="h6" color={"#fff"}>
						Create New
					</Typography>
				</MenuItem>
			</Menu>
			{createNewMemo && <CreateNew open={createNewMemo} handleClose={setCreateNewMemo} />}
			{createMeeting && <CreateNew open={createMeeting} handleClose={setCreateMeeting} />}
		</>
	);
};

export default MemoMenu;
