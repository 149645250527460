import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { images } from "../../../Assets/index";
import CustomDatePicker from "../../../Common/CustomDatePicker";
import { useSelector } from "react-redux";
import CustomTextField from "../../../Common/CustomTextField";
import { useTranslation } from "react-i18next";

const FirstSection = ({
	hearingDate,
	setHearingDate,
	invalidHearingDate,
	setInvalidHearingDate,
	invalidDateErrorText,
	setInvalidDateErrorText,
	notes,
	setNotes,
	requests,
	setRequests,
}) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);
	const currentCase = useSelector((state) => state.case.currentCase);
	return (
		<Box width={"100%"} height={"100%"} minHeight={"300px"} p={2} bgcolor={"#fff"}>
			<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
				<img src={images.hearing} alt="hearing" width={25} height={25} />
				<Typography variant="h4" color="#164c68" fontWeight="500">
					{t("HearingDetails")}:
				</Typography>
			</Stack>
			<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
			<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={5}>
				{currentCase.classification === "litigation" && (
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
						<Typography
							variant={"h4"}
							fontWeight={600}
							color={"#164c68"}
							sx={{ mb: 3, ml: `${isRtl ? "8px" : "0px"} !important`, mr: "8px !important" }}
						>
							{t("CaseFileFullName")}:
						</Typography>

						<Typography variant={"h4"} fontWeight={400} color={"#164c68"} sx={{ mb: 3, ml: `${isRtl ? "8px" : "0px"} !important` }}>
							{currentCase?.caseNumber ? currentCase?.caseNumber : "N/A"} / {currentCase?.caseYear ? currentCase?.caseYear : "N/A"} -{" "}
							{currentCase?.caseStage
								? isRtl
									? currentCase?.caseStage?.nameAr
									: currentCase?.caseStage?.nameEn
									? currentCase?.caseStage?.nameEn
									: currentCase?.caseStage?.nameAr
								: "N/A"}{" "}
							-{" "}
							{currentCase?.caseType
								? isRtl
									? currentCase?.caseType?.nameAr
									: currentCase?.caseType?.nameEn
									? currentCase?.caseType?.nameEn
									: currentCase?.caseType?.nameAr
								: "N/A"}
						</Typography>
					</Stack>
				)}
				<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={2}>
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2}>
						<Typography variant="h5" color="#164c68" fontWeight="500" sx={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }}>
							{t("HearingDate")}:
						</Typography>
						<CustomDatePicker
							title={t("SelectingHearingDate")}
							setInvalidDate={setInvalidHearingDate}
							setErrorText={setInvalidDateErrorText}
							value={hearingDate}
							setDate={setHearingDate}
						/>
						{invalidHearingDate && (
							<Typography variant={"title1"} color={"red"} fontWeight={500}>
								{invalidDateErrorText}
							</Typography>
						)}
					</Stack>
				</Stack>
				<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={1} width={"100%"}>
					<Typography variant="h5" color="#164c68" fontWeight="500">
						{t("HearingNotes")}:
					</Typography>
					<CustomTextField
						type={"text"}
						value={notes}
						resetValue={() => setNotes("")}
						name={"notes"}
						handleInputChange={(e) => {
							setNotes(e.target.value);
						}}
						required={true}
						multiline={true}
					/>
				</Stack>
				<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={1} width={"100%"}>
					<Typography variant="h5" color="#164c68" fontWeight="500">
						{t("HearingRequests")}:
					</Typography>
					<CustomTextField
						type={"text"}
						value={requests}
						resetValue={() => setRequests("")}
						name={"requests"}
						handleInputChange={(e) => {
							setRequests(e.target.value);
						}}
						required={true}
						multiline={true}
					/>
				</Stack>
			</Stack>
		</Box>
	);
};

export default FirstSection;
