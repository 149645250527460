import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SectionHeader from "../Common/SectionHeader";
import { RiLockPasswordFill } from "react-icons/ri";
import CustomTextField from "../Common/CustomTextField";
import PhoneSelect from "../Contacts/PhoneSelect";
import { LoadingButton } from "@mui/lab";
import { useForgotPasswordMutation } from "../../redux/slices/api/auth/authApi";
import { setResetPasswordData, setResetPasswordType, setSnackbar } from "../../redux/slices/app";
import { useNavigate } from "react-router-dom";

const ForgotPasswordType = ({ open, handleClose, openOtp }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const isRtl = useSelector((state) => state.app.isRtl);

	const [tokenThru, setTokenThru] = useState("email");
	const [resetMode, setResetMode] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const [error, setError] = useState({
		email: false,
		phone: false,
	});

	const [errorText, setErrorText] = useState({
		email: "",
		phone: "",
	});

	const handleResetValue = () => {
		setResetMode("");
	};

	const [forgotPassword] = useForgotPasswordMutation();

	const handleSendToken = async (e) => {
		e.preventDefault();
		setIsLoading(true);

		let data = {};

		if (tokenThru === "email") {
			data["email"] = resetMode;
			dispatch(setResetPasswordType("email"));
			dispatch(setResetPasswordData(resetMode));
		} else {
			const formattedPhone = resetMode.replace(/ /g, "");
			data["phone"] = formattedPhone;
			dispatch(setResetPasswordType("phone"));
			dispatch(setResetPasswordData(formattedPhone));
		}

		try {
			await forgotPassword(data).unwrap();
			dispatch(setSnackbar({ open: true, message: t("LinkSetSuccessfully"), severity: "success" }));
			setIsLoading(false);
			handleClose();
			openOtp();
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: t("ErrorPasswordType"), severity: "error" }));
			setIsLoading(false);
		}
	};

	return (
		<Dialog open={open} fullWidth aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth="sm">
			<DialogTitle sx={{ pb: 2, borderBottom: "1px solid #aaaaaa6b", backgroundColor: "#fff" }}>
				<SectionHeader icon={<RiLockPasswordFill color="#164c68" size={18} />} title={`${t("ForgotPassword")}`} type={"dialog"} />
			</DialogTitle>
			<DialogContent sx={{ backgroundColor: "#fff" }}>
				<DialogContentText id="add-user-dialog-description" sx={{ p: 4 }}>
					<Typography variant={"h5"} fontWeight={500} color={"#164c68"} sx={{ mb: 2 }}>
						{t("SendResetPasswordToken")}:
					</Typography>
					<FormControl sx={{ mb: 2 }}>
						<RadioGroup
							aria-labelledby="demo-controlled-radio-buttons-group"
							name="controlled-radio-buttons-group"
							value={tokenThru}
							onChange={(e) => setTokenThru(e.target.value)}
						>
							<FormControlLabel value="email" control={<Radio />} label={"Email"} />
							<FormControlLabel value="phone" control={<Radio />} label={"Phone"} />
						</RadioGroup>
					</FormControl>
					{tokenThru === "email" ? (
						<CustomTextField
							type={"email"}
							name={"email"}
							value={resetMode}
							resetValue={handleResetValue}
							label={t("EmailAddress")}
							setData={setResetMode}
							handleInputChange={(e) => {
								setResetMode(e.target.value);
							}}
							error={error.email}
							errorText={errorText.email}
							required={false}
						/>
					) : (
						<PhoneSelect value={resetMode} formData={setResetMode} id={"phone-0"} setFormData={(e) => setResetMode(e.target.value)} />
					)}
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: "#fff" }}>
				<Button
					onClick={handleClose}
					disabled={isLoading}
					sx={{
						color: "red",
						"&:hover": {
							color: "#fff",
							backgroundColor: "red",
						},
						transition: "all 1s ease",
					}}
				>
					{t("Cancel")}
				</Button>
				<LoadingButton
					loading={isLoading}
					variant="contained"
					size="medium"
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						mr: `${isRtl ? "8px" : "0px"} !important`,
					}}
					onClick={handleSendToken}
				>
					{t("Send")}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default ForgotPasswordType;
