import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Divider, Grid, Stack, Tooltip, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useGetAllContactsQuery, useGetByCriteriaQuery } from "../../redux/slices/api/contacts/contactApi";
import { useSelector } from "react-redux";
import SectionHeader from "../Common/SectionHeader";
import { RiContactsBookLine } from "react-icons/ri";
import CustomTextField from "../Common/CustomTextField";
import CustomCheckbox from "../Common/CustomCheckBox";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ContactSelectDialog = ({ contacts, setContacts, open, handleClose }) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);
	const { data, isLoading, isError } = useGetAllContactsQuery({}, { refetchOnMountOrArgChange: true });
	const [rows, setRows] = useState([]);
	const [filteredRows, setFilteredRows] = useState([]);
	const [originalRows, setOriginalRows] = useState([]);
	const [searchValue, setSearchValue] = useState("");
	const [isSearching, setIsSearching] = useState(false);

	useEffect(() => {
		if (isError) {
			// dispatch(setSnackbar({ open: true, message: "Error fetching data", severity: "error" }));
			return;
		}
		if (data) {
			const checkedContacts = contacts?.map((contact) => contact.id);
			const filteredContacts = data?.data?.filter((contact) => !checkedContacts?.includes(contact.id));
			setRows(filteredContacts);
		}
	}, [data, rows]);

	const searchByCriteria = useGetByCriteriaQuery({ name: searchValue }, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (searchValue?.length >= 3 && searchValue !== "") {
			setIsSearching(true);
			setFilteredRows(searchByCriteria?.data?.data);
		} else {
			setIsSearching(false);
		}
	}, [searchValue]);

	const handleSearchInput = (e) => {
		setSearchValue(e.target.value);
		if (e.target.value === "") {
			setIsSearching(false);
		}
	};

	const clearSearch = () => {
		setSearchValue("");
		setIsSearching(false);
	};

	const handleToggle = (contact) => {
		const currentIndex = contacts.indexOf(contact);
		const newContactsToAdd = [...contacts];

		if (currentIndex === -1) {
			newContactsToAdd.push(contact);
		}

		setContacts(newContactsToAdd);

		if (isSearching) {
			clearSearch();
		}
	};

	const handleRemoveContact = (contact) => {
		const newContactsToAdd = contacts.filter((c) => c !== contact);
		setContacts(newContactsToAdd);
	};

	return (
		<Dialog
			open={open}
			fullWidth
			maxWidth="md"
			onClose={handleClose}
			TransitionComponent={Transition}
			keepMounted
			aria-describedby="add-users-dialog"
			sx={{ height: "100%" }}
		>
			<DialogTitle sx={{ pb: 2, borderBottom: "1px solid #aaaaaa6b", backgroundColor: "#fff" }}>
				<SectionHeader icon={<RiContactsBookLine color="#164c68" size={18} />} title={t("ManageContacts")} type={"dialog"} />
			</DialogTitle>
			<DialogContent sx={{ backgroundColor: "#fff", overflowY: "scroll" }}>
				<DialogContentText id="add-users-dialog-description" sx={{ p: 4 }}>
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={2}>
						<Typography variant={"h5"} fontWeight={600} color={"#164c68"}>
							{t("SelectedContacts")}:
						</Typography>
						{contacts.length > 0 && (
							<Grid container spacing={2}>
								{contacts.map((contact) => (
									<Grid item xs={12} sm={3} key={contact.id}>
										<Box
											display={"flex"}
											justifyContent={"center"}
											alignItems={"center"}
											p={0.5}
											border={".5px solid #164c68"}
											borderRadius={1}
											onClick={() => {
												handleRemoveContact(contact);
											}}
											sx={{
												"&:hover": {
													cursor: "pointer",
												},
											}}
										>
											<Tooltip title={`Remove ${contact.nameAr}?`} placement="top">
												<Typography variant={"caption"} fontWeight={600} color={"#164c68"}>
													{contact.nameAr}
												</Typography>
											</Tooltip>
										</Box>
									</Grid>
								))}
							</Grid>
						)}
					</Stack>
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
						<Typography variant={"h4"} fontWeight={600} color={"#164c68"}>
							{t("Contacts")}
						</Typography>
						<CustomTextField
							label={t("Search")}
							name={"searchValue"}
							value={searchValue}
							type={"text"}
							handleInputChange={handleSearchInput}
							isSearching={isSearching}
							clearSearch={clearSearch}
							required={false}
						/>
					</Stack>
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					{rows?.length >= 1 && !isLoading && !isError ? (
						isSearching ? (
							<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={2} py={2} mb={2}>
								{filteredRows?.map((contact) => (
									<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} key={contact.id} px={16}>
										<Typography variant={"h4"} color={"#164c68"}>
											{contact.nameAr}
										</Typography>
										<CustomCheckbox toggle={() => handleToggle(contact)} />
									</Stack>
								))}
							</Stack>
						) : (
							<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={2} py={2} mb={2}>
								{rows?.map((contact) => (
									<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} key={contact.id} px={16}>
										<Typography variant={"h4"} color={"#164c68"}>
											{contact.nameAr}
										</Typography>
										<CustomCheckbox toggle={() => handleToggle(contact)} />
									</Stack>
								))}
							</Stack>
						)
					) : isLoading && !isError ? (
						<Stack direction={"row"} justifyContent={"center"} alignItems={"center"} width={"100%"}>
							<CircularProgress />
						</Stack>
					) : (
						<Stack direction={"row"} justifyContent={"center"} alignItems={"center"} width={"100%"}>
							<Typography variant={"h5"} fontWeight={600} color={"#164c68"}>
								{t("AllContactsAdded")}.
							</Typography>
						</Stack>
					)}
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: "#fff" }}>
				<Button
					onClick={handleClose}
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						"&:hover": {
							bgcolor: "#00a3b1",
							color: "#fff",
						},
						transition: "all 1s ease",
						marginLeft: `${isRtl ? "8px" : "0px"} !important`,
					}}
				>
					{t("Done")}
				</Button>
				<Button
					onClick={() => {
						setRows(originalRows);
						handleClose();
					}}
					sx={{
						color: "red",
						"&:hover": {
							color: "#fff",
							backgroundColor: "red",
						},
						transition: "all 1s ease",
					}}
				>
					{t("Close")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ContactSelectDialog;
