import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SectionHeader from "../../Common/SectionHeader";
import { PiHandCoinsFill } from "react-icons/pi";
import { useGetFeesByCriteriaQuery } from "../../../redux/slices/api/fees/feesApi";
import { useNavigate } from "react-router-dom";
import CollectFeeDialog from "../../Case/CaseDetails/Fees/CollectFeeDialog";
import { useDispatch } from "react-redux";
import { setSelectedFee } from "../../../redux/slices/fees";
import moment from "moment";
import { images } from "../../Assets";
import { setCurrentCase, setIsViewingCase } from "../../../redux/slices/case";

const UpcomingFees = ({ isSmallScreen }) => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [upcomingFee, setUpcomingFee] = useState([]);

	const [openCollectFeeDialog, setOpenCollectFeeDialog] = useState(false);

	const fee = useGetFeesByCriteriaQuery({}, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (fee.isError) {
			console.log("Error fetching fees");
			return;
		}

		if (fee?.data?.data) {
			let dateToday = moment();

			let filteredFees = fee.data.data
				.filter((item) => {
					let itemDueDate = moment(item.dueDate); // assuming item.date is in a format that can be parsed to a Date
					return !item.collected && itemDueDate.diff(dateToday, "days") < 1;
				})
				.sort((a, b) => moment(b.dueDate).diff(moment(a.dueDate)))
				.reverse().slice(0, 3);

			setUpcomingFee(filteredFees);
		}
	}, [fee]);

	return (
		<Box
			width={"100%"}
			minHeight={"200px"}
			height={"100%"}
			maxHeight={isSmallScreen ? "100%" : "500px"}

			borderRadius={2}
			p={5}
			bgcolor={"#fff"}
			sx={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;" }}
		>
			<SectionHeader icon={<PiHandCoinsFill color="#164c68" size={18} />} title={t("UpcomingFee")} />
			{!upcomingFee ? (
				<Box width={"100%"} height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
					<img src={images.collected} alt="collected" width={150} height={150} />
					<Typography variant={"h4"} fontWeight={600} color={"#164c68"} sx={{ my: 2 }}>
						{t("AllFeeCollected")}
					</Typography>
				</Box>
			) : (
				<Stack width={"100%"} height={"100%"} justifyContent={"flex-start"} alignItems={"center"} spacing={0} mt={2} sx={{ overflowY: "auto" }}>
					{upcomingFee.map((fee) => {
						return (
							<Box
								key={fee.id}
								width={"100%"}
								height={"auto"}
								p={2}
								display={"flex"}
								justifyContent={"space-between"}
								alignItems={"flex-start"}
								gap={2}
								borderBottom={"1px solid #aaaaaa6b"}
							>
								<Stack
									justifyContent={"flex-start"}
									width={"100%"}
									alignItems={"start"}
									spacing={1}
									sx={{ cursor: "pointer" }}
									onClick={() => {
										dispatch(setCurrentCase(fee.caseFile));
										dispatch(setIsViewingCase(true));
										navigate("/dashboard/case/details");
									}}
								>
									<Typography
										variant={"h6"}
										fontWeight={700}
										color={"#164c68"}
										sx={{
											fontFamily: '"Montserrat", "sans-serif"',
										}}
									>
										{t("CaseFile")}: {fee?.caseFile?.fileNumber}
									</Typography>
									<Typography
										variant={"h6"}
										fontWeight={700}
										color={"#164c68"}
										sx={{
											fontFamily: '"Montserrat", "sans-serif"',
										}}
									>
										{t("Amount")}: AED {fee?.amount.toLocaleString()}
									</Typography>
									<Typography
										variant={"subtitle1"}
										fontWeight={500}
										color={moment(fee.dueDate).diff(moment()) <= 1 ? "red" : "#595858"}
										sx={{
											fontFamily: '"Montserrat", "sans-serif"',
										}}
									>
										{t("DueDate")}: {moment(fee.dueDate).format("MMMM DD YYYY")}
									</Typography>
								</Stack>
								<Box
									p={1}
									width={"45%"}
									display={"flex"}
									justifyContent={"center"}
									alignItems={"center"}
									sx={{
										bgcolor: "#164c68",
										color: "#fff",
										"&:hover": {
											bgcolor: "#00a3b1",
										},
										transition: "all 0.5s ease",
										cursor: "pointer",
									}}
									borderRadius={1}
									onClick={() => {
										dispatch(setSelectedFee(fee));
										setOpenCollectFeeDialog(true);
									}}
								>
									<Typography variant={"subtitle1"} fontWeight={500} color={"#fff"}>
										{t("CollectFee")}
									</Typography>
								</Box>
							</Box>
						);
					})}
				</Stack>
			)}

			{openCollectFeeDialog && <CollectFeeDialog open={openCollectFeeDialog} handleClose={() => setOpenCollectFeeDialog(false)} />}
		</Box>
	);
};

export default UpcomingFees;
