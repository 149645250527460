import { createBrowserRouter } from "react-router-dom";
import Login from "../pages/Auth/Login";
import AuthLayout from "../layout/AuthLayout";
import MainLayout from "../layout/MainLayout";
import Dashboard from "../pages/Dashboard/Dashboard";
import Case from "../pages/Case/Case";
import CaseDetailsSection from "../pages/Case/CaseDetailsSection";
import Contacts from "../pages/Contacts/Contacts";
import UserSettings from "../components/Settings/Users/UserSettings";
import MyAccountSettings from "../components/Settings/MyAccount/MyAccountSettings";
import PermissionSettings from "../components/Settings/Permissions/PermissionSettings";
import CreateCase from "../components/Case/CreateCaseFile/CreateCase";
import CreateHearing from "../components/Case/Hearing/Create/CreateHearing";
import HearingDetails from "../components/Case/Hearing/HearingDetails";
import Tasks from "../pages/Tasks";
import TaskForm from "../components/Tasks/TaskForm/TaskForm.js";
import TaskDetails from "../components/Tasks/TaskDetails";
import Meetings from "../pages/Meetings/index.js";
import MeetingForm from "../components/Meetings/MeetingForm.js";
import CreateMemo from "../components/Memo/CreateMemo.js";
import EmailSettings from "../components/Settings/Email/EmailSettings.js";
import CreateTemplate from "../components/Settings/Email/Templates/CreateTemplate.js";
import MeetingDetails from "../components/Meetings/Details/MeetingDetails.js";
import ContactDetails from "../components/Contacts/ContactDetails.js";
import MemoSection from "../components/Case/CaseDetails/ThirdSection/MemoSection.js";
import HearingSection from "../components/Case/CaseDetails/ThirdSection/HearingSection.js";
import Task from "../components/Case/CaseDetails/FourthSection/Task.js";
import Meeting from "../components/Case/CaseDetails/FifthSection/Meeting.js";
import Fees from "../components/Case/CaseDetails/Fees/index.js";
import ConstraintsSettings from "../components/Settings/Constraints/ConstraintsSettings.js";
import Emails from "../pages/Emails/index.js";
import Reports from "../pages/Reports/Reports.js";
import ReminderSettings from "../components/Settings/Reminder/ReminderSettings.js";
import ReminderList from "../components/Settings/Reminder/ReminderList.js";
import ResetPassword from "../components/Auth/ResetPassword.js";

export const router = createBrowserRouter([
	{
		path: "/",
		element: <AuthLayout />,
		children: [
			{
				path: "/",
				index: true,
				element: <Login />,
			},
			{
				path: "passwordReset",
				index: true,
				element: <ResetPassword />,
			},
			{
				path: "dashboard",
				element: <MainLayout />,
				children: [
					{
						index: true,
						element: <Dashboard />,
					},
					{
						path: "case",
						element: <Case />,
					},
					{
						path: "case/create",
						element: <CreateCase />,
					},
					{
						path: "case/update",
						element: <CreateCase />,
					},
					{
						path: "case/details",
						element: <CaseDetailsSection />,
					},
					{
						path: "case/details/memos",
						element: <MemoSection />,
					},
					{
						path: "case/details/hearings",
						element: <HearingSection />,
					},
					{
						path: "case/details/tasks",
						element: <Task />,
					},
					{
						path: "case/details/meetings",
						element: <Meeting />,
					},
					{
						path: "case/hearing",
						element: <CreateHearing />,
					},
					{
						path: "case/hearing-details",
						element: <HearingDetails />,
					},
					{
						path: "contacts",
						element: <Contacts />,
					},
					{
						path: "contacts-details",
						element: <ContactDetails />,
					},
					{
						path: "meetings",
						element: <Meetings />,
					},
					{
						path: "meeting",
						element: <MeetingForm />,
					},
					{
						path: "meeting-detail",
						element: <MeetingDetails />,
					},
					{
						path: "settings",
						element: <UserSettings />,
					},
					{
						path: "permission-group",
						element: <PermissionSettings />,
					},
					{
						path: "my-account",
						element: <MyAccountSettings />,
					},
					{
						path: "tasks",
						element: <Tasks />,
					},
					{
						path: "task",
						element: <TaskForm />,
					},
					{
						path: "task/details",
						element: <TaskDetails />,
					},
					{
						path: "case/memo",
						element: <CreateMemo />,
					},
					{
						path: "email/settings",
						element: <EmailSettings />,
					},
					{
						path: "emails",
						element: <Emails />,
					},
					{
						path: "email/template",
						element: <CreateTemplate />,
					},
					{
						path: "case/details/fees",
						element: <Fees />,
					},
					{
						path: "/dashboard/constraints/settings",
						element: <ConstraintsSettings />,
					},
					{
						path: "reports",
						element: <Reports />,
					},
					{
						path: "reminders/add",
						element: <ReminderSettings />,
					},
					{
						path: "reminders/list",
						element: <ReminderList />,
					},
				],
			},
		],
	},
]);
