import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { MdOutlineRemoveCircle } from "react-icons/md";

const FileBox = ({
	file,
	index,
	fileType,
	previewFile,
	filesToSend,
	setPreviewFile,
	setIsVideo,
	setIsImage,
	setFileName,
	setFileSize,
	setSelectedFile,
	setFilesToSend,
}) => {
	const handleOnClick = (file, fileType) => {
		setPreviewFile(fileType.preview);
		setIsVideo(fileType.isVideo);
		setIsImage(fileType.isImage);
		setFileName(file.name);
		const fileSize = file.size / 1000;
		setFileSize(fileSize.toFixed(2));
		setSelectedFile(file);
	};

	return (
		<Box
			p={0}
			width={"8%"}
			height={"auto"}
			maxHeight={"50px"}
			display={"flex"}
			justifyContent={"center"}
			alignItems={"center"}
			borderRadius={1}
			onClick={() => handleOnClick(file, fileType)}
			sx={{
				"&:hover": {
					cursor: "pointer",
				},
			}}
		>
			<Box
				position={"relative"}
				sx={{
					"&:hover $deleteIcon": {
						opacity: 1,
						maxHeight: "40px",
						minHeight: "40px",
						minWidth: "40px",
						maxWidth: "40px",
					},
				}}
			>
				<img
					src={fileType.preview}
					alt="Image Preview"
					style={{
						maxHeight: "40px",
						minHeight: "40px",
						minWidth: "40px",
						maxWidth: "40px",
						borderRadius: "4px",
						objectFit: "contain",
					}}
				/>
				<Box // Add this Box for the overlay
					position={"absolute"}
					bottom={25}
					left={18}
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
					opacity={1} // Add this
				>
					<Tooltip title={"Remove"} arrow placement="top">
						<IconButton
							sx={{ color: "#fc6739" }} // Add this IconButton for the remove option
							onClick={(e) => {
								e.stopPropagation(); // Prevent triggering the onClick of the parent Box
								const newFilesToSend = filesToSend.filter((file, i) => i !== index);
								setFilesToSend(newFilesToSend);
							}}
						>
							<MdOutlineRemoveCircle />
						</IconButton>
					</Tooltip>
				</Box>
			</Box>
		</Box>
	);
};

export default FileBox;
