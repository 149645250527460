import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Fade, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, {  useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Animate from "../Common/Animate";
import FirstSection from "../Case/CaseDetails/FirstSection";
import { images } from "../Assets";
import { useNavigate } from "react-router-dom";
import { setIsCreatingTask, setIsEditingTask } from "../../redux/slices/task";
import moment from "moment";
import AssignedTo from "./AssignedTo";
import { IoCaretDownOutline } from "react-icons/io5";
import TaskStatuses from "./TaskStatuses";
import FileSection from "../Case/CaseDetails/FileSection";
import { useTranslation } from "react-i18next";
import UpdateTaskDialog from "./TaskForm/UpdateTaskDialog";
import CustomBackdrop from "../Common/CustomBackdrop";
import DeleteTaskDialog from "./DeleteTaskDialog";

const TaskDetails = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const isRtl = useSelector((state) => state.app.isRtl);
	const selectedTask = useSelector((state) => state.task.selectedTask);

	const [isUpdatingTask, setIsUpdatingTask] = useState(false);
	const [currentTask, setCurrentTask] = useState(null);

	const today = moment();

	const [loading, setLoading] = useState(false);

	const [expanded, setExpanded] = useState(false);
	const [expandStatuses, setExpandStatuses] = useState(false);

	const [openDeleteTaskDialog, setOpenDeleteTaskDialog] = useState(false);

	const handleExpansion = () => {
		setExpanded((prevExpanded) => !prevExpanded);
	};

	const handleStatusesExpansion = () => {
		setExpandStatuses((prev) => !prev);
	};

	return (
		<Animate type="fade" delay={0.5}>
			<Box
				width={"100%"}
				height={"max-content"}
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
				sx={{ overflowY: "auto", overflowX: "hidden" }}
				px={isSmallScreen ? 6 : 15}
				py={2}
				gap={4}
				mb={5}
			>
				<FirstSection category={"task"} />

				<Box
					width={"100%"}
					height={"max-content"}
					gap={6}
					bgcolor={"#fff"}
					sx={{
						overflowY: "auto",
						overflowX: "hidden",
						boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;",
					}}
					p={4}
					borderRadius={1.5}
				>
					<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} spacing={1} width={"100%"}>
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
							<img src={images.task} alt="task" width={25} height={25} />
							<Typography variant={"h2"} fontWeight={600} color={"#164c68"} sx={{ mr: `${isRtl ? "8px" : "0px"} !important` }}>
								{t("TaskDetails")}
							</Typography>
						</Stack>
						<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"}>
							<Button
								sx={{
									bgcolor: "#164c68",
									color: "#fff",
									"&:hover": {
										bgcolor: "#00a3b1",
									},
									transition: "all 1s ease",
								}}
								onClick={() => {
									dispatch(setIsEditingTask(true));
									dispatch(setIsCreatingTask(false));
									navigate("/dashboard/task");
								}}
							>
								{t("UpdateTask")}
							</Button>
							<Button
								sx={{
									bgcolor: "#164c68",
									color: "#fff",
									"&:hover": {
										bgcolor: "#00a3b1",
									},
									transition: "all 1s ease",
									mx: `8px !important`,
								}}
								onClick={() => {
									dispatch(setIsEditingTask(true));
									dispatch(setIsCreatingTask(false));
									setIsUpdatingTask(true);
									setCurrentTask(selectedTask);
								}}
							>
								{t("UpdateTaskStatus")}
							</Button>
							<Button
								onClick={() => setOpenDeleteTaskDialog(true)}
								sx={{
									color: "#fff",
									backgroundColor: "#fc6739",
									"&:hover": {
										color: "#fff",
										backgroundColor: "red",
									},
									transition: "all 1s ease",
								}}
							>
								{t("Delete")}
							</Button>
						</Stack>
					</Stack>

					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />

					<Stack justifyContent={"flex-start"} alignItems={"flex-start"} spacing={6} width={"100%"} height={"max-content"}>
						<Stack direction={"row"} width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
							<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
								<img src={images.notes} alt="notes" width={25} height={25} />
								<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mb: 1, mx: "8px !important" }}>
									{t("TaskDescription")}:
								</Typography>
								<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1 }}>
									{selectedTask?.description}
								</Typography>
							</Stack>
							<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1}>
								<Tooltip title={`${t("Priority")}: ${t(selectedTask?.priority)}`} placement="top" arrow>
									{selectedTask?.priority === "Low" ? (
										<img src={images.low} alt="low" width={20} height={20} />
									) : selectedTask?.priority === "Medium" ? (
										<img src={images.medium} alt="medium" width={20} height={20} />
									) : (
										<img src={images.high} alt="high" width={20} height={20} />
									)}
								</Tooltip>
								<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mb: 1, mr: `${isRtl ? "8px" : "0px"} !important` }}>
									{t("Priority")}:
								</Typography>
								<Box
									sx={{
										color: "#fff",
										borderRadius: 6,
										padding: 1,
										backgroundColor:
											selectedTask?.priority === "Low"
												? "#edaf41"
												: selectedTask?.priority === "Medium"
												? "#ecad4d"
												: selectedTask?.priority === "High"
												? "#fc6b77"
												: "#fc6b77",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										minWidth: "65px",
									}}
								>
									<Typography variant="h6" color={"#fff"} fontWeight={500} sx={{ textTransform: "capitalize" }}>
										{t(selectedTask?.priority)}
									</Typography>
								</Box>
							</Stack>
						</Stack>

						<Stack direction={"row"} width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
							<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2} width={"100%"}>
								<img src={images.calendar} alt="calendar" width={20} height={20} />
								<Typography variant={"h5"} fontWeight={700} color={"#164c68"} sx={{ mx: "8px !important" }}>
									{t("CreatedAt")}:
								</Typography>
								<Typography variant={"h5"} fontWeight={500} color={"#164c68"}>
									{moment(selectedTask?.createdAt).format("MMMM Do YYYY")}
								</Typography>
							</Stack>
							<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={2} width={"100%"}>
								<img src={images.calendar} alt="calendar" width={20} height={20} />
								<Typography variant={"h5"} fontWeight={700} color={"#164c68"} sx={{ mx: "8px !important" }}>
									{t("DueDate")}:
								</Typography>
								<Typography
									variant={"h5"}
									fontWeight={500}
									color={moment(selectedTask?.dueDate).diff(today, "days") < 1 ? "#fc6b77" : "#164c68"}
								>
									{moment(selectedTask?.dueDate).format("MMMM Do YYYY")}
								</Typography>
							</Stack>
						</Stack>

						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2} width={"100%"}>
							<img src={images.creator} alt="calendar" width={20} height={20} />
							<Typography variant={"h5"} fontWeight={700} color={"#164c68"} sx={{ mx: "8px !important" }}>
								{t("CreatedBy")}:
							</Typography>
							<Typography variant={"h5"} fontWeight={500} color={"#164c68"}>
								{selectedTask.createdBy?.firstName} {selectedTask.createdBy?.lastName}
							</Typography>
						</Stack>

						<Accordion
							expanded={expanded}
							onChange={handleExpansion}
							slots={{ transition: Fade }}
							slotProps={{ transition: { timeout: 1000 } }}
							sx={{
								width: "100%",
								bgcolor: "#fff",
								boxShadow: "none",
								"& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
								"& .MuiAccordionDetails-root": { display: expanded ? "block" : "none" },
							}}
						>
							<AccordionSummary expandIcon={<IoCaretDownOutline size={20} color={"#164c68"} />} aria-controls="panel1-content" id="panel1-header">
								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2} width={"100%"}>
									<img src={images.team} alt="team" width={20} height={20} />
									<Typography variant={"h5"} fontWeight={700} color={"#164c68"} sx={{ mx: "8px !important" }}>
										{t("AssignedTo")}:
									</Typography>
								</Stack>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container spacing={2}>
									{selectedTask?.assignedTo.map((user, index) => (
										<Grid item xs={12} sm={3} key={index}>
											<AssignedTo userId={user} />
										</Grid>
									))}
								</Grid>
							</AccordionDetails>
						</Accordion>

						<Accordion
							expanded={expandStatuses}
							onChange={handleStatusesExpansion}
							slots={{ transition: Fade }}
							slotProps={{ transition: { timeout: 1000 } }}
							sx={{
								width: "100%",
								bgcolor: "#fff",
								boxShadow: "none",
								"& .MuiAccordion-region": { height: expandStatuses ? "auto" : 0 },
								"& .MuiAccordionDetails-root": { display: expandStatuses ? "block" : "none" },
							}}
						>
							<AccordionSummary expandIcon={<IoCaretDownOutline size={20} color={"#164c68"} />} aria-controls="panel2-content" id="panel2-header">
								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2} width={"100%"}>
									<img src={images.status} alt="status" width={20} height={20} />
									<Typography variant={"h5"} fontWeight={700} color={"#164c68"} sx={{ mx: "8px !important" }}>
										{t("TaskStatuses")}:
									</Typography>
								</Stack>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container spacing={2} sx={{ width: "100%" }}>
									{selectedTask?.statuses.map((status, index) => (
										<Grid item xs={12} sm={3} key={index}>
											<TaskStatuses status={status} />
										</Grid>
									))}
								</Grid>
							</AccordionDetails>
						</Accordion>
					</Stack>
				</Box>
				<FileSection criteria={"task"} criteriaId={selectedTask.id} />
			</Box>

			{isUpdatingTask && (
				<UpdateTaskDialog
					open={isUpdatingTask}
					handleClose={() => setIsUpdatingTask(false)}
					currentTask={currentTask}
					loading={loading}
					setLoading={setLoading}
				/>
			)}
			{loading && <CustomBackdrop open={loading} />}
			{openDeleteTaskDialog && <DeleteTaskDialog open={openDeleteTaskDialog} handleClose={() => setOpenDeleteTaskDialog(false)} />}
		</Animate>
	);
};

export default TaskDetails;
