import React, { useEffect, useState } from "react";
import { useGetMetaDataQuery } from "../../../redux/slices/api/permission/permissionApi";
import { Checkbox, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const PermissionSelection = ({ isSmallScreen, permissions, setPermissions }) => {
	const { t } = useTranslation();

	const [sections, setSections] = useState([]);

	const { data, isLoading, isError } = useGetMetaDataQuery({}, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (isError) {
			return;
		}
		if (data && !isError && !isLoading) {
			const keys = Object.keys(data?.data).map((key) => {
				if (key === "CASE_FILE") {
					return "caseFile";
				} else if (key === "EMAIL_ACCOUNT") {
					return "emailAccount";
				} else if (key === "EMAIL_TEMPLATE") {
					return "emailTemplate";
				} else if (key === "EMAIL_MESSAGE") {
					return "emailMessage";
				} else if (key === "SMS_ACCOUNT") {
					return "smsAccount";
				} else if (key === "SMS_MESSAGE") {
					return "smsMessage";
				} else if (key === "SMS_TEMPLATE") {
					return "smsTemplate";
				} else {
					return key.toLowerCase();
				}
			});
			setSections(keys);
		}
	}, [data]);

	const handleToggleSubsections = (section, subsection) => {
		let newPermissions = { ...permissions };

		// Check if the subsection is already in the permissions object
		if (newPermissions[section] && newPermissions[section]?.includes(subsection)) {
			// If it is, remove it
			newPermissions[section] = newPermissions[section]?.filter((item) => item !== subsection);
		} else {
			// If it's not, add it
			if (!newPermissions[section]) {
				newPermissions = { ...newPermissions, [section]: [] }; // Make a copy of the object before adding a property
			}
			newPermissions[section] = [...newPermissions[section], subsection]; // Make a copy of the array before adding an element
		}

		// Update the permissions object
		setPermissions(newPermissions);
	};

	return (
		<Grid container spacing={1} width={"100%"}>
			{sections.map((section, index) => (
				<Grid item xs={6} sm={2} key={index} mb={2}>
					<Stack justifyContent={"flex-start"} alignItems={"start"} px={1}>
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
							<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ textTransform: "capitalize" }}>
								{section === "caseFile"
									? t("CaseFile")
									: section === "emailAccount"
									? t("EmailAccount")
									: section === "emailTemplate"
									? t("EmailTemplate")
									: section === "emailMessage"
									? t("EmailMessage")
									: section === "smsAccount"
									? t("SmsAccount")
									: section === "smsMessage"
									? t("SmsMessage")
									: section === "smsTemplate"
									? t("SmsTemplate")
									: t(section)}
								:
							</Typography>
						</Stack>

						<Stack justifyContent={"flex-start"} alignItems={"start"}>
							{section !== "emailMessage" && section !== "smsMessage" && (
								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"}>
									<Checkbox
										checked={permissions[section]?.includes("save")}
										size="small"
										disableRipple
										onChange={() => {
											handleToggleSubsections(section, "save");
										}}
										sx={{
											color: "#164c68",
											"&.Mui-checked": {
												color: "#164c68",
											},
										}}
									/>
									<Typography variant={"body2"} fontWeight={600} color={"#164c68"}>
										{t("SAVE")}
									</Typography>
								</Stack>
							)}
							{section !== "constraint" && section !== "contact" ? (
								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"}>
									<Checkbox
										disabled={section === "constraint" && section === "contact"}
										checked={permissions[section]?.includes("view")}
										size="small"
										disableRipple
										onChange={() => {
											handleToggleSubsections(section, "view");
										}}
										sx={{
											color: "#164c68",
											"&.Mui-checked": {
												color: "#164c68",
											},
										}}
									/>
									<Typography variant={"body2"} fontWeight={600} color={"#164c68"}>
										{t("VIEW")}
									</Typography>
								</Stack>
							) : null}
							{section !== "emailMessage" && section !== "emailAccount" && section !== "smsAccount" && section !== "smsMessage" && (
								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"}>
									<Checkbox
										checked={permissions[section]?.includes("update")}
										size="small"
										disableRipple
										onChange={() => {
											handleToggleSubsections(section, "update");
										}}
										sx={{
											color: "#164c68",
											"&.Mui-checked": {
												color: "#164c68",
											},
										}}
									/>
									<Typography variant={"body2"} fontWeight={600} color={"#164c68"}>
										{t("UPDATE")}
									</Typography>
								</Stack>
							)}
							{section === "emailMessage" ||
								(section === "smsMessage" && (
									<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"}>
										<Checkbox
											checked={permissions[section]?.includes("send")}
											size="small"
											disableRipple
											onChange={() => {
												handleToggleSubsections(section, "send");
											}}
											sx={{
												color: "#164c68",
												"&.Mui-checked": {
													color: "#164c68",
												},
											}}
										/>
										<Typography variant={"body2"} fontWeight={600} color={"#164c68"}>
											{t("SEND")}
										</Typography>
									</Stack>
								))}
							<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"}>
								<Checkbox
									checked={permissions[section]?.includes("delete")}
									size="small"
									disableRipple
									onChange={() => {
										handleToggleSubsections(section, "delete");
									}}
									sx={{
										color: "#164c68",
										"&.Mui-checked": {
											color: "#164c68",
										},
									}}
								/>
								<Typography variant={"body2"} fontWeight={600} color={"#164c68"}>
									{t("DELETE")}
								</Typography>
							</Stack>
						</Stack>
					</Stack>
				</Grid>
			))}
		</Grid>
	);
};

export default PermissionSelection;
