import { Autocomplete, Box,  TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetByCategoryQuery } from "../../../redux/slices/api/contacts/contactApi";
import ImageSelect from "./ImageSelect";


const PartySelect = ({ setParty, existingParties }) => {
	const { data, isLoading, isError } = useGetByCategoryQuery({ category: "client", category2: "litigant" }, { refetchOnMountOrArgChange: true });
	const [rows, setRows] = useState([]);

	useEffect(() => {
		if (isError) return;

		if (data && !isError && !isLoading) {
			const partyIds = existingParties?.map((party) => party.party);
			const filteredContacts = data.data.filter((contact) => !partyIds?.includes(contact.id));
			setRows(filteredContacts);
		}
	}, [data, existingParties]);

	return (
		<Autocomplete
			id="party-select"
			loading={isLoading}
			autoComplete
			clearOnEscape
			sx={{ width: "100%" }}
			options={rows}
			autoHighlight
			getOptionLabel={(option) => {
				setParty(option.id);
				return option.nameAr;
			}}
			renderOption={(props, option) => (
				<Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
					<ImageSelect avatar={option.avatar} />
					<Typography variant="h6" sx={{ color: "#fff", ml: 2 }}>
						{option.nameAr}
					</Typography>
				</Box>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					inputProps={{
						...params.inputProps,
						autoComplete: "new-password", // disable autocomplete and autofill
					}}
					sx={{ p: 0, color: "#164c68" }}
				/>
			)}
		/>
	);
};

export default PartySelect;
