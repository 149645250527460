import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Fade, Grid, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { images } from "../../../Assets/index";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setCurrentCase, setIsEditingCase } from "../../../../redux/slices/case";
import CustomBackdrop from "../../../Common/CustomBackdrop";
import { IoCaretDownOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import PartyCard from "./PartyCard";
import UserCard from "./UserCard";
import DeleteCaseDialog from "./DeleteCaseDialog";
import Contact from "../../../../redux/slices/contact";
import ContactPopOut from "../../../Contacts/ContactPopOut";
import { useGetCaseByIdQuery } from "../../../../redux/slices/api/cases/caseApi";
import { current } from "@reduxjs/toolkit";
import { set } from "date-fns";

const SecondSection = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const isRtl = useSelector((state) => state.app.isRtl);

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
	const isTinyScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const currentCase = useSelector((state) => state.case.currentCase);

	const { data: currentCaseData, isLoading, isSuccess } = useGetCaseByIdQuery(currentCase.id);

	const [caseData, setCaseData] = useState({});

	useEffect(() => {
		if (isSuccess && !isLoading && currentCaseData) {
			setCaseData(currentCaseData.data);
		}
	}, [currentCaseData]);

	const [loading, setLoading] = useState(false);

	const [expanded, setExpanded] = useState(true);

	const [partiesExpanded, setPartiesExpanded] = useState(false);
	const [usersExpanded, setUsersExpanded] = useState(false);

	const [handleOpenDeleteDialog, setHandleOpenDeleteDialog] = useState(false);
	const [selectedContactId, setSelectedContactId] = useState(null);
	const [handlePopOutContact, setHandlePopOutContact] = useState(false);

	const handleExpansion = () => {
		setExpanded((prevExpanded) => !prevExpanded);
	};

	const caseDetailsPages = [
		{ id: 1, name: t("Hearings"), path: "/dashboard/case/details/hearings", icon: images.hearing },
		{ id: 2, name: t("Memos"), path: "/dashboard/case/details/memos", icon: images.memos },
		{ id: 3, name: t("Tasks"), path: "/dashboard/case/details/tasks", icon: images.task },
		{ id: 4, name: t("Meetings"), path: "/dashboard/case/details/meetings", icon: images.calendar },
		{ id: 5, name: t("Fees"), path: "/dashboard/case/details/fees", icon: images.fees },
	];

	return (
		<Box
			width={"100%"}
			height={"max-content"}
			sx={{ overflowY: "auto", overflowX: "hidden", boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;" }}
			px={2}
			py={4}
			borderRadius={1.5}
			bgcolor={"#fff"}
		>
			<Stack
				direction={isSmallScreen ? "column" : "row"}
				justifyContent={isSmallScreen ? "flex-start" : "space-between"}
				alignItems={isSmallScreen ? "flex-start" : "center"}
				spacing={1}
				width={"100%"}
				p={2}
			>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
					<img src={images.case} alt="case" width={25} height={25} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
					<Typography variant={"h3"} fontWeight={600} color={"#164c68"} sx={{ mr: `${isRtl ? "8px" : "0px"} !important` }}>
						{t("CaseDetails")}:
					</Typography>
				</Stack>
				<Stack direction={"row"} justifyContent={isSmallScreen ? "flex-start" : "flex-end"} alignItems={"center"} spacing={1} width={"100%"}>
					<Button
						disabled={loading}
						sx={{
							bgcolor: "#164c68",
							color: "#fff",
							"&:hover": {
								bgcolor: "#00a3b1",
							},
							transition: "all 1s ease",
							marginLeft: `${isRtl && "4px"} !important`,
						}}
						onClick={() => {
							dispatch(setIsEditingCase(true));
							dispatch(setCurrentCase(currentCase));
							navigate("/dashboard/case/update");
						}}
					>
						{t("Edit")}
					</Button>
					<Button
						onClick={() => setHandleOpenDeleteDialog(true)}
						disabled={loading}
						sx={{
							color: "#fff",
							backgroundColor: "#fc6739",
							"&:hover": {
								color: "#fff",
								backgroundColor: "red",
							},
							transition: "all 1s ease",
						}}
					>
						{t("Delete")}
					</Button>
				</Stack>
			</Stack>

			<Divider sx={{ mb: 2, bgcolor: "#164c6866" }} />
			<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={4} width={"100%"} p={2}>
				<Stack
					direction={isSmallScreen ? "column" : "row"}
					width={"100%"}
					justifyContent={isSmallScreen ? "flex-start" : "space-between"}
					alignItems={isSmallScreen ? "flex-start" : "center"}
					spacing={isSmallScreen ? 6 : 0}
				>
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
						<img src={images.file} alt="file" width={25} height={25} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
						<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mb: 1, mr: `${isRtl ? "8px" : "0px"} !important` }}>
							{t("FileType")}:
						</Typography>
						<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1 }}>
							{caseData.fileType
								? isRtl
									? caseData.fileType.nameAr
									: caseData.fileType.nameEn
									? caseData.fileType?.nameEn
									: caseData.fileType?.nameAr
								: "N/A"}
						</Typography>
					</Stack>
					<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1}>
						<img
							src={images.classification}
							alt="classification"
							width={25}
							height={25}
							style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }}
						/>
						<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mb: 1, mr: `${isRtl ? "8px" : "0px"} !important` }}>
							{t("Classification")}:
						</Typography>
						<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1, textTransform: "capitalize" }}>
							{t(caseData.classification)}
						</Typography>
					</Stack>
				</Stack>
				<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
					{caseData.classification === "litigation" && (
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
							<img src={images.case} alt="case" width={25} height={25} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
							<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mb: 1, mr: `${isRtl ? "8px" : "0px"} !important` }}>
								{t("CaseNumber")} / {t("Year")}:
							</Typography>
							{caseData.classification === "litigation" ? (
								<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1, mr: `${isRtl ? "8px" : "0px"} !important` }}>
									{caseData.fullCaseNumber}
								</Typography>
							) : (
								<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1, mr: `${isRtl ? "8px" : "0px"} !important` }}>
									N/A
								</Typography>
							)}
						</Stack>
					)}
					<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1}>
						<img src={images.variables} alt="classification" width={25} height={25} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
						<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mb: 1, mr: `${isRtl ? "8px" : "0px"} !important` }}>
							{t("linkedCase")}:
						</Typography>
						<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1, textTransform: "capitalize" }}>
							{caseData.linkedCase
								? caseData.linkedCase?.fullCaseNumber.includes("undefined")
									? caseData.linkedCase?.fileNumber
									: caseData.linkedCase?.fullCaseNumber
								: "N/A"}
						</Typography>
					</Stack>
				</Stack>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<img src={images.variables} alt="classification" width={25} height={25} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
					<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mb: 1, mr: `${isRtl ? "8px" : "0px"} !important` }}>
						{t("CommercialBroker")}:
					</Typography>
					<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1, textTransform: "capitalize" }}>
						{caseData.commercialBroker
							? isRtl
								? caseData.commercialBroker?.nameAr
								: caseData.commercialBroker?.nameEn
								? caseData.commercialBroker?.nameEn
								: caseData.commercialBroker?.nameAr
							: "N/A"}
					</Typography>
				</Stack>

				<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
						<img src={images.calendar} alt="calendar" width={25} height={25} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
						<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mb: 1, mr: `${isRtl ? "8px" : "0px"} !important` }}>
							{t("Previous")}:
						</Typography>
						<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1, fontFamily: '"Montserrat", "sans-serif"' }}>
							{caseData.lastHearing ? moment(caseData.lastHearing).format("YYYY/MM/DD") : "N/A"}
						</Typography>
					</Stack>
					{/* <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1}>
						<img src={images.calendar} alt="calendar" width={25} height={25} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
						<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mb: 1, mr: `${isRtl ? "8px" : "0px"} !important` }}>
							{t("NextHearing")}:
						</Typography>
						<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1, fontFamily: '"Montserrat", "sans-serif"' }}>
							{caseData.expertDate ? moment(caseData.expertDate).format("YYYY/MM/DD") : "N/A"}
						</Typography>
					</Stack> */}
				</Stack>

				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<img src={images.calendar} alt="calendar" width={25} height={25} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
					<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mb: 1, mr: `${isRtl ? "8px" : "0px"} !important` }}>
						{t("ExpertAssignmentDate")}:
					</Typography>
					<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1, fontFamily: '"Montserrat", "sans-serif"' }}>
						{caseData.expertDate ? moment(caseData.expertDate).format("YYYY/MM/DD") : "N/A"}
					</Typography>
				</Stack>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<img src={images.calendar} alt="calendar" width={25} height={25} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
					<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mb: 1, mr: `${isRtl ? "8px" : "0px"} !important` }}>
						{t("DateOpened")}:
					</Typography>
					<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1, fontFamily: '"Montserrat", "sans-serif"' }}>
						{caseData.openDate ? moment(caseData.openDate).format("YYYY/MM/DD") : "N/A"}
					</Typography>
				</Stack>
				<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} spacing={5} width={"100%"}>
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
						<img src={images.calendar} alt="calendar" width={25} height={25} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
						<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mb: 1, mr: `${isRtl ? "8px" : "0px"} !important` }}>
							{t("DateClosed")}:
						</Typography>
						{caseData.status === "closed" ? (
							<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1, fontFamily: '"Montserrat", "sans-serif"' }}>
								{caseData.closeDate ? moment(caseData.expertDate).format("YYYY/MM/DD") : "N/A"}
							</Typography>
						) : (
							<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1 }}>
								N/A
							</Typography>
						)}
					</Stack>
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
						<img src={images.notes} alt="notes" width={25} height={25} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
						<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mb: 1, mr: `${isRtl ? "8px" : "0px"} !important` }}>
							{t("ClosedReason")}:
						</Typography>
						{caseData.status === "closed" ? (
							<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1 }}>
								{caseData.closeReason
									? isRtl
										? caseData.closeReason?.nameAr
										: caseData.closeReason?.nameEn
										? caseData.closeReason?.nameEn
										: caseData.closeReason?.nameAr
									: "N/A"}
							</Typography>
						) : (
							<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1 }}>
								N/A
							</Typography>
						)}
					</Stack>
				</Stack>

				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<img src={images.subject} alt="subject" width={25} height={25} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
					<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mb: 1, mr: `${isRtl ? "8px" : "0px"} !important` }}>
						{t("Subject")}:
					</Typography>
					<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1 }}>
						{caseData.subject ? caseData.subject : "N/A"}
					</Typography>
				</Stack>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<img src={images.notes} alt="notes" width={25} height={25} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
					<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mb: 1, mr: `${isRtl ? "8px" : "0px"} !important` }}>
						{t("Notes")}:
					</Typography>
					<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1 }}>
						{caseData.note ? caseData.note : "N/A"}
					</Typography>
				</Stack>

				<Stack
					direction={isSmallScreen ? "column" : "row"}
					width={"100%"}
					justifyContent={isSmallScreen ? "flex-start" : "space-between"}
					alignItems={isSmallScreen ? "flex-start" : "center"}
					spacing={isSmallScreen ? 6 : 0}
				>
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
						<img src={images.stage} alt="stage" width={25} height={25} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
						<Typography Stage="h5" color={"#164c68"} fontWeight={700} sx={{ mb: 1, mr: `${isRtl ? "8px" : "0px"} !important` }}>
							{t("CaseStage")}:
						</Typography>
						{caseData.classification === "litigation" ? (
							<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1 }}>
								{caseData.caseStage
									? isRtl
										? caseData.caseStage?.nameAr
										: caseData.caseStage?.nameEn
										? caseData.caseStage?.nameEn
										: caseData.caseStage?.nameAr
									: "N/A"}
							</Typography>
						) : (
							<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1 }}>
								N/A
							</Typography>
						)}
					</Stack>
					<Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={1}>
						<img src={images.caseType} alt="case type" width={25} height={25} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
						<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mb: 1, mr: `${isRtl ? "8px" : "0px"} !important` }}>
							{t("CaseType")}:
						</Typography>
						{caseData.classification === "litigation" ? (
							<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1 }}>
								{caseData.caseType
									? isRtl
										? caseData.caseType?.nameAr
										: caseData.caseType?.nameEn
										? caseData.caseType?.nameEn
										: caseData.caseType?.nameAr
									: "N/A"}
							</Typography>
						) : (
							<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1 }}>
								N/A
							</Typography>
						)}
					</Stack>
					<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1}>
						<img src={images.court} alt="court" width={25} height={25} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
						<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mb: 1, mr: `${isRtl ? "8px" : "0px"} !important` }}>
							{t("Court")}:
						</Typography>
						{caseData.classification === "litigation" ? (
							<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1 }}>
								{caseData.court
									? isRtl
										? caseData.court?.nameAr
										: caseData.court?.nameEn
										? caseData.court?.nameEn
										: caseData.court?.nameAr
									: "N/A"}
							</Typography>
						) : (
							<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1 }}>
								N/A
							</Typography>
						)}
					</Stack>
				</Stack>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} mt={4}>
					<img src={images.fees} alt="fees" width={25} height={25} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
					<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mb: 1, mr: `${isRtl ? "8px" : "0px"} !important` }}>
						{t("TotalFees")}:
					</Typography>
					<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1, fontFamily: '"Montserrat", "sans-serif"' }}>
						AED {caseData.totalFee?.toLocaleString()}
					</Typography>
				</Stack>
			</Stack>

			<Accordion
				expanded={partiesExpanded}
				onChange={() => setPartiesExpanded((prev) => !prev)}
				slots={{ transition: Fade }}
				slotProps={{ transition: { timeout: 1500 } }}
				sx={{
					bgcolor: "#fff",
					boxShadow: "none",
					"& .MuiAccordion-region": { height: partiesExpanded ? "auto" : 0 },
					"& .MuiAccordionDetails-root": { display: partiesExpanded ? "block" : "none" },
					my: 6,
				}}
			>
				<AccordionSummary expandIcon={<IoCaretDownOutline size={20} color={"#164c68"} />} aria-controls="panel2-content" id="panel2-header">
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
						<img src={images.team} alt="parties" width={25} height={25} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
						<Typography variant={"h3"} fontWeight={600} color={"#164c68"} sx={{ mr: `${isRtl ? "8px" : "0px"} !important` }}>
							{t("Parties")}:
						</Typography>
					</Stack>
				</AccordionSummary>
				<AccordionDetails>
					<Divider sx={{ mb: 2, bgcolor: "#164c6866" }} />
					<Grid container spacing={2}>
						{caseData.parties?.map((party) => {
							return (
								<Grid item xs={12} sm={3} key={party.id}>
									<PartyCard party={party} setSelectedContactId={setSelectedContactId} setHandlePopOutContact={setHandlePopOutContact} />
								</Grid>
							);
						})}
					</Grid>
				</AccordionDetails>
			</Accordion>

			<Accordion
				expanded={usersExpanded}
				onChange={() => setUsersExpanded((prev) => !prev)}
				slots={{ transition: Fade }}
				slotProps={{ transition: { timeout: 1500 } }}
				sx={{
					bgcolor: "#fff",
					boxShadow: "none",
					"& .MuiAccordion-region": { height: usersExpanded ? "auto" : 0 },
					"& .MuiAccordionDetails-root": { display: usersExpanded ? "block" : "none" },
					my: 6,
				}}
			>
				<AccordionSummary expandIcon={<IoCaretDownOutline size={20} color={"#164c68"} />} aria-controls="panel3-content" id="panel3-header">
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
						<img src={images.authUser} alt="users" width={25} height={25} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
						<Typography variant={"h3"} fontWeight={600} color={"#164c68"} sx={{ mr: `${isRtl ? "8px" : "0px"} !important` }}>
							{t("Users")}:
						</Typography>
					</Stack>
				</AccordionSummary>
				<AccordionDetails>
					<Divider sx={{ mb: 2, bgcolor: "#164c6866" }} />
					<Grid container spacing={2}>
						{caseData.users?.map((user, index) => {
							return (
								<Grid item xs={12} sm={3} key={index}>
									<UserCard user={user} />
								</Grid>
							);
						})}
					</Grid>
				</AccordionDetails>
			</Accordion>

			<Stack justifyContent={"center"} alignItems={"center"} width={"100%"} spacing={4} mt={8}>
				<Stack direction={"row"} justifyContent={"center"} alignItems={"center"} width={"100%"}>
					<Typography variant="h4" color={"#164c68"} fontWeight={700} sx={{ mb: 1 }}>
						{t("Navigate")}:
					</Typography>
				</Stack>
				<Stack direction={isTinyScreen ? "column" : "row"} justifyContent={"space-evenly"} alignItems={"center"} spacing={1} width={"100%"}>
					{caseDetailsPages.map((page) => (
						<Stack
							justifyContent={"center"}
							alignItems={"center"}
							spacing={2}
							key={page.id}
							sx={{
								"&:hover": {
									cursor: "pointer",
								},
							}}
							onClick={() => navigate(page.path)}
						>
							<Box
								width={"50px"}
								height={"50px"}
								p={0}
								borderRadius={"50%"}
								bgcolor={"#f3f7ff"}
								display={"flex"}
								justifyContent={"center"}
								alignItems={"center"}
							>
								<img
									src={page.icon}
									alt="avatar"
									width={"65%"}
									height={"65%"}
									style={{ objectFit: "contain", marginLeft: `${isRtl ? "8px" : "0px"} !important` }}
								/>
							</Box>
							<Typography variant={"h6"} fontWeight={700} color={"#164c68"} textTransform={"uppercase"}>
								{page.name}
							</Typography>
						</Stack>
					))}
				</Stack>
			</Stack>
			{loading && <CustomBackdrop open={loading} />}
			{handleOpenDeleteDialog && <DeleteCaseDialog open={handleOpenDeleteDialog} handleClose={() => setHandleOpenDeleteDialog(false)} />}
			{handlePopOutContact && (
				<ContactPopOut open={handlePopOutContact} handleClose={() => setHandlePopOutContact(false)} contactId={selectedContactId} />
			)}
		</Box>
	);
};

export default SecondSection;
