import { Box, Button, CircularProgress, FormControl, MenuItem, Select, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetTasksQuery } from "../../../redux/slices/api/tasks/tasksApi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { images } from "../../Assets";
import { useTranslation } from "react-i18next";
import { setIsCreatingTask, setIsEditingTask, setSelectedTask } from "../../../redux/slices/task";
import { MdOutlineAdd } from "react-icons/md";

const TaskList = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const isRtl = useSelector((state) => state.app.isRtl);

	const [status, setStatus] = useState("New");
	const [taskData, setTaskData] = useState([]);

	const filteredTaskData = useGetTasksQuery({ status: status, data: true }, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (filteredTaskData.isError) {
			return;
		}
		if (filteredTaskData.data?.data) {
			setTaskData(filteredTaskData?.data?.data);
		}
	}, [status, filteredTaskData]);

	return (
		<>
			<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
					<Button
						startIcon={!isRtl && <MdOutlineAdd color="#fff" />}
						onClick={() => {
							dispatch(setIsCreatingTask(true));
							dispatch(setIsEditingTask(false));
							navigate("/dashboard/task");
						}}
						sx={{
							bgcolor: "#164c68",
							color: "#fff",
							padding: 1,
							"&:hover": {
								bgcolor: "#00a3b1",
							},
							transition: "all 0.5s ease",
						}}
					>
						{t("CreateNew")}
					</Button>
				</Stack>
				<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1} width={"100%"}>
					<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1 }}>
						{t("Status")}:
					</Typography>
					<FormControl sx={{ m: 1, minWidth: 100 }} size="small">
						<Select id="status" value={status} onChange={(e) => setStatus(e.target.value)}>
							<MenuItem value={"New"}>{t("New")}</MenuItem>
							<MenuItem value={"In Progress"}>{t("InProgress")}</MenuItem>
							<MenuItem value={"Completed"}>{t("Completed")}</MenuItem>
							<MenuItem value={"Canceled"}>{t("Canceled")}</MenuItem>
						</Select>
					</FormControl>
				</Stack>
			</Stack>

			<Stack mt={1} width={"100%"} height={"100%"} justifyContent={"flex-start"} alignItems={"center"} spacing={0}>
				{filteredTaskData.isLoading ? (
					<CircularProgress />
				) : taskData.length >= 1 ? (
					[...taskData]
						.sort((a, b) => new Date(b.dueDate) - new Date(a.dueDate))
						.slice(0, 8)
						.map((task) => {
							return (
								<Box
									key={task.id}
									width={"100%"}
									height={"auto"}
									p={2}
									display={"flex"}
									justifyContent={"space-between"}
									alignItems={"flex-start"}
									gap={2}
									borderBottom={"1px solid #aaaaaa6b"}
									sx={{ cursor: "pointer" }}
									onClick={() => {
										dispatch(setSelectedTask(task));
										navigate("/dashboard/task/details");
									}}
								>
									<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={1}>
										<Typography variant={"h6"} fontWeight={700} color={"#164c68"}>
											{t("TaskTitle")}:{" "}
											{task?.taskTitle ? (isRtl ? task?.taskTitle?.nameAr : task?.taskTitle?.nameEn) : task?.title ? task?.title : "N/A"}
										</Typography>
										<Typography
											variant={"subtitle1"}
											fontWeight={500}
											color={moment(task?.dueDate).diff(moment()) <= 1 ? "red" : "#595858"}
											sx={{
												fontFamily: '"Montserrat", "sans-serif"',
											}}
										>
											{t("DueDate")}: {moment(task?.dueDate).format("MMM DD YYYY")}
										</Typography>
									</Stack>
									<Box p={1} display={"flex"} justifyContent={"center"} alignItems={"center"} bgcolor={"#e9e7e770"} borderRadius={1}>
										<Tooltip title={`${t("Priority")}: ${task?.priority}`} placement="top" arrow>
											{task?.priority === "Low" ? (
												<img src={images.low} alt="low" width={20} height={20} />
											) : task?.priority === "Medium" ? (
												<img src={images.medium} alt="medium" width={20} height={20} />
											) : (
												<img src={images.high} alt="high" width={20} height={20} />
											)}
										</Tooltip>
									</Box>
								</Box>
							);
						})
				) : (
					<Box width={"100%"} height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} mt={10}>
						<img src={images.notFound} alt="Not Found" width={200} height={200} />
						<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ my: 2 }}>
							{t("NoTaskFound")}
						</Typography>
						<Typography variant={"subtitle1"} fontWeight={500} color={"#51505099"}>
							{t("AddTask")}
						</Typography>
					</Box>
				)}
			</Stack>
		</>
	);
};

export default TaskList;
