import { Checkbox, Stack, Typography } from "@mui/material";

import React from "react";

const CustomCheckbox = ({ desc, checked, toggle }) => {
	// return <CustomizedCheckbox size="small" disableRipple onChange={() => setChecked(!checked)} />;
	return (
		<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"}>
			<Checkbox
				checked={checked}
				size="small"
				disableRipple
				onChange={() => toggle()}
				sx={{
					color: "#164c68",
					"&.Mui-checked": {
						color: "#164c68",
					},
				}}
			/>
			<Typography variant={"body2"} fontWeight={600} color={"#164c68"}>
				{desc}
			</Typography>
		</Stack>
	);
};

export default CustomCheckbox;
