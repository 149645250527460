import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { useSelector } from "react-redux";

const PermissionGroupCard = ({ data, open, setAnchorEl, setSelectedPermission }) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const [permissions, setPermissions] = React.useState({});

	useEffect(() => {
		const filteredPermissions = Object.fromEntries(Object.entries(data.permissions).filter(([key, value]) => value.length > 0));
		setPermissions(filteredPermissions);
	}, [data]);

	return (
		<Box width={"100%"} p={4} height={"100%"} borderRadius={1.5} bgcolor={"#fff"} sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;" }}>
			<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<Box
						width={"40px"}
						p={1}
						borderRadius={"50%"}
						bgcolor={"#bdbdbd"}
						display={"flex"}
						justifyContent={"center"}
						alignItems={"center"}
						sx={{
							ml: `${isRtl ? "8px" : "0px"} !important`,
						}}
					>
						<Typography variant={"h5"} fontWeight={700} color={"#fff"} textTransform={"capitalize"}>
							{data.name.charAt(0)}
						</Typography>
					</Box>
					<Stack justifyContent={"flex-start"} alignItems={"start"}>
						<Typography variant={"h5"} fontWeight={700} color={"#595858"}>
							{data.name}
						</Typography>
					</Stack>
				</Stack>
				<IconButton
					id="permission-group-menu-button"
					aria-controls={open ? "contact-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
					sx={{ p: 0 }}
					onClick={(e) => {
						setSelectedPermission(data);
						setAnchorEl(e.currentTarget);
					}}
				>
					<HiOutlineDotsVertical color="#164c68" size={25} />
				</IconButton>
			</Stack>
			<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
			<Typography variant={"h6"} fontWeight={700} color={"#595858"} sx={{ mb: 1.5 }}>
				{t("UsersIncluded")}:
			</Typography>
			{data.users.map((user) => (
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} key={user.id}>
					<Typography variant={"h5"} fontWeight={500} color={"#595858"}>
						{user.firstName} {user.lastName}
					</Typography>
				</Stack>
			))}
		</Box>
	);
};

export default PermissionGroupCard;
