import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { PiUsersFourFill } from "react-icons/pi";
import { FaBriefcase } from "react-icons/fa";
import PartyConstraint from "./PartyConstraint";
import PartyMember from "./PartyMember";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const PartyCard = ({ party, parties, setParties }) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const isEditingCase = useSelector((state) => state.case.isEditingCase);
	const isViewingCase = useSelector((state) => state.case.isViewingCase);

	const [partyNAme, setPartyName] = useState(isEditingCase || isViewingCase ? party?.party?.nameAr : "");
	const [caseRoleName, setCaseRoleName] = useState(null);
	const [lawyerName, setLawyerName] = useState(
		isEditingCase || (isViewingCase && party?.lawyer) ? party?.lawyer?.nameAr : isEditingCase || (isViewingCase && !party?.lawyer) ? "N/A" : ""
	);

	return (
		<Box width={"100%"} p={2} height={"100%"} borderRadius={1.5} sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;" }} bgcolor={"#f3f7ff"}>
			<Stack justifyContent={"flex-start"} width={"100%"} spacing={2}>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={1}>
					<PiUsersFourFill color={"#164c68"} />
					<Typography variant={"h5"} fontWeight={600} color={"#595858"} sx={{ mx: `${isRtl ? "8px" : "0px"} !important` }}>
						{t("Party")}:{" "}
					</Typography>{" "}
					<PartyMember contactId={party.party} partyName={partyNAme} setPartyName={setPartyName} />
				</Stack>
				{party.caseRole ? (
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={1}>
						<FaBriefcase color={"#164c68"} />
						<Typography variant={"h5"} fontWeight={600} color={"#595858"} sx={{ mx: `${isRtl ? "8px" : "0px"} !important` }}>
							{t("CaseRole")}:{" "}
						</Typography>
						<PartyConstraint reference={"caseRole"} constraintId={party.caseRole} caseRoleName={caseRoleName} setCaseRoleName={setCaseRoleName} />
					</Stack>
				) : (
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={1}>
						<FaBriefcase color={"#164c68"} />
						<Typography variant={"h5"} fontWeight={600} color={"#595858"} sx={{ mx: `${isRtl ? "8px" : "0px"} !important` }}>
							{t("CaseRole")}:{" "}
						</Typography>
						<Typography variant={"h5"} fontWeight={500} color={"#595858"}>
							N/A
						</Typography>
					</Stack>
				)}
				{party.lawyer ? (
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={1}>
						<FaBriefcase color={"#164c68"} />
						<Typography variant={"h5"} fontWeight={600} color={"#595858"} sx={{ mx: `${isRtl ? "8px" : "0px"} !important` }}>
							{t("Lawyer")}:{" "}
						</Typography>
						<PartyMember contactId={party.lawyer} partyName={lawyerName} setPartyName={setLawyerName} />
					</Stack>
				) : (
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={1}>
						<FaBriefcase color={"#164c68"} />
						<Typography variant={"h5"} fontWeight={600} color={"#595858"} sx={{ mx: `${isRtl ? "8px" : "0px"} !important` }}>
							{t("Lawyer")}:{" "}
						</Typography>
						<Typography variant={"h5"} fontWeight={500} color={"#595858"}>
							N/A
						</Typography>
					</Stack>
				)}

				<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1}>
					<Button
						onClick={() => {
							const newParties = parties.filter((p) => p.party !== party.party);
							setParties(newParties);
						}}
						sx={{
							color: "white",
							backgroundColor: "#ff3030",
							"&:hover": {
								backgroundColor: "red",
							},
						}}
					>
						{t("Remove")}
					</Button>
				</Stack>
			</Stack>
		</Box>
	);
};

export default PartyCard;
