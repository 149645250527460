import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import SectionHeader from "../Common/SectionHeader";
import { FaUserCheck } from "react-icons/fa";
import { LoadingButton } from "@mui/lab";
import { setResetPasswordCode, setResetPasswordData, setResetPasswordType, setSnackbar } from "../../redux/slices/app";
import { useNavigate } from "react-router-dom";
import { useForgotPasswordMutation } from "../../redux/slices/api/auth/authApi";

const ResetPasswordConfirmation = ({ open, handleClose }) => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
  const navigate = useNavigate();

	const isRtl = useSelector((state) => state.app.isRtl);

	const resetPasswordType = useSelector((state) => state.app.resetPasswordType);
	const resetPasswordData = useSelector((state) => state.app.resetPasswordData);

	const [otp, setOtp] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const handleVerifyOtp = () => {
    setIsLoading(true);

    dispatch(setResetPasswordCode(otp));
    
    setTimeout(() => {
      setIsLoading(false);
      handleClose();
    }, 2000);

    navigate("/passwordReset");

  };

  const [forgotPassword] = useForgotPasswordMutation();

  const handleSendToken = async (e) => {
		e.preventDefault();
		setIsLoading(true);

		let data = {};

		if (resetPasswordType === "email") {
			data["email"] = resetPasswordData;
			dispatch(setResetPasswordType("email"));
			dispatch(setResetPasswordData(resetPasswordData));
		} else {
			const formattedPhone = resetPasswordData.replace(/ /g, "");
			data["phone"] = formattedPhone;
			dispatch(setResetPasswordType("phone"));
			dispatch(setResetPasswordData(formattedPhone));
		}

		try {
			await forgotPassword(data).unwrap();
			dispatch(setSnackbar({ open: true, message: t("LinkSetSuccessfully"), severity: "success" }));
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: t("ErrorPasswordType"), severity: "error" }));
		}

    setIsLoading(false);
	};

	return (
		<Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth={"sm"} fullWidth>
			<DialogTitle sx={{ pb: 2, borderBottom: "1px solid #aaaaaa6b", backgroundColor: "#fff" }}>
				<SectionHeader icon={<FaUserCheck color="#164c68" size={18} />} title={`${t("VerifyUser")}`} type={"dialog"} />
			</DialogTitle>
			<DialogContent sx={{ backgroundColor: "#fff" }}>
				<DialogContentText
					id="add-user-dialog-description"
					sx={{ p: 4, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 4 }}
				>
					<Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={1}>
						<Typography variant={"h5"} fontWeight={500} color={"#164c68"} sx={{ mb: 2 }}>
							{t("EnterOTP")}:
						</Typography>
						<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ mb: 2 }}>
							{resetPasswordType}: {resetPasswordData}
						</Typography>
					</Stack>
				</DialogContentText>
				<OtpInput
					value={otp}
					onChange={setOtp}
					numInputs={6}
					renderSeparator={<span style={{ color: "#164c68", fontSize: "1.5rem" }}>-</span>}
					renderInput={(props) => <input {...props} />}
					inputType="text"
					containerStyle={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "6px" }}
					inputStyle={{
						width: "50px",
						height: "50px",
						color: "#164c68",
						borderRadius: "6px",
						border: "1px solid #164c68",
						fontSize: "2rem",
						textAlign: "center",
					}}
				/>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: "#fff" }}>
				<Button
					onClick={handleClose}
          disabled={isLoading}
					sx={{
						color: "red",
						"&:hover": {
							color: "#fff",
							backgroundColor: "red",
						},
						transition: "all 1s ease",
					}}
				>
					{t("Cancel")}
				</Button>
        <LoadingButton
					loading={isLoading}
					variant="contained"
					size="medium"
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						mr: `${isRtl ? "8px" : "0px"} !important`,
					}}
					onClick={handleSendToken}
				>
					{t("SendAgain")}
				</LoadingButton>
				<LoadingButton
					loading={isLoading}
					variant="contained"
					size="medium"
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						mr: `${isRtl ? "8px" : "0px"} !important`,
					}}
					onClick={handleVerifyOtp}
				>
					{t("Verify")}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default ResetPasswordConfirmation;
