export const images = {
	logo: require("./Images/logo.png"),
	loginBg: require("./Images/loginBg.jpg"),
	boxBg: require("./Images/darkblue.jpg"),
	notFound: require("./Images/no-results.png"),
	numbered: require("./Images/numbered.png"),
	calendar: require("./Images/calendar.png"),
	classification: require("./Images/hierarchy.png"),
	case: require("./Images/suitcase.png"),
	court: require("./Images/law.png"),
	folder: require("./Images/folder.png"),
	subject: require("./Images/books.png"),
	file: require("./Images/file.png"),
	notes: require("./Images/notepad.png"),
	stage: require("./Images/timeline.png"),
	caseType: require("./Images/case-file.png"),
	hearing: require("./Images/hearing.png"),
	decision: require("./Images/gavel.png"),
	newTask: require("./Images/addtask.png"),
	inProgress: require("./Images/50-percent.png"),
	complete: require("./Images/goal.png"),
	cancelled: require("./Images/cancelled.png"),
	low: require("./Images/low.png"),
	medium: require("./Images/medium.png"),
	high: require("./Images/high.png"),
	task: require("./Images/task.png"),
	creator: require("./Images/creator.png"),
	team: require("./Images/team.png"),
	id: require("./Images/id.png"),
	role: require("./Images/role.png"),
	status: require("./Images/status.png"),
	contacts: require("./Images/contacts.png"),
	place: require("./Images/map.png"),
	authUser: require("./Images/authorization.png"),
	username: require("./Images/username.png"),
	password: require("./Images/password.png"),
	port: require("./Images/port.png"),
	host: require("./Images/server.png"),
	email: require("./Images/email.png"),
	doc: require("./Files/doc.png"),
	html: require("./Files/html.png"),
	image: require("./Files/image.png"),
	other: require("./Files/other.png"),
	pdf: require("./Files/pdf.png"),
	ppt: require("./Files/ppt.png"),
	txt: require("./Files/txt.png"),
	video: require("./Files/video.png"),
	xls: require("./Files/xls.png"),
	csv: require("./Files/csv.png"),
	individual: require("./Images/individual.png"),
	company: require("./Images/company.png"),
	client: require("./Images/client.png"),
	lawyer: require("./Images/lawyer.png"),
	expertOffice: require("./Images/expert.png"),
	phone: require("./Images/phone.png"),
	variables: require("./Images/variables.png"),
	pageNotFound: require("./Images/404.png"),
	dashboard: require("./Images/dashboard.png"),
	onlineCase: require("./Images/online-case.png"),
	mainMenu: require("./Images/menu.png"),
	permission: require("./Images/permission.png"),
	accountSettings: require("./Images/account-settings.png"),
	emailSettings: require("./Images/email-settings.png"),
	judge: require("./Images/judge.png"),
	avatar: require("./Images/avatar.png"),
	memos: require("./Images/memos.png"),
	uae: require("./Images/arabic.png"),
	us: require("./Images/english.png"),
	fees: require("./Images/fees.png"),
	basicFee: require("./Images/basic.png"),
	additionalFee: require("./Images/additional.png"),
	priceTag: require("./Images/price.png"),
	variable: require("./Images/variable.png"),
	emailSubject: require("./Images/emailSubject.png"),
	sender: require("./Images/sender.png"),
	message: require("./Images/message.png"),
	collected: require("./Images/collected.png"),
	position: require("./Images/position.png"),
	reports: require("./Images/reports.png"),
	website: require("./Images/www.png"),
	reminder: require("./Images/reminder.png"),
	template: require("./Images/template.png"),
};
