import React from "react";
import Animate from "../../../Common/Animate";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import FirstSection from "../FirstSection";
import TaskSection from "./TaskSection";

const Task = () => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
	return (
		<Animate type="fade" delay={0.5}>
			<Box
				width={"100%"}
				height={"max-content"}
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
				sx={{ overflowY: "auto", overflowX: "hidden" }}
				px={isSmallScreen ? 6 : 15}
				py={2}
				gap={2}
			>
				<FirstSection category={"task"} />
				<TaskSection />
			</Box>
		</Animate>
	);
};

export default Task;
