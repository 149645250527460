import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	currentMemo: null,
	isCreatingMemo: false,
	isEditingMemo: false,
	isViewingMemo: false,
};

export const memoSlice = createSlice({
	name: "memo",
	initialState,
	reducers: {
		setCurrentMemo: (state, action) => {
			state.currentMemo = action.payload;
		},
		setIsCreatingMemo: (state, action) => {
			state.isCreatingMemo = action.payload;
		},
		setIsEditingMemo: (state, action) => {
			state.isEditingMemo = action.payload;
		},
		setIsViewingMemo: (state, action) => {
			state.isViewingMemo = action.payload;
		},
	},
});

export const { setCurrentMemo, setIsCreatingMemo, setIsEditingMemo, setIsViewingMemo } = memoSlice.actions;

export default memoSlice.reducer;
