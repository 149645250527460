import { Box, Button, CircularProgress, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { images } from "../Assets";
import { FaCloudUploadAlt } from "react-icons/fa";
import { useGetFilesQuery } from "../../redux/slices/api/files/fileApi";
import FileCard from "../Case/CaseDetails/FileSection/FileCard";
import UploadSection from "../Case/CaseDetails/FileSection/UploadSection";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ContextMenu from "../Case/CaseDetails/FileSection/ContextMenu";

const FileSection = ({ criteria, criteriaId }) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const { data, isLoading, isError } = useGetFilesQuery({ criteria: criteria, criteriaId: criteriaId }, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (isError) return;
		if (data) {
			setFiles(data.data);
			setOriginalFiles(data.data);
		}
	}, [data]);

	const [files, setFiles] = useState(data?.data || []);
	const [originalFiles, setOriginalFiles] = useState(data?.data || []);
	const [sortedFiles, setSortedFiles] = useState([]);
	const [uploading, setUploading] = useState(false);
	const [filesToUpload, setFilesToUpload] = useState([]);
	const [filePreview, setFilePreview] = useState([]);
	const [isSorting, setIsSorting] = useState(false);
	const [viewingByCategory, setViewingByCategory] = useState(null);
	const [selectedCategory, setSelectedCategory] = useState(null);

	const inputRef = useRef(null);

	const handleUpload = (e) => {
		const fileData = e.target.files;
		if (fileData.length < 2 || fileData.length === 1) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setFilePreview(URL.createObjectURL(fileData[0]));
				setFilesToUpload([fileData[0]]);
				setUploading(true);
			};
			reader.readAsDataURL(fileData[0]);
		} else {
			for (let i = 0; i < fileData.length; i++) {
				const reader = new FileReader();
				reader.onloadend = () => {
					setFilePreview((prevFilePreview) => [...prevFilePreview, URL.createObjectURL(fileData[i])]);
					setFilesToUpload((prevFiles) => [...prevFiles, fileData[i]]);
					setUploading(true);
				};
				reader.readAsDataURL(fileData[i]);
			}
		}
	};

	useEffect(() => {
		if (!isSorting) {
			setFiles(originalFiles);
		} else {
			const groupedByCategory = files?.reduce((acc, file) => {
				const categoryKey = isRtl ? file.category?.nameAr : file.category?.nameEn;
				if (!acc[categoryKey]) {
					acc[categoryKey] = [];
				}
				acc[categoryKey].push(file);
				return acc;
			}, {});

			let groupedArray = Object.keys(groupedByCategory).map((category) => ({
				category: category,
				files: groupedByCategory[category],
			}));

			groupedArray?.sort((a, b) => {
				console.log(a.category, selectedCategory);
				console.log(b.category, selectedCategory);
				if (a.category === selectedCategory) return -1;
				if (b.category === selectedCategory) return 1;
				return 0;
			});

			setSortedFiles(groupedArray);
		}
	}, [isSorting, isRtl, selectedCategory, files, originalFiles]);

	const handleContextMenu = (e) => {
		e.preventDefault();
		setViewingByCategory(
			viewingByCategory === null
				? {
						mouseX: e.clientX + 2,
						mouseY: e.clientY - 6,
				  }
				: null
		);
	};

	return (
		<Box
			sx={{
				width: "100%",
				height: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
				mb: 2,
			}}
			onContextMenu={handleContextMenu}
		>
			<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} spacing={1} width={"100%"} mr={1}>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<img src={images.file} alt="file" width={25} height={25} />
					<Typography variant={"h3"} fontWeight={600} color={"#164c68"}>
						{t("Files")}:
					</Typography>
				</Stack>
				<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1}>
					<Button
						startIcon={!isRtl && <FaCloudUploadAlt />}
						sx={{
							bgcolor: "#164c68",
							color: "#fff",
							"&:hover": {
								bgcolor: "#00a3b1",
							},
							transition: "all 1s ease",
							py: 1,
							px: 2,
						}}
						onClick={() => inputRef.current.click()}
					>
						{t("AddFiles")}
					</Button>
				</Stack>
			</Stack>
			<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
			{isLoading && !isError && files.length >= 1 && (
				<Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"} width={"100%"} height={"100%"}>
					<CircularProgress color="primary" />
				</Box>
			)}
			{files.length > 0 && !isLoading && !isError && !isSorting ? (
				<Grid container spacing={4}>
					{files.map((file) => (
						<Grid item xs={12} sm={6} key={file.id}>
							<FileCard file={file} />
						</Grid>
					))}
				</Grid>
			) : sortedFiles?.length > 0 && isSorting ? (
				<Stack justifyContent={"center"} spacing={4} width={"100%"} height={"100%"}>
					{sortedFiles?.map((file) => {
						return (
							<React.Fragment key={file?.category}>
								<Divider sx={{ mb: 2, textTransform: "capitalize" }} textAlign="left">
									<Typography variant={"h4"} fontWeight={600} color={"#164c68"}>
										{file?.category}
									</Typography>
								</Divider>
								<Grid container spacing={2}>
									{file?.files?.map((f) => {
										return (
											<Grid item xs={12} sm={6} key={f.id}>
												<FileCard file={f} />
											</Grid>
										);
									})}
								</Grid>
							</React.Fragment>
						);
					})}
				</Stack>
			) : (
				<Box width={"100%"} height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} p={1} mb={30}>
					<img src={images.notFound} alt="Not Found" width={200} height={200} />
					<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ my: 2 }}>
						{t("NoFileAvailable")}!
					</Typography>
					<Typography variant={"subtitle1"} fontWeight={500} color={"#51505099"}>
						{t("AddFiles")}!
					</Typography>
				</Box>
			)}
			<input ref={inputRef} type="file" multiple hidden onChange={handleUpload} />
			{uploading && (
				<UploadSection
					open={uploading}
					handleClose={() => setUploading(false)}
					criteria={criteria}
					criteriaId={criteriaId}
					files={filesToUpload}
					setFiles={setFilesToUpload}
					filePreview={filePreview}
					setFilePreview={setFilePreview}
				/>
			)}
			{viewingByCategory && (
				<ContextMenu
					open={viewingByCategory}
					handleClose={() => setViewingByCategory(null)}
					context={viewingByCategory}
					selectedCategory={selectedCategory}
					setSelectedCategory={setSelectedCategory}
					setIsSorting={setIsSorting}
				/>
			)}
		</Box>
	);
};

export default FileSection;
