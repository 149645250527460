import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { images } from "../../../Assets";
import { IoIosCloudDownload, IoMdEye } from "react-icons/io";
import { RiDeleteBin6Fill } from "react-icons/ri";
import ConfirmDeleteFile from "./ConfirmDeleteFile";
import FilePreview from "./FilePreview";
import { decodeFileName, fetchMedia } from "../../../Common/utils";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../../../redux/slices/app";
import { useTranslation } from "react-i18next";

const FileCard = ({ file, isSorting }) => {
	const { t } = useTranslation();

	const isRtl = useSelector((state) => state.app.isRtl);

	const dispatch = useDispatch();
	const [fileType, setFileType] = useState("");
	const [filePreview, setFilePreview] = useState(false);
	const [fileName, setFileName] = useState("");

	const [deleteFile, setDeleteFile] = useState(false);

	useEffect(() => {
		if (file) {
			const fileExtension = file.originalname.split(".");
			setFileType(fileExtension[fileExtension.length - 1]);
			const formattedFileName = decodeFileName(file.originalname);
			setFileName(file.originalname);
		}
	}, [file]);

	const handleFile = async () => {
		const fileUrl = await fetchMedia(file.id);
		if (!fileUrl?.dataUrl) {
			dispatch(setSnackbar({ open: true, message: t("FileNotFound"), severity: "error" }));
			return;
		} else {
			if (fileType === "png" || fileType === "jpeg" || fileType === "jpg" || fileType === "pdf") {
				window.open(fileUrl?.dataUrl);
				return;
			}
			let link = document.createElement("a");
			link.href = fileUrl?.dataUrl;
			link.download = fileName;
			link.click();
			link.remove();
			dispatch(setSnackbar({ open: true, message: t("FileDownloaded"), severity: "success" }));
		}
	};

	return (
		<Box
			width={"100%"}
			height={"100%"}
			p={2}
			borderRadius={1.5}
			bgcolor={"#fff"}
			sx={{
				boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;",
			}}
		>
			<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<img
						src={
							fileType === "png" || fileType === "jpeg" || fileType === "jpg"
								? images.image
								: fileType === "doc" || fileType === "docx"
								? images.doc
								: fileType === "html"
								? images.html
								: fileType === "pdf"
								? images.pdf
								: fileType === "ppt" || fileType === "pptx"
								? images.ppt
								: fileType === "txt"
								? images.txt
								: fileType === "mp4" || fileType === "mpeg"
								? images.video
								: fileType === "xls" || fileType === "xlsx"
								? images.xls
								: fileType === "csv"
								? images.csv
								: images.other
						}
						alt="file"
						width={50}
						height={50}
					/>
					<Stack justifyContent={"flex-start"} alignItems={"flex-start"} spacing={0.1}>
						<Typography variant="h6" fontWeight={600} color={"#164c68"}>
							{fileName.length > 30 ? fileName.slice(0, 30) + "..." : fileName}
		
						</Typography>
						<Typography variant="h6" fontWeight={600} color={"#164c68"} sx={{ fontFamily: '"Montserrat", "sans-serif" !important' }}>
							{file.size / 1000} KB
						</Typography>
						{!isSorting && (
							<Typography variant="h6" fontWeight={600} color={"#164c68"} sx={{ fontFamily: '"Montserrat", "sans-serif" !important' }}>
								Category: {file?.category ? (isRtl ? file.category.nameAr : file.category.nameEn) : "N/A"}
							</Typography>
						)}
					</Stack>
				</Stack>
				<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1}>
					{fileType === "png" || fileType === "jpeg" || fileType === "jpg" ? (
						<Tooltip title={t("View")} placement="top" arrow>
							<IconButton onClick={() => setFilePreview(true)}>
								<IoMdEye size={25} color={"#164c68"} />
							</IconButton>
						</Tooltip>
					) : null}
					<Tooltip title={t("Download")} placement="top" arrow>
						<IconButton onClick={handleFile}>
							<IoIosCloudDownload size={25} color={"#164c68"} />
						</IconButton>
					</Tooltip>
					<Tooltip title={t("Delete")} placement="top" arrow>
						<IconButton onClick={() => setDeleteFile(true)}>
							<RiDeleteBin6Fill size={25} color={"#fb6540"} />
						</IconButton>
					</Tooltip>
				</Stack>
			</Stack>
			{deleteFile && <ConfirmDeleteFile open={deleteFile} handleClose={() => setDeleteFile(false)} file={file} />}
			{filePreview && <FilePreview open={filePreview} handleClose={() => setFilePreview(false)} file={file} fileType={fileType} />}
		</Box>
	);
};

export default FileCard;
