import { apiSlice } from "../apiSlice";

export const fileApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getFiles: builder.query({
			query: ({ criteria, criteriaId }) => ({
				url: `/static?${criteria}=${criteriaId}`,
				method: "GET",
			}),
			providesTags: ["Files"],
		}),
		getFileById: builder.query({
			query: (fileId) => ({
				url: `/static/${fileId}`,
				method: "GET",
			}),
		}),
		uploadFile: builder.mutation({
			query: (formData) => ({
				url: `/static`,
				method: "POST",
				body: formData,
			}),
			invalidatesTags: ["Files"],
		}),
		updateFile: builder.mutation({
			query: ({ fileId, data }) => ({
				url: `/static/${fileId}`,
				method: "PATCH",
				body: data,
			}),
			invalidatesTags: ["Files"],
		}),
		deleteFile: builder.mutation({
			query: (fileId) => ({
				url: `/static/${fileId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Files"],
		}),
	}),
});

export const { useGetFilesQuery, useGetFileByIdQuery, useUploadFileMutation, useUpdateFileMutation, useDeleteFileMutation } = fileApi;
