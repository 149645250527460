import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, CircularProgress, FormControl, MenuItem, Select, Stack, Typography } from "@mui/material";
import SectionHeader from "../../../Common/SectionHeader";
import { FaMoneyBill1Wave } from "react-icons/fa6";
import { setIsCreatingFee, setIsEditingFee, setSelectedFee } from "../../../../redux/slices/fees";
import FeeFormDialog from "./FeeFormDialog";
import { useGetFeesByCriteriaQuery } from "../../../../redux/slices/api/fees/feesApi";
import { images } from "../../../Assets";
import FeeTable from "./FeeTable";
import DeleteFeeDialog from "./DeleteFeeDialog";
import CollectFeeDialog from "./CollectFeeDialog";
import { useTranslation } from "react-i18next";
import { MdOutlineAdd } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ScrollableContainer from "../../../Common/ScrollableContainer";
import { useGetTableViewQuery, useUpdateTableViewMutation } from "../../../../redux/slices/api/tableView/tableViewApi";
import { setSnackbar } from "../../../../redux/slices/app";
import { LoadingButton } from "@mui/lab";
import ConfirmDeleteTableView from "../../../Common/ConfirmDeleteTableView";

const FeesSection = ({ isSmallScreen }) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);
	const currentCase = useSelector((state) => state.case.currentCase);

	const [openFeeDialog, setOpenFeeDialog] = useState(false);
	const [openDeleteFeeDialog, setOpenDeleteFeeDialog] = useState(false);
	const [openCollectFeeDialog, setOpenCollectFeeDialog] = useState(false);

	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 50,
	});

	const [colFilter, setColFilter] = useState([]);
	const [columnSizing, setColumnSizing] = useState({});

	const colFilterObject = colFilter?.reduce((acc, { id, value }) => {
		if (id === "caseFile.caseNumber") id = "caseFile";
		if (id === "dueDate") {
			id = "dueDate[gte]";
			let fromDate;
			let toDate;
			if (value[0]) fromDate = value[0].format("YYYY-MM-DD");
			if (value[1]) toDate = value[1].format("YYYY-MM-DD");
			value = `${fromDate}&dueDate[lte]=${toDate}`;
		}
		acc[id] = value;
		return acc;
	}, {});

	const feeQuery = {
		...(colFilter?.length > 0 && { ...colFilterObject }),
		caseFile: currentCase.id,
		skip: pagination.pageIndex * pagination.pageSize,
		limit: pagination.pageSize,
	};

	const { data, isLoading, isError, error } = useGetFeesByCriteriaQuery(feeQuery, { refetchOnMountOrArgChange: true });

	const [rows, setRows] = useState([]);
	const [totalCount, setTotalCount] = useState(data?.totalRecords || 0);

	useEffect(() => {
		if (isError) {
			console.log("Error fetching fees: ", error);
		}
		if (data) {
			setRows(data?.data);
			setTotalCount(data?.totalRecords);
		}
	}, [data, pagination.pageIndex, pagination.pageSize, colFilter]);

	const dispatch = useDispatch();

	const tableView = useGetTableViewQuery({ type: "fee" }, { refetchOnMountOrArgChange: true });

	const [columnVisibility, setColumnVisibility] = useState({});
	const [columnOrder, setColumnOrder] = useState([]);
	const [defaultTemplate, setDefaultTemplate] = useState(null);
	const [templates, setTemplates] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [loading, setLoading] = useState(false);
	const [handleDeleteView, setHandleDeleteView] = useState(false);

	useEffect(() => {
		if (tableView.data?.data?.fee) {
			const defaultView = tableView.data?.data?.fee?.find((view) => view.default === true);
			if (defaultView) {
				setDefaultTemplate(defaultView);
				setSelectedTemplate(defaultView);
				setColumnVisibility(JSON.parse(defaultView?.column));
				setColumnOrder(JSON.parse(defaultView?.columnOrder));
			} else {
				const defaultTemp = tableView.data?.data?.fee[0];
				console.log("default: ", defaultTemp);
				setSelectedTemplate(defaultTemp);
				setColumnVisibility(JSON.parse(defaultTemp?.column));
				setColumnOrder(JSON.parse(defaultTemp?.columnOrder));
			}
			setTemplates(tableView.data?.data?.fee);
		} else {
			setSelectedTemplate(null);
			setColumnVisibility({});
			setColumnOrder([]);
		}
	}, [tableView.data, templates]);

	const [updateTableView] = useUpdateTableViewMutation();

	const handleSetAsDefault = async (e) => {
		e.preventDefault();
		setLoading(true);
		const data = {
			default: true,
		};
		try {
			await updateTableView({ templateId: selectedTemplate?.id, data: data }).unwrap();
			setDefaultTemplate(selectedTemplate);
			setSelectedTemplate(selectedTemplate);
			setLoading(false);
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setLoading(false);
		}
	};

	return (
		<Stack direction={isSmallScreen ? "column" : "row"} justifyContent={"center"} alignItems={"start"} width={"100%"} height={"100%"} spacing={2}>
			<Box
				width={"100%"}
				height={"max-content"}
				p={2}
				borderRadius={1.5}
				bgcolor={"#fff"}
				sx={{
					boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;",
				}}
			>
				<SectionHeader icon={<FaMoneyBill1Wave color="#164c68" size={18} />} title={t("MyFees")} section={"task"} />
				<Box height={"max-content"} width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
					<Box sx={{ width: "100%", height: "max-content" }}>
						<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} height={"max-content"} my={2}>
							<Stack width={"100%"} direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2}>
								<Button
									size="small"
									startIcon={!isRtl && <MdOutlineAdd color="#fff" />}
									onClick={() => {
										dispatch(setIsCreatingFee(true));
										dispatch(setIsEditingFee(false));
										dispatch(setSelectedFee(null));
										setOpenFeeDialog(true);
									}}
									sx={{
										bgcolor: "#164c68",
										color: "#fff",
										padding: 1,
										"&:hover": {
											bgcolor: "#00a3b1",
										},
										transition: "all 0.5s ease",
									}}
								>
									{t("CreateNew")}
								</Button>
								<Typography variant={"h5"} color={"#164c68"}>
									{t("SelectedTemplate")}:
								</Typography>
								{templates?.length > 0 ? (
									<FormControl sx={{ m: 1, minWidth: 100, mr: isRtl ? "8px !important" : 0 }} size="small">
										<Select
											id="select-template"
											value={selectedTemplate}
											onChange={(e) => {
												setSelectedTemplate(e.target.value);
												setColumnVisibility(JSON.parse(e?.target?.value?.column));
												setColumnOrder(JSON.parse(e?.target?.value?.columnOrder));
											}}
											sx={{ color: "#164c68" }}
											displayEmpty
										>
											{defaultTemplate && (
												<MenuItem value={defaultTemplate}>
													{defaultTemplate?.name} ({t("Default")})
												</MenuItem>
											)}
											{templates
												?.filter((template) => defaultTemplate?.id !== template.id)
												.map((template) => (
													<MenuItem key={template.id} value={template}>
														{template.name}
													</MenuItem>
												))}
										</Select>
									</FormControl>
								) : (
									<Typography variant={"h5"} color={"#164c68"}>
										{t("NoTableViewFound")}
									</Typography>
								)}
								{selectedTemplate?.id !== defaultTemplate?.id && (
									<LoadingButton
										loading={isLoading}
										variant="contained"
										size="small"
										onClick={handleSetAsDefault}
										sx={{
											bgcolor: "#164c68",
											color: "#fff",
											padding: 1,
											"&:hover": {
												bgcolor: "#00a3b1",
											},
											transition: "all 0.5s ease",
										}}
									>
										{t("SetAsDefault")}
									</LoadingButton>
								)}
							</Stack>
							<Stack width={"100%"} direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1}>
								<img src={images.fees} alt="fees" width={25} height={25} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
								<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mr: `${isRtl ? "8px" : "0px"} !important` }}>
									{t("TotalFees")}:
								</Typography>
								<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ fontFamily: '"Montserrat", "sans-serif"' }}>
									AED {currentCase.totalFee?.toLocaleString()}
								</Typography>
							</Stack>
						</Stack>
						{isLoading && !isError ? (
							<Box width={"100%"} height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
								<CircularProgress />
							</Box>
						) : rows ? (
							<ScrollableContainer>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<FeeTable
										data={rows}
										openFeeDialog={openFeeDialog}
										setOpenFeeDialog={setOpenFeeDialog}
										setOpenDeleteFeeDialog={setOpenDeleteFeeDialog}
										openCollectFeeDialog={openCollectFeeDialog}
										setOpenCollectFeeDialog={setOpenCollectFeeDialog}
										pagination={pagination}
										setPagination={setPagination}
										pageCount={Math.ceil(totalCount / pagination.pageSize)}
										colFilter={colFilter}
										setColFilter={setColFilter}
										columnVisibility={columnVisibility}
										setColumnVisibility={setColumnVisibility}
										columnSizing={columnSizing}
										setColumnSizing={setColumnSizing}
										columnOrder={columnOrder}
										setColumnOrder={setColumnOrder}
										setDefaultView={setDefaultTemplate}
										selectedTemplate={selectedTemplate}
										setSelectedTemplate={setSelectedTemplate}
										templates={templates}
										setHandleDeleteView={setHandleDeleteView}
									/>
								</LocalizationProvider>
							</ScrollableContainer>
						) : (
							<Box
								width={"100%"}
								height={"100%"}
								display={"flex"}
								flexDirection={"column"}
								justifyContent={"center"}
								alignItems={"center"}
								mt={10}
							>
								<img src={images.notFound} alt="Not Found" width={200} height={200} />
								<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ my: 2 }}>
									{t("NoFeeQuery")}
								</Typography>
								<Button
									size="small"
									endIcon={!isRtl && <IoClose color="red" />}
									onClick={() => {
										setColFilter([]);
									}}
									sx={{
										bgcolor: "#fff",
										color: "#164c68",
										padding: 1,
										border: "1px solid #164c68",
									}}
								>
									{t("ClearSearch")}
								</Button>
							</Box>
						)}
					</Box>
				</Box>
			</Box>
			{openFeeDialog && <FeeFormDialog open={openFeeDialog} handleClose={() => setOpenFeeDialog(false)} />}
			{openDeleteFeeDialog && <DeleteFeeDialog open={openDeleteFeeDialog} handleClose={() => setOpenDeleteFeeDialog(false)} />}
			{openCollectFeeDialog && <CollectFeeDialog open={openCollectFeeDialog} handleClose={() => setOpenCollectFeeDialog(false)} />}
			{handleDeleteView && (
				<ConfirmDeleteTableView
					open={handleDeleteView}
					handleClose={() => setHandleDeleteView(false)}
					templateId={selectedTemplate?.id}
					template={selectedTemplate}
					setTemplates={setTemplates}
					setSelectedTemplate={() => setSelectedTemplate(null)}
					type={"fee"}
					setColumnVisibility={setColumnVisibility}
					setColumnOrder={setColumnOrder}
					setDefaultView={setDefaultTemplate}
				/>
			)}
		</Stack>
	);
};

export default FeesSection;
