import { Box, Button, Fade, Grid, Stack, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { IoCaretDownOutline } from "react-icons/io5";
import React, { useState } from "react";
import PartyCard from "./PartyCard";
import { images } from "../../Assets/index";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Parties = ({ parties, setParties, setAddingParty, setEditParty, setSelectedParty, setOpenAddContact }) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const [expanded, setExpanded] = useState(false);

	const handleExpansion = () => {
		setExpanded((prevExpanded) => !prevExpanded);
	};
	return (
		<Accordion
			expanded={expanded}
			onChange={handleExpansion}
			slots={{ transition: Fade }}
			slotProps={{ transition: { timeout: 1500 } }}
			sx={{
				bgcolor: "#fff",
				boxShadow: "none",
				"& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
				"& .MuiAccordionDetails-root": { display: expanded ? "block" : "none" },
			}}
		>
			<AccordionSummary expandIcon={<IoCaretDownOutline size={20} color={"#164c68"} />} aria-controls="panel1-content" id="panel1-header">
				<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={1}>
						<Box
							p={1}
							display={"flex"}
							justifyContent={"center"}
							alignItems={"center"}
							bgcolor={"#e9e7e770"}
							borderRadius={1}
							sx={{ ml: `${isRtl ? "8px" : "0px"} !important` }}
						>
							<img src={images.team} alt="users" width={20} height={20} />
						</Box>
						<Typography variant={"h3"} fontWeight={500} color={"#164c68"}>
							{t("Parties")}
						</Typography>
					</Stack>
					<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} width={"100%"} mr={1} spacing={1}>
						<Button
							onClick={() => {
								setAddingParty(true);
							}}
							sx={{
								bgcolor: "#164c68",
								color: "#fff",
								"&:hover": {
									bgcolor: "#00a3b1",
									color: "#fff",
								},
								transition: "all 1s ease",
								mx: `${isRtl ? "8px" : "0px"} !important`,
							}}
						>
							{t("AddParty")}
						</Button>
						<Button
							onClick={() => {
								setOpenAddContact(true);
							}}
							sx={{
								bgcolor: "#164c68",
								color: "#fff",
								"&:hover": {
									bgcolor: "#00a3b1",
									color: "#fff",
								},
								transition: "all 1s ease",
							}}
						>
							{t("AddContact")}
						</Button>
					</Stack>
				</Stack>
			</AccordionSummary>
			<AccordionDetails>
				<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={4} p={1} width={"100%"}>
					{parties?.length > 0 ? (
						<Grid container spacing={2}>
							{parties?.map((party, index) => (
								<Grid item xs={12} sm={3} key={index}>
									<PartyCard
										party={party}
										parties={parties}
										setParties={setParties}
										setEditPartyDialog={setEditParty}
										setSelectedParty={setSelectedParty}
									/>
								</Grid>
							))}
						</Grid>
					) : (
						<Box
							width={"100%"}
							height={"100%"}
							display={"flex"}
							flexDirection={"column"}
							justifyContent={"center"}
							alignItems={"center"}
							m={"0 auto"}
							p={2}
						>
							<img src={images.notFound} alt="Not Found" width={100} height={100} />
							<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ my: 2 }}>
								{t("NoParties")}!
							</Typography>
							<Typography variant={"subtitle1"} fontWeight={500} color={"#51505099"}>
								{t("AddParty")}!
							</Typography>
						</Box>
					)}
				</Stack>
			</AccordionDetails>
		</Accordion>
	);
};

export default Parties;
