import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useState } from "react";
import Animate from "../../Common/Animate";
import FirstSection from "../CaseDetails/FirstSection";
import { images } from "../../Assets";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { setCurrentHearing, setIsEditingHearing } from "../../../redux/slices/case";
import FileSection from "../CaseDetails/FileSection";
import { useTranslation } from "react-i18next";
import DeleteHearingDialog from "./DeleteHearingDialog";

const HearingDetails = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	const isRtl = useSelector((state) => state.app.isRtl);
	const currentHearing = useSelector((state) => state.case.currentHearing);

	const [openDeleteHearingDialog, setOpenDeleteHearingDialog] = useState(false);

	return (
		<Animate type="fade" delay={0.5}>
			<Box
				width={"100%"}
				height={"max-content"}
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
				sx={{ overflowY: "auto", overflowX: "hidden" }}
				px={isSmallScreen ? 6 : 15}
				py={2}
				gap={4}
			>
				<FirstSection category={"hearing"} />
				<Box
					width={"100%"}
					height={"max-content"}
					sx={{ overflowY: "auto", overflowX: "hidden", boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;" }}
					p={4}
					borderRadius={1.5}
					bgcolor={"#fff"}
				>
					<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} spacing={1} width={"100%"}>
						<Typography variant={"h2"} fontWeight={600} color={"#164c68"}>
							{t("HearingDetails")}:
						</Typography>
						<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1}>
							<Button
								sx={{
									bgcolor: "#164c68",
									color: "#fff",
									"&:hover": {
										bgcolor: "#00a3b1",
									},
									transition: "all 1s ease",
								}}
								onClick={() => {
									dispatch(setIsEditingHearing(true));
									dispatch(setCurrentHearing(currentHearing));
									navigate("/dashboard/case/hearing");
								}}
							>
								{t("Edit")}
							</Button>
							<Button
								onClick={() => setOpenDeleteHearingDialog(true)}
								sx={{
									color: "#fff",
									backgroundColor: "#fc6739",
									marginRight: `${isRtl ? "8px" : "0px"} !important`,
									"&:hover": {
										color: "#fff",
										backgroundColor: "red",
									},
									transition: "all 1s ease",
								}}
							>
								{t("Delete")}
							</Button>
						</Stack>
					</Stack>

					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />

					<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={4} width={"100%"}>
						<Stack direction={"row"} width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
							<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
								<img src={images.calendar} alt="calendar" width={25} height={25} style={{ marginLeft: isRtl ? "8px" : "0px" }} />
								<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mb: 1, ml: isRtl ? 2 : 0 }}>
									{t("Date")}:
								</Typography>
								<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1 }}>
									{moment(currentHearing?.date).format("MMMM Do YYYY")}
								</Typography>
							</Stack>
						</Stack>
						<Stack direction={"row"} width={"100%"} justifyContent={"flex-start"} alignItems={"center"} spacing={5}>
							<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={3}>
								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
									<img src={images.decision} alt="decision" width={25} height={25} style={{ marginLeft: isRtl ? "8px" : "0px" }} />
									<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mb: 1, ml: isRtl ? 2 : 0 }}>
										{t("HearingDecision")}:
									</Typography>
									<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1, textTransform: "capitalize" }}>
										{currentHearing?.decision ? currentHearing?.decision.nameAr : "N/A"}
									</Typography>
								</Stack>
								{currentHearing?.decision && (
									<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
										<img src={images.notes} alt="notes" width={25} height={25} style={{ marginLeft: isRtl ? "8px" : "0px" }} />
										<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mb: 1, ml: isRtl ? 2 : 0 }}>
											{t("DecisionDetails")}:
										</Typography>
										<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1 }}>
											{currentHearing?.decisionDetails}
										</Typography>
									</Stack>
								)}
							</Stack>
						</Stack>
						<Stack direction={"row"} width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
							<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
								<img src={images.notes} alt="notes" width={25} height={25} style={{ marginLeft: isRtl ? "8px" : "0px" }} />
								<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mb: 1, ml: isRtl ? 2 : 0 }}>
									{t("HearingNotes")}:
								</Typography>
								<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1 }}>
									{currentHearing?.note ? currentHearing?.note : "N/A"}
								</Typography>
							</Stack>
							<Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={1}>
								<img src={images.notes} alt="notes" width={25} height={25} style={{ marginLeft: isRtl ? "8px" : "0px" }} />
								<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mb: 1, ml: isRtl ? 2 : 0 }}>
									{t("HearingRequests")}:
								</Typography>
								<Typography variant="h5" color={"#164c68"} fontWeight={500} textTransform={"capitalize"} sx={{ mb: 1 }}>
									{currentHearing?.requests ? currentHearing?.requests : "N/A"}
								</Typography>
							</Stack>
						</Stack>
					</Stack>
				</Box>
				<FileSection criteria={"hearing"} criteriaId={currentHearing.id} />
			</Box>
			{openDeleteHearingDialog && <DeleteHearingDialog open={openDeleteHearingDialog} handleClose={() => setOpenDeleteHearingDialog(false)} />}
		</Animate>
	);
};

export default HearingDetails;
