import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { images } from "../../Assets";
import { useGetCasesQuery } from "../../../redux/slices/api/cases/caseApi";
import { useTranslation } from "react-i18next";

const StatsList = ({ cases }) => {
	const { t } = useTranslation();
	const ongoingCases = useGetCasesQuery({ status: "ongoing", data: false }, { refetchOnMountOrArgChange: true });
	const closedCases = useGetCasesQuery({ status: "closed", data: false }, { refetchOnMountOrArgChange: true });
	return (
		<Stack width={"100%"} height={"100%"} justifyContent={"center"} alignItems={"center"} spacing={10}>
			<Stack direction={"row"} width={"100%"} justifyContent={"space-between"} alignItems={"center"} p={2} borderBottom={"1px solid #aaaaaa6b"}>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<Box p={1} display={"flex"} justifyContent={"center"} alignItems={"center"} bgcolor={"#e9e7e770"} borderRadius={1}>
						<img src={images.case} alt="cases" width={30} height={30} />
					</Box>
					<Stack justifyContent={"flex-start"} alignItems={"flex-start"}>
						<Typography variant={"h4"} fontWeight={600}>
							{t("TotalCases")}:
						</Typography>
					</Stack>
				</Stack>
				<Stack justifyContent={"flex-end"} alignItems={"flex-end"} spacing={1}>
					<Typography
						variant={"h5"}
						fontWeight={500}
						sx={{
							fontFamily: '"Montserrat", "sans-serif"',
						}}
					>
						{cases}
					</Typography>
				</Stack>
			</Stack>
			<Stack direction={"row"} width={"100%"} justifyContent={"space-between"} alignItems={"center"} p={2} borderBottom={"1px solid #aaaaaa6b"}>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<Box p={1} display={"flex"} justifyContent={"center"} alignItems={"center"} bgcolor={"#e9e7e770"} borderRadius={1}>
						<img src={images.decision} alt="decision" width={30} height={30} />
					</Box>
					<Stack justifyContent={"flex-start"} alignItems={"flex-start"}>
						<Typography variant={"h4"} fontWeight={600}>
							{t("ClosedCases")}:
						</Typography>
					</Stack>
				</Stack>
				<Stack justifyContent={"flex-end"} alignItems={"flex-end"} spacing={1}>
					<Typography
						variant={"h5"}
						fontWeight={500}
						sx={{
							fontFamily: '"Montserrat", "sans-serif"',
						}}
					>
						{closedCases?.data?.totalRecords}
					</Typography>
				</Stack>
			</Stack>
			<Stack direction={"row"} width={"100%"} justifyContent={"space-between"} alignItems={"center"} p={2} borderBottom={"1px solid #aaaaaa6b"}>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<Box p={1} display={"flex"} justifyContent={"center"} alignItems={"center"} bgcolor={"#e9e7e770"} borderRadius={1}>
						<img src={images.judge} alt="judge" width={30} height={30} />
					</Box>
					<Stack justifyContent={"flex-start"} alignItems={"flex-start"}>
						<Typography variant={"h4"} fontWeight={600}>
							{t("OngoingCases")}:
						</Typography>
					</Stack>
				</Stack>
				<Stack justifyContent={"flex-end"} alignItems={"flex-end"} spacing={1}>
					<Typography
						variant={"h5"}
						fontWeight={500}
						sx={{
							fontFamily: '"Montserrat", "sans-serif"',
						}}
					>
						{ongoingCases?.data?.totalRecords}
					</Typography>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default StatsList;
