import { Box, Divider, ListItemIcon, MenuItem, Typography } from "@mui/material";
import { FaUsersCog } from "react-icons/fa";
import { MdManageAccounts, MdOutlineMail } from "react-icons/md";
import { FaBell, FaUserShield } from "react-icons/fa6";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "./MenuCommon";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { TbVariable } from "react-icons/tb";

const AppBarMenu = ({ open, anchorEl, handleClose, setAnchorEl }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const userDetails = useSelector((state) => state.app.userDetails);
	return (
		<Menu
			id="appBar-menu"
			anchorEl={anchorEl}
			open={open}
			onClose={handleClose}
			MenuListProps={{
				"aria-labelledby": "menu-button",
			}}
		>
			<MenuItem
				onClick={() => {
					navigate("/dashboard/my-account");
					setAnchorEl(null);
				}}
			>
				<ListItemIcon>
					<MdManageAccounts size={22} color={"#fff"} />
				</ListItemIcon>
				<Typography variant="h6" color={"#fff"}>
					{t("AccountSettings")}
				</Typography>
			</MenuItem>
			<Divider sx={{ my: 1, bgcolor: "#164c6866" }} />
			<MenuItem
				onClick={() => {
					navigate("/dashboard/settings");
					setAnchorEl(null);
				}}
			>
				<ListItemIcon>
					<FaUsersCog size={22} color={"#fff"} />
				</ListItemIcon>
				<Typography variant="h6" color={"#fff"}>
					{t("UserSettings")}
				</Typography>
			</MenuItem>
			{userDetails?.role !== "Employee" && (
				<Box>
					<Divider sx={{ my: 1, bgcolor: "#164c6866" }} />
					<MenuItem
						onClick={() => {
							navigate("/dashboard/permission-group");
							setAnchorEl(null);
						}}
					>
						<ListItemIcon>
							<FaUserShield size={22} color={"#fff"} />
						</ListItemIcon>
						<Typography variant="h6" color={"#fff"}>
							{t("PermissionSettings")}
						</Typography>
					</MenuItem>
				</Box>
			)}
			<Divider sx={{ my: 1, bgcolor: "#164c6866" }} />
			<MenuItem
				onClick={() => {
					navigate("/dashboard/constraints/settings");
					setAnchorEl(null);
				}}
			>
				<ListItemIcon>
					<TbVariable size={22} color={"#fff"} />
				</ListItemIcon>
				<Typography variant="h6" color={"#fff"}>
					{t("ConstraintsSettings")}
				</Typography>
			</MenuItem>
			<Divider sx={{ my: 1, bgcolor: "#164c6866" }} />
			<MenuItem
				onClick={() => {
					navigate("/dashboard/email/settings");
					setAnchorEl(null);
				}}
			>
				<ListItemIcon>
					<MdOutlineMail size={22} color={"#fff"} />
				</ListItemIcon>
				<Typography variant="h6" color={"#fff"}>
					{t("EmailSettings")}
				</Typography>
			</MenuItem>
			<Divider sx={{ my: 1, bgcolor: "#164c6866" }} />
			<MenuItem
				onClick={() => {
					navigate("/dashboard/reminders/list");
					setAnchorEl(null);
				}}
			>
				<ListItemIcon>
					<FaBell size={22} color={"#fff"} />
				</ListItemIcon>
				<Typography variant="h6" color={"#fff"}>
					{t("ReminderSettings")}
				</Typography>
			</MenuItem>
		</Menu>
	);
};

export default AppBarMenu;
