import { Box, Button, Grid, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import RecentCase from "../../../components/Dashboard/ThirdSection/RecentCase";
import TaskOverview from "../../../components/Dashboard/ThirdSection/TaskOverview";
import TaskList from "../../../components/Dashboard/ThirdSection/TaskList";
import SectionHeader from "../../../components/Common/SectionHeader";
import { FaTasks } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import UpcomingFees from "../../../components/Dashboard/ThirdSection/UpcomingFees";

const ThirdSection = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
	const navigate = useNavigate();
	const isRtl = useSelector((state) => state.app.isRtl);
	return (
		<Grid container spacing={2} width={"100%"} sx={{ marginLeft: "0px" }}>
			<Grid item xs={12} sm={4} pl={`0px !important`} pr={4}>
				<Stack
					width={"100%"}
					height={"100%"}
					spacing={2}
					justifyContent={"flex-start"}
					alignItems={"start"}
					sx={{ marginLeft: `${isRtl && "32px"} !important` }}
				>
					<UpcomingFees isSmallScreen={isSmallScreen} />
					<RecentCase />
				</Stack>
			</Grid>
			<Grid item xs={12} sm={4} pl={`0px !important`} pr={4}>
				<Box
					width={"100%"}
					height={"100%"}
					borderRadius={2}
					p={3}
					sx={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;" }}
					bgcolor={"#fff"}
				>
					<TaskOverview />
				</Box>
			</Grid>
			<Grid item xs={12} sm={4} pl={`0px !important`} pr={`${isRtl ? "32px" : "0px"} !important`}>
				<Stack
					width={"100%"}
					height={"100%"}
					spacing={2}
					justifyContent={"flex-start"}
					alignItems={"start"}
					borderRadius={2}
					p={2}
					sx={{
						boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;",
					}}
					bgcolor={"#fff"}
				>
					<SectionHeader icon={<FaTasks color="#164c68" size={18} />} title={t("TaskList")} />
					<TaskList />
					<Button
						variant="contained"
						size="small"
						fullWidth
						sx={{
							bgcolor: "#164c68",
							color: "#fff",
							"&:hover": {
								bgcolor: "#00a3b1",
							},
							transition: "all 0.5s ease",
							mt: 2,
							ml: 2, // Add margin to the left
							mr: 2, // Add margin to the right
						}}
						onClick={() => navigate("/dashboard/tasks")}
					>
						{t("ViewAll")}
					</Button>
				</Stack>
			</Grid>
		</Grid>
	);
};

export default ThirdSection;
