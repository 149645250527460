import { queryBuilder } from "../../../../utils/queryBuilder";
import { apiSlice } from "../apiSlice";

export const hearingApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAllHearings: builder.query({
			query: (query) => ({
				url: `/hearing?total=true&${queryBuilder(query)}`,
				method: "GET",
			}),
			providesTags: ["Hearings"],
		}),
		getHearingById: builder.query({
			query: (hearingId) => ({
				url: `/hearing/${hearingId}`,
				method: "GET",
			}),
		}),
		addHearing: builder.mutation({
			query: (data) => ({
				url: "/hearing",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["Hearings"],
		}),
		updateHearing: builder.mutation({
			query: ({ hearingId, data }) => ({
				url: `/hearing/${hearingId}`,
				method: "PATCH",
				body: data,
			}),
			invalidatesTags: ["Hearings"],
		}),
		deleteHearing: builder.mutation({
			query: (hearingId) => ({
				url: `/hearing/${hearingId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Hearings"],
		}),
	}),
});

export const { useGetAllHearingsQuery, useGetHearingByIdQuery, useAddHearingMutation, useUpdateHearingMutation, useDeleteHearingMutation } = hearingApi;
