import { Box, Button, CircularProgress, FormControl, IconButton, MenuItem, Select, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddConstraintDialog from "../../Case/CreateCaseFile/AddConstraintDialog";
import { useGetConstraintsQuery } from "../../../redux/slices/api/constraints/constraintsApi";
import { setSnackbar } from "../../../redux/slices/app";
import { images } from "../../Assets";
import { setIsEditingConstraint, setSelectedConstraint } from "../../../redux/slices/constraints";
import { RiDeleteBin6Fill, RiEdit2Fill } from "react-icons/ri";
import DeleteConstraintDialog from "./DeleteConstraintDialog";

const ConstraintsList = () => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [reference, setReference] = useState("court");
	const [constraints, setConstraints] = useState([]);
	const [openAddConstraintDialog, setOpenAddConstraintDialog] = useState(false);
	const [openDeleteConstraintDialog, setOpenDeleteConstraintDialog] = useState(false);

	const { data, isLoading, isError, error } = useGetConstraintsQuery({ reference }, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (isError) {
			dispatch(setSnackbar({ open: true, message: t("AnErrorOccurred"), severity: "error" }));
			console.log("Fetch constraint error", error);
			return;
		}
		if (data) {
			setConstraints(data?.data);
		}
	}, [data, reference]);

	return (
		<Stack justifyContent={"center"} direction={"column"} spacing={2} width={"100%"} height={"100%"} alignItems={"center"}>
			<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
					<Button
						// startIcon={!isRtl && <TbVariable color="#fff" />}
						onClick={() => {
							dispatch(setIsEditingConstraint(false));
							dispatch(setSelectedConstraint(null));
							setOpenAddConstraintDialog(true);
						}}
						sx={{
							bgcolor: "#164c68",
							color: "#fff",
							padding: 1,
							"&:hover": {
								bgcolor: "#00a3b1",
							},
							transition: "all 0.5s ease",
						}}
					>
						{t("CreateNew")}
					</Button>
				</Stack>
				<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1} width={"100%"}>
					<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1 }}>
						{t("Reference")}:
					</Typography>
					<FormControl sx={{ m: 1, minWidth: 100 }} size="small">
						<Select id="reference" value={reference} onChange={(e) => setReference(e.target.value)}>
							<MenuItem value={"court"}>{t("Court")}</MenuItem>
							<MenuItem value={"fileType"}>{t("FileType")}</MenuItem>
							<MenuItem value={"caseStage"}>{t("CaseStage")}</MenuItem>
							<MenuItem value={"caseType"}>{t("CaseType")}</MenuItem>
							<MenuItem value={"closeReason"}>{t("CloseReason")}</MenuItem>
							<MenuItem value={"caseRole"}>{t("CaseRole")}</MenuItem>
							<MenuItem value={"decision"}>{t("Decision")}</MenuItem>
							<MenuItem value={"memoType"}>{t("MemoType")}</MenuItem>
							<MenuItem value={"fileCategory"}>{t("FileCategory")}</MenuItem>
							<MenuItem value={"commercialBroker"}>{t("CommercialBroker")}</MenuItem>
						</Select>
					</FormControl>
				</Stack>
			</Stack>
			<Box
				mt={6}
				width={"100%"}
				height={"100%"}
				maxHeight={"600px"}
				overflow={"auto"}
				display={"flex"}
				flexDirection={"column"}
				justifyContent={"flex-start"}
				alignItems={"center"}
				gap={4}
			>
				{isLoading ? (
					<CircularProgress />
				) : constraints?.length >= 1 ? (
					constraints.map((constraint) => (
						<Box
							key={constraint.id}
							width={"100%"}
							height={"auto"}
							p={2}
							display={"flex"}
							justifyContent={"space-between"}
							alignItems={"center"}
							gap={2}
							borderBottom={"1px solid #aaaaaa6b"}
						>
							<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={2} width={"100%"}>
								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
									<img src={images.variables} alt={"constraint"} width={25} height={25} />
									<Typography variant={"h4"} fontWeight={700} color={"#164c68"}>
										{t("ArabicName")}:
									</Typography>
									<Typography variant={"h5"} fontWeight={500} color={"#164c68"}>
										{constraint.nameAr}
									</Typography>
								</Stack>
								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
									<img src={images.variables} alt={"constraint"} width={25} height={25} />
									<Typography variant={"h4"} fontWeight={700} color={"#164c68"}>
										{t("EnglishName")}:
									</Typography>
									<Typography variant={"h5"} fontWeight={500} color={"#164c68"}>
										{constraint.nameEn ? constraint.nameEn : "N/A"}
									</Typography>
								</Stack>
								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
									<img src={constraint.status === true ? images.low : images.high} alt={"constraint"} width={25} height={25} />
									<Typography variant={"h4"} fontWeight={700} color={"#164c68"}>
										{t("status")}:
									</Typography>
									<Typography variant={"h5"} fontWeight={500} color={"#164c68"}>
										{constraint.status === true ? t("Active") : t("Inactive")}
									</Typography>
								</Stack>
							</Stack>
							<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={2} width={"100%"}>
								<Tooltip title={t("Update")} placement="top" arrow>
									<IconButton
										onClick={() => {
											dispatch(setSelectedConstraint(constraint));
											dispatch(setIsEditingConstraint(true));
											setOpenAddConstraintDialog(true);
										}}
									>
										<RiEdit2Fill size={25} color={"#164c68"} />
									</IconButton>
								</Tooltip>
								<Tooltip title={t("Delete")} placement="top" arrow>
									<IconButton
										onClick={() => {
											dispatch(setSelectedConstraint(constraint));
											setOpenDeleteConstraintDialog(true);
										}}
									>
										<RiDeleteBin6Fill size={25} color={"#fb6540"} />
									</IconButton>
								</Tooltip>
							</Stack>
						</Box>
					))
				) : (
					<Box width={"100%"} height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} mt={10}>
						<img src={images.notFound} alt="Not Found" width={200} height={200} />
						<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ my: 2 }}>
							{t("NoConstraintsFound")}
						</Typography>
						<Typography variant={"subtitle1"} fontWeight={500} color={"#51505099"}>
							{t("AddConstraint")}
						</Typography>
					</Box>
				)}
			</Box>
			{openAddConstraintDialog && (
				<AddConstraintDialog open={openAddConstraintDialog} handleClose={() => setOpenAddConstraintDialog(false)} reference={reference} />
			)}
			{openDeleteConstraintDialog && (
				<DeleteConstraintDialog open={openDeleteConstraintDialog} handleClose={() => setOpenDeleteConstraintDialog(false)} />
			)}
		</Stack>
	);
};

export default ConstraintsList;
