import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import SectionHeader from "./SectionHeader";
import { FaUserCheck } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../redux/slices/app";
import { useVerifyTypeMutation } from "../../redux/slices/api/auth/authApi";
import { useGetMineQuery } from "../../redux/slices/api/user/userApi";

const VerificationDialog = ({ open, handleClose, verificationMethod, setVerificationMethod, openOtpDialog }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const isRtl = useSelector((state) => state.app.isRtl);

	const fetchMine = useGetMineQuery({}, { refetchOnMountOrArgChange: true });

	const [isLoading, setIsLoading] = useState(false);

	const [verifyType] = useVerifyTypeMutation();

	const handleVerifyUser = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		let data = {};

		if (verificationMethod === "email") {
			data["email"] = true;
		} else {
			data["phone"] = true;
		}

		try {
			await verifyType(data).unwrap();
			dispatch(setSnackbar({ open: true, message: t("CodeSentSuccessfully"), severity: "success" }));
			setIsLoading(false);
			handleClose();
			openOtpDialog();
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setIsLoading(false);
		}
	};

	return (
		<Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
			<DialogTitle sx={{ pb: 2, borderBottom: "1px solid #aaaaaa6b", backgroundColor: "#fff" }}>
				<SectionHeader icon={<FaUserCheck color="#164c68" size={18} />} title={`${t("VerifyUser")}`} type={"dialog"} />
			</DialogTitle>
			<DialogContent sx={{ backgroundColor: "#fff" }}>
				<DialogContentText id="add-user-dialog-description" sx={{ p: 4 }}>
					<Typography variant={"h5"} fontWeight={500} color={"#164c68"} sx={{ mb: 2 }}>
						{t("VerifyUserDescription")}.
					</Typography>
					<Typography variant={"h5"} fontWeight={500} color={"#164c68"} sx={{ mb: 2 }}>
						{t("VerifyThru")}:
					</Typography>
					<FormControl>
						<RadioGroup
							aria-labelledby="demo-controlled-radio-buttons-group"
							name="controlled-radio-buttons-group"
							value={verificationMethod}
							onChange={(e) => setVerificationMethod(e.target.value)}
						>
							{!fetchMine.data?.data?.isVerified.email && (
								<FormControlLabel value="email" control={<Radio />} label={`${fetchMine.data?.data?.email}`} />
							)}
							{!fetchMine.data?.data?.isVerified.phone && (
								<FormControlLabel value="phone" control={<Radio />} label={`${fetchMine.data?.data?.phone}`} />
							)}
						</RadioGroup>
					</FormControl>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: "#fff" }}>
				<LoadingButton
					loading={isLoading}
					variant="contained"
					size="medium"
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						mr: `${isRtl ? "8px" : "0px"} !important`,
					}}
					onClick={handleVerifyUser}
				>
					{t("Send")}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default VerificationDialog;
