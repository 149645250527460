import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import CustomTextField from "../../Common/CustomTextField";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CiCalendarDate } from "react-icons/ci";
import { setCurrentMeeting, setIsCreatingMeeting, setIsEditingMeeting } from "../../../redux/slices/meetings";
import { images } from "../../Assets";
import ListCard from "./ListCard";
import { useTranslation } from "react-i18next";
import { SiMicrosoftexcel } from "react-icons/si";
import { excelExport, meetingFilePreProcessor } from "../../../utils/exportHelper";

const ListView = ({ rows, filteredRows, searchValue, handleSearchInput, isSearching, clearSearch, isLoading, isError, setDeleteMeeting }) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	const navigate = useNavigate();
	const dispatch = useDispatch();
	return (
		<Box
			height={"max-content"}
			width={isSmallScreen ? "100%" : "80%"}
			display={"flex"}
			justifyContent={"center"}
			alignItems={"center"}
			flexDirection={"column"}
			p={2}
		>
			<Stack direction="row" justifyContent={"space-between"} alignItems={"center"} width={"100%"} height={"max-content"} my={1}>
				<Stack width={"100%"} direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<Button
						size="small"
						startIcon={!isRtl && <CiCalendarDate color="#fff" />}
						onClick={() => {
							dispatch(setIsCreatingMeeting(true));
							dispatch(setIsEditingMeeting(false));
							dispatch(setCurrentMeeting(null));
							navigate("/dashboard/meeting");
						}}
						sx={{
							bgcolor: "#164c68",
							color: "#fff",
							padding: 1.5,
							"&:hover": {
								bgcolor: "#00a3b1",
							},
							transition: "all 0.5s ease",
						}}
					>
						{t("CreateNew")}
					</Button>
					<Button
						size="small"
						startIcon={!isRtl && <SiMicrosoftexcel color="#fff" />}
						onClick={() => {
							const preProcessedData = meetingFilePreProcessor(rows);
							excelExport(preProcessedData, "test", "MeetingsReport.xlsx");
						}}
						sx={{
							bgcolor: "#164c68",
							color: "#fff",
							padding: 1.5,
							"&:hover": {
								bgcolor: "#00a3b1",
							},
							transition: "all 0.5s ease",
						}}
					>
						{t("ExportExcel")}
					</Button>
				</Stack>
				<Stack width={"100%"} direction={"row"} justifyContent={"flex-end"} alignItems={"center"}>
					<CustomTextField
						label={t("Search")}
						name={"searchValue"}
						value={searchValue}
						type={"text"}
						handleInputChange={handleSearchInput}
						isSearching={isSearching}
						clearSearch={clearSearch}
						require={false}
					/>
				</Stack>
			</Stack>
			{isLoading && !isError ? (
				<CircularProgress />
			) : (rows?.length < 1 && !isLoading && !isError) || (isSearching && rows?.length < 1) ? (
				<Box
					width={"100%"}
					height={"100%"}
					display={"flex"}
					flexDirection={"column"}
					justifyContent={"center"}
					alignItems={"center"}
					m={"0 auto"}
					p={2}
				>
					<img src={images.notFound} alt="Not Found" width={200} height={200} />
					<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ my: 2 }}>
						{t("NoMeetingAvailable")}!
					</Typography>
					<Typography variant={"subtitle1"} fontWeight={500} color={"#51505099"}>
						{t("CreateNewMeeting")}.
					</Typography>
				</Box>
			) : rows && rows?.length >= 1 && !isError ? (
				<Stack justifyContent={"center"} alignItems={"center"} spacing={4} mt={4} width={isSmallScreen ? "100%" : "80%"} height={"100%"}>
					{isSearching
						? filteredRows?.map((row) => <ListCard key={row.id} data={row} setDeleteMeeting={setDeleteMeeting} />)
						: rows?.map((row) => <ListCard key={row.id} data={row} setDeleteMeeting={setDeleteMeeting} />)}
				</Stack>
			) : null}
		</Box>
	);
};

export default ListView;
