import { Box, Button, Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useState } from "react";
import SectionHeader from "../../../Common/SectionHeader";
import { MdOutlineAlternateEmail, MdOutlineEmail } from "react-icons/md";
import { images } from "../../../Assets";
import { useGetEmailAccountQuery } from "../../../../redux/slices/api/email/emailApi";
import CustomBackdrop from "../../../Common/CustomBackdrop";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { IoEye, IoEyeOff } from "react-icons/io5";
import DeleteEmailDialog from "./DeleteEmailDialog";
import CreateEmailDialog from "./CreateEmailDialog";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const EmailAccount = () => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const { data, isLoading, isError } = useGetEmailAccountQuery({}, { refetchOnMountOrArgChange: true });
	const [emailAccount, setEmailAccount] = useState(null);
	const [showPassword, setShowPassword] = useState(false);

	const [deleteEmail, setDeleteEmail] = useState(false);
	const [createEmail, setCreateEmail] = useState(false);

	useEffect(() => {
		if (isError) return;
		if (data && !isLoading) {
			setEmailAccount(data);
		}
	}, [data, isLoading, isError, emailAccount]);

	return (
		<Box
			width={isSmallScreen ? "100%" : "65%"}
			height={"auto"}
			p={2}
			borderRadius={1.5}
			sx={{
				boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
			}}
			bgcolor={"#fff"}
		>
			<SectionHeader icon={<MdOutlineAlternateEmail color="#164c68" size={18} />} title={t("EmailAccount")} type={"dialog"} />
			<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
			{isLoading && !isError && <CustomBackdrop open={true} />}
			{emailAccount && !isLoading && !isError ? (
				<>
					<Stack justifyContent={"flex-start"} alignItems={"flex-start"} spacing={3}>
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1.5}>
							<img src={images.email} alt="email" width={25} height={25} />
							<Typography variant={"h5"} fontWeight={700} color={"#164c68"} sx={{ mx: `${isRtl ? "8px" : "0px"} !important` }}>
								{t("EmailAddress")}:
							</Typography>
							<Typography variant={"h5"} fontWeight={600} color={"#164c68"}>
								{emailAccount?.username}
							</Typography>
						</Stack>
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1.5}>
							<img src={images.password} alt="password" width={25} height={25} />
							<Typography variant={"h5"} fontWeight={700} color={"#164c68"} sx={{ mx: `${isRtl ? "8px" : "0px"} !important`, width: "100%" }}>
								{t("Password")}:
							</Typography>
							<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={6}>
								<>
									{showPassword ? (
										<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ mx: `${isRtl ? "8px" : "0px"} !important` }}>
											{emailAccount?.password}
										</Typography>
									) : (
										<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ mx: `${isRtl ? "8px" : "0px"} !important` }}>
											{emailAccount?.password.replace(/./g, "*")}
										</Typography>
									)}
								</>
								<Tooltip title={showPassword ? t("HidePassword") : t("ShowPassword")} arrow placement="right">
									<IconButton onClick={() => setShowPassword(!showPassword)} sx={{ p: 0 }}>
										{showPassword ? <IoEyeOff color={"#164c68"} /> : <IoEye color={"#164c68"} />}
									</IconButton>
								</Tooltip>
							</Stack>
						</Stack>
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1.5}>
							<img src={images.username} alt="username" width={25} height={25} />
							<Typography variant={"h5"} fontWeight={700} color={"#164c68"} sx={{ mx: `${isRtl ? "8px" : "0px"} !important` }}>
								{t("DisplayName")}:
							</Typography>
							<Typography variant={"h5"} fontWeight={600} color={"#164c68"}>
								{emailAccount?.name}
							</Typography>
						</Stack>
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1.5}>
							<img src={images.host} alt="hostname" width={25} height={25} />
							<Typography variant={"h5"} fontWeight={700} color={"#164c68"} sx={{ mx: `${isRtl ? "8px" : "0px"} !important` }}>
								{t("HostName")}:
							</Typography>
							<Typography variant={"h5"} fontWeight={600} color={"#164c68"}>
								{emailAccount?.host}
							</Typography>
						</Stack>
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1.5}>
							<img src={images.port} alt="port" width={25} height={25} />
							<Typography variant={"h5"} fontWeight={700} color={"#164c68"} sx={{ mx: `${isRtl ? "8px" : "0px"} !important` }}>
								{t("Port")}:
							</Typography>
							<Typography variant={"h5"} fontWeight={600} color={"#164c68"}>
								{emailAccount?.port}
							</Typography>
						</Stack>
					</Stack>
				</>
			) : (
				<Box
					width={"100%"}
					height={"100%"}
					display={"flex"}
					flexDirection={"column"}
					justifyContent={"center"}
					alignItems={"center"}
					m={"0 auto"}
					p={2}
				>
					<img src={images.notFound} alt="Not Found" width={200} height={200} />
					<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ my: 2 }}>
						{t("NoEmailAccount")}!
					</Typography>
					<Typography variant={"subtitle1"} fontWeight={500} color={"#51505099"}>
						{t("AddEmailAccount")}!
					</Typography>
				</Box>
			)}

			<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
			<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} width={"100%"}>
				{emailAccount && !isLoading && !isError ? (
					<Button
						onClick={() => setDeleteEmail(true)}
						startIcon={!isRtl && <RiDeleteBin6Fill />}
						sx={{
							color: "#fff",
							backgroundColor: "#fc6739",
							"&:hover": {
								color: "#fff",
								backgroundColor: "red",
							},
							transition: "all 1s ease",
						}}
					>
						{t("Delete")}
					</Button>
				) : (
					<Button
						variant="contained"
						startIcon={!isRtl && <MdOutlineEmail />}
						size="medium"
						sx={{
							bgcolor: "#164c68",
							color: "#fff",
						}}
						onClick={() => setCreateEmail(true)}
					>
						{t("Create")}
					</Button>
				)}
			</Stack>
			{deleteEmail && <DeleteEmailDialog open={deleteEmail} handleClose={() => setDeleteEmail(false)} />}
			{createEmail && <CreateEmailDialog open={createEmail} handleClose={() => setCreateEmail(false)} />}
		</Box>
	);
};

export default EmailAccount;
