import { Box, Stack, Typography } from "@mui/material";
import DOMPurify from "dompurify";
import moment from "moment/moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const EmailList = ({ email, setSelectedEmail }) => {
	const [cleanHTML, setCleanHTML] = React.useState("");

	useEffect(() => {
		const imgRegex = /<img[^>]+>/;

		if (imgRegex.test(email.email.html)) {
			const formattedHtml = DOMPurify.sanitize(email.email.html)
				.replace(imgRegex, "<span id='image-email'>Image - </span>")
				.replace(/<(\/)?p>/g, "<$1span>");
			setCleanHTML(formattedHtml);
		} else {
			const formattedHtml = DOMPurify.sanitize(email.email.html)
				.replace(/<(\/)?p>/g, "<$1span>")
				.replace(/<(\/)?h[1-6]>/g, "<$1span>");
			setCleanHTML(formattedHtml);
		}
	}, [email, cleanHTML]);

	const { t } = useTranslation();

	return (
		<Box
			width={"100%"}
			display={"flex"}
			justifyContent={"space-between"}
			alignItems={"center"}
			p={2}
			border={"1px solid #f5f4f4"}
			sx={{
				cursor: "pointer",
				"&:hover": {
					backgroundColor: "#f5f4f4",
				},
				transition: "all 0.3s",
			}}
			onClick={() => setSelectedEmail(email)}
		>
			<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
				<Typography variant={"h5"} fontWeight={500} color={"#164c68"}>
					{t("To")}:
				</Typography>
				<Typography variant={"h5"} fontWeight={500} color={"#164c68"}>
					{email.destination.to[0]}
				</Typography>
			</Stack>
			<Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={1} width={"100%"}>
				<Typography variant={"h5"} fontWeight={600} color={"#164c68"}>
					{email.email.subject}
				</Typography>
				<Typography variant={"h5"} fontWeight={500} color={"#164c68"}>
					-
				</Typography>
				<Typography variant={"h5"} fontWeight={500} color={"#164c68"}>
					{email.email.text ? email.email.text : email.email.html ? <div dangerouslySetInnerHTML={{ __html: cleanHTML }} /> : "N/A"}
				</Typography>
			</Stack>
			<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1} width={"100%"}>
				<Typography
					variant={"h5"}
					fontWeight={500}
					color={"#164c68"}
					sx={{
						fontFamily: '"Montserrat", "sans-serif"',
					}}
				>
					{moment(email.createdAt).format("DD MMM")}
				</Typography>
			</Stack>
		</Box>
	);
};

export default EmailList;
