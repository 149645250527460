import { Divider, ListItemIcon, MenuItem, Typography } from "@mui/material";
import { IoMdLogOut } from "react-icons/io";
import React from "react";
import { useNavigate } from "react-router";
import { Menu } from "./MenuCommon";
import { logout, setIsAuthorized } from "../../../redux/slices/auth";
import { useDispatch } from "react-redux";
import { setUserDetails, setSnackbar } from "../../../redux/slices/app";
import { useTranslation } from "react-i18next";
import { useGetMineQuery } from "../../../redux/slices/api/user/userApi";

const UserMenu = ({ open, anchorEl, handleClose }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const myData = useGetMineQuery({}, { refetchOnMountOrArgChange: true });

	return (
		<Menu
			id="appBar-menu"
			anchorEl={anchorEl}
			open={open}
			onClose={handleClose}
			MenuListProps={{
				"aria-labelledby": "menu-button",
			}}
		>
			<MenuItem disableRipple sx={{ cursor: "default" }}>
				<Typography variant="h6" color={"#fff"}>
					{myData?.data?.data?.firstName} {myData?.data?.data?.lastName}
				</Typography>
			</MenuItem>
			<Divider sx={{ my: 1, bgcolor: "#164c6866" }} />
			<MenuItem
				onClick={() => {
					navigate("/");
					dispatch(setSnackbar({ open: true, message: t("LogOutSuccessful"), severity: "success" }));
					dispatch(setIsAuthorized(false));
					dispatch(setUserDetails(null));
					dispatch(logout());
				}}
			>
				<ListItemIcon>
					<IoMdLogOut size={22} color={"#fff"} />
				</ListItemIcon>
				<Typography variant="h6" color={"#fff"}>
					{t("Logout")}
				</Typography>
			</MenuItem>
		</Menu>
	);
};

export default UserMenu;
