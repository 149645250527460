import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Stack } from "@mui/material";
import Cases from "./Cases";

const FirstSection = ({ caseData }) => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
	return (
		<Stack
			direction={isSmallScreen ? "column" : "row"}
			justifyContent={"center"}
			alignItems={"start"}
			width={"100%"}
			height={"100%"}
			spacing={2}
			bgcolor={"#fff"}
		>
			<Cases isSmallScreen={isSmallScreen} caseData={caseData} />
		</Stack>
	);
};

export default FirstSection;
