import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import { countries } from "../../utils/exportHelper";

export default function PhoneSelect({ formData, id, setFormData }) {
	const { t } = useTranslation();

	return (
		<Autocomplete
			id={id}
			sx={{ width: "100%" }}
			options={countries}
			inputValue={formData}
			autoHighlight
			freeSolo
			getOptionLabel={(option) => `+${option.phone} `}
			renderOption={(props, option) => (
				<Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
					<img
						loading="lazy"
						width="20"
						srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
						src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
						alt=""
					/>
					{option.label} ({option.code}) +{option.phone}
				</Box>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					value={formData}
					onChange={setFormData}
					label={t("PhoneNumber")}
					inputProps={{
						...params.inputProps, // disable autocomplete and autofill
					}}
					sx={{ p: 0, color: "#164c68" }}
				/>
			)}
		/>
	);
}


