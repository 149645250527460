import React, { useState } from "react";
import { useUpdateTableViewMutation } from "../../redux/slices/api/tableView/tableViewApi";
import { setSnackbar } from "../../redux/slices/app";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FaSave } from "react-icons/fa";
import { LoadingButton } from "@mui/lab";
import SaveTableViewDialog from "./SaveTableViewDialog";

const SaveTableView = ({
	allColumns,
	visibleColumns,
	setColumnVisibility,
	selectedColumnOrder,
	setColumnOrder,
	isLoading,
	setIsLoading,
	selectedTemplate,
	setSelectedTemplate,
	setDefaultView,
	mode,
	tableType,
}) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);
	const dispatch = useDispatch();
	const [updateTableView] = useUpdateTableViewMutation();
	const [isSaving, setIsSaving] = useState(false);

	const handleSetTableView = async (allColumns, visibleColumns) => {
		setIsLoading(true);
		const defaultVisibleColumns = allColumns.reduce((acc, key) => {
			acc[key] = visibleColumns.includes(key);
			return acc;
		}, {});

		const templateToSave = { column: JSON.stringify(defaultVisibleColumns), columnOrder: JSON.stringify(selectedColumnOrder) };
		try {
			await updateTableView({ templateId: selectedTemplate.id, data: templateToSave }).unwrap();
			dispatch(setSnackbar({ open: true, message: t("TableViewSaved"), severity: "success" }));
			setColumnVisibility(defaultVisibleColumns);
			setColumnOrder(selectedColumnOrder);
			setIsLoading(false);
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setIsLoading(false);
		}
	};
	return (
		<>
			<LoadingButton
				loading={isLoading}
				variant="contained"
				size="small"
				startIcon={!isRtl && <FaSave color="#fff" />}
				onClick={() => {
					if (mode === "update") {
						const allTableColumns = allColumns?.map((column) => column.id);
						const allVisibleColumns = visibleColumns?.map((column) => column.id);
						handleSetTableView(allTableColumns, allVisibleColumns);
					} else {
						setIsSaving(true);
					}
				}}
				sx={{
					bgcolor: "#164c68",
					color: "#fff",
					padding: 1,
					"&:hover": {
						bgcolor: "#00a3b1",
					},
					transition: "all 0.5s ease",
				}}
			>
				{mode === "save" ? t("SaveAsTemplate") : t("UpdateThisTableView")}
			</LoadingButton>
			{isSaving && (
				<SaveTableViewDialog
					open={isSaving}
					handleClose={() => setIsSaving(false)}
					allColumns={allColumns}
					visibleColumns={visibleColumns}
					setColumnVisibility={setColumnVisibility}
					columnOrder={selectedColumnOrder}
					setColumnOrder={setColumnOrder}
					tableType={tableType}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
					setSelectedTemplate={setSelectedTemplate}
					setDefaultView={setDefaultView}
				/>
			)}
		</>
	);
};

export default SaveTableView;
