import { Divider, Menu, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetConstraintsQuery } from "../../../../redux/slices/api/constraints/constraintsApi";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ContextMenu = ({ open, handleClose, context, selectedCategory, setSelectedCategory, setIsSorting }) => {
	const isRtl = useSelector((state) => state.app.isRtl);
	const { t } = useTranslation();

	const fileCategory = useGetConstraintsQuery({ reference: "fileCategory" }, { refetchOnMountOrArgChange: true });
	const [categories, setCategories] = useState(fileCategory.data?.data || []);

	useEffect(() => {
		if (fileCategory.error) {
			console.log("Error fetching file categories");
		}
		if (fileCategory.data) {
			setCategories(fileCategory.data.data);
		}
	}, [fileCategory]);

	const handleSortByCategory = (category) => {
		setSelectedCategory(category);
		setIsSorting(true);
		handleClose();
	};

	return (
		<>
			<Menu
				open={open}
				onClose={handleClose}
				anchorReference="anchorPosition"
				anchorPosition={context !== null ? { top: context.mouseY, left: context.mouseX } : undefined}
			>
				<Typography variant="h6" sx={{ px: 2, py: 1 }}>
					{t("SortByCategory")}:{" "}
				</Typography>
				<Divider />
				<MenuItem
					onClick={() => {
						setSelectedCategory(null);
						setIsSorting(false);
						handleClose();
					}}
				>
					{t("ClearSorting")}
				</MenuItem>
				{categories.map((category) => (
					<MenuItem
						key={category.id}
						onClick={() => handleSortByCategory(isRtl ? category.nameAr : category.nameEn)}
						sx={{ background: selectedCategory === (isRtl ? category.nameAr : category.nameEn) && "#03bbef3b", transition: "0.3s ease" }}
					>
						{isRtl ? category.nameAr : category.nameEn ? category.nameEn : category.nameAr}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

export default ContextMenu;
