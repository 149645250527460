import { Box, CircularProgress, Divider, IconButton, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { CgProfile } from "react-icons/cg";
import SectionHeader from "../../Common/SectionHeader";
import EditableTextField from "../../Common/EditableTextField";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setSnackbar } from "../../../redux/slices/app";
import { fetchMedia } from "../../Common/utils";
import { useGetMineQuery, useUpdateMyAvatarMutation } from "../../../redux/slices/api/user/userApi";
import { images } from "../../Assets";
import { FaCameraRetro } from "react-icons/fa6";
import VerificationDialog from "../../Common/VerificationDialog";
import OtpDialog from "../../Common/OtpDialog";

const MyProfileSection = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	const userDetails = useSelector((state) => state.app.userDetails);

	const { data } = useGetMineQuery({}, { refetchOnMountOrArgChange: true });

	const [prevData, setPrevData] = useState(userDetails);
	const [isEditingFirstName, setIsEditingFirstName] = useState(false);
	const [isEditingLastName, setIsEditingLastName] = useState(false);
	const [isEditingEmail, setIsEditingEmail] = useState(false);
	const [isEditingPhone, setIsEditingPhone] = useState(false);

	const [firstName, setFirstName] = useState(userDetails?.firstName || "");

	const [lastName, setLastName] = useState(userDetails?.lastName || "");

	const [email, setEmail] = useState(userDetails?.email || "");

	const [phone, setPhone] = useState(userDetails?.phone || "");

	const [loadingAvatar, setIsLoadingAvatar] = useState(false);
	const [avatar, setAvatar] = useState(null);
	const [newAvatar, setNewAvatar] = useState([]);
	const [uploadError, setUploadError] = useState(false);

	const [openVerification, setOpenVerification] = useState(false);
	const [openOtpDialog, setOpenOtpDialog] = useState(false);
	const [verificationMethod, setVerificationMethod] = useState("email");

	const isRtl = useSelector((state) => state.app.isRtl);

	const inputRef = useRef(null);

	useEffect(() => {
		if (userDetails?.avatar) {
			const fetchData = async () => {
				setIsLoadingAvatar(true);
				const data = await fetchMedia(userDetails?.avatar);
				setAvatar(data?.dataUrl);
				setIsLoadingAvatar(false);
			};
			fetchData();
		}
	}, []);

	const [updateAvatar] = useUpdateMyAvatarMutation();

	const handleUpdateAvatar = async (e) => {
		setIsLoadingAvatar(true);
		const fileData = e.target.files;
		const file = fileData[0];

		if (file.size <= 10 * 1024 * 1024) {
			const reader = new FileReader();

			reader.onloadend = () => {
				setAvatar(URL.createObjectURL(fileData[0]));
			};
			reader.readAsDataURL(fileData[0]);

			const data = new FormData();
			data.append("avatar", fileData[0]);
			try {
				await updateAvatar(data).unwrap();
				dispatch(setSnackbar({ open: true, message: t("AvatarUpdated"), severity: "success" }));
				setIsLoadingAvatar(false);
			} catch (error) {
				dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
				setIsLoadingAvatar(false);
			}
		} else {
			setUploadError(true);
			dispatch(setSnackbar({ open: true, message: `${t("ThisFile")} ${file.name}: ${t("TooLarge")}`, severity: "error" }));
		}
	};

	return (
		<Box
			width={isSmallScreen ? "100%" : "60%"}
			height={"auto"}
			p={2}
			borderRadius={1.5}
			sx={{
				boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
			}}
			bgcolor={"#fff"}
		>
			<SectionHeader icon={<CgProfile color="#164c68" size={18} />} title={t("MyProfile")} type={"dialog"} />
			<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
			<Box display={"flex"} justifyContent={"flex-start"} width={"100%"} alignItems={"center"} mb={2}>
				<Box
					width={"100px"}
					height={"100px"}
					p={avatar ? 0 : 2}
					borderRadius={"50%"}
					bgcolor={"#bdbdbd"}
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
					position={"relative"}
					border={uploadError && "2px solid red"}
				>
					{loadingAvatar ? (
						<CircularProgress />
					) : (
						<img src={avatar || images.avatar} alt="avatar" width={"100%"} height={"100%"} style={{ objectFit: "cover", borderRadius: "50%" }} />
					)}

					<Box
						width={"30px"}
						height={"30px"}
						borderRadius={"50%"}
						bgcolor={"#164c68"}
						display={"flex"}
						justifyContent={"center"}
						alignItems={"center"}
						position={"absolute"}
						sx={{
							top: "70px",
							right: "0",
						}}
					>
						<IconButton
							sx={{ p: 0 }}
							onClick={() => {
								setUploadError(false);
								inputRef.current.click();
							}}
						>
							<FaCameraRetro size={17} color={"#fff"} />
						</IconButton>
					</Box>
				</Box>
			</Box>
			<Stack direction={isSmallScreen ? "column" : "row"} justifyContent={isSmallScreen ? "center" : "space-between"} alignItems={"center"}>
				<EditableTextField
					label={t("FirstName")}
					value={firstName}
					setValue={setFirstName}
					name={"firstName"}
					isEditing={isEditingFirstName}
					setIsEditing={setIsEditingFirstName}
					prevValue={prevData?.firstName}
					prevData={prevData}
					setPrevData={setPrevData}
					section={"account"}
				/>
				<EditableTextField
					label={t("LastName")}
					value={lastName}
					setValue={setLastName}
					name={"lastName"}
					isEditing={isEditingLastName}
					setIsEditing={setIsEditingLastName}
					prevValue={prevData?.lastName}
					prevData={prevData}
					setPrevData={setPrevData}
					section={"account"}
				/>
			</Stack>
			<Stack direction={isSmallScreen ? "column" : "row"} justifyContent={isSmallScreen ? "center" : "space-between"} alignItems={"center"}>
				<EditableTextField
					label={t("EmailAddress")}
					value={email}
					setValue={setEmail}
					name={"email"}
					isEditing={isEditingEmail}
					setIsEditing={setIsEditingEmail}
					prevValue={prevData?.email}
					prevData={prevData}
					setPrevData={setPrevData}
					verified={userDetails?.isVerified?.email}
					section={"account"}
					setOpenVerification={() => setOpenVerification(true)}
					setVerificationMethod={() => setVerificationMethod("email")}
				/>
				<EditableTextField
					label={t("PhoneNumber")}
					value={phone}
					setValue={setPhone}
					name={"phone"}
					isEditing={isEditingPhone}
					setIsEditing={setIsEditingPhone}
					prevValue={prevData?.phone}
					prevData={prevData}
					setPrevData={setPrevData}
					verified={userDetails?.isVerified?.phone}
					section={"account"}
					setOpenVerification={() => setOpenVerification(true)}
					setVerificationMethod={() => setVerificationMethod("phone")}
				/>
			</Stack>
			<input ref={inputRef} type="file" hidden accept="image/*" onChange={handleUpdateAvatar} />
			{openVerification && (
				<VerificationDialog
					open={openVerification}
					handleClose={() => setOpenVerification(false)}
					myData={data?.data}
					verificationMethod={verificationMethod}
					setVerificationMethod={setVerificationMethod}
					openOtpDialog={() => setOpenOtpDialog(true)}
				/>
			)}
			{openOtpDialog && (
				<OtpDialog
					open={openOtpDialog}
					handleClose={() => setOpenOtpDialog(false)}
					closeVerificationDialog={() => setOpenVerification(false)}
					verificationMethod={verificationMethod}
					myData={data?.data}
				/>
			)}
		</Box>
	);
};

export default MyProfileSection;
