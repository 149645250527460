import React, { useEffect, useState } from "react";
import Animate from "../../Common/Animate";
import { Box, Stack, Tab, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../Common/Breadcrumbs";
import { images } from "../../Assets";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useGetReminderMetadataQuery } from "../../../redux/slices/api/reminders/reminderApi";
import Reminders from "./Reminders";
import { TabContext, TabList, TabPanel } from "@mui/lab";

function a11yProps(ref) {
	return {
		id: `vertical-tab-${ref}`,
		"aria-controls": `vertical-tabpanel-${ref}`,
	};
}

const ReminderList = () => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const [value, setValue] = useState("CaseFile");

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const reminderMetadata = useGetReminderMetadataQuery({}, { refetchOnMountOrArgChange: true });

	const [modelRefSelect, setModelRefSelect] = useState(useGetReminderMetadataQuery.data?.data?.MODEL_REF || []);

	useEffect(() => {
		if (reminderMetadata.isError) return;

		if (reminderMetadata.data?.data || !reminderMetadata.isLoading || !reminderMetadata.isError) {
			setModelRefSelect(reminderMetadata.data?.data?.MODEL_REF);
		}
	}, []);

	return (
		<Animate type="fade" delay={0.5}>
			<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={2} px={6} pt={4}>
				<CustomBreadcrumbs section={t("ReminderSettings")} />
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<img src={images.reminder} alt={"reminder"} width={40} height={40} />
					<Typography variant={"h2"} fontWeight={600} color={"#164c68"} sx={{ mr: `${isRtl ? "8px" : "0px"} !important` }}>
						{t("ReminderSettings")}
					</Typography>
				</Stack>
			</Stack>

			<Box
				width={"100%"}
				height={"100%"}
				display={"flex"}
				justifyContent={"flex-start"}
				alignItems={"center"}
				flexDirection={"column"}
				gap={4}
				sx={{ overflowY: "auto", overflowX: "hidden" }}
				px={6}
				py={3}
			>
				<TabContext value={value}>
					<Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
						<TabList onChange={handleChange} aria-label="lab API tabs example">
							{modelRefSelect?.map((item, index) => (
								<Tab key={index} label={item} value={item} />
							))}
						</TabList>
					</Box>

					{modelRefSelect?.map((item, index) => (
						<TabPanel
							key={index}
							value={item}
							sx={{ width: "100%", display: value === item ? "flex" : "none", justifyContent: "center", alignItems: "center" }}
						>
							<Reminders modelRef={item} />
						</TabPanel>
					))}
				</TabContext>
			</Box>
		</Animate>
	);
};

export default ReminderList;
