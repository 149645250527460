import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Animate from "../../Common/Animate";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Divider, FormControl, IconButton, MenuItem, Select, Stack, Tooltip, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../Common/Breadcrumbs";
import { images } from "../../Assets";
import moment from "moment";
import TaskInformation from "./TaskInformation";
import UsersSection from "./UsersSection";
import UserSelection from "./UserSelection";
import { LoadingButton } from "@mui/lab";
import { FaTasks } from "react-icons/fa";
import { useAddTaskMutation, useUpdateTaskMutation } from "../../../redux/slices/api/tasks/tasksApi";
import CancellingDialog from "../../Common/CancellingDialog";
import { useNavigate } from "react-router-dom";
import { setSnackbar } from "../../../redux/slices/app";
import { useTranslation } from "react-i18next";
import CustomConstraintSelect from "../../Common/CustomConstraintSelect";
import { IoAddCircle } from "react-icons/io5";
import AddConstraintDialog from "../../Case/CreateCaseFile/AddConstraintDialog";
import axiosInstance from "../../../utils/axios";
import { setSelectedTask } from "../../../redux/slices/task";
import { setCurrentCase } from "../../../redux/slices/case";

const TaskForm = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	const isRtl = useSelector((state) => state.app.isRtl);
	const isEditingTask = useSelector((state) => state.task.isEditingTask);
	const selectedTask = useSelector((state) => state.task.selectedTask);
	const isCreatingTask = useSelector((state) => state.task.isCreatingTask);

	const currentCase = useSelector((state) => state.case.currentCase);

	const today = moment();

	const [taskTitle, setTaskTitle] = useState(isEditingTask ? (selectedTask?.taskTitle ? selectedTask?.taskTitle?.id : selectedTask?.title) : null);
	const [classification, setClassification] = useState(currentCase ? "caseFile" : "none");
	const [caseFile, setCaseFile] = useState(
		isEditingTask ? selectedTask?.caseFile?.id : !isEditingTask && isCreatingTask && currentCase ? currentCase.id : null
	);
	const [classificationData, setClassificationData] = useState(
		isEditingTask && (!selectedTask.memo || !selectedTask.hearing || !selectedTask.meeting || !selectedTask.fee)
			? selectedTask.caseFile.id
			: isEditingTask && (!selectedTask.caseFile || !selectedTask.hearing || !selectedTask.meeting || !selectedTask.fee)
			? selectedTask.memo.id
			: isEditingTask && (!selectedTask.caseFile || !selectedTask.memo || !selectedTask.meeting || !selectedTask.fee)
			? selectedTask.hearing.id
			: isEditingTask && (!selectedTask.caseFile || !selectedTask.memo || !selectedTask.hearing || !selectedTask.fee)
			? selectedTask.meeting.id
			: isEditingTask && (!selectedTask.caseFile || !selectedTask.memo || !selectedTask.hearing || !selectedTask.meeting)
			? selectedTask.fee.id
			: !isEditingTask && isCreatingTask && currentCase
			? currentCase.id
			: null
	);
	const [taskDescription, setTaskDescription] = useState(isEditingTask ? selectedTask?.description : "");
	const [taskPriority, setTaskPriority] = useState(isEditingTask ? selectedTask?.priority : "Low");
	const [taskStatus, setTaskStatus] = useState(isEditingTask ? selectedTask?.status : "New");
	const [taskDueDate, setTaskDueDate] = useState(isEditingTask ? moment(selectedTask?.dueDate) : moment().add(1, "days"));
	const [assignedTo, setAssignedTo] = useState(isEditingTask ? selectedTask?.assignedTo : []);
	const [assigningUsers, setAssigningUsers] = useState(false);
	const [statusNote, setStatusNote] = useState("");
	const [addConstraint, setAddConstraint] = useState(false);
	const [constraintReference, setConstraintReference] = useState("");

	const [cancelling, setCancelling] = useState(false);

	const [loading, setLoading] = useState(false);

	const [addTask] = useAddTaskMutation();
	const [updateTask] = useUpdateTaskMutation();

	const handleTaskAction = async () => {
		setLoading(true);

		if (!taskTitle) {
			dispatch(setSnackbar({ open: true, message: t("TaskTitleRequired"), severity: "error" }));
			setLoading(false);
			return;
		}

		if (!taskDescription || taskDescription === "") {
			dispatch(setSnackbar({ open: true, message: t("TaskDescriptionRequired"), severity: "error" }));
			setLoading(false);
			return;
		}
		if (!taskPriority || taskPriority === "") {
			dispatch(setSnackbar({ open: true, message: t("TaskPriorityRequired"), severity: "error" }));
			setLoading(false);
			return;
		}
		if (!taskStatus || taskStatus === "") {
			dispatch(setSnackbar({ open: true, message: t("TaskStatusRequired"), severity: "error" }));
			setLoading(false);
			return;
		}
		if (!taskDueDate || taskDueDate === "") {
			dispatch(setSnackbar({ open: true, message: t("TaskDueDateRequired"), severity: "error" }));
			setLoading(false);
			return;
		}

		if (taskDueDate.diff(today, "days") < 0) {
			dispatch(setSnackbar({ open: true, message: "Due date cannot be in the past.", severity: "error" }));
			setLoading(false);
			return;
		}

		if (assignedTo.length < 1) {
			dispatch(setSnackbar({ open: true, message: t("UserMinimum"), severity: "error" }));
			setLoading(false);
			return;
		}

		if (!statusNote || statusNote === "") {
			dispatch(setSnackbar({ open: true, message: t("StatusNoteRequired"), severity: "error" }));
			setLoading(false);
			return;
		}

		const userIds = assignedTo.map((user) => user.id);

		let task = {
			taskTitle: taskTitle,
			description: taskDescription,
			priority: taskPriority,
			status: taskStatus,
			dueDate: taskDueDate,
			assignedTo: userIds,
			statusNote: statusNote,
		};

		if (classification === "caseFile") {
			task = {
				...task,
				caseFile: classificationData || currentCase.id,
			};
		} else if (classification === "hearing") {
			task = {
				...task,
				hearing: classificationData,
			};
		} else if (classification === "meeting") {
			task = {
				...task,
				meeting: classificationData,
			};
		} else if (classification === "memo") {
			task = {
				...task,
				memo: classificationData,
			};
		} else {
		}

		if (isCreatingTask && !isEditingTask) {
			try {
				const res = await addTask(task).unwrap();
				const newTaskData = await axiosInstance.get(`/task/${res.id || res._id}`);
				dispatch(setSelectedTask(newTaskData.data.data));
				if (!currentCase && classification !== "none") {
					const newCaseFileData = await axiosInstance.get(`/case-file/${newTaskData.data.data.caseFile.id}`);
					dispatch(setCurrentCase(newCaseFileData.data.data));
				}
				dispatch(setSnackbar({ open: true, message: t("TaskCreatedSuccessfully"), severity: "success" }));
				setLoading(false);
				navigate("/dashboard/task/details");
			} catch (error) {
				dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
				setLoading(false);
			}
		} else if (isEditingTask && !isCreatingTask) {
			let taskUpdate = {
				taskTitle: taskTitle,
				status: taskStatus,
				statusNote: statusNote,
				dueDate: taskDueDate,
				priority: taskPriority,
				assignedTo: userIds,
			};

			try {
				await updateTask({ taskId: selectedTask.id, data: taskUpdate }).unwrap();
				const newTaskData = await axiosInstance.get(`/task/${selectedTask.id}`);
				dispatch(setSelectedTask(newTaskData.data.data));
				if (!currentCase && classification !== "none") {
					const newCaseFileData = await axiosInstance.get(`/case-file/${newTaskData.data.data.caseFile.id}`);
					dispatch(setCurrentCase(newCaseFileData.data.data));
				}
				dispatch(setSnackbar({ open: true, message: t("TaskUpdatedSuccessfully"), severity: "success" }));
				setLoading(false);
				navigate("/dashboard/task/details");
			} catch (error) {
				dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
				dispatch(setSnackbar({ open: true, message: t("AnErrorOccurred"), severity: "error" }));
				setLoading(false);
			}
		} else {
			dispatch(setSnackbar({ open: true, message: t("AnErrorOccurred"), severity: "error" }));
			setLoading(false);
			navigate(-1);
			return;
		}
	};

	return (
		<Animate type="fade" delay={0.5}>
			<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={2} px={6} py={4}>
				<CustomBreadcrumbs section={t("Cases")} />
				<Typography variant={"h2"} fontWeight={600} color={"#164c68"}>
					{isEditingTask ? t("UpdatingTask") : isCreatingTask ? t("CreateTask") : t("Task")}
				</Typography>
			</Stack>
			<Box
				width={"100%"}
				height={"max-content"}
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
				gap={4}
				sx={{ overflowY: "auto", overflowX: "hidden" }}
				p={4}
			>
				<Box
					width={isSmallScreen ? "100%" : "90%"}
					height={"auto"}
					p={2}
					bgcolor={"#fff"}
					borderRadius={1.5}
					sx={{
						boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
					}}
				>
					<Stack direction={"row"} width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
						<Stack width={"100%"} direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
							<Box p={1} display={"flex"} justifyContent={"center"} alignItems={"center"} bgcolor={"#e9e7e770"} borderRadius={1}>
								<img src={images.task} alt="task" width={30} height={30} />
							</Box>
							<Typography variant={"h4"} fontWeight={700} color={"#595858"} sx={{ marginRight: `${isRtl ? "8px" : "0px"} !important` }}>
								{isEditingTask ? t("UpdatingTask") : isCreatingTask ? t("CreateTask") : t("Task")}
							</Typography>
						</Stack>
						<Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={1} width={"100%"}>
							<Typography variant={"h3"} fontWeight={500} color={"#164c68"} sx={{ ml: `${isRtl ? "8px" : "0px"} !important` }}>
								{t("TaskTitle")}:
							</Typography>
							<CustomConstraintSelect reference={"taskTitle"} dataSet={taskTitle} setData={setTaskTitle} />
							<Tooltip title={t("AddFileType")}>
								<IconButton
									onClick={() => {
										setAddConstraint(true);
										setConstraintReference("taskTitle");
									}}
								>
									<IoAddCircle color={"#164c68"} />
								</IconButton>
							</Tooltip>
						</Stack>
						<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1} width={"100%"}>
							<Typography variant="body1" color={"#164c68"} fontWeight={500} sx={{ mb: 1, ml: `${isRtl ? "8px" : "0px"} !important` }}>
								{t("TaskClass")}:
							</Typography>
							<FormControl sx={{ m: 1, minWidth: 100 }} size="small">
								<Select
									id="select-classification"
									value={classification}
									onChange={(e) => setClassification(e.target.value)}
									sx={{ color: "#164c68" }}
								>
									{!currentCase && <MenuItem value={"none"}>{t("General")} </MenuItem>}
									<MenuItem value={"caseFile"}>{t("CaseFile")} </MenuItem>
									<MenuItem value={"hearing"}>{t("Hearing")}</MenuItem>
									<MenuItem value={"Meeting"}>{t("Meeting")}</MenuItem>
									<MenuItem value={"memo"}>{t("Memo")}</MenuItem>
								</Select>
							</FormControl>
						</Stack>
					</Stack>

					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />

					<TaskInformation
						isSmallScreen={isSmallScreen}
						taskDescription={taskDescription}
						setTaskDescription={setTaskDescription}
						taskPriority={taskPriority}
						setTaskPriority={setTaskPriority}
						taskStatus={taskStatus}
						setTaskStatus={setTaskStatus}
						taskDueDate={taskDueDate}
						setTaskDueDate={setTaskDueDate}
						statusNote={statusNote}
						setStatusNote={setStatusNote}
						classificationData={classificationData}
						setClassificationData={setClassificationData}
						classification={classification}
						caseFile={caseFile}
						setCaseFile={setCaseFile}
					/>

					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<UsersSection isSmallScreen={isSmallScreen} assignedTo={assignedTo} setAssigningUsers={setAssigningUsers} />
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1.5}>
						<LoadingButton
							loading={loading}
							variant="contained"
							loadingPosition="start"
							startIcon={!isRtl && <FaTasks />}
							size="medium"
							sx={{
								bgcolor: "#164c68",
								color: "#fff",
							}}
							onClick={handleTaskAction}
						>
							{isEditingTask ? t("Update") : t("Save")}
						</LoadingButton>
						<Button
							onClick={() => setCancelling(true)}
							disabled={loading}
							sx={{
								color: "#fff",
								backgroundColor: "#fc6739",
								mr: `${isRtl ? "8px" : "0px"} !important`,
								"&:hover": {
									color: "#fff",
									backgroundColor: "red",
								},
								transition: "all 1s ease",
							}}
						>
							{t("Cancel")}
						</Button>
					</Stack>
				</Box>
			</Box>
			{addConstraint && (
				<AddConstraintDialog
					open={addConstraint}
					handleClose={() => setAddConstraint(false)}
					reference={constraintReference}
					setTaskTitle={setTaskTitle}
				/>
			)}
			{assigningUsers && <UserSelection open={assigningUsers} handleClose={() => setAssigningUsers(false)} users={assignedTo} setUsers={setAssignedTo} />}
			{cancelling && <CancellingDialog open={cancelling} handleClose={() => setCancelling(false)} path={"tasks"} />}
		</Animate>
	);
};

export default TaskForm;
