import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IoBriefcaseSharp } from "react-icons/io5";
import SectionHeader from "../../Common/SectionHeader";
import { useGetCasesQuery } from "../../../redux/slices/api/cases/caseApi";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const RecentCase = ({ section }) => {
	const { t } = useTranslation();
	const [closedCase, setClosedCase] = useState(null);
	const isRtl = useSelector((state) => state.app.isRtl);

	const closedCases = useGetCasesQuery({ status: "closed", data: true }, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (closedCases.data) {
			let mostRecentCloseDate = new Date(0);
			closedCases.data.data.forEach((item) => {
				const closeDate = new Date(item.closeDate);

				// Compare with the most recent closeDate found so far
				if (closeDate > mostRecentCloseDate) {
					mostRecentCloseDate = closeDate;
					setClosedCase(item);
				}
			});
		}
	}, [closedCases]);
	return (
		<Box
			width={"100%"}
			maxHeight={"340px"}
			height={section === "case" ? "300px" : "100%"}
			borderRadius={2}
			p={section === "case" ? 5 : 4.3}
			sx={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;" }}
			bgcolor={"#fff"}
		>
			<SectionHeader icon={<IoBriefcaseSharp color="#164c68" size={18} />} title={t("RecentlyClosedCase")} />
			<Stack width={"100%"} direction={"row"} justifyContent={"space-between"} alignItems={"center"} p={2} borderBottom={"1px solid #aaaaaa6b"} mt={3}>
				<Typography variant={"h6"} fontWeight={700} color={"#595858"}>
					{t("CaseFileFullName")}
				</Typography>
				<Typography
					variant={"h6"}
					fontWeight={700}
					color={"#595858"}
					sx={{
						fontFamily: '"Montserrat", "sans-serif"',
					}}
				>
					{closedCase?.classification === "litigation"
						? `${closedCase?.fullCaseNumber} - ${
								isRtl
									? closedCase?.caseType?.nameAr
									: closedCase?.caseType?.nameEn
									? closedCase?.caseType?.nameEn
									: closedCase?.caseType?.nameAr
						  }`
						: closedCase?.fileNumber}
				</Typography>
			</Stack>
			<Stack width={"100%"} direction={"row"} justifyContent={"space-between"} alignItems={"center"} p={2} borderBottom={"1px solid #aaaaaa6b"}>
				<Typography variant={"h6"} fontWeight={700} color={"#595858"}>
					{t("DateOpened")}
				</Typography>
				<Typography
					variant={"h6"}
					fontWeight={700}
					color={"#595858"}
					sx={{
						fontFamily: '"Montserrat", "sans-serif"',
					}}
				>
					{moment(closedCase?.openDate).format("MMM DD YYYY")}
				</Typography>
			</Stack>
			<Stack width={"100%"} direction={"row"} justifyContent={"space-between"} alignItems={"center"} p={2} borderBottom={"1px solid #aaaaaa6b"}>
				<Typography variant={"h6"} fontWeight={700} color={"#595858"}>
					{t("DateClosed")}
				</Typography>
				<Typography
					variant={"h6"}
					fontWeight={700}
					color={"#595858"}
					sx={{
						fontFamily: '"Montserrat", "sans-serif"',
					}}
				>
					{moment(closedCase?.closeDate).format("MMM DD YYYY")}
				</Typography>
			</Stack>
			<Stack width={"100%"} direction={"row"} justifyContent={"space-between"} alignItems={"center"} p={2}>
				<Typography variant={"h6"} fontWeight={700} color={"#595858"}>
					{t("ClosedReason")}
				</Typography>
				<Typography variant={"h6"} fontWeight={700} color={"#595858"}>
					{closedCase?.closeReason.nameAr}
				</Typography>
			</Stack>
		</Box>
	);
};

export default RecentCase;
