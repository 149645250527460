import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	selectedConstraint: null,
	isEditingConstraint: false,
};

export const constraintsSlice = createSlice({
	name: "constraints",
	initialState,
	reducers: {
		setSelectedConstraint: (state, action) => {
			state.selectedConstraint = action.payload;
		},
		setIsEditingConstraint: (state, action) => {
			state.isEditingConstraint = action.payload;
		},
	},
});

export const { setSelectedConstraint, setIsEditingConstraint } = constraintsSlice.actions;

export default constraintsSlice.reducer;
