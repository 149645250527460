import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { useDeleteFeeMutation } from "../../../../redux/slices/api/fees/feesApi";
import { FcCancel } from "react-icons/fc";
import { Typography } from "@mui/material";
import SectionHeader from "../../../Common/SectionHeader";
import { LoadingButton } from "@mui/lab";
import { setSnackbar } from "../../../../redux/slices/app";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../../utils/axios";
import { setCurrentCase } from "../../../../redux/slices/case";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteFeeDialog = ({ open, handleClose }) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);
	const dispatch = useDispatch();

	const selectedFee = useSelector((state) => state.fees.selectedFee);
	const currentCase = useSelector((state) => state.case.currentCase);
	const [loading, setLoading] = useState(false);

	const [deleteFee] = useDeleteFeeMutation();

	const handleDelete = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			await deleteFee(selectedFee.id).unwrap();
			if (currentCase) {
				const caseFileData = await axiosInstance.get(`/case-file/${currentCase.id}`);
				dispatch(setCurrentCase(caseFileData.data.data));
			}
			dispatch(setSnackbar({ open: true, message: t("FeeDeletedSuccessfully"), severity: "success" }));
			setLoading(false);
			handleClose();
			return;
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setLoading(false);
		}
	};

	return (
		<Dialog open={open} fullWidth TransitionComponent={Transition} keepMounted aria-describedby="add-user-dialog" sx={{ height: "100%" }}>
			<DialogTitle sx={{ pb: 2, borderBottom: "1px solid #aaaaaa6b", backgroundColor: "#fff" }}>
				<SectionHeader icon={<FcCancel size={18} />} title={`${t("DeleteFee")}?`} type={"dialog"} />
			</DialogTitle>
			<DialogContent sx={{ backgroundColor: "#fff" }}>
				<DialogContentText id="cancelling-dialog-description" sx={{ p: 4 }}>
					<Typography variant={"h5"} fontWeight={600} color={"#164c68"}>
						{t("DeleteFeeDialog")}?
					</Typography>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: "#fff" }}>
				<Button
					onClick={handleClose}
					disabled={loading}
					sx={{
						color: "#fff",
						backgroundColor: "#fc6739",
						"&:hover": {
							color: "#fff",
							backgroundColor: "red",
						},
						transition: "all 1s ease",
					}}
				>
					{t("No")}
				</Button>
				<LoadingButton
					loading={loading}
					variant="contained"
					size="medium"
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						mr: `${isRtl ? "8px" : "0px"} !important`,
					}}
					onClick={handleDelete}
				>
					{t("Yes")}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteFeeDialog;
