import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Stack, Typography, Select as MuiSelect, FormControl, InputLabel, MenuItem, Box, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import CustomTextField from "../Common/CustomTextField";
import { BiSolidContact } from "react-icons/bi";
import SectionHeader from "../Common/SectionHeader";
import { setSnackbar } from "../../redux/slices/app";
import { useDispatch, useSelector } from "react-redux";
import { useAddContactAvatarMutation, useAddContactMutation, useUpdateContactMutation } from "../../redux/slices/api/contacts/contactApi";
import { LoadingButton } from "@mui/lab";
import { images } from "../Assets";
import { fetchMedia } from "../Common/utils";
import { MdAddCircle, MdEdit } from "react-icons/md";
import { FaCameraRetro, FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import PhoneSelect from "./PhoneSelect";
import axiosInstance from "../../utils/axios";
import { setCurrentContact, setIsEditingContact, setIsViewingContact } from "../../redux/slices/contact";
import { isValidPhoneNumber } from "libphonenumber-js";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const Select = styled(MuiSelect)({
	color: "#164c68",
	"& .MuiSvgIcon-root": { color: "#164c68" },
	"& .MuiFormLabel-root": { color: "#164c68" },
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: "#164c68",
		},
		"&:hover fieldset": {
			borderColor: "#164c68",
		},
		"&.Mui-focused fieldset": {
			borderColor: "#164c68",
		},
	},
});

const NewContact = ({ open, handleClose }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const isEditingContact = useSelector((state) => state.contacts.isEditingContact);
	const currentContact = useSelector((state) => state.contacts.currentContact);

	const [loading, setLoading] = useState(false);
	const [loadingAvatar, setIsLoadingAvatar] = useState(false);
	const [avatar, setAvatar] = useState(null);
	const [newAvatar, setNewAvatar] = useState([]);
	const [uploadError, setUploadError] = useState(false);
	const [error, setError] = useState(false);

	const [emails, setEmails] = useState(isEditingContact ? currentContact?.email : [""]);
	const [phone, setPhone] = useState(isEditingContact ? currentContact?.phone : [""]);

	useEffect(() => {
		if (currentContact && isEditingContact && currentContact.avatar) {
			const fetchData = async () => {
				setIsLoadingAvatar(true);
				const data = await fetchMedia(currentContact.avatar);
				setAvatar(data?.dataUrl);
				setIsLoadingAvatar(false);
			};
			fetchData();
		}
	}, [currentContact]);

	const [formData, setFormData] = useState({
		nameAr: isEditingContact ? currentContact.nameAr : "",
		nameEn: isEditingContact ? currentContact.nameEn : "",
		phone: isEditingContact ? currentContact.phone : "",
		address: isEditingContact ? currentContact.address : "",
		note: isEditingContact ? currentContact.note : "",
	});

	const [hasError, setHasError] = useState({
		nameAr: false,
		nameEn: false,
		phone: false,
		address: false,
		note: false,
	});

	const [errorText, setErrorText] = useState({
		nameAr: "",
		nameEn: "",
		phone: "",
		address: "",
		note: "",
	});

	const [type, setType] = useState(isEditingContact ? currentContact.type : "individual");
	const [category, setCategory] = useState(isEditingContact ? currentContact.category : "client");
	const [contactNameAr, setContactNameAr] = useState("");
	const [contactNameEn, setContactNameEn] = useState("");

	const inputRef = useRef(null);

	const addEmailField = () => {
		const newEmails = [...emails, ""]; // Add an empty email field
		setEmails(newEmails);
	};

	const removeEmailField = (index) => {
		const updatedEmails = emails?.filter((email, i) => i !== index);
		setEmails(updatedEmails);
	};

	const handleEmailChange = (index, newValue) => {
		const updatedEmails = [...emails];
		updatedEmails[index] = newValue;
		setEmails(updatedEmails);
	};

	const handleResetEmailValue = (index) => {
		const updatedEmails = [...emails];
		updatedEmails[index] = "";
		setEmails(updatedEmails);
	};

	const addPhoneField = () => {
		const newPhones = [...phone, ""]; // Add an empty phone field
		setPhone(newPhones);
	};

	const removePhoneField = (index) => {
		const updatedPhones = phone?.filter((phone, i) => i !== index);
		setPhone(updatedPhones);
	};

	const handlePhoneChange = (index, newValue) => {
		const updatedPhones = [...phone];
		updatedPhones[index] = newValue;
		setPhone(updatedPhones);
	};

	const handleCloseDialog = () => {
		handleClose();
	};
	const handleInputChange = (e) => {
		if (e.target.name === "phone") {
			const phoneRegex = /^\+971(50|52|54|55|56|58)\d{7}$/;
			if (!phoneRegex.test(e.target.value)) {
				setHasError({ ...hasError, phone: true });
				setErrorText({ ...errorText, phone: "Please enter a valid phone number." });
			} else if (formData.phone.length < 1) {
				setHasError({ ...hasError, phone: true });
				setErrorText({ ...errorText, phone: "Phone number is required!" });
			} else {
				setHasError({ ...hasError, phone: false });
				setErrorText({ ...errorText, phone: "" });
			}
		}
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleResetValue = (name) => {
		setFormData({ ...formData, [name]: "" });
	};

	const [addNewContact] = useAddContactMutation();

	const [updateContact] = useUpdateContactMutation();

	const isRtl = useSelector((state) => state.app.isRtl);

	const handleAddContact = async (e) => {
		e.preventDefault();
		setLoading(true);

		const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
		const { nameAr, nameEn, address, note } = formData;

		if (nameAr === "") {
			setHasError({ ...hasError, nameAr: true });
			setErrorText({
				...errorText,
				nameAr: nameAr === "" ? t("ArNameRequired") : "",
			});
			setLoading(false);
			return;
		}

		let data = new FormData();

		if (emails.length === 1 && emails[0] === "" && (category === "expertOffice" || category === "lawyer")) {
			setLoading(false);
			dispatch(setSnackbar({ open: true, message: t("EmailIsRequired"), severity: "error" }));
			return;
		}

		if (phone.length === 1 && phone[0] === "" && (category === "expertOffice" || category === "lawyer")) {
			setLoading(false);
			dispatch(setSnackbar({ open: true, message: t("PhoneNumberRequired"), severity: "error" }));
			return;
		}

		for (let i = 0; i < emails.length; i++) {
			if (emails[i] === "" && (category === "expertOffice" || category === "lawyer")) {
				setLoading(false);
				dispatch(setSnackbar({ open: true, message: t("EmailIsRequired"), severity: "error" }));
				const emailInput = document.getElementById(`email-${i}`);
				emailInput.focus();
				return;
			}
			if (emails[i] !== "" && !emailRegex.test(emails[i]) && (category === "expertOffice" || category === "lawyer")) {
				setLoading(false);
				dispatch(setSnackbar({ open: true, message: t("InvalidEmail"), severity: "error" }));
				return;
			} else if (emailRegex.test(emails[i]) && emails[i] !== "") {
				data.append(`email[${i}]`, emails[i]);
			}
		}

		for (let i = 0; i < phone.length; i++) {
			if (phone[i] === "" && (category === "expertOffice" || category === "lawyer")) {
				setLoading(false);
				dispatch(setSnackbar({ open: true, message: t("PhoneNumberRequired"), severity: "error" }));
				const phoneInput = document.getElementById(`phone-${i}`);
				phoneInput.focus();
				return;
			}
			const trimmedPhone = phone[i].replace(/\s+/g, "");
			if (!isValidPhoneNumber(trimmedPhone) && (category === "expertOffice" || category === "lawyer")) {
				setLoading(false);
				dispatch(setSnackbar({ open: true, message: t("InvalidPhoneNumber"), severity: "error" }));
				return; // Exit the entire function
			} else if (isValidPhoneNumber(trimmedPhone) && trimmedPhone.length >= 1) {
				data.append(`phone[${i}]`, trimmedPhone);
			}
		}

		data.append("nameAr", nameAr);
		data.append("category", category);
		data.append("type", type);
		if (nameEn) data.append("nameEn", nameEn);
		if (address) data.append("address", address);
		if (newAvatar.length > 0) data.append("avatar", newAvatar[0]);
		if (note) data.append("note", note);
		if (contactNameAr) data.append("contactNameAr", contactNameAr);
		if (contactNameEn) data.append("contactNameEn", contactNameEn);

		try {
			await addNewContact({ data: data }).unwrap();
			dispatch(setSnackbar({ open: true, message: t("ContactAddedSuccessfully"), severity: "success" }));
			setLoading(false);
			handleClose();
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setLoading(false);
		}
	};

	const handleUpdateContact = async (e) => {
		e.preventDefault();
		setLoading(true);
		const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
		const { nameAr, nameEn, address, note } = formData;
		if (nameAr === "") {
			setHasError({ ...hasError, nameAr: true });
			setErrorText({
				...errorText,
				nameAr: nameAr === "" ? t("ArNameRequired") : "",
			});
			setLoading(false);
			return;
		}

		let data = {
			nameAr: nameAr,
			category: category,
			type: type,
			nameEn: nameEn,
			address: address,
			note: note,
			email: [],
			phone: [],
		};

		if (emails.length === 1 && emails[0] === "" && (category === "expertOffice" || category === "lawyer")) {
			setLoading(false);
			dispatch(setSnackbar({ open: true, message: t("EmailIsRequired"), severity: "error" }));
			return;
		}

		if (phone.length === 1 && phone[0] === "" && (category === "expertOffice" || category === "lawyer")) {
			setLoading(false);
			dispatch(setSnackbar({ open: true, message: t("PhoneNumberRequired"), severity: "error" }));
			return;
		}

		for (let i = 0; i < emails.length; i++) {
			if (emails[i] === "" && (category === "expertOffice" || category === "lawyer")) {
				setLoading(false);
				dispatch(setSnackbar({ open: true, message: t("EmailIsRequired"), severity: "error" }));
				const emailInput = document.getElementById(`email-${i}`);
				emailInput.focus();
				return;
			}
			if (emails[i] !== "" && !emailRegex.test(emails[i])) {
				setLoading(false);
				dispatch(setSnackbar({ open: true, message: t("InvalidEmail"), severity: "error" }));
				return; // Exit the entire function
			} else if (emailRegex.test(emails[i]) && emails[i] !== "") {
				data = { ...data, email: [...data.email, emails[i]] };
			}
		}

		for (let i = 0; i < phone.length; i++) {
			if (phone[i] === "" && (category === "expertOffice" || category === "lawyer")) {
				setLoading(false);
				dispatch(setSnackbar({ open: true, message: t("PhoneNumberRequired"), severity: "error" }));
				const phoneInput = document.getElementById(`phone-${i}`);
				phoneInput.focus();
				return;
			}
			const trimmedPhone = phone[i].replace(/\s+/g, "");
			if (!isValidPhoneNumber(trimmedPhone)) {
				setLoading(false);
				dispatch(setSnackbar({ open: true, message: t("InvalidPhoneNumber"), severity: "error" }));
				return; // Exit the entire function
			} else if (isValidPhoneNumber(trimmedPhone) && trimmedPhone.length >= 1) {
				data = { ...data, phone: [...data.phone, trimmedPhone] };
			}
		}

		if (type === "company") {
			data = { ...data, contactNameAr: contactNameAr, contactNameEn: contactNameEn };
		}

		try {
			await updateContact({
				contactId: currentContact.id,
				data: data,
			}).unwrap();
			const getContactDetails = await axiosInstance.get(`/contact/${currentContact.id}`);
			dispatch(setCurrentContact(getContactDetails.data.data));
			dispatch(setIsViewingContact(true));
			dispatch(setIsEditingContact(false));
			dispatch(setSnackbar({ open: true, message: t("ContactUpdatedSuccessfully"), severity: "success" }));
			setLoading(false);
			handleClose();
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setLoading(false);
		}
	};

	const [updateAvatar] = useAddContactAvatarMutation();

	const handleUpload = async (e) => {
		const fileData = e.target.files;
		const file = fileData[0];

		if (file.size <= 10 * 1024 * 1024) {
			const reader = new FileReader();

			reader.onloadend = () => {
				setAvatar(URL.createObjectURL(fileData[0]));
				setNewAvatar([fileData[0]]);
			};
			reader.readAsDataURL(fileData[0]);
		} else {
			setUploadError(true);
			dispatch(setSnackbar({ open: true, message: `${t("ThisFile")} ${file.name}: ${t("TooLarge")}`, severity: "error" }));
		}
	};

	const handleUpdateAvatar = async (e) => {
		setIsLoadingAvatar(true);
		const fileData = e.target.files;
		const file = fileData[0];

		if (file.size <= 10 * 1024 * 1024) {
			const reader = new FileReader();

			reader.onloadend = () => {
				setAvatar(URL.createObjectURL(fileData[0]));
			};
			reader.readAsDataURL(fileData[0]);

			const data = new FormData();
			data.append("avatar", fileData[0]);
			try {
				await updateAvatar({ contactId: currentContact.id, data: data });
				setIsLoadingAvatar(false);
			} catch (error) {
				dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
				setIsLoadingAvatar(false);
			}
		} else {
			setUploadError(true);
			dispatch(setSnackbar({ open: true, message: `${t("ThisFile")} ${file.name}: ${t("TooLarge")}`, severity: "error" }));
		}
	};

	return (
		<Dialog open={open} fullWidth TransitionComponent={Transition} keepMounted aria-describedby="add-contact-dialog">
			<DialogTitle sx={{ pb: 1, borderBottom: "1px solid #aaaaaa6b", backgroundColor: "#fff" }}>
				<SectionHeader
					icon={<BiSolidContact color="#164c68" size={18} />}
					title={`${isEditingContact ? t("Update") : t("New")} ${t("Contact")}`}
					type={"dialog"}
				/>
			</DialogTitle>
			<DialogContent sx={{ backgroundColor: "#fff" }}>
				<DialogContentText id="add-contact-dialog-description" sx={{ p: 2 }}>
					<Stack justifyContent={"flex-start"} alignItems={"start"} width={"100%"} mb={2}>
						<Box
							width={"100px"}
							height={"100px"}
							p={avatar ? 0 : 2}
							borderRadius={"50%"}
							bgcolor={"#bdbdbd"}
							display={"flex"}
							justifyContent={"center"}
							alignItems={"center"}
							position={"relative"}
							border={uploadError && "2px solid red"}
						>
							<img
								src={avatar ? avatar : images.avatar}
								alt="avatar"
								width={"100%"}
								height={"100%"}
								style={{ objectFit: "cover", borderRadius: "50%" }}
							/>
							<Box
								width={"30px"}
								height={"30px"}
								borderRadius={"50%"}
								bgcolor={"#164c68"}
								display={"flex"}
								justifyContent={"center"}
								alignItems={"center"}
								position={"absolute"}
								sx={{
									top: "70px",
									right: "0",
								}}
							>
								<IconButton
									sx={{ p: 0 }}
									onClick={() => {
										setUploadError(false);
										inputRef.current.click();
									}}
								>
									{isEditingContact || avatar ? <MdEdit size={17} color={"#fff"} /> : <FaCameraRetro size={17} color={"#fff"} />}
								</IconButton>
							</Box>
						</Box>
					</Stack>
					<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ mb: 3 }}>
						{t("ContactDetails")}
					</Typography>
					<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={3}>
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={1}>
							<Box width={"50%"}>
								<FormControl fullWidth size="small">
									<InputLabel id="type-select-small-label">{t("Type")}</InputLabel>
									<Select
										labelId="type-select-small-label"
										id="type-select-small"
										value={type}
										label="Type"
										onChange={(e) => setType(e.target.value)}
									>
										<MenuItem value={"individual"}>{t("Individual")}</MenuItem>
										<MenuItem value={"company"}>{t("Company")}</MenuItem>
									</Select>
								</FormControl>
							</Box>
							<Box width={"50%"}>
								<FormControl fullWidth size="small">
									<InputLabel id="category-select-small-label">{t("Category")}</InputLabel>
									<Select
										labelId="category-select-small-label"
										id="category-select-small"
										value={category}
										label="Category"
										onChange={(e) => setCategory(e.target.value)}
									>
										<MenuItem value={"client"}>{t("Client")}</MenuItem>
										<MenuItem value={"litigant"}>{t("Litigant")}</MenuItem>
										<MenuItem value={"lawyer"}>{t("Lawyer")}</MenuItem>
										<MenuItem value={"expertOffice"}>{t("ExpertOffice")}</MenuItem>
									</Select>
								</FormControl>
							</Box>
						</Stack>
						{type === "company" && (
							<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={1}>
								<Box width={"50%"}>
									<CustomTextField
										label={t("Arabic")}
										name={"nameAr"}
										type={"text"}
										value={contactNameAr}
										resetValue={handleResetValue}
										handleInputChange={(e) => setContactNameAr(e.target.value)}
										required={false}
									/>
								</Box>
								<Box width={"50%"}>
									<CustomTextField
										label={t("English")}
										name={"nameEn"}
										type={"text"}
										value={contactNameEn}
										resetValue={handleResetValue}
										handleInputChange={(e) => setContactNameEn(e.target.value)}
										required={false}
									/>
								</Box>
							</Stack>
						)}
						<CustomTextField
							label={t("Arabic")}
							name={"nameAr"}
							type={"text"}
							value={formData.nameAr}
							resetValue={handleResetValue}
							handleInputChange={handleInputChange}
							error={hasError.nameAr}
							errorText={errorText.nameAr}
							required={true}
						/>
						<CustomTextField
							label={t("English")}
							name={"nameEn"}
							type={"text"}
							value={formData.nameEn}
							resetValue={handleResetValue}
							handleInputChange={handleInputChange}
							error={hasError.nameEn}
							errorText={errorText.nameEn}
							required={false}
						/>

						{emails.map((email, index) => (
							<Stack key={index} direction="row" justifyContent="flex-start" alignItems="center" spacing={1} width="100%">
								<CustomTextField
									label={t("EmailAddress")}
									name={`email-${index}`}
									id={`email-${index}`}
									type="email"
									value={email}
									resetValue={() => handleResetEmailValue(index)}
									handleInputChange={(e) => handleEmailChange(index, e.target.value)}
									required={false}
								/>
								{index === emails.length - 1 ? (
									<IconButton disableRipple sx={{ p: 0 }} onClick={addEmailField}>
										<MdAddCircle color="#164c68" size={22} />
									</IconButton>
								) : (
									<IconButton
										disableRipple
										onClick={() => {
											removeEmailField(index);
										}}
										sx={{ p: 0 }}
									>
										<FaTimes color="red" size={22} />
									</IconButton>
								)}
							</Stack>
						))}

						{emails.length === 0 && (
							<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1} width="100%">
								<CustomTextField
									label="EmailAddress"
									name={`email-0`}
									type="email"
									value={emails[0]}
									resetValue={() => handleResetEmailValue(0)}
									handleInputChange={(e) => handleEmailChange(0, e.target.value)}
									required={false}
								/>
								<IconButton disableRipple sx={{ p: 0 }} onClick={addEmailField}>
									<MdAddCircle color="#164c68" size={22} />
								</IconButton>
							</Stack>
						)}

						{phone.length === 0 && (
							<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1} width="100%">
								<PhoneSelect value={phone[0]} formData={phone[0]} id={"phone-0"} setFormData={(e) => handlePhoneChange(0, e.target.value)} />
								<IconButton disableRipple sx={{ p: 0 }} onClick={addPhoneField}>
									<MdAddCircle color="#164c68" size={22} />
								</IconButton>
							</Stack>
						)}

						{phone.map((item, index) => {
							return (
								<Stack key={index} direction="row" justifyContent="flex-start" alignItems="center" spacing={1} width="100%">
									<PhoneSelect
										phone={phone[index]}
										formData={item}
										id={`phone-${index}`}
										setFormData={(e) => handlePhoneChange(index, e.target.value)}
									/>
									{index === phone.length - 1 ? (
										<IconButton disableRipple sx={{ p: 0 }} onClick={addPhoneField}>
											<MdAddCircle color="#164c68" size={22} />
										</IconButton>
									) : (
										<IconButton
											disableRipple
											onClick={() => {
												removePhoneField(index);
											}}
											sx={{ p: 0 }}
										>
											<FaTimes color="red" size={22} />
										</IconButton>
									)}
								</Stack>
							);
						})}
						<CustomTextField
							label={`${t("Address")}`}
							name={"address"}
							type={"text"}
							value={formData.address}
							resetValue={handleResetValue}
							handleInputChange={handleInputChange}
							required={false}
							multiline={true}
						/>
						<CustomTextField
							label={`${t("note")}`}
							name={"note"}
							type={"text"}
							value={formData.note}
							resetValue={handleResetValue}
							handleInputChange={handleInputChange}
							required={false}
							multiline={true}
						/>
					</Stack>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: "#fff", py: 1 }}>
				<Button
					onClick={handleCloseDialog}
					disabled={loading}
					sx={{
						color: "red",
						"&:hover": {
							color: "#fff",
							backgroundColor: "red",
						},
						transition: "all 1s ease",
					}}
				>
					{t("Cancel")}
				</Button>
				<LoadingButton
					loading={loading}
					variant="contained"
					size="medium"
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
					}}
					onClick={isEditingContact ? handleUpdateContact : handleAddContact}
				>
					{isEditingContact ? t("Update") : t("Save")}
				</LoadingButton>
			</DialogActions>
			<input ref={inputRef} type="file" hidden accept="image/*" onChange={isEditingContact ? handleUpdateAvatar : handleUpload} />
		</Dialog>
	);
};

export default NewContact;
