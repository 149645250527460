import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { useCreateEmailAccountMutation } from "../../../../redux/slices/api/email/emailApi";
import SectionHeader from "../../../Common/SectionHeader";
import { MdAlternateEmail } from "react-icons/md";
import { LoadingButton } from "@mui/lab";
import { Divider, Stack, Typography } from "@mui/material";
import CustomTextField from "../../../Common/CustomTextField";
import { setSnackbar } from "../../../../redux/slices/app";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const CreateEmailDialog = ({ open, handleClose }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [isLoading, setIsLoading] = useState(false);
	const [host, setHost] = useState("");
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [name, setName] = useState("");

	const [error, setError] = useState({
		host: false,
		username: false,
		password: false,
		name: false,
	});

	const [errorText, setErrorText] = useState({
		host: "",
		username: "",
		password: "",
		name: "",
	});

	const [createEmail] = useCreateEmailAccountMutation();

	const isRtl = useSelector((state) => state.app.isRtl);

	const handleCreateEmail = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

		if (!name || name === "") {
			setError({ ...error, name: true });
			setErrorText({ ...errorText, name: "Name is required" });
			setIsLoading(false);
			return;
		}
		if (!username || username === "") {
			setError({ ...error, username: true });
			setErrorText({ ...errorText, username: "Email is required" });
			setIsLoading(false);
			return;
		}

		if (username && !emailRegex.test(username)) {
			setError({ ...error, username: true });
			setErrorText({ ...errorText, username: "Please set a valid email" });
			setIsLoading(false);
			return;
		}

		if (!password || password === "") {
			setError({ ...error, password: true });
			setErrorText({ ...errorText, password: "Password is required" });
			setIsLoading(false);
			return;
		}
		if (!host || host === "") {
			setError({ ...error, host: true });
			setErrorText({ ...errorText, host: "Host is required" });
			setIsLoading(false);
			return;
		}

		const emailData = {
			host: host,
			port: 465,
			username: username,
			password: password,
			name: name,
		};

		try {
			await createEmail(emailData).unwrap();
			setIsLoading(false);
			dispatch(setSnackbar({ open: true, message: t("EmailAccountAddedSuccessfully"), severity: "success" }));
			handleClose();
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setIsLoading(false);
		}
	};

	return (
		<Dialog open={open} fullWidth TransitionComponent={Transition} keepMounted aria-describedby="add-user-dialog" sx={{ height: "100%" }}>
			<DialogTitle sx={{ pb: 2, borderBottom: "1px solid #aaaaaa6b", backgroundColor: "#fff" }}>
				<SectionHeader icon={<MdAlternateEmail color="#164c68" size={18} />} title={t("CreateEmailAccount")} type={"dialog"} />
			</DialogTitle>
			<DialogContent sx={{ backgroundColor: "#fff" }}>
				<DialogContentText id="add-user-dialog-description" sx={{ p: 4 }}>
					<Typography variant={"h5"} fontWeight={600} color={"#164c68"}>
						{t("EmailDetails")}
					</Typography>
					<Divider sx={{ my: 3, bgcolor: "#164c6866" }} />
					<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={3} width={"100%"}>
						<CustomTextField
							type={"text"}
							label={`${t("DisplayName")}: `}
							value={name}
							resetValue={() => setName("")}
							name={"name"}
							handleInputChange={(e) => setName(e.target.value)}
							error={error.name}
							errorText={errorText.name}
							required={true}
						/>
						<CustomTextField
							type={"email"}
							label={`${t("EmailAddress")}: `}
							value={username}
							resetValue={() => setUsername("")}
							name={"username"}
							handleInputChange={(e) => setUsername(e.target.value)}
							error={error.username}
							errorText={errorText.username}
							required={true}
						/>
						<CustomTextField
							type={"password"}
							label={`${t("Password")}: `}
							value={password}
							name={"password"}
							handleInputChange={(e) => setPassword(e.target.value)}
							error={error.password}
							errorText={errorText.password}
							showPassword={showPassword}
							setShowPassword={setShowPassword}
							required={true}
						/>
						<CustomTextField
							type={"text"}
							label={`${t("HostName")}: `}
							value={host}
							resetValue={() => setHost("")}
							name={"host"}
							handleInputChange={(e) => setHost(e.target.value)}
							error={error.host}
							errorText={errorText.host}
							required={true}
						/>
					</Stack>
					<Divider sx={{ my: 3, bgcolor: "#164c6866" }} />
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: "#fff" }}>
				<Button
					onClick={handleClose}
					disabled={isLoading}
					sx={{
						color: "#fff",
						backgroundColor: "#fc6739",
						"&:hover": {
							color: "#fff",
							backgroundColor: "red",
						},
						transition: "all 1s ease",
					}}
				>
					{t("Cancel")}
				</Button>
				<LoadingButton
					loading={isLoading}
					variant="contained"
					size="medium"
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
					}}
					onClick={handleCreateEmail}
				>
					{t("Create")}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default CreateEmailDialog;
