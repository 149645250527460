import React from "react";
import { Menu } from "./MenuCommon";
import { useDispatch, useSelector } from "react-redux";
import { ListItemIcon, MenuItem, Typography } from "@mui/material";
import { CiEdit } from "react-icons/ci";
import { MdDelete } from "react-icons/md";
import { useDeletePermissionMutation } from "../../../redux/slices/api/permission/permissionApi";
import { setSnackbar } from "../../../redux/slices/app";
import { useTranslation } from "react-i18next";

const PermissionsMenu = ({ open, anchorEl, handleClose, permission, setEditPermission, setAnchorEl }) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const dispatch = useDispatch();
	const [deletePermission] = useDeletePermissionMutation();

	const handleDelete = async (e) => {
		e.preventDefault();

		try {
			await deletePermission(permission.id);
			dispatch(setSnackbar({ open: true, message: t("PermissionGroupDeletedSuccessfully"), severity: "info" }));
			handleClose();
			setAnchorEl(null);
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
		}
	};

	return (
		<Menu
			id="permission-menu"
			anchorEl={anchorEl}
			open={open}
			onClose={handleClose}
			MenuListProps={{
				"aria-labelledby": "permission-menu-button",
			}}
		>
			<MenuItem
				onClick={() => {
					setEditPermission(true);
					setAnchorEl(null);
				}}
			>
				<ListItemIcon>
					<CiEdit size={22} color={"#fff"} />
				</ListItemIcon>
				<Typography variant="h6" color={"#fff"}>
					{t("EditPermission")}
				</Typography>
			</MenuItem>

			<MenuItem onClick={handleDelete}>
				<ListItemIcon>
					<MdDelete size={22} color={"#fff"} />
				</ListItemIcon>
				<Typography variant="h6" color={"#fff"}>
					{t("DeletePermission")}
				</Typography>
			</MenuItem>
		</Menu>
	);
};

export default PermissionsMenu;
