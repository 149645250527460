import React, { useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Animate from "../Common/Animate";
import { Box, Button, Divider, FormControl, IconButton, MenuItem, Select, Stack, Tooltip, Typography } from "@mui/material";
import CustomBreadcrumbs from "../Common/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import SectionHeader from "../Common/SectionHeader";
import { CiEdit, CiMemoPad } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoMdAdd } from "react-icons/io";
import CustomConstraintSelect from "../Common/CustomConstraintSelect";
import moment from "moment";
import CustomDatePicker from "../Common/CustomDatePicker";
import CustomTextField from "../Common/CustomTextField";
import Memo from "./index";
import { LoadingButton } from "@mui/lab";
import CancellingDialog from "../Common/CancellingDialog";
import { useAddMemoMutation, useUpdateMemoMutation } from "../../redux/slices/api/memo/memoApi";
import { useNavigate } from "react-router-dom";
import { setSnackbar } from "../../redux/slices/app";
import { setCurrentMemo, setIsCreatingMemo, setIsEditingMemo, setIsViewingMemo } from "../../redux/slices/memo";
import ConfirmDeleteMemo from "./ConfirmDeleteMemo";
import { FaRegEye } from "react-icons/fa";
import FileSection from "../Case/CaseDetails/FileSection";
import { images } from "../Assets";
import { useTranslation } from "react-i18next";
import AddConstraintDialog from "../Case/CreateCaseFile/AddConstraintDialog";
import { IoAddCircle } from "react-icons/io5";
import axiosInstance from "../../utils/axios";

const CreateMemo = () => {
	const { t } = useTranslation();

	const quillRef = useRef(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const isRtl = useSelector((state) => state.app.isRtl);
	const currentCase = useSelector((state) => state.case.currentCase);
	const currentMemo = useSelector((state) => state.memo.currentMemo);
	const isEditingMemo = useSelector((state) => state.memo.isEditingMemo);
	const isViewingMemo = useSelector((state) => state.memo.isViewingMemo);
	const isCreatingMemo = useSelector((state) => state.memo.isCreatingMemo);

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	const [caseFile, setCaseFile] = useState(currentCase?.fileNumber || "N/A");
	const [memoType, setMemoType] = useState(isEditingMemo || isViewingMemo ? currentMemo.memoType.id : null);
	const [status, setStatus] = useState(isEditingMemo || isViewingMemo ? currentMemo.status : "");
	const [date, setDate] = useState(isEditingMemo || isViewingMemo ? moment(currentMemo.date) : null);
	const [invalidDate, setInvalidDate] = useState(false);
	const [errorDate, setErrorDate] = useState("");
	const [description, setDescription] = useState(isEditingMemo || isViewingMemo ? currentMemo.description : "");
	const [value, setValue] = useState(isEditingMemo || isViewingMemo ? currentMemo.content : "");

	const [loading, setLoading] = useState(false);
	const [cancelling, setCancelling] = useState(false);
	const [deleting, setDeleting] = useState(false);

	const [addConstraint, setAddConstraint] = useState(false);
	const [constraintReference, setConstraintReference] = useState(null);

	const [createMemo] = useAddMemoMutation();

	const handleCreateMemo = async () => {
		setLoading(true);

		if (date && date < moment()) {
			setInvalidDate(true);
			setErrorDate(t("PastDateError"));
			setLoading(false);
			return;
		}
		if (!memoType) {
			dispatch(setSnackbar({ open: true, message: t("MemoTypeRequired"), severity: "error" }));
			setLoading(false);
			return;
		}

		let data = {
			caseFile: currentCase.id,
			memoType: memoType,
		};

		if (date && date !== moment() && date > moment()) {
			data = { ...data, date: date };
		}
		if (status) {
			data = { ...data, status: status };
		}
		if (description) {
			data = { ...data, description: description };
		}
		if (value) {
			data = { ...data, content: value };
		}

		try {
			const res = await createMemo(data).unwrap();
			const newMemoData = await axiosInstance.get(`/memo/${res.id || res._id}/`);
			dispatch(setCurrentMemo(newMemoData.data.data));
			dispatch(setSnackbar({ open: true, message: t("MemoCreatedSuccessfully"), severity: "success" }));
			setLoading(false);
			dispatch(setIsCreatingMemo(false));
			dispatch(setIsEditingMemo(false));
			dispatch(setIsViewingMemo(true));
			if (currentCase) {
				navigate("/dashboard/case/memo");
			} else {
				navigate("/dashboard/reports");
			}
			return;
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setLoading(false);
		}
	};

	const [updateMemo] = useUpdateMemoMutation();

	const handleUpdateMemo = async () => {
		if (
			memoType !== currentMemo.memoType.id ||
			status !== currentMemo.status ||
			date !== moment(currentMemo.date) ||
			description !== currentMemo.description ||
			value !== currentMemo.content
		) {
			setLoading(true);

			if (!memoType) {
				dispatch(setSnackbar({ open: true, message: t("MemoTypeRequired"), severity: "error" }));
				setLoading(false);
				return;
			}

			let data = {
				caseFile: currentCase ? currentCase?.id : currentMemo?.caseFile?.id,
				status: status,
				// memoType: memoType,
				description: description,
				content: value,
			};

			if (date) {
				data = { ...data, date: date };
			}

			try {
				await updateMemo({ memoId: currentMemo.id, data: data }).unwrap();
				const newMemoData = await axiosInstance.get(`/memo/${currentMemo.id}/`);
				dispatch(setCurrentMemo(newMemoData.data.data));
				dispatch(setSnackbar({ open: true, message: t("MemoUpdatedSuccessfully"), severity: "success" }));
				setLoading(false);
				dispatch(setIsCreatingMemo(false));
				dispatch(setIsEditingMemo(false));
				dispatch(setIsViewingMemo(true));
				if (currentCase) {
					navigate("/dashboard/case/memo");
				} else {
					navigate("/dashboard/reports");
				}
				return;
			} catch (error) {
				dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
				setLoading(false);
			}
		} else {
			return;
		}
	};

	return (
		<Animate type="fade" delay={0.5}>
			<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={2} px={6} pt={4}>
				<CustomBreadcrumbs section={t("Cases")} />
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<img src={images.notes} alt={"notes"} width={35} height={35} />
					<Typography variant={"h2"} fontWeight={600} color={"#164c68"}>
						{isEditingMemo && !isCreatingMemo && !isViewingMemo
							? t("Editing")
							: isViewingMemo && !isEditingMemo && !isCreatingMemo
							? t("Viewing")
							: t("Creating")}{" "}
						{t("MemoCaseNumber")}: {caseFile || "N/A"}
					</Typography>
				</Stack>
			</Stack>

			<Box
				width={"100%"}
				height={"max-content"}
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
				gap={4}
				sx={{ overflowY: "auto", overflowX: "hidden" }}
				p={4}
				mb={5}
			>
				<Box
					width={isSmallScreen ? "100%" : "90%"}
					height={"auto"}
					p={2}
					borderRadius={1.5}
					bgcolor={"#fff"}
					sx={{
						boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;",
					}}
				>
					<SectionHeader
						icon={
							isEditingMemo && !isCreatingMemo && !isViewingMemo ? (
								<CiEdit color="#164c68" size={18} />
							) : isViewingMemo && !isEditingMemo && !isCreatingMemo ? (
								<FaRegEye color="#164c68" size={18} />
							) : (
								<IoMdAdd color="#164c68" size={18} />
							)
						}
						title={
							isEditingMemo && !isCreatingMemo && !isViewingMemo
								? `${t("Editing")} ${t("Memo")}`
								: isViewingMemo && !isEditingMemo && !isCreatingMemo
								? `${t("Viewing")} ${t("Memo")}`
								: `${t("Creating")} ${t("Memo")}`
						}
						type={"dialog"}
					/>
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<Stack direction={"row"} width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
						<Stack width={"100%"} direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
							<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }}>
								{t("CaseFile")}:
							</Typography>
							<Typography variant="h5" color={"#164c68"} fontWeight={500}>
								{caseFile}
							</Typography>
						</Stack>
						{!isEditingMemo && (
							<Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={1} width={"100%"}>
								<Typography variant={"h5"} fontWeight={500} color={"#164c68"} sx={{ mb: 3, marginLeft: `${isRtl ? "8px" : "0px"} !important` }}>
									{t("MemoType")}:
								</Typography>
								<CustomConstraintSelect reference={"memoType"} dataSet={memoType} setData={setMemoType} classification={""} />
								<Tooltip title={`${t("Add")}  ${t("MemoType")}`}>
									<IconButton
										onClick={() => {
											setAddConstraint(true);
											setConstraintReference("memoType");
										}}
									>
										<IoAddCircle color={"#164c68"} />
									</IconButton>
								</Tooltip>
							</Stack>
						)}
						<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1} width={"100%"}>
							<Typography variant="body1" color={"#164c68"} fontWeight={500} sx={{ mb: 1, marginLeft: `${isRtl ? "8px" : "0px"} !important` }}>
								{t("Status")}:
							</Typography>

							<FormControl sx={{ m: 1, minWidth: 100 }} size="small">
								<Select
									id="select-status"
									value={status}
									onChange={(e) => {
										if ((isEditingMemo || isCreatingMemo) && !isViewingMemo) {
											setStatus(e.target.value);
										} else {
											return;
										}
									}}
									sx={{ color: "#164c68" }}
								>
									<MenuItem value={"pending"}>{t("Pending")}</MenuItem>
									<MenuItem value={"finished"}>{t("Finished")}</MenuItem>
								</Select>
							</FormControl>
						</Stack>
					</Stack>
					<Stack justifyContent={"flex-start"} alignItems={"start"} width={isSmallScreen ? "100%" : "50%"} spacing={1} mt={3}>
						<CustomDatePicker title={"Memo"} value={date} setDate={(isEditingMemo || isCreatingMemo) && setDate} />
						{invalidDate && (
							<Typography variant={"title1"} color={"red"} fontWeight={500}>
								{errorDate}
							</Typography>
						)}
					</Stack>
					<Stack justifyContent={"flex-start"} alignItems={"start"} width={isSmallScreen ? "100%" : "50%"} mt={5}>
						<CustomTextField
							type={"text"}
							label={t("MemoDescription")}
							value={description}
							resetValue={() => setDescription("")}
							name={"description"}
							handleInputChange={(e) => {
								if ((isEditingMemo || isCreatingMemo) && !isViewingMemo) {
									setDescription(e.target.value);
								} else {
									return;
								}
							}}
							required={false}
							multiline={true}
						/>
					</Stack>
					<Stack justifyContent={"flex-start"} alignItems={"start"} width={"100%"} spacing={3} mt={5}>
						<Typography variant="h5" color={"#164c68"} fontWeight={500}>
							{t("MemoContent")}:
						</Typography>
						<Memo
							ref={quillRef}
							value={value}
							handleChange={(content) => {
								if ((isEditingMemo || isCreatingMemo) && !isViewingMemo) {
									setValue(content);
								} else {
									return;
								}
							}}
						/>
					</Stack>
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1.5}>
						{(isEditingMemo || isCreatingMemo) && !isViewingMemo ? (
							<LoadingButton
								loading={loading}
								variant="contained"
								loadingPosition="start"
								startIcon={!isRtl && <CiMemoPad />}
								size="medium"
								sx={{
									bgcolor: "#164c68",
									color: "#fff",
								}}
								onClick={isEditingMemo ? handleUpdateMemo : handleCreateMemo}
							>
								{t("Save")}
							</LoadingButton>
						) : null}
						{isViewingMemo && (
							<Button
								variant="contained"
								loadingPosition="start"
								startIcon={!isRtl && <CiMemoPad />}
								size="medium"
								sx={{
									bgcolor: "#164c68",
									color: "#fff",
								}}
								onClick={() => {
									dispatch(setIsEditingMemo(true));
									dispatch(setIsViewingMemo(false));
								}}
							>
								{t("Update")}
							</Button>
						)}
						{!isEditingMemo && !isCreatingMemo && (
							<>
								<Button
									variant="contained"
									startIcon={!isRtl && <RiDeleteBin6Line />}
									size="medium"
									sx={{
										color: "#fff",
										backgroundColor: "#fc6739",
										"&:hover": {
											color: "#fff",
											backgroundColor: "red",
										},
										transition: "all 1s ease",
									}}
									onClick={() => {
										setDeleting(true);
									}}
								>
									{t("Delete")}
								</Button>
								<Button
									variant="contained"
									size="medium"
									sx={{
										color: "#fff",
										backgroundColor: "#fc6739",
										"&:hover": {
											color: "#fff",
											backgroundColor: "red",
										},
										transition: "all 1s ease",
									}}
									onClick={() => {
										dispatch(setIsViewingMemo(false));
										dispatch(setIsEditingMemo(false));
										dispatch(setIsCreatingMemo(false));
										if (currentCase) {
											navigate("/dashboard/case/details/memos");
										} else {
											navigate("/dashboard/reports");
										}
									}}
								>
									{t("Back")}
								</Button>
							</>
						)}

						{(isEditingMemo || isCreatingMemo) && !isViewingMemo ? (
							<Button
								onClick={() => setCancelling(true)}
								disabled={loading}
								sx={{
									color: "#fff",
									backgroundColor: "#fc6739",
									"&:hover": {
										color: "#fff",
										backgroundColor: "red",
									},
									transition: "all 1s ease",
									marginRight: `${isRtl ? "8px" : "0px"} !important`,
								}}
							>
								{t("Cancel")}
							</Button>
						) : null}
					</Stack>

					{!isCreatingMemo && (
						<>
							<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
							<FileSection criteria={"memo"} criteriaId={currentMemo?.id} />
						</>
					)}
				</Box>
			</Box>
			{cancelling && <CancellingDialog open={cancelling} handleClose={() => setCancelling(false)} path={"memo"} />}
			{deleting && <ConfirmDeleteMemo open={deleting} handleClose={() => setDeleting(false)} />}
			{addConstraint && (
				<AddConstraintDialog
					open={addConstraint}
					handleClose={() => setAddConstraint(false)}
					reference={constraintReference}
					setMemoType={setMemoType}
				/>
			)}
		</Animate>
	);
};

export default CreateMemo;
