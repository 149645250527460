import * as React from "react";
import Animate from "../../../Common/Animate";
import { Button, FormControl, MenuItem, Select, Stack, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../Common/Breadcrumbs";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import FolderIcon from "@mui/icons-material/Folder";
import { TbVariablePlus } from "react-icons/tb";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { images } from "../../../Assets";
import { useCreateEmailTemplateMutation, useGetCaseFileMetaDataQuery, useGetTaskMetaDataQuery } from "../../../../redux/slices/api/email/templateApi";
import Memo from "../../../Memo";
import { FaSave } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ArrowLeftIcon } from "@mui/x-date-pickers";
import CustomTextField from "../../../Common/CustomTextField";
import { useNavigate } from "react-router-dom";
import { setSnackbar } from "../../../../redux/slices/app";

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
	// color: theme.palette.text.secondary,
	color: "#164c68",
	[`& .${treeItemClasses.content}`]: {
		// color: theme.palette.text.secondary,
		color: "#164c68",
		borderTopRightRadius: theme.spacing(2),
		borderBottomRightRadius: theme.spacing(2),
		paddingRight: theme.spacing(1),
		marginBottom: theme.spacing(2),
		fontWeight: theme.typography.fontWeightMedium,
		"&.Mui-expanded": {
			fontWeight: theme.typography.fontWeightRegular,
		},
		"&:hover": {
			backgroundColor: theme.palette.action.hover,
		},
		"&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
			// backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
			backgroundColor: "#e9e7e7",
			color: "#164c68",
		},
		[`& .${treeItemClasses.label}`]: {
			fontWeight: "inherit",
			color: "#164c68",
		},
	},
	[`& .${treeItemClasses.group}`]: {
		marginLeft: 0,
		[`& .${treeItemClasses.content}`]: {
			paddingLeft: theme.spacing(2),
		},
	},
}));

const StyledTreeItem = React.forwardRef(function StyledTreeItem(props, ref) {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);
	const theme = useTheme();
	const { bgColor, color, labelIcon: LabelIcon, labelInfo, labelText, colorForDarkMode, bgColorForDarkMode, ...other } = props;

	const styleProps = {
		"--tree-view-color": theme.palette.mode !== "dark" ? color : colorForDarkMode,
		"--tree-view-bg-color": theme.palette.mode !== "dark" ? bgColor : bgColorForDarkMode,
	};

	return (
		<StyledTreeItemRoot
			label={
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						p: 0.5,
						pr: 0,
					}}
				>
					<Box component={LabelIcon} color="inherit" sx={{ mr: 1, ml: `${isRtl ? "8px" : "0px"} !important` }} />
					<Typography variant="h5" sx={{ fontWeight: "inherit", flexGrow: 1, textTransform: "capitalize" }}>
						{labelText === "fileStorage"
							? t("FileStorage")
							: labelText === "fileNumber"
							? t("FileNumber")
							: labelText === "caseNumber"
							? t("CaseNumber")
							: labelText === "caseYear"
							? t("CaseYear")
							: labelText === "openDate"
							? t("OpenDate")
							: labelText === "closeDate"
							? t("CloseDate")
							: labelText === "fileType"
							? t("FileType")
							: labelText === "nameAr"
							? t("ArabicName")
							: labelText === "nameEn"
							? t("EnglishName")
							: labelText === "caseStage"
							? t("CaseStage")
							: labelText === "caseType"
							? t("CaseType")
							: labelText === "closeReason"
							? t("CloseReason")
							: labelText === "caseFile"
							? t("CaseFile")
							: labelText === "Case File"
							? t("CaseFile")
							: labelText === "meetingType"
							? t("MeetingType")
							: labelText === "memoType"
							? t("MemoType")
							: labelText === "endDate"
							? t("EndDate")
							: labelText === "dueDate"
							? t("DueDate")
							: t(labelText)}
					</Typography>
				</Box>
			}
			style={styleProps}
			{...other}
			ref={ref}
		/>
	);
});

const CreateTemplate = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const quillRef = React.useRef(null);

	const caseFileVariables = useGetCaseFileMetaDataQuery({}, { refetchOnMountOrArgChange: true });
	const taskVariables = useGetTaskMetaDataQuery({}, { refetchOnMountOrArgChange: true });

	const [isLoading, setIsLoading] = React.useState(false);
	const [caseFileVar, setCaseFileVar] = React.useState({});
	const [taskFileVar, setTaskFileVar] = React.useState({});
	const [templateName, setTemplateName] = React.useState("");
	const [templateSubject, setTemplateSubject] = React.useState("");
	const [reference, setReference] = React.useState("CaseFile");

	const [error, setError] = React.useState({
		templateName: false,
		templateSubject: false,
	});

	const [errorText, setErrorText] = React.useState({
		templateName: "",
		templateSubject: "",
	});

	const [value, setValue] = React.useState("");

	const handleAddVariable = (variable) => {
		const editor = quillRef.current?.getEditor();
		const currentValue = editor.getText();
		const insertIndex = currentValue.length - 1;
		editor.insertText(insertIndex, `{${variable}}`);
	};

	React.useEffect(() => {
		if (caseFileVariables.isError) return;
		if (caseFileVariables.data) {
			setCaseFileVar(caseFileVariables?.data?.data?.templateVariable);
		}
	}, [caseFileVariables]);

	React.useEffect(() => {
		if (taskVariables.isError) return;
		if (taskVariables.data) {
			setTaskFileVar(taskVariables?.data.templateVariable);
		}
	}, [taskVariables]);

	const renderTreeItems = (obj, parentKey, cat) => {
		return Object.keys(obj).map((key, index) => {
			const objValue = obj[key];
			const nodeId = parentKey + key;
			const fullPath = parentKey === "" ? key : `${parentKey}.${key}`;
			if (typeof objValue === "object" && !Array.isArray(objValue)) {
				return (
					<StyledTreeItem key={nodeId} nodeId={nodeId} labelText={key} labelIcon={FolderIcon}>
						{renderTreeItems(objValue, fullPath, cat)}
					</StyledTreeItem>
				);
			} else {
				return (
					<StyledTreeItem
						key={nodeId}
						nodeId={nodeId}
						labelText={`${key}`}
						labelIcon={TbVariablePlus}
						// onClick={() => handleAddVariable(parentKey === "" ? `${key}` : `${parentKey}.${key}`)}
						onDoubleClick={() => handleAddVariable(parentKey === "" ? `${key}` : `${parentKey}.${key}`)}
					/>
				);
			}
		});
	};

	const [createEmailTemplate] = useCreateEmailTemplateMutation();

	const handleCreateEmailTemplate = async (e) => {
		e.preventDefault();
		setIsLoading(true);

		if (!reference || reference === "") {
			dispatch(setSnackbar({ open: true, message: t("EmailReferenceIsRequired"), severity: "error" }));
			setIsLoading(false);
			return;
		}

		if (!templateName || templateName === "") {
			setError({ ...error, templateName: true });
			setErrorText({ ...errorText, templateName: t("TemplateNameIsRequired") });
			setIsLoading(false);
			return;
		}

		if (!templateSubject || templateSubject === "") {
			setError({ ...error, templateSubject: true });
			setErrorText({ ...errorText, templateSubject: t("TemplateSubjectIsRequired") });
			setIsLoading(false);
			return;
		}

		if (!value || value === "") {
			dispatch(setSnackbar({ open: true, message: t("EmailBodyIsRequired"), type: "error" }));
			setIsLoading(false);
			return;
		}

		const data = {
			name: templateName,
			subject: templateSubject,
			reference: reference,
			html: value,
		};

		try {
			await createEmailTemplate(data).unwrap();
			dispatch(setSnackbar({ open: true, message: t("EmailTemplateCreated"), type: "success" }));
			navigate("/dashboard/email/settings");
			setIsLoading(false);
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setIsLoading(false);
		}
	};

	return (
		<Animate type="fade" delay={0.5}>
			<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={2} px={6} py={4}>
				<CustomBreadcrumbs section={t("Email")} />
				<Typography variant={"h2"} fontWeight={600} color={"#164c68"}>
					{t("EmailTemplateCreator")}
				</Typography>
			</Stack>

			<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"flex-start"} width={"100%"} height={"100%"} px={4}>
				<Stack justifyContent={"flex-start"} alignItems={"center"} spacing={5} width={"30%"} height={"100%"}>
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2} width={"100%"} mb={2}>
						<img src={images.variables} alt="variables" width={25} height={25} />
						<Typography variant={"h3"} fontWeight={600} color={"#164c68"} sx={{ mr: `${isRtl ? "8px" : "0px"} !important` }}>
							{t("TemplateVariables")}
						</Typography>
					</Stack>
					<TreeView
						aria-label="gmail"
						defaultExpanded={["3"]}
						defaultCollapseIcon={<ArrowDropDownIcon />}
						defaultExpandIcon={isRtl ? <ArrowLeftIcon /> : <ArrowRightIcon />}
						defaultEndIcon={<div style={{ width: 24 }} />}
						sx={{ height: "100%", flexGrow: 1, maxWidth: 400, overflowY: "auto", width: "100%" }}
					>
						<StyledTreeItem nodeId="1" labelText="Case File" labelIcon={FolderIcon}>
							{renderTreeItems(caseFileVar, "", "caseFile")}
						</StyledTreeItem>
						<StyledTreeItem nodeId="2" labelText="Task" labelIcon={FolderIcon}>
							{renderTreeItems(taskFileVar, "", "task")}
						</StyledTreeItem>
					</TreeView>
				</Stack>

				<Box
					width={"100%"}
					height={"max-content"}
					p={2}
					bgcolor={"#fff"}
					borderRadius={1.5}
					sx={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;" }}
				>
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"} height={"100%"} mb={2}>
						<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1, ml: `${isRtl ? "8px" : "0px"} !important` }}>
							{t("Reference")}:
						</Typography>
						<FormControl sx={{ m: 1, minWidth: 100 }} size="small">
							<Select id="reference" value={reference} onChange={(e) => setReference(e.target.value)}>
								<MenuItem value={"CaseFile"}>{t("CaseFile")}</MenuItem>
								<MenuItem value={"Hearing"}>{t("Hearing")}</MenuItem>
								<MenuItem value={"Memo"}>{t("Memo")}</MenuItem>
								<MenuItem value={"Task"}>{t("Task")}</MenuItem>
							</Select>
						</FormControl>
					</Stack>
					<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={2} width={"100%"} height={"100%"} mb={2}>
						<Typography variant={"h4"} fontWeight={600} color={"#164c68"}>
							{t("TemplateName")}:
						</Typography>
						<CustomTextField
							type={"text"}
							name={"templateName"}
							value={templateName}
							resetValue={() => setTemplateName("")}
							handleInputChange={(e) => setTemplateName(e.target.value)}
							error={error.templateName}
							errorText={errorText.templateName}
							required={true}
						/>
					</Stack>
					<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={2} width={"100%"} height={"100%"} mb={2}>
						<Typography variant={"h4"} fontWeight={600} color={"#164c68"}>
							{t("TemplateSubject")}:
						</Typography>
						<CustomTextField
							type={"text"}
							name={"templateSubject"}
							value={templateSubject}
							resetValue={() => setTemplateSubject("")}
							handleInputChange={(e) => setTemplateSubject(e.target.value)}
							error={error.templateSubject}
							errorText={errorText.templateSubject}
							required={true}
							multiline={true}
						/>
					</Stack>
					<Memo
						ref={quillRef}
						value={value}
						handleChange={(content) => {
							setValue(content);
						}}
					/>
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"} height={"100%"} mt={2}>
						<Button
							disabled={isLoading}
							startIcon={!isRtl && <FaSave />}
							sx={{
								bgcolor: "#164c68",
								color: "#fff",
								"&:hover": {
									bgcolor: "#00a3b1",
								},
								transition: "all 1s ease",
								px: 2,
							}}
							onClick={handleCreateEmailTemplate}
						>
							{t("Save")}
						</Button>
						<Button
							disabled={isLoading}
							sx={{
								color: "#fff",
								backgroundColor: "#fc6739",
								"&:hover": {
									color: "#fff",
									backgroundColor: "red",
								},
								transition: "all 1s ease",
							}}
							onClick={() => navigate(-1)}
						>
							{t("Back")}
						</Button>
					</Stack>
				</Box>
			</Stack>
		</Animate>
	);
};

export default CreateTemplate;
