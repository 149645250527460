import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	selectedFee: null,
	isEditingFee: false,
	isCreatingFee: false,
};

const feesSlice = createSlice({
	name: "fees",
	initialState,
	reducers: {
		setSelectedFee: (state, action) => {
			state.selectedFee = action.payload;
		},
		setIsEditingFee: (state, action) => {
			state.isEditingFee = action.payload;
		},
		setIsCreatingFee: (state, action) => {
			state.isCreatingFee = action.payload;
		},
	},
});

export const { setSelectedFee, setIsEditingFee, setIsCreatingFee } = feesSlice.actions;
export default feesSlice.reducer;
