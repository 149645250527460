import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useGetCasesQuery } from "../../../redux/slices/api/cases/caseApi";
import ClassificationChart from "../../Common/Charts/ClassificationChart";
import { useTranslation } from "react-i18next";

const ClassificationStatBox = ({ isSmallScreen }) => {
	const { t } = useTranslation();
	const [stats, setStats] = React.useState({});

	const { data, isLoading, isError } = useGetCasesQuery({}, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (isError) return;

		if (data) {
			setStats(data?.data);
		}
	}, [data]);

	return (
		<Box
			width={isSmallScreen ? "100%" : "25%"}
			height={"auto"}
			minHeight={"353px"}
			p={2}
			borderRadius={1.5}
			bgcolor={"#fff"}
			sx={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;" }}
		>
			<Stack direction={"row"} width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
				<Typography variant={"h5"} fontWeight={500}>
					{t("CaseFiles")}:
				</Typography>
				<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"}>
					<Typography variant={"h5"} fontWeight={500}>
						{t("Count")}:
					</Typography>
					<Typography
						variant={"h5"}
						fontWeight={500}
						sx={{
							fontFamily: '"Montserrat", "sans-serif"',
						}}
					>
						{data?.totalRecords}
					</Typography>
				</Stack>
			</Stack>

			<Box height={"300px"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
				{isLoading ? <CircularProgress /> : <ClassificationChart type={"classification"} />}
			</Box>
		</Box>
	);
};

export default ClassificationStatBox;
