import { queryBuilder } from "../../../../utils/queryBuilder";
import { apiSlice } from "../apiSlice";

export const contactApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAllContacts: builder.query({
			query: () => ({
				url: "/contact",
				method: "GET",
			}),
			providesTags: ["Contacts"],
		}),
		getByCategory: builder.query({
			query: ({ category, category2 }) => ({
				url: `/contact?category[0]=${category}&category[1]=${category2}`,
				method: "GET",
			}),
			providesTags: ["Contacts"],
		}),
		getContactById: builder.query({
			query: (contactId) => ({
				url: `/contact/${contactId}`,
				method: "GET",
			}),
			providesTags: ["Contacts"],
		}),
		getByCriteria: builder.query({
			query: (query) => ({
				url: `/contact?${queryBuilder(query)}`,
				method: "GET",
			}),
		}),
		addContact: builder.mutation({
			query: ({ data }) => ({
				url: "/contact",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["Contacts"],
		}),
		updateContact: builder.mutation({
			query: ({ contactId, data }) => ({
				url: `/contact/${contactId}`,
				method: "PATCH",
				body: data,
			}),
			invalidatesTags: ["Contacts"],
		}),
		addContactAvatar: builder.mutation({
			query: ({ contactId, data }) => ({
				url: `/contact/${contactId}/file`,
				method: "PATCH",
				body: data,
			}),
			invalidatesTags: ["Contacts"],
		}),
		deleteContact: builder.mutation({
			query: (contactId) => ({
				url: `/contact/${contactId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Contacts"],
		}),
	}),
});

export const {
	useGetAllContactsQuery,
	useGetByCategoryQuery,
	useGetContactByIdQuery,
	useGetByCriteriaQuery,
	useAddContactMutation,
	useUpdateContactMutation,
	useAddContactAvatarMutation,
	useDeleteContactMutation,
} = contactApi;
