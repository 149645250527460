import { ListItemIcon, MenuItem, Typography } from "@mui/material";
import { Menu } from "../../Common/Menu/MenuCommon";
import React, { useState } from "react";
import { CiEdit } from "react-icons/ci";
import { FaUser } from "react-icons/fa";
import EditUser from "./EditUser";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RiLockPasswordLine } from "react-icons/ri";
import UpdatePasswordDialog from "./UpdatePasswordDialog";

const UserSettingMenu = ({ open, anchorEl, setAnchorEl, handleClose, user, setUser, rows, setRows }) => {
	const { t } = useTranslation();
	// const dispatch = useDispatch();
	const userDetails = useSelector((state) => state.app.userDetails);
	const [mode, setMode] = useState(null);
	const [editing, setEditing] = useState(false);
	const [updatePassword, setUpdatePassword] = useState(false);

	const handleCloseDialog = () => {
		handleClose();
	};

	return (
		<>
			<Menu
				id="user-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleCloseDialog}
				MenuListProps={{
					"aria-labelledby": "user-button",
				}}
			>
				<MenuItem
					onClick={() => {
						setEditing(true);
						setMode("view");
					}}
				>
					<ListItemIcon>
						<FaUser size={22} color={"#fff"} />
					</ListItemIcon>
					<Typography variant="h6" color={"#fff"}>
						{t("ViewUser")}
					</Typography>
				</MenuItem>
				{userDetails?.role !== "Employee" && (
					<>
						<MenuItem
							onClick={() => {
								setEditing(true);
								setMode("edit");
							}}
						>
							<ListItemIcon>
								<CiEdit size={22} color={"#fff"} />
							</ListItemIcon>
							<Typography variant="h6" color={"#fff"}>
								{t("UpdateUser")}
							</Typography>
						</MenuItem>
						<MenuItem
							onClick={() => {
								setUpdatePassword(true);
							}}
						>
							<ListItemIcon>
								<RiLockPasswordLine size={22} color={"#fff"} />
							</ListItemIcon>
							<Typography variant="h6" color={"#fff"}>
								{t("UpdatePassword")}
							</Typography>
						</MenuItem>
					</>
				)}
			</Menu>
			{editing && (
				<EditUser open={editing} handleClose={() => setEditing(false)} user={user} setUser={setUser} rows={rows} setRows={setRows} mode={mode} />
			)}

			{updatePassword && <UpdatePasswordDialog open={updatePassword} handleClose={() => setUpdatePassword(false)} user={user} />}
		</>
	);
};

export default UserSettingMenu;
