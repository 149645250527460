import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SectionHeader from "../../Common/SectionHeader";
import { FaBell } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useGetReminderQuery } from "../../../redux/slices/api/reminders/reminderApi";
import CustomCheckbox from "../../Common/CustomCheckBox";
import ReminderEmailTemplate from "./ReminderEmailTemplate";
import { images } from "../../Assets";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Reminders = ({ modelRef }) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const navigate = useNavigate();

	const allReminders = useGetReminderQuery({}, { refetchOnMountOrArgChange: true });
	const [rows, setRows] = useState([]);

	useEffect(() => {
		if (allReminders.isError) return;

		if (allReminders.data && !allReminders.isLoading && !allReminders.isError) {
			const filteredReminders = allReminders.data?.filter((reminder) => reminder?.action?.modelRef === modelRef);
			setRows(filteredReminders);
		}
	}, [modelRef]);

	return (
		<Box
			width={"80%"}
			height={"max-content"}
			p={2}
			borderRadius={2}
			bgcolor={"#fff"}
			sx={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;" }}
		>
			<SectionHeader icon={<FaBell color="#164c68" size={18} />} title={`${t(modelRef)} ${t("Reminders")}`} section={"cases"} type={"dialog"} />
			<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"}>
				<Button
					size="small"
					startIcon={!isRtl && <FaPlus color="#fff" />}
					onClick={() => navigate("/dashboard/reminders/add")}
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						padding: 1,
						"&:hover": {
							bgcolor: "#00a3b1",
						},
						transition: "all 0.5s ease",
						mx: 1,
					}}
				>
					{t("CreateReminder")}
				</Button>
			</Stack>
			<Box height={"100%"} width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"} pb={2}>
				{rows?.length > 0 ? (
					rows?.map((reminder, index) => {
						const daysConverted = reminder.email.days * 24;
						const hours = reminder.email.hours + daysConverted;
						return (
							<Stack
								key={reminder.id}
								width={"100%"}
								direction={"row"}
								justifyContent={"space-between"}
								alignItems={"center"}
								p={2}
								borderBottom={index === rows.length - 1 && "1px solid #aaaaaa6b"}
								mt={3}
							>
								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
									<CustomCheckbox desc={`${t("Active")}`} checked={reminder.active} toggle={() => {}} />
								</Stack>
								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
									<Typography variant={"h6"} fontWeight={700} color={"#164c68"}>
										{t("Type")}:
									</Typography>
									<Typography variant={"h6"} fontWeight={500} color={"#164c68"}>
										{t(reminder.action.type)}
									</Typography>
								</Stack>
								<ReminderEmailTemplate templateId={reminder.email.template} />
								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
									<Typography variant={"h6"} fontWeight={700} color={"#164c68"}>
										{t("Duration")}:
									</Typography>
									<Typography variant={"h6"} fontWeight={500} color={"#164c68"}>
										{hours} {t("Hours")}
									</Typography>
								</Stack>
							</Stack>
						);
					})
				) : (
					<Box
						width={"100%"}
						height={"100%"}
						display={"flex"}
						flexDirection={"column"}
						justifyContent={"center"}
						alignItems={"center"}
						m={"0 auto"}
						p={2}
					>
						<img src={images.notFound} alt="Not Found" width={200} height={200} />
						<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ mt: 4 }}>
							{t("NoReminder")}: {t(modelRef)}
						</Typography>
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default Reminders;
