import React, { useMemo, useState } from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Box, Button, Checkbox, IconButton, Tooltip, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { FcCancel } from "react-icons/fc";
import { useTranslation } from "react-i18next";
import { setIsCreatingFee, setIsEditingFee, setSelectedFee } from "../../../redux/slices/fees";
import { MdEdit } from "react-icons/md";
import { useGetCasesQuery } from "../../../redux/slices/api/cases/caseApi";
import { AiOutlineFilePdf } from "react-icons/ai";
import { SiMicrosoftexcel } from "react-icons/si";
import { excelExport, feeFilePreProcessor, handleConvertToPdf } from "../../../utils/exportHelper";
import SaveTableView from "../../Common/SaveTableView";

const FeeTableReport = ({
	data,
	setOpenFeeDialog,
	setOpenDeleteFeeDialog,
	openCollectFeeDialog,
	setOpenCollectFeeDialog,
	pagination,
	setPagination,
	pageCount,
	colFilter,
	setColFilter,
	columnVisibility,
	setColumnVisibility,
	columnSizing,
	setColumnSizing,
	columnOrder,
	setColumnOrder,
	setDefaultView,
	selectedTemplate,
	setSelectedTemplate,
	setHandleDeleteView,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const isRtl = useSelector((state) => state.app.isRtl);

	const selectedFee = useSelector((state) => state.fees.selectedFee);
	const cases = useGetCasesQuery({}, { refetchOnMountOrArgChange: true });

	const [isLoading, setIsLoading] = useState(false);

	const columns = useMemo(
		() => [
			{
				accessorFn: (row) => (row.caseFile ? row.caseFile.id : "N/A"),
				id: "caseFile",
				header: t("FileNumber"),
				filterVariant: "autocomplete",
				filterSelectOptions: cases.data?.data?.map((c) => ({ value: c.id, label: c.fileNumber.toString() })),
				size: columnSizing?.caseFile || 300,
				Cell: ({ renderedCellValue, row }) => {
					const caseFileNumber = row?.original?.caseFile ? row?.original?.caseFile?.fileNumber.toString() : "N/A";
					return (
						<Box
							sx={{
								borderRadius: "5px",
								padding: "5px",
								fontWeight: "bold",
							}}
						>
							<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
								{caseFileNumber}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessorFn: (row) => (row.caseFile ? row.caseFile.id : "N/A"),
				id: "caseFile.caseNumber",
				header: t("CaseFileFullName"),
				filterVariant: "autocomplete",
				filterSelectOptions: cases.data?.data?.map((c) => ({ value: c.id, label: c.caseNumber?.toString() })),
				size: columnSizing?.["caseFile.caseNumber"] || 300,
				Cell: ({ renderedCellValue, row }) => {
					const caseFileNumber = row?.original?.caseFile
						? row?.original?.caseFile?.classification === "litigation"
							? `${row?.original?.caseFile?.caseNumber}/${row?.original?.caseFile?.caseYear}`
							: "N/A"
						: "N/A";
					return (
						<Box
							sx={{
								borderRadius: "5px",
								padding: "5px",
								fontWeight: "bold",
							}}
						>
							<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
								{caseFileNumber}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessorKey: "type",
				header: t("FeeType"),
				enableColumnFilter: false,
				size: columnSizing?.type || 300,
				Cell: ({ renderedCellValue, row }) => (
					<Box
						sx={{
							borderRadius: "5px",
							padding: "5px",
							fontWeight: "bold",
						}}
					>
						<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
							{renderedCellValue}
						</Typography>
					</Box>
				),
			},
			{
				accessorKey: "amount",
				header: t("Amount"),
				size: columnSizing?.amount || 300,
				enableColumnFilter: false,
				Cell: ({ renderedCellValue, row }) => {
					return (
						<Box
							sx={{
								borderRadius: "5px",
								padding: "5px",
								fontWeight: "bold",
							}}
						>
							<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
								AED {renderedCellValue.toLocaleString()}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessorKey: "dueDate",
				header: t("DueDate"),
				size: columnSizing?.dueDate || 400,
				filterVariant: "date-range",
				Cell: ({ renderedCellValue, row }) => (
					<Box
						sx={{
							borderRadius: "5px",
							padding: "5px",
							fontWeight: "bold",
						}}
					>
						<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
							{moment(renderedCellValue).format("DD/MM/YYYY")}
						</Typography>
					</Box>
				),
			},
			{
				accessorFn: (row) => row.collected,
				accessorKey: "collected",
				header: t("Collected"),
				filterVariant: "select",
				filterSelectOptions: [
					{ value: "true", label: "Yes" },
					{ value: "false", label: "No" },
				],
				size: columnSizing?.collected || 300,
				Cell: ({ renderedCellValue, row }) => {
					const isCollected = row?.original?.collected ? "Yes" : "No";
					return (
						<Box
							sx={{
								borderRadius: "5px",
								padding: "5px",
								fontWeight: "bold",
							}}
						>
							<Typography variant={"h6"} fontWeight={500} color={renderedCellValue === "No" ? "red" : "#164c68"} textTransform={"capitalize"}>
								{isCollected}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessorFn: (row) => (row.collectionDate ? moment(row.collectionDate).format("DD/MM/YYYY") : "N/A"),
				accessorKey: "collectionDate",
				header: t("DateCollected"),
				size: columnSizing?.collectionDate || 400,
				enableColumnFilter: false,
				Cell: ({ renderedCellValue, row }) => (
					<Box
						sx={{
							borderRadius: "5px",
							padding: "5px",
							fontWeight: "bold",
						}}
					>
						<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
							{renderedCellValue}
						</Typography>
					</Box>
				),
			},
			{
				header: t("Actions"),
				id: "Actions",
				size: 300,
				Cell: ({ renderedCellValue, row }) => {
					return (
						<Box
							display={"flex"}
							justifyContent={"center"}
							alignItems={"center"}
							gap={1}
							width={"100%"}
							sx={{
								borderRadius: "5px",
								padding: "3px",
								fontWeight: "bold",
							}}
						>
							{!row.original.collected && (
								<Tooltip title={`${t("CollectFee")}?`}>
									<Checkbox
										checked={openCollectFeeDialog && selectedFee?.id === row?.original?.id}
										size="medium"
										onChange={() => {
											dispatch(setSelectedFee(row?.original));
											dispatch(setIsCreatingFee(false));
											dispatch(setIsEditingFee(true));
											setOpenCollectFeeDialog(true);
										}}
										sx={{
											color: "#164c68",
											"&.Mui-checked": {
												color: "#164c68",
											},
										}}
									/>
								</Tooltip>
							)}
							<Tooltip title={`${t("EditFee")}?`}>
								<IconButton
									onClick={() => {
										dispatch(setSelectedFee(row?.original));
										dispatch(setIsCreatingFee(false));
										dispatch(setIsEditingFee(true));
										setOpenFeeDialog(true);
									}}
								>
									<MdEdit color={"#164c68"} />
								</IconButton>
							</Tooltip>
							<Tooltip title={`${t("DeleteFee")}?`}>
								<IconButton
									onClick={() => {
										dispatch(setSelectedFee(row?.original));
										setOpenDeleteFeeDialog(true);
									}}
								>
									<FcCancel />
								</IconButton>
							</Tooltip>
						</Box>
					);
				},
			},
		],
		[]
	);

	const table = useMaterialReactTable({
		columns,
		data,
		columnFilterDisplayMode: "popover",
		muiTableHeadCellProps: {
			align: "center",
			sx: {
				color: "#164c68",
				"& .css-d0cm6k .MuiTableSortLabel-icon": {
					color: "#164c68 !important",
				},
				"& .MuiTableSortLabel-icon": {
					color: "#164c68 !important",
				},
				"& .MuiTableSortLabel-iconDirectionAsc": {
					color: "#164c68 !important",
				},
				"& .MuiTableSortLabel-iconDirectionDesc": {
					color: "#164c68 !important",
				},
			},
		},
		muiTableBodyCellProps: {
			align: "center",
		},
		enableStickyHeader: true,
		enableGlobalFilter: false,
		enableColumnResizing: true,
		enableFacetedValues: true,
		enableColumnDragging: true,
		enableColumnOrdering: true,
		initialState: { density: "compact" },
		enableFullScreenToggle: false,
		enableRowActions: false,
		layoutMode: "grid",
		columnResizeMode: "onEnd",
		paginationDisplayMode: "pages",
		muiTableBodyRowProps: ({ row }) => ({
			sx: {
				backgroundColor: "#fff !important",
			},
		}),
		manualPagination: true,
		muiPaginationProps: {
			color: "primary",
			rowsPerPageOptions: [10, 20, 30, 50, 100],
			shape: "rounded",
			variant: "outlined",
		},
		rowCount: pagination.pageSize,
		pageCount: pageCount,
		onPaginationChange: setPagination,
		onColumnFiltersChange: setColFilter,
		onColumnVisibilityChange: setColumnVisibility,
		onColumnOrderChange: setColumnOrder,
		onColumnSizingChange: setColumnSizing,
		state: {
			pagination,
			colFilter,
			columnVisibility,
			columnOrder,
			columnSizing,
		},
		renderTopToolbarCustomActions: ({ table }) => (
			<Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} gap={1}>
				<Button
					disabled={isLoading}
					size="small"
					startIcon={!isRtl && <AiOutlineFilePdf color="#fff" />}
					onClick={() => {
						const visibleColumns = table.getVisibleFlatColumns();
						const notReorderedColumns = visibleColumns?.map((column) => column.id);
						const reorderedColumns = columnOrder.filter(
							(column) => notReorderedColumns.includes(column) && column !== "mrt-row-spacer" && column !== "Actions"
						);
						const formattedVisibleColumns = reorderedColumns.map((column) => {
							let columnHeaderName = column;
							if (columnHeaderName === "caseFile") {
								columnHeaderName = t("FileNumber");
							}
							if (columnHeaderName === "caseFile.caseNumber") {
								columnHeaderName = t("CaseFileFullName");
							}
							if (columnHeaderName === "dueDate") {
								columnHeaderName = t("DueDate");
							}
							if (columnHeaderName === "type") {
								columnHeaderName = t("FeeType");
							}
							if (columnHeaderName === "amount") {
								columnHeaderName = t("Amount");
							}
							if (columnHeaderName === "collected") {
								columnHeaderName = t("Collected");
							}

							if (columnHeaderName === "collectionDate") {
								columnHeaderName = t("DateCollected");
							}
							return { title: columnHeaderName, dataKey: columnHeaderName };
						});
						const rows = table.getPrePaginationRowModel().rows.map((row) => row.original);
						const dataToConvert = feeFilePreProcessor(rows, visibleColumns, isRtl);
						const reorderedData = dataToConvert.map((obj) => {
							let reorderedObj = {};
							reorderedColumns.forEach((key) => {
								reorderedObj[key] = obj[key];
							});
							return reorderedObj;
						});
						handleConvertToPdf(formattedVisibleColumns, reorderedData, "Fee Report.pdf", isRtl);
					}}
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						padding: 1,
						"&:hover": {
							bgcolor: "#00a3b1",
						},
						transition: "all 0.5s ease",
						marginLeft: `${isRtl ? "16px" : "0px"} !important`,
					}}
				>
					{t("ExportPdf")}
				</Button>
				<Button
					disabled={isLoading}
					size="small"
					startIcon={!isRtl && <SiMicrosoftexcel color="#fff" />}
					onClick={() => {
						const visibleColumns = table.getVisibleFlatColumns();
						const notReorderedColumns = visibleColumns?.map((column) => column.id);
						const reorderedColumns = columnOrder.filter(
							(column) => notReorderedColumns.includes(column) && column !== "mrt-row-spacer" && column !== "Actions"
						);
						const formattedVisibleColumns = reorderedColumns.map((column) => {
							let columnHeaderName = column;
							if (columnHeaderName === "caseFile") {
								columnHeaderName = t("FileNumber");
							}
							if (columnHeaderName === "caseFile.caseNumber") {
								columnHeaderName = t("CaseFileFullName");
							}
							if (columnHeaderName === "dueDate") {
								columnHeaderName = t("DueDate");
							}
							if (columnHeaderName === "type") {
								columnHeaderName = t("FeeType");
							}
							if (columnHeaderName === "amount") {
								columnHeaderName = t("Amount");
							}
							if (columnHeaderName === "collected") {
								columnHeaderName = t("Collected");
							}

							if (columnHeaderName === "collectionDate") {
								columnHeaderName = t("DateCollected");
							}
							return columnHeaderName;
						});
						const rows = table.getPrePaginationRowModel().rows.map((row) => row.original);
						const dataToConvert = feeFilePreProcessor(rows, visibleColumns, isRtl);
						const formattedData = dataToConvert.map((row) => {
							let formattedRow = {};
							for (let key in row) {
								let newKey = key;
								if (key === "caseFile") {
									newKey = t("FileNumber");
								}
								if (key === "caseFile.caseNumber") {
									newKey = t("CaseFileFullName");
								}
								if (key === "dueDate") {
									newKey = t("DueDate");
								}
								if (key === "type") {
									newKey = t("FeeType");
								}
								if (key === "amount") {
									newKey = t("Amount");
								}
								if (key === "collected") {
									newKey = t("Collected");
								}

								if (key === "collectionDate") {
									newKey = t("DateCollected");
								}
								formattedRow[newKey] = row[key];
							}
							return formattedRow;
						});
						excelExport(formattedData, formattedVisibleColumns, "Fee Report", "Fee Report.xlsx");
					}}
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						padding: 1,
						"&:hover": {
							bgcolor: "#00a3b1",
						},
						transition: "all 0.5s ease",
					}}
				>
					{t("ExportExcel")}
				</Button>
				<SaveTableView
					allColumns={table.getAllFlatColumns()}
					visibleColumns={table.getVisibleFlatColumns()}
					setColumnVisibility={setColumnVisibility}
					selectedColumnOrder={columnOrder}
					setColumnOrder={setColumnOrder}
					tableType={"fee"}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
					selectedTemplate={selectedTemplate}
					setSelectedTemplate={setSelectedTemplate}
					setDefaultView={setDefaultView}
					mode={"update"}
				/>

				<SaveTableView
					allColumns={table.getAllFlatColumns()}
					visibleColumns={table.getVisibleFlatColumns()}
					setColumnVisibility={setColumnVisibility}
					selectedColumnOrder={columnOrder}
					setColumnOrder={setColumnOrder}
					tableType={"fee"}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
					selectedTemplate={selectedTemplate}
					setSelectedTemplate={setSelectedTemplate}
					setDefaultView={setDefaultView}
					mode={"save"}
				/>
				<Button
					onClick={() => setHandleDeleteView(true)}
					disabled={isLoading}
					sx={{
						color: "#fff",
						backgroundColor: "#fc6739",
						"&:hover": {
							color: "#fff",
							backgroundColor: "red",
						},
						transition: "all 1s ease",
						padding: 1,
					}}
				>
					{t("DeleteTableView")}
				</Button>
				{colFilter?.length > 0 && (
					<Button
						onClick={() => setColFilter([])}
						disabled={isLoading}
						sx={{
							color: "#fff",
							backgroundColor: "#fc6739",
							"&:hover": {
								color: "#fff",
								backgroundColor: "red",
							},
							transition: "all 1s ease",
							padding: 1,
						}}
					>
						{t("ClearFilter")}
					</Button>
				)}
			</Box>
		),
	});

	return <MaterialReactTable table={table} />;
};

export default FeeTableReport;
