import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaBriefcase } from "react-icons/fa";
import { PiUsersFourFill } from "react-icons/pi";
import { useSelector } from "react-redux";

const PartyCard = ({ party, setSelectedContactId, setHandlePopOutContact }) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);
	return (
		<Box width={"100%"} p={2} height={"100%"} borderRadius={1.5} sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;" }} bgcolor={"#f3f7ff"}>
			<Stack justifyContent={"flex-start"} width={"100%"} spacing={2}>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={1}>
					<PiUsersFourFill color={"#164c68"} />
					<Typography variant={"h5"} fontWeight={600} color={"#595858"} sx={{ mx: `8px  !important` }}>
						{t("Party")}:{" "}
					</Typography>{" "}
					<Typography
						variant={"h5"}
						fontWeight={500}
						color={"#595858"}
						sx={{
							"&:hover": {
								cursor: "pointer",
								textDecoration: "underline",
							},
							transition: "all 1s ease-in-out",
						}}
						onClick={() => {
							setSelectedContactId(party?.party?.id);
							setHandlePopOutContact(true);
						}}
					>
						{party.party.nameAr ? (isRtl ? party.party.nameAr : party.party.nameEn ? party.party.nameEn : party.party.nameAr) : "N/A"}
					</Typography>
				</Stack>
				{party.caseRole ? (
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={1}>
						<FaBriefcase color={"#164c68"} />
						<Typography variant={"h5"} fontWeight={600} color={"#595858"} sx={{ mx: `8px !important` }}>
							{t("CaseRole")}:{" "}
						</Typography>
						<Typography
							variant={"h5"}
							fontWeight={500}
							color={"#595858"}
						>
							{isRtl ? party.caseRole.nameAr : party.caseRole.nameEn ? party.caseRole.nameEn : party.caseRole.nameAr}
						</Typography>
					</Stack>
				) : (
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={1}>
						<FaBriefcase color={"#164c68"} />
						<Typography variant={"h5"} fontWeight={600} color={"#595858"} sx={{ mx: `8px !important` }}>
							{t("CaseRole")}:{" "}
						</Typography>
						<Typography variant={"h5"} fontWeight={500} color={"#595858"}>
							N/A
						</Typography>
					</Stack>
				)}
				{party.lawyer ? (
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={1}>
						<FaBriefcase color={"#164c68"} />
						<Typography variant={"h5"} fontWeight={600} color={"#595858"} sx={{ mx: `8px !important` }}>
							{t("Lawyer")}:{" "}
						</Typography>
						<Typography
							variant={"h5"}
							fontWeight={500}
							color={"#595858"}
							sx={{
								"&:hover": {
									cursor: "pointer",
									textDecoration: "underline",
								},
								transition: "all 1s ease-in-out",
							}}
							onClick={() => {
								setSelectedContactId(party?.lawyer?.id);
								setHandlePopOutContact(true);
							}}
						>
							{isRtl ? party.lawyer.nameAr : party.lawyer.nameEn ? party.lawyer.nameEn : party.lawyer.nameAr}
						</Typography>
					</Stack>
				) : (
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={1}>
						<FaBriefcase color={"#164c68"} />
						<Typography variant={"h5"} fontWeight={600} color={"#595858"} sx={{ mx: `8px !important` }}>
							{t("Lawyer")}:{" "}
						</Typography>
						<Typography variant={"h5"} fontWeight={500} color={"#595858"}>
							N/A
						</Typography>
					</Stack>
				)}
			</Stack>
		</Box>
	);
};

export default PartyCard;
