import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	currentCase: null,
	currentHearing: null,
	isEditingHearing: false,
	isEditingCase: false,
	isViewingCase: false,
	meetings: [],
};

export const caseSlice = createSlice({
	name: "case",
	initialState,
	reducers: {
		setCurrentCase: (state, action) => {
			state.currentCase = action.payload;
		},
		setCurrentHearing: (state, action) => {
			state.currentHearing = action.payload;
		},
		setIsEditingHearing: (state, action) => {
			state.isEditingHearing = action.payload;
		},
		setIsEditingCase: (state, action) => {
			state.isEditingCase = action.payload;
		},
		setIsViewingCase: (state, action) => {
			state.isViewingCase = action.payload;
		},
		setMeetings: (state, action) => {
			state.meetings = action.payload;
		},
	},
});

export const { setCurrentCase, setCurrentHearing, setIsEditingCase, setIsViewingCase, setIsEditingHearing, setMeetings } = caseSlice.actions;

export default caseSlice.reducer;
