import { apiSlice } from "../apiSlice";

export const reminderApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getReminder: builder.query({
			query: () => ({
				url: "/reminder",
				method: "GET",
			}),
			providesTags: ["Reminders"],
		}),
		getReminderMetadata: builder.query({
			query: () => ({
				url: "/reminder/metadata",
				method: "GET",
			}),
		}),
		addReminder: builder.mutation({
			query: (data) => ({
				url: "/reminder",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["Reminders"],
		}),
		deleteReminder: builder.mutation({
			query: (reminderId) => ({
				url: `/reminder/${reminderId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Reminders"],
		}),
	}),
});

export const { useGetReminderQuery, useGetReminderMetadataQuery, useAddReminderMutation, useDeleteReminderMutation } = reminderApi;
