import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import SectionHeader from "../../Common/SectionHeader";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { MdAddModerator } from "react-icons/md";
import CustomTextField from "../../Common/CustomTextField";
import UserSelection from "./UserSelection";
import PermissionSelection from "./PermissionSelection";
import { useGetAllUsersQuery } from "../../../redux/slices/api/user/userApi";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../../redux/slices/app";
import { useUpdatePermissionMutation } from "../../../redux/slices/api/permission/permissionApi";
import CustomBackdrop from "../../Common/CustomBackdrop";
import { useNavigate } from "react-router-dom";
import { images } from "../../Assets";
import { useTranslation } from "react-i18next";

const EditPermission = ({ isSmallScreen, handleClose, selected }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const [formData, setFormData] = useState({
		permissionName: selected.name,
		description: selected.description ? selected.description : "",
	});

	const { data, isLoading, isError } = useGetAllUsersQuery({}, { refetchOnMountOrArgChange: true });

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [usersToAdd, setUsersToAdd] = useState(selected.users);

	const [userList, setUserList] = useState(null);
	const [originalRows, setOriginalRows] = useState(null);

	const [permissionsToAdd, setPermissionsToAdd] = useState(selected.permissions);

	const [openUserSelection, setOpenUserSelection] = useState(false);

	const [hasError, setHasError] = useState(false);

	useEffect(() => {
		if (isError) {
			// dispatch(setSnackbar({ open: true, message: "Error fetching data", severity: "error" }));
			return;
		}
		if (data && !isError && !isLoading) {
			const usersToAddSet = new Set(usersToAdd.map((addUser) => addUser.id));

			const filteredRows = data?.data.filter((rowUser) => !usersToAddSet.has(rowUser.id));

			setUserList(filteredRows);
			setOriginalRows(filteredRows);
		}
	}, [data, isLoading, isError, usersToAdd]);

	const handleInput = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const [updatePermission] = useUpdatePermissionMutation();

	const handleUpdatePermission = async (e) => {
		e.preventDefault();
		setIsSubmitting(true);

		if (formData.permissionName === "") {
			setHasError(true);
			setIsSubmitting(false);
			return;
		}
		if (usersToAdd.length < 1) {
			dispatch(setSnackbar({ open: true, message: t("UserMinimum"), severity: "error" }));
			setIsSubmitting(false);
			return;
		}

		// Filter out keys with empty array values
		const filteredPermissions = Object.fromEntries(
			Object.entries(permissionsToAdd)
				.filter(([key, value]) => value.length > 0)
				.map(([key, value]) => [key, key === "constraint" || key === "contact" ? value.filter((permission) => permission !== "view") : value])
				.map(([key, value]) => [
					key === "case_file"
						? "caseFile"
						: key === "email_account"
						? "emailAccount"
						: key === "email_template"
						? "emailTemplate"
						: key === "email_message"
						? "emailMessage"
						: key,
					value,
				])
		);

		const formattedUsersToAdd = usersToAdd.map((user) => user.id);

		const payload = {
			name: formData.permissionName,
			users: formattedUsersToAdd,
			permissions: filteredPermissions,
			description: formData.description,
		};

		try {
			await updatePermission({ permissionId: selected.id, data: payload }).unwrap();
			dispatch(setSnackbar({ open: true, message: t("PermissionGroupUpdatedSuccessfully"), severity: "info" }));
			setIsSubmitting(false);
			navigate(-1);
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setIsSubmitting(false);
		}
	};

	return (
		<>
			<Box
				width={"100%"}
				height={"max-content"}
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
				gap={4}
				sx={{ overflowY: "auto", overflowX: "hidden" }}
				p={4}
			>
				<Box
					width={isSmallScreen ? "100%" : "60%"}
					height={"auto"}
					p={2}
					borderRadius={1.5}
					sx={{
						boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
					}}
					bgcolor={"#fff"}
				>
					<SectionHeader
						icon={<MdAddModerator color="#164c68" size={18} />}
						title={`${t("EditPermissionGroup")}: ${selected.name}`}
						type={"dialog"}
					/>
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<Stack justifyContent={"flex-start"} alignItems={"center"} spacing={2} p={4} mb={2}>
						<CustomTextField
							type={"text"}
							label={`${t("PermissionName")}: `}
							value={formData.permissionName}
							resetValue={() => setFormData({ ...formData, permissionName: "" })}
							name={"permissionName"}
							handleInputChange={handleInput}
							error={hasError}
							errorText={"Permission Group Name is required"}
							required={true}
						/>
						<CustomTextField
							type={"text"}
							label={`${t("PermissionGroupDesc")}:`}
							value={formData.description}
							resetValue={() => setFormData({ ...formData, description: "" })}
							name={"description"}
							handleInputChange={handleInput}
							required={false}
							multiline={true}
						/>
					</Stack>
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={2} p={1} width={"100%"}>
						<Box width={"100%"}>
							<Typography variant={"h4"} fontWeight={600} color={"#164c68"} sx={{ mb: 2 }}>
								{t("Users")}:
							</Typography>
							{usersToAdd.length > 0 && (
								<Grid container spacing={2} width={"100%"}>
									{usersToAdd.map((user) => (
										<Grid item xs={12} sm={3} key={user.id} width={"100%"}>
											<Box
												width={"100%"}
												p={2}
												height={"100%"}
												borderRadius={1.5}
												display={"flex"}
												justifyContent={"flex-start"}
												alignItems={"start"}
												flexDirection={"column"}
												gap={2}
												bgcolor={"#f3f7ff"}
												sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;" }}
											>
												<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={1}>
													<img src={images.id} alt="id" width={23} height={23} />
													<Typography
														variant="h5"
														color={"#164c68"}
														fontWeight={700}
														sx={{ mx: `${isRtl ? "8px" : "0px"} !important` }}
													>
														{t("FirstName")}:
													</Typography>
													<Typography variant="h5" color={"#164c68"} fontWeight={500}>
														{user?.firstName}
													</Typography>
												</Stack>
												<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={1}>
													<img src={images.id} alt="id" width={23} height={23} />
													<Typography
														variant="h5"
														color={"#164c68"}
														fontWeight={700}
														sx={{ mx: `${isRtl ? "8px" : "0px"} !important` }}
													>
														{t("LastName")}:
													</Typography>
													<Typography variant="h5" color={"#164c68"} fontWeight={500}>
														{user?.lastName}
													</Typography>
												</Stack>

												<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={1}>
													<img src={images.role} alt="role" width={23} height={23} />
													<Typography
														variant="h5"
														color={"#164c68"}
														fontWeight={700}
														sx={{ mx: `${isRtl ? "8px" : "0px"} !important` }}
													>
														{t("Role")}:
													</Typography>
													<Typography variant="h5" color={"#164c68"} fontWeight={500}>
														{user?.role}
													</Typography>
												</Stack>
											</Box>
										</Grid>
									))}
								</Grid>
							)}
						</Box>
						<Button
							onClick={() => setOpenUserSelection(true)}
							sx={{
								bgcolor: "#164c68",
								color: "#fff",
								"&:hover": {
									bgcolor: "#00a3b1",
									color: "#fff",
								},
								transition: "all 1s ease",
							}}
						>
							{t("ManageUsers")}
						</Button>
					</Stack>

					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<Typography variant={"h5"} fontWeight={600} color={"#164c68"}>
						{t("ManagePermissions")}:
					</Typography>
					<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={2} p={1}>
						<PermissionSelection isSmallScreen={isSmallScreen} permissions={permissionsToAdd} setPermissions={setPermissionsToAdd} />
					</Stack>
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<Stack direction="row" justifyContent={"flex-end"} alignItems={"center"} spacing={2} p={1}>
						<Button
							onClick={handleUpdatePermission}
							disabled={isSubmitting}
							sx={{
								bgcolor: "#164c68",
								color: "#fff",
								"&:hover": {
									bgcolor: "#00a3b1",
									color: "#fff",
								},
								transition: "all 1s ease",
								mr: 1,
							}}
						>
							{t("Save")}
						</Button>
						<Button
							onClick={handleClose}
							disabled={isSubmitting}
							sx={{
								color: "red",
								"&:hover": {
									color: "#fff",
									backgroundColor: "red",
								},
								transition: "all 1s ease",
								mr: `${isRtl ? "8px" : "0px"} !important`,
							}}
						>
							{t("Cancel")}
						</Button>
					</Stack>
				</Box>
			</Box>
			{isSubmitting && <CustomBackdrop open={isSubmitting} />}
			{openUserSelection && (
				<UserSelection
					open={openUserSelection}
					handleClose={() => setOpenUserSelection(false)}
					usersToAdd={usersToAdd}
					setUsersToAdd={setUsersToAdd}
					rows={userList}
					setRows={setUserList}
					originalRows={originalRows}
				/>
			)}
		</>
	);
};

export default EditPermission;
