import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import SectionHeader from "../../Common/SectionHeader";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TbVariable } from "react-icons/tb";
import { Divider, FormControl, MenuItem, Select, Stack, Typography } from "@mui/material";
import CustomTextField from "../../Common/CustomTextField";
import { LoadingButton } from "@mui/lab";
import { useCreateConstraintMutation, useUpdateConstraintMutation } from "../../../redux/slices/api/constraints/constraintsApi";
import { setSnackbar } from "../../../redux/slices/app";
import { setIsEditingConstraint, setSelectedConstraint } from "../../../redux/slices/constraints";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const AddConstraintDialog = ({ open, handleClose, reference }) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);
	const isEditingConstraint = useSelector((state) => state.constraints.isEditingConstraint);
	const selectedConstraint = useSelector((state) => state.constraints.selectedConstraint);

	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({
		nameAr: isEditingConstraint ? selectedConstraint.nameAr : "",
		reference: reference,
	});

	const [nameEn, setNameEn] = useState(isEditingConstraint ? (selectedConstraint.nameEn ? selectedConstraint.nameEn : "") : "");
	const [status, setStatus] = useState(isEditingConstraint ? selectedConstraint.status : true);

	const [hasError, setHasError] = useState(false);
	const [errorText, setErrorText] = useState("");

	const handleCloseAction = () => {
		dispatch(setIsEditingConstraint(false));
		handleClose();
		dispatch(setSelectedConstraint(null));
	};

	const [createConstraint] = useCreateConstraintMutation();

	const handleCreateConstraint = async (e) => {
		e.preventDefault();
		setLoading(true);

		if (!formData.nameAr || formData.nameAr === "") {
			setHasError(true);
			setErrorText("Name is required");
			setLoading(false);
			return;
		}

		if (!reference || reference === "") {
			setLoading(false);
			dispatch(setSnackbar({ open: true, message: t("ReferenceIsRequired"), severity: "error" }));
			return;
		}

		let data = {
			nameAr: formData.nameAr,
			reference: reference,
		};

		if (nameEn !== "") {
			data = { ...data, nameEn: nameEn };
		}
		if (status !== null) {
			data = { ...data, status: status };
		}

		try {
			await createConstraint({ data }).unwrap();
			setLoading(false);
			dispatch(setSnackbar({ open: true, message: `${t(reference)} ${t("AddedSuccessfully")}`, severity: "success" }));
			handleClose();
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setLoading(false);
		}
	};

	const [updateConstraint] = useUpdateConstraintMutation();

	const handleUpdateConstraint = async (e) => {
		e.preventDefault();
		setLoading(true);

		if (!formData.nameAr || formData.nameAr === "") {
			setHasError(true);
			setErrorText("Name is required");
			setLoading(false);
			return;
		}

		if (!reference || reference === "") {
			setLoading(false);
			dispatch(setSnackbar({ open: true, message: t("ReferenceIsRequired"), severity: "error" }));
			return;
		}

		let data = {
			nameAr: formData.nameAr,
			nameEn: nameEn,
			status: status,
			reference: reference,
		};
		try {
			await updateConstraint({ id: selectedConstraint.id, data: data }).unwrap();
			setLoading(false);
			dispatch(setSnackbar({ open: true, message: `${t(reference)} ${t("UpdatedSuccessfully")}`, severity: "success" }));
			handleClose();
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setLoading(false);
		}
	};

	return (
		<Dialog open={open} fullWidth TransitionComponent={Transition} keepMounted aria-describedby="add-constraint-dialog" sx={{ height: "100%" }}>
			<DialogTitle sx={{ pb: 2, borderBottom: "1px solid #aaaaaa6b", backgroundColor: "#fff" }}>
				<SectionHeader
					icon={<TbVariable color="#164c68" size={18} />}
					title={`${isEditingConstraint ? t("UpdatingConstraint") : t("AddingConstraint")}: ${reference}`}
					type={"dialog"}
				/>
			</DialogTitle>
			<DialogContent sx={{ backgroundColor: "#fff" }}>
				<DialogContentText id="add-constraint-dialog-description" sx={{ p: 4 }}>
					<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ mb: 2 }}>
						{t("ConstraintDetails")}:
					</Typography>
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={3} width={"100%"} sx={{ mb: 2 }}>
						<CustomTextField
							type={"text"}
							label={`${t("ArabicName")}: `}
							value={formData.nameAr}
							resetValue={() => setFormData({ ...formData, nameAr: "" })}
							name={"nameAe"}
							handleInputChange={(e) => {
								setFormData({ ...formData, nameAr: e.target.value });
							}}
							error={hasError}
							errorText={errorText}
							required={true}
						/>
						<CustomTextField
							type={"text"}
							label={`${t("EnglishName")}: `}
							value={nameEn}
							resetValue={() => setNameEn("")}
							name={"nameEn"}
							handleInputChange={(e) => {
								setNameEn(e.target.value);
							}}
							required={false}
						/>
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
							<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1, ml: `${isRtl ? "8px" : "0px"} !important` }}>
								{t("Status")}:
							</Typography>
							<FormControl sx={{ m: 1, minWidth: 100 }} size="small">
								<Select id="select-status" value={status} onChange={(e) => setStatus(e.target.value)} sx={{ color: "#164c68" }} displayEmpty>
									<MenuItem value={true}>{t("Active")}</MenuItem>
									<MenuItem value={false}>{t("Inactive")}</MenuItem>
								</Select>
							</FormControl>
						</Stack>
					</Stack>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: "#fff" }}>
				<Button
					disabled={loading}
					onClick={handleCloseAction}
					sx={{
						color: "red",
						"&:hover": {
							color: "#fff",
							backgroundColor: "red",
						},
						transition: "all 1s ease",
					}}
				>
					{t("Cancel")}
				</Button>
				<LoadingButton
					loading={loading}
					variant="contained"
					size="medium"
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						mr: `${isRtl ? "8px" : "0px"} !important`,
					}}
					onClick={isEditingConstraint ? handleUpdateConstraint : handleCreateConstraint}
				>
					{t("Save")}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default AddConstraintDialog;
