import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Divider, Fade, Grid, Stack, Typography } from "@mui/material";
import { useGetFilesQuery } from "../../../../redux/slices/api/files/fileApi";
import { images } from "../../../Assets";
import FileCard from "./FileCard";
import { FaCloudUploadAlt } from "react-icons/fa";
import UploadSection from "./UploadSection";
import { IoCaretDownOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../../../redux/slices/app";
import { useGetConstraintsQuery } from "../../../../redux/slices/api/constraints/constraintsApi";
import ContextMenu from "./ContextMenu";

const FileSection = ({ criteria, criteriaId }) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	const dispatch = useDispatch();

	const fileCategory = useGetConstraintsQuery({ reference: "fileCategory" }, { refetchOnMountOrArgChange: true });

	const { data, isLoading, isError } = useGetFilesQuery({ criteria: criteria, criteriaId: criteriaId }, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (isError) return;
		if (data) {
			setFiles(data.data);
			setOriginalFiles(data.data);
		}
	}, [data]);

	const [files, setFiles] = useState(data?.data || []);
	const [originalFiles, setOriginalFiles] = useState(data?.data || []);
	const [sortedFiles, setSortedFiles] = useState([]);
	const [uploading, setUploading] = useState(false);
	const [filesToUpload, setFilesToUpload] = useState([]);
	const [filePreview, setFilePreview] = useState([]);
	const [isSorting, setIsSorting] = useState(true);
	const [viewingByCategory, setViewingByCategory] = useState(null);
	const [selectedCategory, setSelectedCategory] = useState(isRtl ? fileCategory.data?.data[0]?.nameAr : fileCategory.data?.data[0]?.nameEn);

	const [expanded, setExpanded] = useState(false);

	useEffect(() => {
		if (!isSorting) {
			setFiles(originalFiles);
		} else {
			const groupedByCategory = files?.reduce((acc, file) => {
				const categoryKey = isRtl ? file.category?.nameAr : file.category?.nameEn;
				if (!acc[categoryKey]) {
					acc[categoryKey] = [];
				}
				acc[categoryKey].push(file);
				return acc;
			}, {});

			let groupedArray = Object.keys(groupedByCategory).map((category) => ({
				category: category,
				files: groupedByCategory[category],
			}));

			groupedArray?.sort((a, b) => {
				if (a.category === selectedCategory) return -1;
				if (b.category === selectedCategory) return 1;
				return 0;
			});

			setSortedFiles(groupedArray);
		}
	}, [isSorting, isRtl, selectedCategory, files, originalFiles]);

	const handleExpansion = () => {
		setExpanded((prevExpanded) => !prevExpanded);
	};

	const inputRef = useRef(null);

	const handleUpload = (e) => {
		const fileData = e.target.files;
		if (fileData.length < 2 || fileData.length === 1) {
			const file = fileData[0];
			if (file.size <= 10 * 1024 * 1024) {
				const reader = new FileReader();
				reader.onloadend = () => {
					setFilePreview(URL.createObjectURL(file));
					setFilesToUpload([file]);
					setUploading(true);
				};
				reader.readAsDataURL(file);
			} else {
				// Handle error or notify the user about the file size limit
				dispatch(setSnackbar({ open: true, message: `${t("ThisFile")} ${file.name} ${t("TooLarge")}`, severity: "error" }));
			}
		} else {
			for (let i = 0; i < fileData.length; i++) {
				const file = fileData[i];
				if (file.size <= 10 * 1024 * 1024) {
					const reader = new FileReader();
					reader.onloadend = () => {
						setFilePreview((prevFilePreview) => [...prevFilePreview, URL.createObjectURL(fileData[i])]);
						setFilesToUpload((prevFiles) => [...prevFiles, fileData[i]]);
						setUploading(true);
					};
					reader.readAsDataURL(fileData[i]);
				} else {
					// Handle error or notify the user about the file size limit
					dispatch(setSnackbar({ open: true, message: `${t("ThisFile")} ${file.name}: ${t("TooLarge")}`, severity: "error" }));
				}
			}
		}
	};

	const handleContextMenu = (e) => {
		e.preventDefault();
		setViewingByCategory(
			viewingByCategory === null
				? {
						mouseX: e.clientX + 2,
						mouseY: e.clientY - 6,
				  }
				: null
		);
	};

	return (
		<Box
			width={"100%"}
			height={"max-content"}
			sx={{
				overflowY: "auto",
				overflowX: "hidden",
				boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;",
				cursor: "context-menu",
			}}
			p={2}
			borderRadius={1.5}
			bgcolor={"#fff"}
			onContextMenu={handleContextMenu}
		>
			<Accordion
				expanded={expanded}
				onChange={handleExpansion}
				slots={{ transition: Fade }}
				slotProps={{ transition: { timeout: 1500 } }}
				sx={{
					bgcolor: "#fff",
					boxShadow: "none",
					"& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
					"& .MuiAccordionDetails-root": { display: expanded ? "block" : "none" },
				}}
			>
				<AccordionSummary expandIcon={<IoCaretDownOutline size={20} color={"#164c68"} />} aria-controls="panel1-content" id="panel1-header">
					<Stack
						direction={isSmallScreen ? "column" : "row"}
						justifyContent={isSmallScreen ? "flex-start" : "space-between"}
						alignItems={isSmallScreen ? "flex-start" : "center"}
						spacing={1}
						width={"100%"}
						mr={2}
					>
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
							<img src={images.file} alt="file" width={25} height={25} />
							<Typography variant={"h3"} fontWeight={600} color={"#164c68"}>
								{t("Files")}:
							</Typography>
						</Stack>
						<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1} width={"100%"}>
							<Button
								startIcon={!isRtl && <FaCloudUploadAlt />}
								sx={{
									bgcolor: "#164c68",
									color: "#fff",
									"&:hover": {
										bgcolor: "#00a3b1",
									},
									transition: "all 1s ease",
									py: 1,
									px: 2,
								}}
								onClick={() => inputRef.current.click()}
							>
								{t("AddFiles")}
							</Button>
						</Stack>
					</Stack>
				</AccordionSummary>
				<AccordionDetails>
					{!isSorting && <Divider sx={{ mb: 2, bgcolor: "#164c6866" }} />}
					{isLoading && !isError && (files.length >= 1 || sortedFiles.length >= 1) && (
						<Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"} width={"100%"} height={"100%"}>
							<CircularProgress color="primary" />
						</Box>
					)}
					{files?.length > 0 && !isLoading && !isError && !isSorting ? (
						<Grid container spacing={2}>
							{files.map((file) => (
								<Grid item xs={12} sm={3} key={file.id}>
									<FileCard file={file} isSorting={isSorting} />
								</Grid>
							))}
						</Grid>
					) : sortedFiles?.length > 0 && isSorting ? (
						<Stack justifyContent={"center"} spacing={4} width={"100%"} height={"100%"}>
							{sortedFiles?.map((file) => {
								return (
									<React.Fragment key={file?.category}>
										<Divider sx={{ mb: 2, textTransform: "capitalize" }} textAlign="left">
											<Typography variant={"h4"} fontWeight={600} color={"#164c68"}>
												{file?.category}
											</Typography>
										</Divider>
										<Grid container spacing={2}>
											{file?.files?.map((f) => {
												return (
													<Grid item xs={12} sm={4} key={f.id}>
														<FileCard file={f} isSorting={isSorting} />
													</Grid>
												);
											})}
										</Grid>
									</React.Fragment>
								);
							})}
						</Stack>
					) : (
						<Box width={"100%"} height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} p={1}>
							<img src={images.notFound} alt="Not Found" width={100} height={100} />
							<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ my: 2 }}>
								{t("NoFileAvailable")}!
							</Typography>
							<Typography variant={"subtitle1"} fontWeight={500} color={"#51505099"}>
								{t("AddFiles")}!
							</Typography>
						</Box>
					)}
				</AccordionDetails>
			</Accordion>
			<input ref={inputRef} type="file" multiple hidden accept="image/*, .pdf, .doc, .docx, .xls, .xlsx,.ppt, .pptx" onChange={handleUpload} />
			{uploading && (
				<UploadSection
					open={uploading}
					handleClose={() => setUploading(false)}
					criteria={criteria}
					criteriaId={criteriaId}
					files={filesToUpload}
					setFiles={setFilesToUpload}
					filePreview={filePreview}
					setFilePreview={setFilePreview}
				/>
			)}
			{viewingByCategory && (
				<ContextMenu
					open={viewingByCategory}
					handleClose={() => setViewingByCategory(null)}
					context={viewingByCategory}
					selectedCategory={selectedCategory}
					setSelectedCategory={setSelectedCategory}
					setIsSorting={setIsSorting}
				/>
			)}
		</Box>
	);
};

export default FileSection;
