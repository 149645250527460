import { queryBuilder } from "../../../../utils/queryBuilder";
import { apiSlice } from "../apiSlice";

export const meetingsApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAllMeetings: builder.query({
			query: (query) => ({
				url: `/meeting?total=true&${queryBuilder(query)}`,
				method: "GET",
			}),
			providesTags: ["Meetings"],
		}),
		getMeetingById: builder.query({
			query: (meetingId) => ({
				url: `/meeting/${meetingId}`,
				method: "GET",
			}),
		}),
		addMeeting: builder.mutation({
			query: (data) => ({
				url: "/meeting",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["Meetings"],
		}),
		updateMeeting: builder.mutation({
			query: ({ meetingId, data }) => ({
				url: `/meeting/${meetingId}`,
				method: "PATCH",
				body: data,
			}),
			invalidatesTags: ["Meetings"],
		}),
		deleteMeeting: builder.mutation({
			query: (meetingId) => ({
				url: `/meeting/${meetingId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Meetings"],
		}),
		getMeetingsByCriteria: builder.query({
			query: (queryData) => ({
				url: `/meeting?${queryBuilder(queryData)}`,
				method: "GET",
			}),
		}),
	}),
});

export const {
	useGetAllMeetingsQuery,
	useGetMeetingByIdQuery,
	useAddMeetingMutation,
	useUpdateMeetingMutation,
	useDeleteMeetingMutation,
	useGetMeetingsByCriteriaQuery,
} = meetingsApi;
