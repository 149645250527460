import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { images } from "../Assets";
import { AppBar, IconButton, Stack, Toolbar, Tooltip, Typography } from "@mui/material";
import { TiThMenu } from "react-icons/ti";
import { MdOutlineClose } from "react-icons/md";
import { appBarMenu, fetchMedia } from "../Common/utils";
import { useDispatch, useSelector } from "react-redux";
import { setIsDrawerOpen, setIsRtl } from "../../redux/slices/app";
import AppBarMenu from "../Common/Menu/AppBarMenu";
import UserMenu from "../Common/Menu/UserMenu";
import { useTranslation } from "react-i18next";
import { useGetMineQuery } from "../../redux/slices/api/user/userApi";

const AppBarComponent = () => {
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const isDrawerOpen = useSelector((state) => state.app.isDrawerOpen);
	const isRtl = useSelector((state) => state.app.isRtl);

	const [userAnchorEl, setUserAnchorEl] = useState(null);
	const [avatarData, setAvatarData] = useState(null);
	const [avatarLoading, setAvatarLoading] = useState(false);

	const userOpen = Boolean(userAnchorEl);

	const handleCloseUserMenu = () => {
		setUserAnchorEl(null);
	};

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const [path, setPath] = useState("");
	const handleClose = () => {
		setAnchorEl(null);
	};

	const myData = useGetMineQuery({}, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (myData?.data?.data?.avatar) {
			const fetchData = async () => {
				setAvatarLoading(true);
				const data = await fetchMedia(myData?.data?.data?.avatar);
				setAvatarData(data?.dataUrl);
				setAvatarLoading(false);
			};
			fetchData();
		}
	}, [myData.data]);

	return (
		<>
			<AppBar position="fixed" color="default">
				<Toolbar sx={{ bgcolor: "#fff" }}>
					<Box
						width={"100%"}
						height={"auto"}
						display={"flex"}
						justifyContent={"space-between"}
						alignItems={"center"}
						bgcolor={"#fff"}
						p={1}
						sx={{ borderBottom: "1px solid #e9e7e7" }}
					>
						<Box px={2}>
							<Box
								p={1}
								display={"flex"}
								justifyContent={"center"}
								alignItems={"center"}
								bgcolor={"#e9e7e770"}
								borderRadius={1}
								onClick={() => dispatch(setIsDrawerOpen(!isDrawerOpen))}
							>
								<IconButton sx={{ p: 0 }}>
									{isDrawerOpen ? <MdOutlineClose size={25} color="#164c68" /> : <TiThMenu size={25} color="#164c68" />}
								</IconButton>
							</Box>
						</Box>

						<Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={1.5}>
							<img src={images.logo} alt="logo" width={50} height={50} />
							<Typography variant={"h2"} fontWeight={600} color={"#164c68"}>
								Vision Expert System
							</Typography>
						</Stack>

						<Stack direction="row" justifyContent={"space-around"} alignItems={"center"} spacing={2} px={2}>
							<Tooltip
								title={`${t("SwitchTo")} ${isRtl ? "English" : "Arabic"}`}
								placement="bottom"
								sx={{ ml: `${isRtl ? "8px" : "0px"} !important` }}
							>
								<Box
									width={"50px"}
									height={"50px"}
									p={0}
									borderRadius={1}
									bgcolor={"#fff"}
									display={"flex"}
									justifyContent={"center"}
									alignItems={"center"}
									sx={{ mx: "16px !important" }}
									onClick={() => {
										i18n.changeLanguage(isRtl ? "en" : "ar");
										dispatch(setIsRtl(!isRtl));
									}}
								>
									<img src={!isRtl ? images.uae : images.us} alt="avatar" width={"100%"} height={"100%"} style={{ objectFit: "contain" }} />
								</Box>
							</Tooltip>

							{appBarMenu.map((item) =>
								item.name === "Settings" ? (
									<Box
										key={item.id}
										p={1}
										display={"flex"}
										justifyContent={"center"}
										alignItems={"center"}
										bgcolor={"#e9e7e770"}
										borderRadius={1}
									>
										<IconButton
											onClick={(event) => {
												if (item.name === "Settings") {
													setAnchorEl(event.currentTarget);
													setPath(item.path);
												}
											}}
											id="appBar-button"
											aria-controls={open ? "appBar-menu" : undefined}
											aria-haspopup="true"
											aria-expanded={open ? "true" : undefined}
										>
											{item.icon}
										</IconButton>
									</Box>
								) : (
									<Box
										width={"50px"}
										height={"50px"}
										borderRadius={"50%"}
										bgcolor={"#bdbdbd"}
										display={"flex"}
										justifyContent={"center"}
										alignItems={"center"}
										sx={{ ml: `${isRtl ? "16px" : "0px"} !important`, mx: "16px", cursor: "pointer" }}
										onClick={(event) => {
											setUserAnchorEl(event.currentTarget);
										}}
									>
										<img
											id="party-avatar"
											loading="lazy"
											width="100%"
											height="100%"
											srcSet={avatarData || images.avatar}
											src={avatarData || images.avatar}
											alt="avatar"
											style={{ objectFit: "cover", borderRadius: "50%" }}
										/>
									</Box>
								)
							)}
							<Stack justifyContent={"flex-start"} alignItems={"center"} spacing={0.5}>
								<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ ml: `${isRtl ? "8px" : "0px"} !important` }}>
									{myData?.data?.data?.firstName ? myData?.data?.data?.firstName : "N/A"}
								</Typography>
								<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ ml: `${isRtl ? "8px" : "0px"} !important` }}>
									{myData?.data?.data?.lastName ? myData?.data?.data?.lastName : "N/A"}
								</Typography>
							</Stack>
						</Stack>
					</Box>
				</Toolbar>
			</AppBar>
			{open && <AppBarMenu open={open} anchorEl={anchorEl} handleClose={handleClose} path={path} setAnchorEl={setAnchorEl} />}
			{userOpen && <UserMenu open={userOpen} anchorEl={userAnchorEl} handleClose={handleCloseUserMenu} />}
		</>
	);
};

export default AppBarComponent;
