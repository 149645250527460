import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Stack, Typography, FormControl, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useGetAllMeetingsQuery } from "../../../redux/slices/api/meetings/meetingsApi";
import SectionHeader from "../../Common/SectionHeader";
import { FaCalendar } from "react-icons/fa";
import ConfirmDeleteDialog from "../../Meetings/Details/ConfirmDeleteDialog";
import MeetingTableReport from "./MeetingTableReport";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ScrollableContainer from "../../Common/ScrollableContainer";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
import moment from "moment";
import { useGetTableViewQuery, useUpdateTableViewMutation } from "../../../redux/slices/api/tableView/tableViewApi";
import { setSnackbar } from "../../../redux/slices/app";
import { LoadingButton } from "@mui/lab";
import ConfirmDeleteTableView from "../../Common/ConfirmDeleteTableView";

const predefinedRanges = [
	{
		label: "Today",
		value: [new Date(), new Date()],
		placement: "left",
	},
	{
		label: "Yesterday",
		value: [addDays(new Date(), -1), addDays(new Date(), -1)],
		placement: "left",
	},
	{
		label: "This week",
		value: [startOfWeek(new Date()), endOfWeek(new Date())],
		placement: "left",
	},
	{
		label: "Last 7 days",
		value: [subDays(new Date(), 6), new Date()],
		placement: "left",
	},
	{
		label: "Last 30 days",
		value: [subDays(new Date(), 29), new Date()],
		placement: "left",
	},
	{
		label: "This month",
		value: [startOfMonth(new Date()), new Date()],
		placement: "left",
	},
	{
		label: "Last month",
		value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
		placement: "left",
	},
	{
		label: "This year",
		value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
		placement: "left",
	},
	{
		label: "Last year",
		value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
		placement: "left",
	},
	{
		label: "All time",
		value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
		placement: "left",
	},
	{
		label: "Last week",
		closeOverlay: false,
		value: (value) => {
			const [start = new Date()] = value || [];
			return [addDays(startOfWeek(start, { weekStartsOn: 0 }), -7), addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)];
		},
		appearance: "default",
	},
	{
		label: "Next week",
		closeOverlay: false,
		value: (value) => {
			const [start = new Date()] = value || [];
			return [addDays(startOfWeek(start, { weekStartsOn: 0 }), 7), addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)];
		},
		appearance: "default",
	},
];

const MeetingsReportSection = () => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const dispatch = useDispatch();

	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 50,
	});

	const [dateRange, setDateRange] = useState([moment().toDate(), moment().toDate()]);
	const [dateDuration, setDateDuration] = useState("All");

	const [searchQuery, setSearchQuery] = useState("");
	const [colFilter, setColFilter] = useState([]);
	const [columnSizing, setColumnSizing] = useState({});

	const colFilterObject = colFilter?.reduce((acc, { id, value }) => {
		if (id === "caseFile.caseNumber") id = "caseFile";
		if (id === "users") id = "user";
		if (id === "contacts") id = "contact";

		acc[id] = value;
		return acc;
	}, {});

	const meetingQuery = {
		...(searchQuery?.length > 2 && { search: searchQuery }),
		...(colFilter?.length > 0 && { ...colFilterObject }),
		...(typeof dateRange[0] !== "string" && { "date[gte]": moment(dateRange[0]).format("YYYY-MM-DD") }),
		...(typeof dateRange[1] !== "string" && { "date[lte]": moment(dateRange[1]).format("YYYY-MM-DD") }),
		// "date[gte]": moment(dateRange[0]).format("YYYY-MM-DD"),
		// "date[lte]": moment(dateRange[1]).format("YYYY-MM-DD"),
		skip: pagination.pageIndex * pagination.pageSize,
		limit: pagination.pageSize,
	};

	const { data, isLoading, isError } = useGetAllMeetingsQuery(meetingQuery, { refetchOnMountOrArgChange: true });

	const [rows, setRows] = useState([]);
	const [deleteMeeting, setDeleteMeeting] = useState(false);
	const [totalCount, setTotalCount] = useState(data?.totalRecords || 0);

	useEffect(() => {
		if (isError) return;

		if (data && !isLoading && !isError) {
			const sortedData = [...data.data].sort((a, b) => {
				// Convert dates to Date objects for comparison
				const dateA = new Date(a.date);
				const dateB = new Date(b.date);

				return dateA - dateB;
			});
			setRows(sortedData);
			setTotalCount(data?.totalRecords);
		}
	}, [data, pagination.pageIndex, pagination.pageSize, searchQuery, colFilter, dateRange]);

	const tableView = useGetTableViewQuery({ type: "meeting" }, { refetchOnMountOrArgChange: true });

	const [columnVisibility, setColumnVisibility] = useState({});
	const [columnOrder, setColumnOrder] = useState([]);
	const [defaultTemplate, setDefaultTemplate] = useState(null);
	const [templates, setTemplates] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [loading, setLoading] = useState(false);
	const [handleDeleteView, setHandleDeleteView] = useState(false);

	useEffect(() => {
		if (tableView.data?.data?.meeting) {
			const defaultView = tableView.data?.data?.meeting?.find((view) => view.default === true);
			if (defaultView) {
				setDefaultTemplate(defaultView);
				setSelectedTemplate(defaultView);
				setColumnVisibility(JSON.parse(defaultView?.column));
				setColumnOrder(JSON.parse(defaultView?.columnOrder));
			} else {
				const defaultTemp = tableView.data?.data?.meeting[0];
				console.log("default: ", defaultTemp);
				setSelectedTemplate(defaultTemp);
				setColumnVisibility(JSON.parse(defaultTemp?.column));
				setColumnOrder(JSON.parse(defaultTemp?.columnOrder));
			}
			setTemplates(tableView.data?.data?.meeting);
		} else {
			setSelectedTemplate(null);
			setColumnVisibility({});
			setColumnOrder([]);
		}
	}, [tableView.data, templates]);

	useEffect(() => {
		if (dateDuration === "New") {
			setDateRange([moment().toDate(), ""]);
		} else if (dateDuration === "Past") {
			setDateRange(["", moment().toDate()]);
		} else {
			setDateRange([new Date(new Date().getFullYear() - 1, 0, 1), ""]);
		}
	}, [dateDuration]);

	const [updateTableView] = useUpdateTableViewMutation();

	const handleSetAsDefault = async (e) => {
		e.preventDefault();
		setLoading(true);
		const data = {
			default: true,
		};
		try {
			await updateTableView({ templateId: selectedTemplate?.id, data: data }).unwrap();
			setDefaultTemplate(selectedTemplate);
			setSelectedTemplate(selectedTemplate);
			setLoading(false);
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setLoading(false);
		}
	};

	return (
		<Box
			width={"100%"}
			height={"max-content"}
			p={2}
			borderRadius={2}
			bgcolor={"#fff"}
			sx={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;" }}
		>
			<SectionHeader icon={<FaCalendar color="#164c68" size={18} />} title={`${t("My")} ${t("Meetings")}`} section={"meetings"} />
			<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} my={2}>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={2}>
					<DateRangePicker
						format="MMM dd, yyyy"
						character=" - "
						ranges={predefinedRanges}
						value={dateRange}
						onChange={setDateRange}
						onClean={() => setDateRange([moment().toDate(), moment().toDate()])}
					/>
					<FormControl sx={{ m: 1, minWidth: 100, mr: isRtl ? "8px !important" : 0 }} size="small">
						<Select
							id="select-date-range"
							value={dateDuration}
							onChange={(e) => setDateDuration(e.target.value)}
							sx={{ color: "#164c68" }}
							displayEmpty
						>
							<MenuItem value={"All"}>{t("All")}</MenuItem>
							<MenuItem value={"New"}>{t("UpcomingMeetings")}</MenuItem>
							<MenuItem value={"Past"}>{t("PastMeetings")}</MenuItem>
						</Select>
					</FormControl>
					<Typography variant={"h5"} color={"#164c68"}>
						{t("SelectedTemplate")}:
					</Typography>
					{templates?.length > 0 ? (
						<FormControl sx={{ m: 1, minWidth: 100, mr: isRtl ? "8px !important" : 0 }} size="small">
							<Select
								id="select-template"
								value={selectedTemplate}
								onChange={(e) => {
									setSelectedTemplate(e.target.value);
									setColumnVisibility(JSON.parse(e?.target?.value?.column));
									setColumnOrder(JSON.parse(e?.target?.value?.columnOrder));
								}}
								sx={{ color: "#164c68" }}
								displayEmpty
							>
								{defaultTemplate && (
									<MenuItem value={defaultTemplate}>
										{defaultTemplate?.name} ({t("Default")})
									</MenuItem>
								)}
								{templates
									?.filter((template) => defaultTemplate?.id !== template.id)
									.map((template) => (
										<MenuItem key={template.id} value={template}>
											{template.name}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					) : (
						<Typography variant={"h5"} color={"#164c68"}>
							{t("NoTableViewFound")}
						</Typography>
					)}
					{selectedTemplate?.id !== defaultTemplate?.id && (
						<LoadingButton
							loading={isLoading}
							variant="contained"
							size="small"
							onClick={handleSetAsDefault}
							sx={{
								bgcolor: "#164c68",
								color: "#fff",
								padding: 1,
								"&:hover": {
									bgcolor: "#00a3b1",
								},
								transition: "all 0.5s ease",
							}}
						>
							{t("SetAsDefault")}
						</LoadingButton>
					)}
				</Stack>
			</Stack>

			<Box height={"100%"} width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"} pb={2}>
				{isLoading && !isError ? (
					<CircularProgress />
				) : rows ? (
					<ScrollableContainer>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<MeetingTableReport
								data={rows}
								deleteMeeting={() => setDeleteMeeting(true)}
								pagination={pagination}
								setPagination={setPagination}
								pageCount={Math.ceil(totalCount / pagination.pageSize)}
								searchQuery={searchQuery}
								setSearchQuery={setSearchQuery}
								colFilter={colFilter}
								setColFilter={setColFilter}
								columnVisibility={columnVisibility}
								setColumnVisibility={setColumnVisibility}
								columnSizing={columnSizing}
								setColumnSizing={setColumnSizing}
								columnOrder={columnOrder}
								setColumnOrder={setColumnOrder}
								setDefaultView={setDefaultTemplate}
								selectedTemplate={selectedTemplate}
								setSelectedTemplate={setSelectedTemplate}
								templates={templates}
								setHandleDeleteView={setHandleDeleteView}
							/>
						</LocalizationProvider>
					</ScrollableContainer>
				) : null}
			</Box>
			{deleteMeeting && <ConfirmDeleteDialog open={deleteMeeting} handleClose={() => setDeleteMeeting(false)} />}
			{handleDeleteView && (
				<ConfirmDeleteTableView
					open={handleDeleteView}
					handleClose={() => setHandleDeleteView(false)}
					templateId={selectedTemplate?.id}
					template={selectedTemplate}
					setTemplates={setTemplates}
					setSelectedTemplate={() => setSelectedTemplate(null)}
					type={"meeting"}
					setColumnVisibility={setColumnVisibility}
					setColumnOrder={setColumnOrder}
					setDefaultView={setDefaultTemplate}
				/>
			)}
		</Box>
	);
};

export default MeetingsReportSection;
