import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, FormControl, InputLabel, MenuItem, Select as MuiSelect, Slide } from "@mui/material";
import { CiMemoPad } from "react-icons/ci";
import CustomTextField from "./CustomTextField";
import { useDispatch, useSelector } from "react-redux";
import SectionHeader from "./SectionHeader";
import { styled } from "@mui/material/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const Select = styled(MuiSelect)({
	color: "#164c68", // Change this to your desired color
	"& .MuiSelect-outlined": {
		// Change this to your desired color
		borderRadius: 5,
	},
	"&:active": {
		"& .MuiSelect-outlined": {
			boxShadow: "0 0 5px rgba(0,0,0,0.3)",
			// Example border color when focused
		},
	},
});

const CreateNew = ({ open, handleClose }) => {
	const dispatch = useDispatch();
	const memo = useSelector((state) => state.case.memo);
	const [flag, setFlag] = useState("Important");
	const date = new Date().toISOString().split("T")[0];
	const [formData, setFormData] = useState({
		memoDetails: "",
		createdAt: date,
	});
	const close = () => {
		handleClose(false);
	};
	const handleChange = (e) => {
		setFormData((prev) => ({ ...prev, memoDetails: e.target.value }));
	};
	return (
		<Dialog
			open={open}
			onClose={close}
			fullWidth
			TransitionComponent={Transition}
			keepMounted
			sx={{
				px: 2,
			}}
			PaperProps={{
				component: "form",
				onSubmit: (event) => {
					event.preventDefault();
					const data = {
						flag: flag,
						...formData,
					};
					handleClose();
				},
			}}
		>
			<DialogTitle>
				<SectionHeader icon={<CiMemoPad color="#164c68" size={18} />} title={"Create New Memo"} type={"dialog"} />
			</DialogTitle>
			<DialogContent sx={{ backgroundColor: "#fff" }}>
				<DialogContentText sx={{ mb: 1 }}>
					<FormControl size="small" sx={{ my: 2 }}>
						<InputLabel id="flag">Flag</InputLabel>
						<Select labelId="flag" id="status" value={flag} label="Flag" onChange={(e) => setFlag(e.target.value)} displayEmpty>
							<MenuItem value={"Important"}>Important</MenuItem>
							<MenuItem value={"Normal"}>Normal</MenuItem>
						</Select>
					</FormControl>
				</DialogContentText>
				<CustomTextField
					type={"text"}
					name={"memoDetails"}
					value={formData.memoDetails}
					resetValue={() => setFormData({ ...formData, memoDetails: "" })}
					label={"Memo"}
					handleInputChange={handleChange}
				/>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: "#fff" }}>
				<Button
					onClick={close}
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						"&:hover": {
							bgcolor: "#00a3b1",
						},
						transition: "all 0.5s ease",
					}}
				>
					Cancel
				</Button>
				<Button
					type="submit"
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						"&:hover": {
							bgcolor: "#00a3b1",
						},
						transition: "all 0.5s ease",
					}}
				>
					Create
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CreateNew;
