import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import SectionHeader from "../../Common/SectionHeader";
import { GrShieldSecurity } from "react-icons/gr";
import EditableTextField from "../../Common/EditableTextField";
import CustomTextField from "../../Common/CustomTextField";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../../redux/slices/app";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useUpdatePasswordMutation } from "../../../redux/slices/api/user/userApi";
import { useTranslation } from "react-i18next";

const SecuritySection = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));


	const dispatch = useDispatch();

	const { userDetails } = useSelector((state) => state.app);

	const [prevData, setPrevData] = useState(userDetails);
	const [showPassword, setShowPassword] = useState(false);
	const [isEditingPassword, setIsEditingPassword] = useState(false);
	const [submittingPassword, setSubmittingPassword] = useState(false);
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const [updatePassword] = useUpdatePasswordMutation();
	
	const isRtl = useSelector((state) => state.app.isRtl);

	const handleChangePassword = async () => {
		try {
			await updatePassword({ old: password, new: confirmPassword }).unwrap();
			dispatch(setSnackbar({ open: true, message: t("PasswordSuccessfully"), severity: "success" }));
			setIsEditingPassword(false);
			setSubmittingPassword(false);
			setPassword("");
			setShowPassword(false);
			setConfirmPassword("");
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			// setLoading(false);
		}
	};

	return (
		<Box
			width={isSmallScreen ? "100%" : "60%"}
			height={"auto"}
			p={2}
			mb={13}
			borderRadius={1.5}
			sx={{
				boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
			}}
			bgcolor={"#fff"}
		>
			<SectionHeader icon={<GrShieldSecurity color="#164c68" size={18} />} title={t("Security")} type={"dialog"} />
			<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
			{isEditingPassword && (
				<Typography variant={"body1"} fontWeight={600} color={"#164c68"}>
					{t("EnterPassword")}:
				</Typography>
			)}
			<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={isSmallScreen ? "100%" : "70%"} mt={1}>
				<EditableTextField
					label={t("Password")}
					value={password}
					setValue={setPassword}
					name={"password"}
					isEditing={isEditingPassword}
					setIsEditing={setIsEditingPassword}
					prevValue={""}
					prevData={prevData}
					setPrevData={setPrevData}
					showPassword={showPassword}
					setShowPassword={setShowPassword}
					setSubmittingPassword={setSubmittingPassword}
				/>
			</Stack>
			{submittingPassword && (
				<>
					<Typography variant={"body1"} fontWeight={600} color={"#164c68"}>
						{t("EnterNewPassword")}:
					</Typography>
					<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={2} width={"61.5%"} mt={1}>
						<CustomTextField
							type={"password"}
							name={"confirmPassword"}
							value={confirmPassword}
							label={"New Password"}
							handleInputChange={(e) => setConfirmPassword(e.target.value)}
							required={true}
						/>
						<Button
							variant={"contained"}
							color={"primary"}
							size={"small"}
							sx={{
								bgcolor: "#164c68",
								color: "#fff",
								"&:hover": {
									bgcolor: "#00a3b1",
								},
								transition: "all 0.5s ease",
							}}
							onClick={() => {
								handleChangePassword();
							}}
						>
							{t("Save")}
						</Button>
					</Stack>
				</>
			)}
		</Box>
	);
};

export default SecuritySection;
