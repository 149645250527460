import { Stack } from "@mui/material";
import React from "react";
import StatBox from "../../../components/Dashboard/FirstSection/StatBox";
import StorageBox from "../../../components/Dashboard/FirstSection/StorageBox";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import FileTypeStatBox from "../../../components/Dashboard/FirstSection/FileTypeStatBox";
import ClassificationStatBox from "../../../components/Dashboard/FirstSection/ClassificationStatbox";

const FirstSection = () => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
	return (
		<Stack
			direction={isSmallScreen ? "column" : "row"}
			justifyContent={"center"}
			alignItems={"center"}
			width={"100%"}
			height={isSmallScreen ? "100%" : "17%"}
			spacing={2}
		>
			<StatBox isSmallScreen={isSmallScreen} />
			<ClassificationStatBox isSmallScreen={isSmallScreen} />
			<FileTypeStatBox isSmallScreen={isSmallScreen} />
			<StorageBox isSmallScreen={isSmallScreen} />
		</Stack>
	);
};

export default FirstSection;
