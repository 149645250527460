import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	currentMeeting: null,
	isCreatingMeeting: false,
	isEditingMeeting: false,
};

export const meetingSlice = createSlice({
	name: "meetings",
	initialState,
	reducers: {
		setCurrentMeeting: (state, action) => {
			state.currentMeeting = action.payload;
		},
		setIsCreatingMeeting: (state, action) => {
			state.isCreatingMeeting = action.payload;
		},
		setIsEditingMeeting: (state, action) => {
			state.isEditingMeeting = action.payload;
		},
	},
});

export const { setCurrentMeeting, setIsCreatingMeeting, setIsEditingMeeting } = meetingSlice.actions;

export default meetingSlice.reducer;
