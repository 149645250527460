import { apiSlice } from "../apiSlice";

export const emailTemplatesApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getByCriteriaEmailTemplates: builder.query({
			query: () => ({
				url: "/email/template",
				method: "GET",
			}),
			providesTags: ["EmailTemplates"],
		}),
		createEmailTemplate: builder.mutation({
			query: (data) => ({
				url: "/email/template",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["EmailTemplates"],
		}),
		deleteEmailTemplate: builder.mutation({
			query: (id) => ({
				url: `/email/template/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["EmailTemplates"],
		}),
		getCaseFileMetaData: builder.query({
			query: () => ({
				url: "/case-file/metadata",
				method: "GET",
			}),
		}),
		getHearingMetaData: builder.query({
			query: () => ({
				url: "/hearing/metadata",
				method: "GET",
			}),
		}),
		getTaskMetaData: builder.query({
			query: () => ({
				url: "/task/metadata",
				method: "GET",
			}),
		}),
		getMemoMetaData: builder.query({
			query: () => ({
				url: "/memo/metadata",
				method: "GET",
			}),
		}),
	}),
});

export const {
	useGetByCriteriaEmailTemplatesQuery,
	useCreateEmailTemplateMutation,
	useDeleteEmailTemplateMutation,
	useGetCaseFileMetaDataQuery,
	useGetHearingMetaDataQuery,
	useGetTaskMetaDataQuery,
	useGetMemoMetaDataQuery,
} = emailTemplatesApi;
