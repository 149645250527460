export const errorMessage = {
	"0301": {
		ar: "Duplication conflict.",
		en: "Duplication conflict.",
	},
	"0302": {
		ar: "Reference non existing data.",
		en: "Reference non existing data.",
	},
	"0501": {
		ar: "المنطقة المحددة غير موجودة",
		en: "The specified country does not exist in our records.",
	},
	"0502": {
		ar: "المدينة المحددة غير موجودة",
		en: "The specified city does not exist in our records.",
	},
	"0201": {
		ar: "بعض المعلومات خاطئة يرجى التحقق من صحة البيانات",
		en: "Some information is incorrect. Please check and try again.",
	},
	"0202": {
		ar: "JSON parsing error.",
		en: "JSON parsing error.",
	},
	"0401": {
		ar: "الملف غير موجود",
		en: "The file you are looking for could not be found.",
	},
	"0402": {
		ar: "حدث خطأ عند تحميل الملف",
		en: "There was an error while uploading the file. Please try again.",
	},
	"0403": {
		ar: "حدث خطأ عند حذف الملف",
		en: "There was an error while deleting the file. Please try again.",
	},
	"0404": {
		ar: "تم الوصول إلى الحد الأقصى من التخزين",
		en: "You have reached your storage limit.",
	},
	"0601": {
		ar: "غير مصرح لك بالوصول",
		en: "You need to be logged in to access this.",
	},
	"0602": {
		ar: "خطأ في بيانات تسجيل الدخول",
		en: "Your login details are incorrect.",
	},
	"0605": {
		ar: "رمز التحقق او الايميل غير صحيح",
		en: "The email or code you entered is invalid.",
	},
	"0606": {
		ar: "رمز التحقق او رقم الهاتف غير صحيح",
		en: "The phone number or code you entered is invalid.",
	},
	"0620": {
		ar: "غير مصرح لك بالوصول",
		en: "You are not allowed to access this.",
	},
	"0621": {
		ar: "جلسة تسجيل الدخول انتهت, يرجى تسجيل الدخول مرة اخرى",
		en: "Your session has expired. Please log in again.",
	},
	"0622": {
		ar: "جلسة تسجيل الدخول غير صالحة, يرجى تسجيل الدخول مرة اخرى",
		en: "Your session is invalid. Please log in again.",
	},
	"0623": {
		ar: "جلسة تحديث تسجيل الدخول انتهت, يرجى تسجيل الدخول مرة اخرى",
		en: "Your session needs to be refreshed and has expired. Please log in again.",
	},
	"0624": {
		ar: "جلسة تحديث تسجيل الدخول غير صالحة, يرجى تسجيل الدخول مرة اخرى",
		en: "Your session refresh details are incorrect.",
	},
	"0625": {
		ar: "يرجى تأكيد حسابك ",
		en: "You cannot access this because your account is not verified.",
	},
	"0626": {
		ar: "الحساب غير مفعل ",
		en: "You cannot access this because your account has been deactivated.",
	},
	"0627": {
		ar: "يرجى اكمال ملفك الشخصي قبل المتابعة",
		en: "Your user profile needs to be completed before proceeding.",
	},
	"0640": {
		ar: "لا تملك صلاحية للقيام بهذه العملية",
		en: "You do not have permission to make this request.",
	},
	"0660": {
		ar: "كلمة المرور القديمة غير صالحة",
		en: "The old password you entered is incorrect.",
	},
	"0661": {
		ar: "تم التحقق من هذا الحساب بالفعل.",
		en: "This account is already verified.",
	},
	"0662": {
		ar: "هذا الحساب مفعل بالفعل.",
		en: "This account is already active.",
	},
	"0663": {
		ar: "هذا الحساب غير مفعل بالفعل.",
		en: "This account is already inactive.",
	},
	2901: {
		ar: "لقد حاولت عدة مرات. يرجى الانتظار قليلاً قبل المحاولة مرة أخرى.",
		en: "You've tried too many times. Please wait a while before trying again.",
	},
	1101: {
		ar: "لم يتم العثور على مستخدم بهذه المعلومات.",
		en: "No user found with this information.",
	},
	1102: {
		ar: "رمز التحقق الذي أدخلته غير صالح.",
		en: "The validation code you entered is invalid.",
	},
	1103: {
		ar: "لا يمكن العثور على المشرف الذي تبحث عنه.",
		en: "The supervisor you are looking for cannot be found.",
	},
	1104: {
		ar: "لا يمكنك حذف مستخدم نشط حاليًا.",
		en: "You cannot delete a user who is currently active.",
	},
	1201: {
		ar: "لم يتم العثور على الشركة المحددة.",
		en: "The specified company cannot be found.",
	},
	1202: {
		ar: "هذه الشركة غير نشطة حاليًا.",
		en: "This company is not currently active.",
	},
	2201: {
		ar: "لا يمكن العثور على مجموعة الأذونات.",
		en: "The permissions group cannot be found.",
	},
	2101: {
		ar: "لا يمكن العثور على معلومات حول هذا العنصر.",
		en: "Information about this item cannot be found.",
	},
	1401: {
		ar: "لم يتم العثور على جهة اتصال بهذه المعلومات.",
		en: "No contact found with this information.",
	},
	1402: {
		ar: "هذه الجهة مرتبطة ببيانات أخرى ولا يمكن حذفها.",
		en: "This contact is linked to other data and cannot be deleted.",
	},
	500: {
		ar: "حدث خطأ في الخادم. يرجى المحاولة مرة أخرى لاحقًا.",
		en: "A server error occurred. Please try again later.",
	},
	1301: {
		ar: "إعداد مطلوب مفقود.",
		en: "A required setting is missing.",
	},
	1302: {
		ar: "لا يمكن حذف هذا الإعداد.",
		en: "This setting cannot be deleted.",
	},
	1501: {
		ar: "لا يمكن العثور على ملف الحالة الذي تبحث عنه.",
		en: "The case file you are looking for cannot be found.",
	},
	1403: {
		ar: "جهة الاتصال موجودة بالفعل.",
		en: "The contact is already existing.",
	},
	1502: {
		ar: "تم استخدام رقم الملف هذا بالفعل.",
		en: "This file number has already been used.",
	},
	1503: {
		ar: "هذا الملف مرتبط بعلاقات ولا يمكن حذفه.",
		en: "This file has connections and cannot be deleted.",
	},
	1601: {
		ar: "لا يمكن العثور على الجلسة التي تبحث عنها.",
		en: "The hearing you are looking for cannot be found.",
	},
	1701: {
		ar: "لا يمكن العثور على الاجتماع الذي تبحث عنه.",
		en: "The meeting you are looking for cannot be found.",
	},
	1801: {
		ar: "لا يمكن العثور على المهمة التي تبحث عنها.",
		en: "The task you are looking for cannot be found.",
	},
	1901: {
		ar: "لا يمكن العثور على المذكرة التي تبحث عنها.",
		en: "The memo you are looking for cannot be found.",
	},
	2401: {
		ar: "لا يمكن العثور على حساب البريد الإلكتروني.",
		en: "The email account cannot be found.",
	},
	2501: {
		ar: "لا يمكن العثور على قالب البريد الإلكتروني.",
		en: "The email template cannot be found.",
	},
	2601: {
		ar: "لا يمكن العثور على رسالة البريد الإلكتروني.",
		en: "The email message cannot be found.",
	},
	2602: {
		ar: "لقد حاولت عدة مرات. يرجى المحاولة مرة أخرى لاحقًا.",
		en: "You've tried too many times. Please wait until later to try again.",
	},
	2701: {
		ar: "لا يمكن العثور على تفاصيل الرسوم.",
		en: "The fee details cannot be found.",
	},
	2801: {
		ar: "لا يمكن العثور على التذكير.",
		en: "The reminder cannot be found.",
	},
	"0628": {
		ar: "رمز إعادة التعيين غير صالح.",
		en: "Invalid reset code.",
	},
	3301: {
		ar: "المستخدم غير موجود.",
		en: "Table view not found",
	},
	3302: {
		ar: "المستخدم غير موجود.",
		en: "Table view name already exists.",
	},
	3303: {
		ar: "المستخدم غير موجود.",
		en: "Table view default already exists.",
	},
};
