import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import { FcCancel } from "react-icons/fc";
import { Slide, Typography } from "@mui/material";
import SectionHeader from "./SectionHeader";
import { useDispatch, useSelector } from "react-redux";
import {setIsEditingCase } from "../../redux/slices/case";
import { setIsCreatingTask, setIsEditingTask} from "../../redux/slices/task";
import { setIsCreatingMeeting, setIsEditingMeeting } from "../../redux/slices/meetings";
import { setIsCreatingMemo, setIsEditingMemo, setIsViewingMemo } from "../../redux/slices/memo";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function CancellingDialog({ open, handleClose, path }) {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const currentCase = useSelector((state) => state.case.currentCase);
	return (
		<Dialog open={open} fullWidth TransitionComponent={Transition} keepMounted aria-describedby="add-user-dialog" sx={{ height: "100%" }}>
			<DialogTitle sx={{ pb: 2, borderBottom: "1px solid #aaaaaa6b", backgroundColor: "#fff" }}>
				<SectionHeader icon={<FcCancel size={18} />} title={`${t("CancelChanges")}?`} type={"dialog"} />
			</DialogTitle>
			<DialogContent sx={{ backgroundColor: "#fff" }}>
				<DialogContentText id="cancelling-dialog-description" sx={{ p: 4 }}>
					<Typography variant={"h5"} fontWeight={600} color={"#164c68"}>
						{t("CancelDialog")}?
					</Typography>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: "#fff" }}>
				<Button
					onClick={handleClose}
					sx={{
						color: "#fff",
						backgroundColor: "#fc6739",
						"&:hover": {
							color: "#fff",
							backgroundColor: "red",
						},
						transition: "all 1s ease",
					}}
				>
					{t("No")}
				</Button>
				<Button
					variant="contained"
					size="medium"
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						marginRight: `${isRtl ? "8px" : "0px"} !important`,
					}}
					onClick={() => {
						if (path === "case") {
							dispatch(setIsEditingCase(false));
							navigate(`/dashboard/case/`);
						} else if (path === "tasks") {
							dispatch(setIsEditingTask(false));
							dispatch(setIsCreatingTask(false));
							if (currentCase) {
								navigate(`/dashboard/case/details/`);
							} else {
								navigate(-1);
							}
						} else if (path === "meetings") {
							dispatch(setIsEditingMeeting(false));
							dispatch(setIsCreatingMeeting(false));
							if (currentCase) {
								navigate(`/dashboard/case/details/`);
							} else {
								navigate(-1);
							}
						} else if (path === "memo") {
							dispatch(setIsEditingMemo(false));
							dispatch(setIsCreatingMemo(false));
							dispatch(setIsViewingMemo(false));
							if (currentCase) {
								navigate(`/dashboard/case/details/memos`);
							} else {
								navigate(-1);
							}
						} else if (path === "hearing") {
							if (currentCase) {
								navigate(`/dashboard/case/details/`);
							} else {
								navigate(-1);
							}
						} else {
							navigate("/dashboard");
						}
					}}
				>
					{t("Yes")}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
