import React, { useEffect, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { Box, Typography } from "@mui/material";
import { transformLineChartData } from "../utils";
import { useTranslation } from "react-i18next";

const LineChart = ({ cases, isSmallScreen }) => {
	const { t } = useTranslation();
	const margin = isSmallScreen ? { top: 20, right: 20, bottom: 50, left: 60 } : { top: 20, right: 60, bottom: 50, left: 60 };
	const [chartData, setChartData] = useState([]);

	useEffect(() => {
		if (cases) {
			const formattedData = transformLineChartData(cases);
			setChartData(formattedData);
		}
	}, [cases]);

	return (
		<ResponsiveLine
			data={chartData}
			margin={margin}
			xScale={{ type: "point" }}
			yScale={{
				type: "linear",
				min: "auto",
				max: "auto",
				stacked: true,
				reverse: false,
			}}
			yFormat=" >-.2f"
			axisTop={null}
			axisRight={null}
			axisBottom={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: t("Months"),
				legendOffset: 36,
				legendPosition: "middle",
			}}
			axisLeft={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: t("Count"),
				legendOffset: -40,
				legendPosition: "middle",
			}}
			curve="monotoneX"
			colors={{ scheme: "dark2" }}
			pointSize={10}
			pointColor={{ theme: "background" }}
			pointBorderWidth={2}
			pointBorderColor={{ from: "serieColor" }}
			pointLabelYOffset={-12}
			useMesh={true}
			enableGridX={false}
			tooltip={({ point }) => {
				const serie = point.serieId === "litigation" ? t("Litigation") : t("Consultation");
				return (
					<Box
						sx={{
							backgroundColor: "#d9e6e6",
							borderRadius: 1,
							p: 1,
							zIndex: 1000,
							boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
						}}
					>
						<Typography variant={"h6"} fontWeight={600} color={"#595858"} textTransform={"capitalize"}>
							{serie}: {point.data.y}
						</Typography>
					</Box>
				);
			}}
		/>
	);
};

export default LineChart;
