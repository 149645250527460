import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React from "react";
import PermissionGroupCard from "./PermissionGroupCard";
import { useTranslation } from "react-i18next";

const PermissionGroupList = ({ isSmallScreen, data, filteredRows, isSearching, isLoading, isError, image, open, setAnchorEl, setSelectedPermission }) => {
	const { t } = useTranslation();
	return (
		<>
			{isLoading && (
				<Box display={"flex"} justifyContent={"center"} alignItems={"center"} width={"100%"} height={"100%"} bgcolor={"#fff"} mb={10}>
					<CircularProgress />
				</Box>
			)}
			{data?.length >= 1 && !isError && !isLoading ? (
				<Box
					width={"100%"}
					height={"100%"}
					display={"flex"}
					flexDirection={"column"}
					justifyContent={"center"}
					alignItems={"center"}
					m={"0 auto"}
					p={3.5}
					sx={{ boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;" }}
					bgcolor={"#fff"}
					borderRadius={1}
				>
					{isSearching ? (
						<Grid container spacing={2} width={"100%"}>
							{filteredRows?.map((permission) => (
								<Grid item xs={12} sm={3} key={permission.id}>
									<PermissionGroupCard
										data={permission}
										open={open}
										setAnchorEl={setAnchorEl}
										setSelectedPermission={setSelectedPermission}
									/>
								</Grid>
							))}
						</Grid>
					) : (
						<Grid container spacing={2} width={"100%"}>
							{data?.map((permission) => (
								<Grid item xs={12} sm={3} key={permission.id}>
									<PermissionGroupCard
										data={permission}
										open={open}
										setAnchorEl={setAnchorEl}
										setSelectedPermission={setSelectedPermission}
									/>
								</Grid>
							))}
						</Grid>
					)}
				</Box>
			) : (
				<Box
					width={"100%"}
					height={"100%"}
					display={"flex"}
					flexDirection={"column"}
					justifyContent={"center"}
					alignItems={"center"}
					m={"0 auto"}
					p={3.5}
					sx={{ boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;" }}
					bgcolor={"#fff"}
					mb={4}
				>
					<img src={image} alt="Not Found" width={200} height={200} />
					<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ my: 2 }}>
						{t("NoPermissionGroup")}!
					</Typography>
					<Typography variant={"subtitle1"} fontWeight={500} color={"#51505099"}>
						{t("CreateNewPermissionGroup")}
					</Typography>
				</Box>
			)}
		</>
	);
};

export default PermissionGroupList;
