import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import { images } from "../../Assets";
import { CiEdit } from "react-icons/ci";
import { FaCheck } from "react-icons/fa6";

const CaseFileUser = ({ user, users, setUsers }) => {
	const { t } = useTranslation();

	const [userPosition, setUserPosition] = useState(user.position);
	const [isEditingPosition, setIsEditingPosition] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleUpdatePosition = () => {
		setIsLoading(true);

		const updatedUsers = users.map((u) => {
			if (u.id === user.id) {
				return { ...u, position: userPosition };
			}
			return u;
		});

		setUsers(updatedUsers);

		setTimeout(() => {
			setIsEditingPosition(false);
			setIsLoading(false);
		}, 500);
	};

	return (
		<Box
			display={"flex"}
			justifyContent={"center"}
			alignItems={"center"}
			bgcolor={"#f3f7ff"}
			width={"100%"}
			p={2}
			height={"100%"}
			borderRadius={1.5}
			sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;", position: "relative" }}
		>
			<Stack justifyContent={"flex-start"} width={"100%"} spacing={2}>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={0.5}>
					<img src={images.id} alt="id" width={20} height={20} />
					<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mx: ` 8px  !important` }}>
						{t("FirstName")}: {user?.firstName || user?.user?.firstName}
					</Typography>
				</Stack>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={0.5}>
					<img src={images.id} alt="id" width={20} height={20} />
					<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mx: `8px  !important` }}>
						{t("LastName")}: {user?.lastName || user?.user?.lastName}
					</Typography>
				</Stack>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={0.5}>
					{!isEditingPosition ? (
						<>
							<img src={images.role} alt="position" width={20} height={20} />

							<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mx: `8px  !important` }}>
								{t("Position")}: {userPosition ? userPosition : "N/A"}
							</Typography>
						</>
					) : (
						<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
							<InputLabel id="role-select-small-label">{t("Position")}</InputLabel>
							<Select
								labelId="position-select-small-label"
								id="position-select-small"
								value={userPosition}
								label="Position"
								onChange={(e) => setUserPosition(e.target.value)}
							>
								<MenuItem value={"Admin"}>{t("Admin")}</MenuItem>
								<MenuItem value={"Expert"}>{t("Expert")}</MenuItem>
								<MenuItem value={"Expert Assistant"}>{t("ExpertAssistant")}</MenuItem>
								<MenuItem value={"Auditor"}>{t("Auditor")}</MenuItem>
								<MenuItem value={"Translator"}>{t("Translator")}</MenuItem>
								<MenuItem value={"Accountant"}>{t("Accountant")}</MenuItem>
								<MenuItem value={"Secretary"}>{t("Secretary")}</MenuItem>
								<MenuItem value={"Administrative"}>{t("Administrative")}</MenuItem>
							</Select>
						</FormControl>
					)}
					{!isEditingPosition ? (
						<Tooltip title={"Update Position"} arrow placement={"top"}>
							<IconButton
								disableRipple
								onClick={() => {
									setIsEditingPosition(true);
								}}
								sx={{ p: 0 }}
							>
								<CiEdit color="#164c68" size={22} />
							</IconButton>
						</Tooltip>
					) : (
						<>
							<Tooltip title={"Save"} arrow placement={"top"}>
								<IconButton disableRipple disabled={isLoading} onClick={handleUpdatePosition} sx={{ p: 0 }}>
									<FaCheck color="#2abe5d" size={22} />
								</IconButton>
							</Tooltip>
							<Tooltip title={"Cancel"} arrow placement={"top"}>
								<IconButton
									disableRipple
									disabled={isLoading}
									onClick={() => {
										setIsEditingPosition(false);
									}}
									sx={{ p: 0 }}
								>
									<FaTimes color="#164c68" size={22} />
								</IconButton>
							</Tooltip>
						</>
					)}
				</Stack>
				<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1}>
					<Button
						onClick={() => {
							const newUsers = users.filter((u) => u.id !== user.id);
							setUsers(newUsers);
						}}
						sx={{
							color: "white",
							backgroundColor: "#ff3030",
							"&:hover": {
								backgroundColor: "red",
							},
						}}
					>
						{t("Remove")}
					</Button>
				</Stack>
			</Stack>
		</Box>
	);
};

export default CaseFileUser;
