import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useGetContactByIdQuery } from "../../redux/slices/api/contacts/contactApi";
import { fetchMedia } from "../Common/utils";
import SectionHeader from "../Common/SectionHeader";
import { BiSolidContact } from "react-icons/bi";
import { Box, IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import { images } from "../Assets";
import { useTheme } from "@mui/material/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ContactPopOut = ({ open, handleClose, contactId }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	const contact = useGetContactByIdQuery(contactId, { refetchOnMountOrArgChange: true });

	const [avatar, setAvatar] = useState(null);
	const [isLoadingAvatar, setIsLoadingAvatar] = useState(false);

	useEffect(() => {
		if (contact && contact?.data?.data.avatar) {
			const fetchData = async () => {
				setIsLoadingAvatar(true);
				const data = await fetchMedia(contact?.data?.data.avatar);
				console.log(data);
				setAvatar(data?.dataUrl);
				setIsLoadingAvatar(false);
			};
			fetchData();
		}
	}, [contact]);

	return (
		<Dialog open={open} fullWidth TransitionComponent={Transition} keepMounted aria-describedby="view-contact-dialog">
			<DialogTitle sx={{ pb: 1, borderBottom: "1px solid #aaaaaa6b", backgroundColor: "#fff" }}>
				<SectionHeader icon={<BiSolidContact color="#164c68" size={18} />} title={t("ContactDetails")} type={"dialog"} />
			</DialogTitle>
			<DialogContent sx={{ backgroundColor: "#fff" }}>
				<DialogContentText id="view-contact-dialog-description" sx={{ p: 2 }}>
					<Box
						width={"100px"}
						height={"100px"}
						p={avatar ? 0 : 2}
						borderRadius={"50%"}
						bgcolor={"#bdbdbd"}
						display={"flex"}
						justifyContent={"center"}
						alignItems={"center"}
						position={"relative"}
						mb={3}
					>
						<img
							src={avatar ? avatar : images.avatar}
							alt="avatar"
							width={"100%"}
							height={"100%"}
							style={{ objectFit: "cover", borderRadius: "50%" }}
						/>
					</Box>
					<Stack justifyContent={"flex-start"} alignItems={"start"} width={"100%"} spacing={3} my={2}>
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
							<Typography variant={"h4"} fontWeight={600} color={"#164c68"}>
								{t("ArabicName")}:
							</Typography>
							<Typography variant={"h5"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
								{contact?.data?.data?.nameAr ? contact?.data?.data?.nameAr : "N/A"}
							</Typography>
						</Stack>
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
							<Typography variant={"h4"} fontWeight={600} color={"#164c68"}>
								{t("EnglishName")}:
							</Typography>
							<Typography variant={"h5"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
								{contact?.data?.data?.nameEn ? contact?.data?.data?.nameEn : "N/A"}
							</Typography>
						</Stack>

						<Stack
							direction={isSmallScreen ? "column" : "row"}
							justifyContent={isSmallScreen ? "flex-start" : "space-between"}
							alignItems={"center"}
							spacing={1}
							width={"100%"}
						>
							<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
								<Typography variant={"h4"} fontWeight={600} color={"#164c68"}>
									{t("Type")}:
								</Typography>
								<Typography variant={"h5"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
									{contact?.data?.data?.type ? contact?.data?.data?.type : "N/A"}
								</Typography>
							</Stack>
							<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
								<Typography variant={"h4"} fontWeight={600} color={"#164c68"}>
									{t("Category")}:
								</Typography>
								<Typography variant={"h5"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
									{contact?.data?.data?.category ? contact?.data?.data?.category : "N/A"}
								</Typography>
							</Stack>
						</Stack>
						{contact?.data?.data?.email &&
							contact?.data?.data?.email.map((email, index) => (
								<Stack key={index} direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
									<Typography variant={"h4"} fontWeight={600} color={"#164c68"}>
										{t("EmailAddress")} {index + 1}:
									</Typography>
									<a href={"mailto:" + email} style={{ textDecoration: "none" }}>
										<Typography variant={"h5"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
											{email}
										</Typography>
									</a>
								</Stack>
							))}
						{contact?.data?.data?.phone &&
							contact?.data?.data?.phone.map((phone, index) => (
								<Stack key={index} direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
									<Typography variant={"h4"} fontWeight={600} color={"#164c68"}>
										{t("PhoneNumber")} {index + 1}:
									</Typography>
									<a href={"tel:" + phone.trim()} style={{ textDecoration: "none" }}>
										<Typography variant={"h5"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
											{phone}
										</Typography>
									</a>
								</Stack>
							))}
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
							<Typography variant={"h4"} fontWeight={600} color={"#164c68"}>
								{t("Address")}:
							</Typography>
							<Typography variant={"h5"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
								{contact?.data?.data?.address ? contact?.data?.data?.address : "N/A"}
							</Typography>
						</Stack>
					</Stack>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: "#fff", py: 1 }}>
				<Button
					onClick={handleClose}
					sx={{
						color: "red",
						"&:hover": {
							color: "#fff",
							backgroundColor: "red",
						},
						transition: "all 1s ease",
					}}
				>
					{t("Close")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ContactPopOut;
