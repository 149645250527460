import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetByCriteriaQuery } from "../../../redux/slices/api/contacts/contactApi";
import ImageSelect from "./ImageSelect";

const LawyerSelect = ({ setLawyer, existingParties, category }) => {
	const { data, isLoading, isError } = useGetByCriteriaQuery({ "category[0]": category }, { refetchOnMountOrArgChange: true });

	const [rows, setRows] = useState([data?.data]);

	useEffect(() => {
		if (isError) return;

		if (data && !isError && !isLoading) {
			setRows(data.data);
		}
	}, [data, existingParties]);

	return (
		<Autocomplete
			id="party-select"
			loading={isLoading}
			sx={{ width: "100%" }}
			options={rows}
			autoHighlight
			autoComplete
			clearOnEscape
			getOptionLabel={(option) => {
				setLawyer(option.id);
				return option.nameAr;
			}}
			renderOption={(props, option) => (
				<Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
					<ImageSelect avatar={option.avatar} />
					<Typography variant="h6" sx={{ color: "#fff" }}>
						{option.nameAr}
					</Typography>
				</Box>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					inputProps={{
						...params.inputProps,
						autoComplete: "new-password", // disable autocomplete and autofill
					}}
					sx={{ p: 0, color: "#164c68" }}
				/>
			)}
		/>
	);
};

export default LawyerSelect;
