import { Button, Stack } from "@mui/material";
import React from "react";
import CustomTextField from "../../Common/CustomTextField";
import { IoMdAdd } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const SearchSection = ({
	isSmallScreen,
	searchValue,
	handleSearchInput,
	clearSearch,
	isSearching,
	categoryValue,
	setCategoryValue,
	setOpenAddPermission,
	data,
}) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);
	return (
		<Stack
			direction={"row"}
			justifyContent={"space-between"}
			alignItems={isSmallScreen ? "start" : "center"}
			width={"100%"}
			borderRadius={1}
			p={2}
			bgcolor={"#fff"}
			sx={{ boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;" }}
		>
			<Stack direction={isSmallScreen ? "column" : "row"} justifyContent={"flex-start"} alignItems={isSmallScreen ? "start" : "center"} spacing={2}>
				<CustomTextField
					label={t("Search")}
					name={"searchValue"}
					value={searchValue}
					type={"text"}
					handleInputChange={handleSearchInput}
					isSearching={isSearching}
					clearSearch={clearSearch}
					setSortValue={setCategoryValue}
					required={false}
				/>
			</Stack>
			<Button
				variant="contained"
				startIcon={!isRtl && <IoMdAdd />}
				size="medium"
				sx={{
					bgcolor: "#164c68",
					color: "#fff",
					"&:hover": {
						bgcolor: "#00a3b1",
					},
					transition: "all 0.5s ease",
				}}
				onClick={() => {
					setOpenAddPermission(true);
				}}
			>
				{t("AddPermissionGroup")}
			</Button>
		</Stack>
	);
};

export default SearchSection;
