import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { images } from "../../Assets/index";
import { useTranslation } from "react-i18next";
import CaseFileUser from "./CaseFileUser";

const Users = ({ users, setUsers, setAddingUsers }) => {
	const { t } = useTranslation();
	return (
		<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={4} p={1} width={"100%"}>
			<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={1}>
					<Box p={1} display={"flex"} justifyContent={"center"} alignItems={"center"} bgcolor={"#e9e7e770"} borderRadius={1}>
						<img src={images.authUser} alt="authorized users" width={20} height={20} />
					</Box>
					<Typography variant={"h3"} fontWeight={500} color={"#164c68"}>
						{t("Users")}
					</Typography>
				</Stack>
				<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} width={"100%"} spacing={1}>
					<Button
						onClick={() => {
							setAddingUsers(true);
						}}
						sx={{
							bgcolor: "#164c68",
							color: "#fff",
							"&:hover": {
								bgcolor: "#00a3b1",
								color: "#fff",
							},
							transition: "all 1s ease",
						}}
					>
						{t("ManageUsers")}
					</Button>
				</Stack>
			</Stack>
			<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2} p={2} width={"100%"}>
				{users?.length > 0 ? (
					<Grid container spacing={2}>
						{users?.map((user) => {
							return (
								<Grid item xs={12} sm={3} key={user.id}>
									<CaseFileUser user={user} users={users} setUsers={setUsers} />
								</Grid>
							);
						})}
					</Grid>
				) : (
					<Box
						width={"100%"}
						height={"100%"}
						display={"flex"}
						flexDirection={"column"}
						justifyContent={"center"}
						alignItems={"center"}
						m={"0 auto"}
						p={2}
					>
						<img src={images.notFound} alt="Not Found" width={100} height={100} />
						<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ my: 2 }}>
							{t("NoUsers")}!
						</Typography>
						<Typography variant={"subtitle1"} fontWeight={500} color={"#51505099"}>
							{t("AddUser")}!
						</Typography>
					</Box>
				)}
			</Stack>
		</Stack>
	);
};

export default Users;
