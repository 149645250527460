import { Menu as MuiMenu } from "@mui/material";
import { styled } from "@mui/system";

const Menu = styled(MuiMenu)({
	"& .MuiMenu-paper": {
		backgroundColor: "#164c68",
		color: "#fff",
		padding: "0 8px",
	},
	"& .MuiMenuItem-root": {
		borderRadius: "4px",
		"&:hover": {
			bgcolor: "#00a3b1",
		},
		transition: "all 0.5s ease",
	},
});

export { Menu };
