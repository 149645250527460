import React, { useState } from "react";
import Animate from "../../Common/Animate";
import { Box, Stack, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../Common/Breadcrumbs";
import EmailAccount from "./Account/EmailAccount";
import EmailTemplates from "./Templates/EmailTemplates";
import CreateEmail from "../../Settings/Email/CreateEmail";
import { images } from "../../Assets";
import { useTranslation } from "react-i18next";


const EmailSettings = () => {
	const { t } = useTranslation();
	const [createEmail, setCreateEmail] = useState(false);

	return (
		<Animate type="fade" delay={0.5}>
			<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={2} px={6} py={4}>
				<CustomBreadcrumbs section={t("Email")} />
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<img src={images.emailSettings} alt={"email"} width={30} height={30} />
					<Typography variant={"h2"} fontWeight={600} color={"#164c68"}>
						{t("EmailSettings")}
					</Typography>
				</Stack>
			</Stack>
			<Box
				width={"100%"}
				height={"max-content"}
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
				gap={4}
				sx={{ overflowY: "auto", overflowX: "hidden" }}
				p={4}
				mb={10}
			>
				<EmailAccount />
				<EmailTemplates createEmail={createEmail} setCreateEmail={setCreateEmail} />
			</Box>
			{createEmail && <CreateEmail open={createEmail} handleClose={() => setCreateEmail(false)} />}
		</Animate>
	);
};

export default EmailSettings;
