import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Memo = React.forwardRef(({ value, handleChange }, ref) => {
	const modules = {
		toolbar: [
			[{ header: [1, 2, 3, 4, 5, 6, false] }],
			[{ font: [] }],
			[{ size: [] }],
			[{ color: [] }, { background: [] }],
			["bold", "italic", "underline", "strike", "blockquote"],
			[{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
			[{ align: [] }],
			["link", "image"],
		],
	};

	return <ReactQuill theme="snow" value={value} onChange={handleChange} modules={modules} ref={ref} />;
});

export default Memo;
