import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { useGetCasesQuery } from "../../../redux/slices/api/cases/caseApi";
import { setSnackbar } from "../../../redux/slices/app";
import { useTranslation } from "react-i18next";

const LinkedCaseSelect = ({ fileNumber, linkedCase, setLinkedCase }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const isRtl = useSelector((state) => state.app.isRtl);
	const currentCase = useSelector((state) => state.case.currentCase);
	const isEditingCase = useSelector((state) => state.case.isEditingCase);

	const [linkedCaseNumber, setLinkedCaseNumber] = useState("");
	const [selectedLinkedCase, setSelectedLinkedCase] = useState(null);

	const { data, error, isError } = useGetCasesQuery({}, { refetchOnMountOrArgChange: true });

	const [casesData, setCasesData] = useState([]);

	useEffect(() => {
		if (isError) {
			dispatch(setSnackbar({ open: true, message: t("AnErrorOccurred"), severity: "error" }));
			console.log(error);
			return;
		}

		if (data) {
			if (isEditingCase) {
				const filteredData = data?.data
					?.filter((item) => item.id !== currentCase.id)
					.map((item) => {
						return {
							id: item.id,
							linkedCase:
								item?.classification === "litigation"
									? `${item.fullCaseNumber}-${isRtl ? item?.caseStage?.nameAr : item?.caseStage?.nameEn || item?.caseStage?.nameAr}`
									: `${item.fileNumber}`,
						};
					});
				setCasesData(filteredData);
				if (linkedCase) {
					const foundCase = casesData.find((item) => item.id === linkedCase);
					setSelectedLinkedCase(foundCase);
				}
			} else {
				const filteredData = data?.data
					?.filter((item) => item.id !== fileNumber)
					.map((item) => {
						return {
							id: item.id,
							linkedCase:
								item?.classification === "litigation"
									? `${item.fullCaseNumber}-${isRtl ? item?.caseStage?.nameAr : item?.caseStage?.nameEn || item?.caseStage?.nameAr}`
									: `${item.fileNumber}`,
						};
					});

				setCasesData(filteredData);
			}
		}
	}, [data]);

	useEffect(() => {
		if (linkedCase) {
			const foundCase = casesData.find((item) => item.id === linkedCase);
			setSelectedLinkedCase(foundCase);
		} else {
			setSelectedLinkedCase(null);
		}
	}, [linkedCase, casesData]);

	return (
		<Autocomplete
			id="constraint-select"
			clearOnEscape
			options={casesData}
			value={selectedLinkedCase}
			inputValue={linkedCaseNumber}
			autoHighlight
			size="small"
			autoComplete
			onChange={(event, value) => {
				setLinkedCase(value?.id);
			}}
			onInputChange={(event, value) => {
				setLinkedCaseNumber(value);
			}}
			getOptionLabel={(option) => option?.linkedCase}
			renderOption={(props, option) => {
				const returnValue = option?.linkedCase;
				return (
					<Box width={"100%"} component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
						<Typography variant="h6" sx={{ color: "#fff", ml: 1 }}>
							{returnValue}
						</Typography>
					</Box>
				);
			}}
			renderInput={(params) => (
				<TextField
					size="small"
					{...params}
					autoComplete="off"
					inputProps={{
						...params.inputProps,
						autoComplete: "off",
					}}
					sx={{ p: 0, color: "#164c68", width: "100%" }}
				/>
			)}
		/>
	);
};

export default LinkedCaseSelect;
