import React, { useState } from "react";
import { useDeleteHearingMutation } from "../../../redux/slices/api/hearing/hearingApi";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../../redux/slices/app";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Typography } from "@mui/material";
import SectionHeader from "../../Common/SectionHeader";
import { FcCancel } from "react-icons/fc";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteHearingDialog = ({ open, handleClose }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const isRtl = useSelector((state) => state.app.isRtl);
	const currentHearing = useSelector((state) => state.case.currentHearing);
	const currentCase = useSelector((state) => state.case.currentCase);

	const [loading, setLoading] = useState(false);

	const [deleteHearing] = useDeleteHearingMutation();

	const handleDelete = async () => {
		setLoading(true);

		try {
			await deleteHearing(currentHearing.id).unwrap();
			setLoading(false);
			dispatch(setSnackbar({ open: true, message: t("HearingDeletedSuccessfully"), severity: "success" }));
			if (currentCase) {
				navigate("/dashboard/case/details/hearings");
			}
			handleClose();
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setLoading(false);
		}
	};

	return (
		<Dialog open={open} fullWidth TransitionComponent={Transition} keepMounted aria-describedby="add-user-dialog" sx={{ height: "100%" }}>
			<DialogTitle sx={{ pb: 2, borderBottom: "1px solid #aaaaaa6b", backgroundColor: "#fff" }}>
				<SectionHeader icon={<FcCancel size={18} />} title={`${t("DeleteHearing")}?`} type={"dialog"} />
			</DialogTitle>
			<DialogContent sx={{ backgroundColor: "#fff" }}>
				<DialogContentText id="cancelling-dialog-description" sx={{ p: 4 }}>
					<Typography variant={"h5"} fontWeight={600} color={"#164c68"}>
						{t("DeleteHearingDialog")}
					</Typography>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: "#fff" }}>
				<Button
					disabled={loading}
					onClick={handleClose}
					sx={{
						color: "#fff",
						backgroundColor: "#fc6739",
						"&:hover": {
							color: "#fff",
							backgroundColor: "red",
						},
						transition: "all 1s ease",
					}}
				>
					{t("No")}
				</Button>
				<Button
					disabled={loading}
					variant="contained"
					size="medium"
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						marginRight: `${isRtl ? "8px" : "0px"} !important`,
					}}
					onClick={handleDelete}
				>
					{t("Yes")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteHearingDialog;
