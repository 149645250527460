import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateFeeMutation } from "../../../../redux/slices/api/fees/feesApi";
import { Typography } from "@mui/material";
import SectionHeader from "../../../Common/SectionHeader";
import { LoadingButton } from "@mui/lab";
import { setSnackbar } from "../../../../redux/slices/app";
import { RiEdit2Fill } from "react-icons/ri";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const CollectFeeDialog = ({ open, handleClose }) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const selectedFee = useSelector((state) => state.fees.selectedFee);
	const isRtl = useSelector((state) => state.app.isRtl);

	const [loading, setLoading] = useState(false);

	const [updateFee] = useUpdateFeeMutation();

	const handleCollectFee = async (e) => {
		e.preventDefault();
		setLoading(true);

		const data = {
			collected: true,
		};
		try {
			await updateFee({ id: selectedFee.id, data: data }).unwrap();
			dispatch(setSnackbar({ open: true, message: t("FeeUpdatedSuccessfully"), severity: "success" }));
			handleClose();
			setLoading(false);
			return;
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setLoading(false);
		}
	};

	return (
		<Dialog open={open} fullWidth TransitionComponent={Transition} keepMounted aria-describedby="add-user-dialog" sx={{ height: "100%" }}>
			<DialogTitle sx={{ pb: 2, borderBottom: "1px solid #aaaaaa6b", backgroundColor: "#fff" }}>
				<SectionHeader icon={<RiEdit2Fill size={18} color={"#164c68"} />} title={`Collect Fee?`} type={"dialog"} />
			</DialogTitle>
			<DialogContent sx={{ backgroundColor: "#fff" }}>
				<DialogContentText id="cancelling-dialog-description" sx={{ p: 4 }}>
					<Typography variant={"h5"} fontWeight={600} color={"#164c68"}>
						Are you sure you want to update this fee to Collected?
					</Typography>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: "#fff" }}>
				<Button
					onClick={handleClose}
					disabled={loading}
					sx={{
						color: "#fff",
						backgroundColor: "#fc6739",
						"&:hover": {
							color: "#fff",
							backgroundColor: "red",
						},
						transition: "all 1s ease",
					}}
				>
					No
				</Button>
				<LoadingButton
					loading={loading}
					variant="contained"
					size="medium"
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
					}}
					onClick={handleCollectFee}
				>
					Yes
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default CollectFeeDialog;
