import { Box, Tooltip, Typography } from "@mui/material";
import React from "react";

const SelectedUsers = ({ user, handleRemoveUser }) => {
	return (
		<Box
			display={"flex"}
			justifyContent={"center"}
			alignItems={"center"}
			p={0.5}
			border={".5px solid #164c68"}
			borderRadius={1}
			onClick={() => {
				handleRemoveUser(user);
			}}
			sx={{
				"&:hover": {
					cursor: "pointer",
				},
			}}
		>
			<Tooltip title={`Remove ${user?.firstName} ${user?.lastName}?`} placement="top">
				<Typography variant={"caption"} fontWeight={600} color={"#164c68"}>
					{user?.firstName} {user?.lastName}
				</Typography>
			</Tooltip>
		</Box>
	);
};

export default SelectedUsers;
