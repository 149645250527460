import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import AppBarComponent from "../components/App/AppBarComponent";
import Footer from "../components/App/Footer";
import { useDispatch, useSelector } from "react-redux";
import DrawerMenu from "../components/App/DrawerMenu";
import { useGetMineQuery } from "../redux/slices/api/user/userApi";
import CustomBackdrop from "../components/Common/CustomBackdrop";
import { setSnackbar, setUserDetails } from "../redux/slices/app";
import { useTranslation } from "react-i18next";
import VerificationDialog from "../components/Common/VerificationDialog";
import OtpDialog from "../components/Common/OtpDialog";

const MainLayout = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isDrawerOpen = useSelector((state) => state.app.isDrawerOpen);
	const drawerWidth = 300;
	const isAuthorized = useSelector((state) => state.auth.isAuthorized);

	const { data, isLoading, isError } = useGetMineQuery({}, { refetchOnMountOrArgChange: true });

	const [openVerification, setOpenVerification] = useState(false);
	const [openOtpDialog, setOpenOtpDialog] = useState(false);
	const [verificationMethod, setVerificationMethod] = useState("email");

	useEffect(() => {
		if (!isAuthorized) {
			dispatch(setSnackbar({ open: true, message: t("Unauthorized"), severity: "error" }));
			navigate("/");
		} else {
			if (data && !isError) {
				const isVerifiedEmail = data?.data?.isVerified?.email;
				const isVerifiedPhone = data?.data?.isVerified?.phone;
				// if (isVerifiedEmail === false || isVerifiedPhone === false) {
				// 	console.log("verification dialog")
				// 	setOpenVerification(true);
				// }
				dispatch(setUserDetails(data?.data));
			} else {
				console.error("Error fetching user data");
			}
		}
	}, [data]);

	return (
		<Box height={"100%"} width={"100%"} display={"flex"}>
			{isDrawerOpen && <DrawerMenu drawerWidth={drawerWidth} />}
			<Stack justifyContent={"flex-start"} alignItems={"start"} width={"100%"} height={"100%"} sx={{ overflowX: "hidden" }}>
				<Box
					height={"100%"}
					width={"100%"}
					display={"flex"}
					justifyContent={"space-between"}
					alignItems={"center"}
					flexDirection={"column"}
					sx={{ overflowY: "auto" }}
				>
					<AppBarComponent />
					<Outlet />
				</Box>
				<Footer />
			</Stack>
			{isLoading && <CustomBackdrop open={isLoading} />}
			{openVerification && (
				<VerificationDialog
					open={openVerification}
					handleClose={() => setOpenVerification(false)}
					myData={data?.data}
					verificationMethod={verificationMethod}
					setVerificationMethod={setVerificationMethod}
					openOtpDialog={() => setOpenOtpDialog(true)}
				/>
			)}
			{openOtpDialog && (
				<OtpDialog
					open={openOtpDialog}
					handleClose={() => setOpenOtpDialog(false)}
					closeVerificationDialog={() => setOpenVerification(false)}
					verificationMethod={verificationMethod}
					myData={data?.data}
				/>
			)}
		</Box>
	);
};

export default MainLayout;
