import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { images } from "../Assets";
import { useTranslation } from "react-i18next";

const Contact = ({ contact }) => {
	const { t } = useTranslation();
	return (
		<Box
			width={"100%"}
			p={2}
			height={"100%"}
			borderRadius={1.5}
			display={"flex"}
			justifyContent={"flex-start"}
			alignItems={"start"}
			flexDirection={"column"}
			gap={2}
			bgcolor={"#f3f7ff"}
			sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;" }}
		>
			<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={1}>
				<img src={images.id} alt="id" width={23} height={23} />
				<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mx: "8px !important" }}>
					{t("ArabicName")}:
				</Typography>
				<Typography variant="h5" color={"#164c68"} fontWeight={500}>
					{contact?.nameAr}
				</Typography>
			</Stack>
			<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={1}>
				<img src={images.id} alt="id" width={23} height={23} />
				<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mx: "8px !important" }}>
					{t("EnglishName")}:
				</Typography>
				<Typography variant="h5" color={"#164c68"} fontWeight={500}>
					{contact?.nameEn ? contact?.nameEn : "N/A"}
				</Typography>
			</Stack>

			<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={1}>
				<img src={images.role} alt="role" width={23} height={23} />
				<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mx: "8px !important" }}>
					{t("ContactType")}:
				</Typography>
				<Typography variant="h5" color={"#164c68"} fontWeight={500} textTransform={"capitalize"}>
					{contact?.type ? contact?.type : "N/A"}
				</Typography>
			</Stack>

			<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={1}>
				<img src={images.role} alt="role" width={23} height={23} />
				<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mx: "8px !important" }}>
					{t("ContactCategory")}:
				</Typography>
				<Typography variant="h5" color={"#164c68"} fontWeight={500} textTransform={"capitalize"}>
					{contact?.category ? contact?.type : "N/A"}
				</Typography>
			</Stack>
		</Box>
	);
};

export default Contact;
