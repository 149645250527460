import React, { useEffect, useRef, useState } from "react";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import SectionHeader from "../../Common/SectionHeader";
import { FaUserPen } from "react-icons/fa6";
import { FaCameraRetro, FaUser } from "react-icons/fa";
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import EditableTextField from "../../Common/EditableTextField";
import UserRoleCard from "./UserRoleCard";
import CustomCheckbox from "../../Common/CustomCheckBox";
import {
	useActivateUserMutation,
	useDeactivateUserMutation,
	useDeleteUserMutation,
	useUpdateUserMutation,
	useUploadUserFileMutation,
} from "../../../redux/slices/api/user/userApi";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../../redux/slices/app";
import { useTranslation } from "react-i18next";
import { images } from "../../Assets";
import { fetchMedia } from "../../Common/utils";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const EditUser = ({ open, handleClose, user, setUser, mode }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const inputRef = useRef(null);

	const userDetails = useSelector((state) => state.app.userDetails);

	const [userName, setUserName] = useState(user.firstName);
	const [lastName, setLastName] = useState(user.lastName);
	const [phone, setPhone] = useState(user.phone);
	const [position, setPosition] = useState(user.position);
	const [userEmail, setUserEmail] = useState(user.email);
	const [prevEmail, setPrevEmail] = useState(user.email);
	const [prevPhone, setPrevPhone] = useState(user.phone);
	const [isActive, setIsActive] = useState(user.isActive);
	const [userRole, setUserRole] = useState(user.role);
	const [editingName, setEditingName] = useState(false);
	const [editingLastName, setEditingLastName] = useState(false);
	const [editingEmail, setEditingEmail] = useState(false);
	const [editingPhone, setEditingPhone] = useState(false);

	const [loadingAvatar, setIsLoadingAvatar] = useState(false);
	const [avatar, setAvatar] = useState(null);
	const [newAvatar, setNewAvatar] = useState([]);
	const [uploadError, setUploadError] = useState(false);

	const [activateUser] = useActivateUserMutation();
	const [deactivateUser] = useDeactivateUserMutation();
	const [updateUser] = useUpdateUserMutation();
	const [deleteUser] = useDeleteUserMutation();
	const [updateAvatar] = useUploadUserFileMutation();

	const isRtl = useSelector((state) => state.app.isRtl);

	useEffect(() => {
		if (user.avatar) {
			const fetchData = async () => {
				setIsLoadingAvatar(true);
				const data = await fetchMedia(user.avatar);
				setAvatar(data?.dataUrl);
				setIsLoadingAvatar(false);
			};
			fetchData();
		}

		if (mode === "view") {
			const phoneNumber = parsePhoneNumberFromString(user.phone);
			console.log("phoneNumber", phoneNumber);
			const formattedPhoneNumber = `(${phoneNumber.formatInternational().substring(0, 4)}) ${phoneNumber.formatInternational().substring(5)}`;
			// const formattedPhoneNumber = `(+${phoneNumber.countryCallingCode}) ${phoneNumber.formatInternational()}`;
			setPhone(formattedPhoneNumber);
		}
	}, [user]);

	const handleEditEvent = async (e) => {
		e.preventDefault();

		let data = {
			firstName: userName,
			lastName: lastName,
			position: position,
			role: userRole,
		};

		if (prevEmail !== userEmail) {
			data = {
				...data,
				email: userEmail,
			};
		}
		if (prevPhone !== phone) {
			data = {
				...data,
				phone: phone,
			};
		}

		try {
			if (!isActive) {
				await deactivateUser(user.id);
			} else {
				await activateUser(user.id);
			}
			await updateUser({ userId: user.id, data: data }).unwrap();
			dispatch(setSnackbar({ open: true, message: t("UserUpdatedSuccessfully"), severity: "success" }));
			handleClose();
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			// setLoading(false);
		}
	};

	const handleUpdateAvatar = async (e) => {
		setIsLoadingAvatar(true);
		const fileData = e.target.files;
		const file = fileData[0];

		if (file.size <= 10 * 1024 * 1024) {
			const reader = new FileReader();

			reader.onloadend = () => {
				setAvatar(URL.createObjectURL(fileData[0]));
			};
			reader.readAsDataURL(fileData[0]);

			const data = new FormData();
			data.append("avatar", fileData[0]);
			try {
				await updateAvatar({ userId: user.id, data: data });
				setIsLoadingAvatar(false);
			} catch (error) {
				dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
				setIsLoadingAvatar(false);
			}
		} else {
			setUploadError(true);
			dispatch(setSnackbar({ open: true, message: `${t("ThisFile")} ${file.name}: ${t("TooLarge")}`, severity: "error" }));
		}
	};

	const handleDeleteUser = async () => {
		try {
			await deleteUser(user.id);
			dispatch(setSnackbar({ open: true, message: `${userName} ${t("IsDeletedUser")}`, severity: "info" }));
			handleClose();
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			// setLoading(false);
		}
	};

	return (
		<Dialog
			open={open}
			fullWidth
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			aria-describedby="add-user-dialog"
			sx={{ height: "100%" }}
		>
			<DialogTitle sx={{ pb: 2, borderBottom: "1px solid #aaaaaa6b", backgroundColor: "#fff" }}>
				<SectionHeader
					icon={mode !== "view" ? <FaUserPen color="#164c68" size={18} /> : <FaUser color="#164c68" size={18} />}
					title={`${t("User")}: ${user.firstName} ${user.lastName} `}
					type={"dialog"}
				/>
			</DialogTitle>
			<DialogContent sx={{ backgroundColor: "#fff" }}>
				<DialogContentText id="add-user-dialog-description" sx={{ p: 2 }}>
					<Stack justifyContent={"flex-start"} alignItems={"start"} width={"100%"} mb={2}>
						<Box
							width={"100px"}
							height={"100px"}
							p={avatar ? 0 : 2}
							borderRadius={"50%"}
							bgcolor={"#bdbdbd"}
							display={"flex"}
							justifyContent={"center"}
							alignItems={"center"}
							position={"relative"}
							border={uploadError && "2px solid red"}
						>
							<img
								src={avatar ? avatar : images.avatar}
								alt="avatar"
								width={"100%"}
								height={"100%"}
								style={{ objectFit: "cover", borderRadius: "50%" }}
							/>
							{mode !== "view" && (
								<Box
									width={"30px"}
									height={"30px"}
									borderRadius={"50%"}
									bgcolor={"#164c68"}
									display={"flex"}
									justifyContent={"center"}
									alignItems={"center"}
									position={"absolute"}
									sx={{
										top: "70px",
										right: "0",
									}}
								>
									<IconButton
										sx={{ p: 0 }}
										onClick={() => {
											setUploadError(false);
											inputRef.current.click();
										}}
									>
										<FaCameraRetro size={17} color={"#fff"} />
									</IconButton>
								</Box>
							)}
						</Box>
					</Stack>
					<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={2} width={"100%"} mb={2}>
						<EditableTextField
							label={t("FirstName")}
							value={userName}
							setValue={setUserName}
							name={"firstName"}
							isEditing={editingName}
							setIsEditing={setEditingName}
							prevValue={user.firstName}
							prevData={user}
							setPrevData={setUser}
							section={"user"}
							mode={mode}
						/>

						<EditableTextField
							label={t("LastName")}
							value={lastName}
							setValue={setLastName}
							name={"lastName"}
							isEditing={editingLastName}
							setIsEditing={setEditingLastName}
							prevValue={user.lastName}
							prevData={user}
							setPrevData={setUser}
							section={"user"}
							mode={mode}
						/>

						<EditableTextField
							label={t("PhoneNumber")}
							value={phone}
							setValue={setPhone}
							name={"phone"}
							isEditing={editingPhone}
							setIsEditing={setEditingPhone}
							prevValue={user.phone}
							prevData={user}
							setPrevData={setUser}
							section={"user"}
							mode={mode}
						/>

						<EditableTextField
							label={t("EmailAddress")}
							value={userEmail}
							setValue={setUserEmail}
							name={"userEmail"}
							isEditing={editingEmail}
							setIsEditing={setEditingEmail}
							prevValue={user.email}
							prevData={user}
							setPrevData={setUser}
							section={"user"}
							mode={mode}
						/>
					</Stack>

					{mode === "view" ? (
						<>
							<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1.5} width={"100%"} mb={1}>
								<Typography variant={"h5"} fontWeight={600} color={"#164c68"}>
									{t("Position")}:
								</Typography>
							</Stack>
							<Box
								display={"flex"}
								justifyContent={"center"}
								alignItems={"center"}
								p={1}
								gap={1}
								borderRadius={1.5}
								sx={{
									boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;",
									transition: "width 3s ease-out",
									width: "max-content", // Updated transition effect with a slower duration
								}}
							>
								<Typography variant={"h6"} fontWeight={600} color={"#595858"}>
									{user?.position ? t(user?.position) : "N/A"}
								</Typography>
							</Box>
						</>
					) : (
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} my={2}>
							<FormControl sx={{ minWidth: 120 }} size="small">
								<InputLabel id="role-select-small-label">{t("Position")}</InputLabel>
								<Select
									labelId="position-select-small-label"
									id="position-select-small"
									value={position}
									label="Position"
									onChange={(e) => setPosition(e.target.value)}
								>
									<MenuItem value={"Admin"}>{t("Admin")}</MenuItem>
									<MenuItem value={"Expert"}>{t("Expert")}</MenuItem>
									<MenuItem value={"Expert Assistant"}>{t("ExpertAssistant")}</MenuItem>
									<MenuItem value={"Auditor"}>{t("Auditor")}</MenuItem>
									<MenuItem value={"Translator"}>{t("Translator")}</MenuItem>
									<MenuItem value={"Accountant"}>{t("Accountant")}</MenuItem>
									<MenuItem value={"Secretary"}>{t("Secretary")}</MenuItem>
									<MenuItem value={"Administrative"}>{t("Administrative")}</MenuItem>
								</Select>
							</FormControl>
						</Stack>
					)}
					{mode === "view" ? (
						<>
							<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1.5} width={"100%"} my={1}>
								<Typography variant={"h5"} fontWeight={600} color={"#164c68"}>
									{t("Role")}:
								</Typography>
							</Stack>
							<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
								<UserRoleCard role={user.role} />
							</Stack>
						</>
					) : (
						<>
							<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"} my={1}>
								<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
									<InputLabel id="role-select-small-label">{t("Role")}</InputLabel>
									<Select
										labelId="role-select-small-label"
										id="role-select-small"
										value={userRole}
										label="Role"
										onChange={(e) => setUserRole(e.target.value)}
									>
										<MenuItem value={"Employee"}>{t("Employee")}</MenuItem>
										{userDetails.role === "Owner" && <MenuItem value={"Admin"}>{t("Admin")}</MenuItem>}
										{userDetails.role === "Owner" && <MenuItem value={"Owner"}>{t("Owner")}</MenuItem>}
									</Select>
								</FormControl>
							</Stack>
						</>
					)}
					{mode !== "view" && (
						<CustomCheckbox
							desc={`${t("ActiveUser")}?`}
							checked={isActive}
							toggle={() => {
								setIsActive(!isActive);
							}}
						/>
					)}
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: "#fff" }}>
				{mode !== "view" && (
					<Button
						onClick={handleDeleteUser}
						sx={{
							color: "red",
							"&:hover": {
								color: "#fff",
								backgroundColor: "red",
							},
							transition: "all 1s ease",
						}}
					>
						{t("Delete")}
					</Button>
				)}
				<Button
					onClick={handleClose}
					sx={{
						color: "red",
						"&:hover": {
							color: "#fff",
							backgroundColor: "red",
						},
						transition: "all 1s ease",
					}}
				>
					{mode === "view" ? t("Close") : t("Cancel")}
				</Button>
				{mode !== "view" && (
					<Button
						onClick={handleEditEvent}
						sx={{
							bgcolor: "#fff",
							color: "#00a3b1",
							"&:hover": {
								bgcolor: "#00a3b1",
								color: "#fff",
							},
							transition: "all 1s ease",
						}}
					>
						{t("Save")}
					</Button>
				)}
			</DialogActions>
			<input ref={inputRef} type="file" hidden accept="image/*" onChange={handleUpdateAvatar} />
		</Dialog>
	);
};

export default EditUser;
