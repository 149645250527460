import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import MiniCharts from "../../Common/Charts/MiniCharts";
import { useGetCasesQuery } from "../../../redux/slices/api/cases/caseApi";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const StatBox = ({ isSmallScreen }) => {
	const isRtl = useSelector((state) => state.app.isRtl);
	const [stats, setStats] = React.useState({});
	const { t } = useTranslation();

	const { data, isLoading, isError, error } = useGetCasesQuery({}, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (isError) {
			if (error.message === "Request failed with status code 401") {
				console.log(error.message);
			}
			return;
		}
		if (data) {
			setStats(data?.data);
		}
	}, [data, isError, error]);

	return (
		<Box
			width={isSmallScreen ? "100%" : "25%"}
			height={"auto"}
			minHeight={"353px"}
			p={2}
			borderRadius={1.5}
			bgcolor={"#fff"}
			sx={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;", marginLeft: `${isRtl && "16px"} !important` }}
		>
			<Stack direction={"row"} width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
				<Typography variant={"h5"} fontWeight={500}>
					{t("CaseFiles")}:
				</Typography>
				<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"}>
					<Typography variant={"h5"} fontWeight={500}>
						{t("Count")}:
					</Typography>
					<Typography
						variant={"h5"}
						fontWeight={500}
						sx={{
							fontFamily: '"Montserrat", "sans-serif"',
						}}
					>
						{data?.totalRecords}
					</Typography>
				</Stack>
			</Stack>

			<Box height={"300px"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
				{isLoading ? <CircularProgress /> : <MiniCharts type={"firstSection"} />}
			</Box>
		</Box>
	);
};

export default StatBox;
