import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import SectionHeader from "../../../Common/SectionHeader";
import { FaCloudUploadAlt } from "react-icons/fa";
import pdf from "../../../Assets/Files/pdf.png";
import doc from "../../../Assets/Files/doc.png";
import ppt from "../../../Assets/Files/ppt.png";
import xls from "../../../Assets/Files/xls.png";
import html from "../../../Assets/Files/html.png";
import other from "../../../Assets/Files/other.png";
import txt from "../../../Assets/Files/txt.png";
import csv from "../../../Assets/Files/csv.png";
import video from "../../../Assets/Files/video.png";
import { Box, Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { IoMdAddCircleOutline } from "react-icons/io";
import FileBox from "./FileBox";
import { LoadingButton } from "@mui/lab";
import { setSnackbar } from "../../../../redux/slices/app";
import { useUploadFileMutation } from "../../../../redux/slices/api/files/fileApi";
import { images } from "../../../Assets";
import { useTranslation } from "react-i18next";
import CustomConstraintSelect from "../../../Common/CustomConstraintSelect";
import { IoAddCircle } from "react-icons/io5";
import AddConstraintDialog from "../../CreateCaseFile/AddConstraintDialog";


const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const UploadSection = ({ open, handleClose, criteria, criteriaId, files, setFiles, filePreview, setFilePreview }) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);
	const dispatch = useDispatch();
	const inputRef = useRef(null);

	const [previewFile, setPreviewFile] = useState(null);
	const [isImage, setIsImage] = useState(false);
	const [isVideo, setIsVideo] = useState(false);
	const [filesToSend, setFilesToSend] = useState([]);
	const [selectedFile, setSelectedFile] = useState(null);
	const [fileCategory, setFileCategory] = useState(null);
	const [addConstraint, setAddConstraint] = useState(false);
	const [constraintReference, setConstraintReference] = useState("fileCategory");

	const [loading, setLoading] = useState(false);

	const [fileName, setFileName] = useState("");
	const [fileSize, setFileSize] = useState("");

	useEffect(() => {
		const fileNameParts = files[0].name.split(".");
		const fileNameExtension = fileNameParts[fileNameParts.length - 1];
		const fileSize = files[0].size / 1000;
		setFileName(files[0].name);
		setFileSize(fileSize.toFixed(2));
		setFilesToSend(files);
		if (files.length < 1) {
			if (fileNameExtension === "png" || fileNameExtension === "jpg" || fileNameExtension === "jpeg") {
				setPreviewFile(filePreview);
			} else if (fileNameExtension === "pdf") {
				setPreviewFile(pdf);
				setIsImage(false);
			} else if (fileNameExtension === "docx" || fileNameExtension === "doc") {
				setPreviewFile(doc);
				setIsImage(false);
			} else if (fileNameExtension === "xlsx" || fileNameExtension === "xls") {
				setPreviewFile(xls);
				setIsImage(false);
			} else if (fileNameExtension === "csv") {
				setPreviewFile(csv);
				setIsImage(false);
			} else if (fileNameExtension === "txt") {
				setPreviewFile(txt);
				setIsImage(false);
			} else if (fileNameExtension === "html") {
				setPreviewFile(html);
				setIsImage(false);
			} else if (fileNameExtension === "pptx" || fileNameExtension === "ppt") {
				setPreviewFile(ppt);
				setIsImage(false);
			} else if (fileNameExtension === "mp4" || fileNameExtension === "avi" || fileNameExtension === "mov" || fileNameExtension === "wmv") {
				setIsVideo(true);
				setPreviewFile(filePreview);
			} else {
				setPreviewFile(other);
				setIsImage(false);
			}
		} else {
			setSelectedFile(files[0]);
			if (fileNameExtension === "png" || fileNameExtension === "jpg" || fileNameExtension === "jpeg") {
				setPreviewFile(URL.createObjectURL(files[0]));
			} else if (fileNameExtension === "pdf") {
				setPreviewFile(pdf);
				setIsImage(false);
			} else if (fileNameExtension === "docx" || fileNameExtension === "doc") {
				setPreviewFile(doc);
				setIsImage(false);
			} else if (fileNameExtension === "xlsx" || fileNameExtension === "xls") {
				setPreviewFile(xls);
				setIsImage(false);
			} else if (fileNameExtension === "csv") {
				setPreviewFile(csv);
				setIsImage(false);
			} else if (fileNameExtension === "txt") {
				setPreviewFile(txt);
				setIsImage(false);
			} else if (fileNameExtension === "html") {
				setPreviewFile(html);
				setIsImage(false);
			} else if (fileNameExtension === "pptx" || fileNameExtension === "ppt") {
				setPreviewFile(ppt);
				setIsImage(false);
			} else if (fileNameExtension === "mp4" || fileNameExtension === "avi" || fileNameExtension === "mov" || fileNameExtension === "wmv") {
				setIsVideo(true);
				setPreviewFile(URL.createObjectURL(files[0]));
			} else {
				setPreviewFile(other);
				setIsImage(false);
			}
		}
	}, [files, filePreview]);

	const handleAdditionalFiles = (e) => {
		const fileData = e.target.files;
		for (let i = 0; i < fileData.length; i++) {
			const file = fileData[i];
			// Check if file size is less than or equal to 10MB (in bytes)
			if (file.size <= 10 * 1024 * 1024) {
				const reader = new FileReader();
				reader.onloadend = () => {
					setFilesToSend((prevFiles) => [...prevFiles, file]);
				};
				reader.readAsDataURL(file);
			} else {
				// Handle error or notify the user about the file size limit
				dispatch(setSnackbar({ open: true, message: `${t("ThisFile")} ${file.name}: ${t("TooLarge")}`, severity: "error" }));
			}
		}
		setFilePreview(fileData[0]);
	};

	const handleDragOver = (e) => {
		e.preventDefault();
	};

	const handleDragEnter = (e) => {
		e.preventDefault();
	};

	const handleDragLeave = (e) => {
		e.preventDefault();
	};

	const handleDrop = (e) => {
		e.preventDefault();
		let fileData = e.dataTransfer.files;

		// Merge the dropped files with the existing files
		const newFiles = [...filesToSend];
		for (let i = 0; i < fileData.length; i++) {
			if (fileData[i].size <= 10 * 1024 * 1024) {
				newFiles.push(fileData[i]);
			} else {
				dispatch(setSnackbar({ open: true, message: `${t("ThisFile")} ${fileData[i].name} ${t("TooLarge")}`, severity: "error" }));
			}
		}
		// Update state with the merged files
		setFilesToSend(newFiles);

		// Update preview file if only one file is present
		if (newFiles.length === 1) {
			if (newFiles[0].size <= 10 * 1024 * 1024) {
				const reader = new FileReader();
				reader.onloadend = () => {
					setFilePreview(URL.createObjectURL(newFiles[0]));
				};
				reader.readAsDataURL(newFiles[0]);
			} else {
				dispatch(setSnackbar({ open: true, message: `${t("ThisFile")} ${newFiles[0].name} ${t("TooLarge")}`, severity: "error" }));
			}
		}
	};

	const [uploadFile] = useUploadFileMutation();

	const handleUploadFiles = async (e) => {
		e.preventDefault();
		setLoading(true);

		if (!fileCategory) {
			dispatch(setSnackbar({ open: true, message: t("PleaseSelectCategory"), severity: "error" }));
			setLoading(false);
			return;
		}


		let data = new FormData();
		filesToSend.forEach((file) => {
			data.append("documents", file)
		});
		data.append(criteria, criteriaId);
		data.append("category", fileCategory);
		try {
			await uploadFile(data).unwrap();
			dispatch(setSnackbar({ open: true, message: t("FilesUploadedSuccessfully"), severity: "success" }));
			setLoading(false);
			handleClose();
		} catch (error) {
			console.log(error);
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setLoading(false);
		}
	};

	return (
		<Dialog open={open} fullWidth TransitionComponent={Transition} keepMounted aria-describedby="upload-file-dialog" sx={{ height: "100%" }}>
			<DialogTitle sx={{ pb: 2, borderBottom: "1px solid #aaaaaa6b", backgroundColor: "#fff" }}>
				<SectionHeader icon={<FaCloudUploadAlt color="#164c68" size={18} />} title={`${t("Upload")} ${t("File")}`} type={"dialog"} />
			</DialogTitle>
			<DialogContent sx={{ backgroundColor: "#fff", overflowX: "hidden" }}>
				<DialogContentText id="upload-file-dialog-description" sx={{ py: 4, px: 2 }}>
					<Box
						sx={{
							width: "100%",
							height: "100%",
						}}
					>
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"} mb={2}>
							<Typography variant="body1" color={"#164c68"} fontWeight={500} sx={{ mb: 1, ml: `${isRtl ? "8px" : "0px"} !important` }}>
								{t("Category")}:
							</Typography>
							<CustomConstraintSelect reference={"fileCategory"} dataSet={fileCategory} setData={setFileCategory} />
							<Tooltip title={`${t("AddCategory")}`}>
								<IconButton
									onClick={() => {
										setAddConstraint(true);
									}}
								>
									<IoAddCircle color={"#164c68"} />
								</IconButton>
							</Tooltip>
						</Stack>
						<div onDragOver={handleDragOver} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave} onDrop={handleDrop}>
							{filesToSend?.length >= 1 ? (
								<Stack
									justifyContent={"flex-start"}
									alignItems={"center"}
									spacing={0.5}
									mb={2}
									sx={{
										height: "100%",
									}}
								>
									{isVideo ? (
										<video controls style={{ height: "300px", width: "100%" }}>
											<source src={previewFile} type="video/mp4" />
											Your browser does not support the video tag.
										</video>
									) : (
										<img
											src={previewFile}
											alt="Image Preview"
											style={{
												height: "300px",
												width: "60%",
												objectFit: "shrink",
											}}
										/>
									)}
									<Typography variant="body1" color="#164c68">
										{fileName}
									</Typography>
									<Typography variant="body1" color="#164c68">
										{fileSize} KB
									</Typography>
								</Stack>
							) : (
								<Box
									width={"100%"}
									height={"100%"}
									display={"flex"}
									flexDirection={"column"}
									justifyContent={"center"}
									alignItems={"center"}
									m={"0 auto"}
									p={2}
								>
									<img src={images.notFound} alt="Not Found" width={200} height={200} />
									<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ my: 2 }}>
										{t("NoFileAvailable")}
									</Typography>
									<Typography variant={"subtitle1"} fontWeight={500} color={"#51505099"} sx={{ textAlign: "center" }}>
										{t("AddFilesByDragging")}
									</Typography>
								</Box>
							)}

							<Divider variant="middle" />
							<Stack justifyContent={"center"} alignItems={"center"} width={"100%"} mt={2}>
								<Stack
									direction={"row"}
									justifyContent={"center"}
									alignItems={"flex-start"}
									spacing={2}
									width={"100%"}
									py={2}
									sx={{ overflowX: "auto" }}
								>
									{filesToSend?.map((file, index) => {
										const fileNameParts = file.name.split(".");
										const fileNameExtension = fileNameParts[fileNameParts.length - 1];
										let fileType = {
											extension: fileNameExtension,
											preview: other, // Default icon
											isVideo: false,
											isImage: false,
										};

										if (["mp4"].includes(fileNameExtension)) {
											fileType = { ...fileType, preview: video, isVideo: true };
										} else if (["png", "jpg", "jpeg"].includes(fileNameExtension)) {
											fileType = { ...fileType, preview: URL.createObjectURL(file), isImage: true };
										} else if (["pdf"].includes(fileNameExtension)) {
											fileType = { ...fileType, preview: pdf };
										} else if (["xlsx", "xls"].includes(fileNameExtension)) {
											fileType = { ...fileType, preview: xls };
										} else if (["docx", "doc"].includes(fileNameExtension)) {
											fileType = { ...fileType, preview: doc };
										} else if (["ppt", "pptx"].includes(fileNameExtension)) {
											fileType = { ...fileType, preview: ppt };
										}
										return (
											<FileBox
												key={index}
												file={file}
												index={index}
												fileType={fileType}
												filesToSend={filesToSend}
												setPreviewFile={setPreviewFile}
												setIsVideo={setIsVideo}
												setIsImage={setIsImage}
												setFileName={setFileName}
												setFileSize={setFileSize}
												setSelectedFile={setSelectedFile}
												setFilesToSend={setFilesToSend}
											/>
										);
									})}
								</Stack>
								<IconButton component="label" sx={{ color: "#164c68" }}>
									<IoMdAddCircleOutline color="#164c68" size={30} />
									<input
										hidden
										accept="image/*, .pdf, .doc, .docx, .xls, .xlsx,.ppt, .pptx"
										multiple
										type="file"
										onChange={handleAdditionalFiles}
									/>
								</IconButton>
							</Stack>
						</div>
					</Box>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: "#fff" }}>
				<Button
					disabled={loading}
					onClick={() => {
						setFilesToSend([]);
						setPreviewFile(null);
						setFileCategory(null);
						setAddConstraint(false);
						setSelectedFile(null);
						handleClose();
					}}
					sx={{ color: "#fff", backgroundColor: "#fc6739", "&:hover": { color: "#fff", backgroundColor: "#164c68" } }}
				>
					{t("Cancel")}
				</Button>
				<LoadingButton
					disabled={filesToSend?.length < 1}
					loading={loading}
					onClick={handleUploadFiles}
					sx={{
						color: "#fff",
						backgroundColor: "#164c68",
						marginRight: `${isRtl ? "8px" : "0px"} !important`,
						"&:hover": { color: "#fff", backgroundColor: "#00a3b1" },
					}}
				>
					{t("Upload")}
				</LoadingButton>
			</DialogActions>
			{addConstraint && (
				<AddConstraintDialog
					open={addConstraint}
					handleClose={() => setAddConstraint(false)}
					reference={constraintReference}
					setFileCategory={setFileCategory}
				/>
			)}
		</Dialog>
	);
};

export default UploadSection;
