import { IconButton, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { IoIosSearch } from "react-icons/io";
import { MdClear } from "react-icons/md";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { styled } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";

const CustomizedTextField = styled(TextField)({
	"& value.Mui-focused": {
		color: "#575757",
	},
	"& .MuiFormLabel-root": {
		color: "#575757", // change this to your desired color
	},
	"& .MuiFormLabel-root.Mui-focused": {
		color: "#575757", // replace with your desired color when focused
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: "#00a3b1",
	},
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: "#00a3b1",
			width: "100%",
		},
		"&:hover fieldset": {
			borderColor: "#00a3b1",
		},
		"&.Mui-focused fieldset": {
			borderColor: "#00a3b1",
		},
		"& .MuiInputBase-input": {
			color: "#575757",
		},
	},
	"&.Mui-disabled": {
		"& .MuiInputBase-input": {
			border: "1px solid #00a3b1",
			padding: "8px",
		},
	},
});

const CustomTextField = ({
	type,
	name,
	value,
	resetValue,
	label,
	handleInputChange,
	showPassword,
	setShowPassword,
	isSearching,
	clearSearch,
	isSorting,
	setSortValue,
	error,
	errorText,
	multiline,
	required,
	disable,
	placeholder,
	id,
}) => {
	const { t } = useTranslation();
	return (
		<CustomizedTextField
			id={id}
			variant="outlined"
			label={label}
			size={name === "searchValue" && "small"}
			fullWidth={
				name !== "searchValue" &&
				name !== "fileNumber" &&
				name !== "caseNumber" &&
				name !== "caseYear" &&
				name !== "taskTitle" &&
				name !== "code" &&
				name !== "place" &&
				name !== "url" &&
				name !== "reminderDay" &&
				name !== "reminderHour"
			}
			type={showPassword ? "text" : type}
			placeholder={placeholder ? placeholder : ""}
			name={name}
			value={value}
			onChange={handleInputChange}
			required={required}
			multiline={multiline}
			disabled={disable}
			error={error}
			autoComplete={name === "searchValue" && "off"}
			helperText={
				error && (
					<Typography variant={"caption"} color={"red"} fontWeight={500}>
						*{errorText}
					</Typography>
				)
			}
			InputProps={{
				endAdornment:
					name === "password" ? (
						<IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
							{showPassword ? <FaEyeSlash color="#51505099" /> : <FaEye color="#164c68" />}
						</IconButton>
					) : name === "searchValue" ? (
						<>
							{isSearching ? (
								<Tooltip title={t("Clear")} placement="top" arrow>
									<IconButton
										onClick={() => {
											clearSearch();
											if (isSorting) {
												setSortValue("None");
											}
										}}
										edge="end"
									>
										<MdClear color="#164c68" size={20} />
									</IconButton>
								</Tooltip>
							) : (
								<IoIosSearch color="#164c68" size={20} />
							)}
						</>
					) : (
						<Tooltip title={t("Clear")} placement="top" arrow>
							<IconButton onClick={() => resetValue(name)} edge="end" disabled={value && (value?.length < 1 || value < 1)}>
								<MdClear color={value && (value?.length < 1 || value < 1) ? "#979797bd" : "#164c68"} size={20} />
							</IconButton>
						</Tooltip>
					),
				startAdornment:
					name === "phone" ? (
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} mr={2}>
							<Typography variant={"body1"} fontWeight={500} color={"#51505099"}>
								+971
							</Typography>
						</Stack>
					) : name === "emailTo" || name === "emailCc" || name === "emailBcc" ? (
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} mr={2}>
							<Typography variant={"body1"} fontWeight={500} color={"#51505099"}>
								{name === "emailTo" ? "To:" : name === "emailCc" ? "Cc:" : "Bcc:"}
							</Typography>
						</Stack>
					) : name === "amount" ? (
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} mr={2}>
							<Typography variant={"body1"} fontWeight={500} color={"#51505099"}>
								AED
							</Typography>
						</Stack>
					) : null,
			}}
			onPaste={(e) => {
				if (name === "nameAr" || name === "nameEn") {
					e.preventDefault();
				}
			}}
		/>
	);
};

export default CustomTextField;
