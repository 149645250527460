import { ResponsiveBar } from "@nivo/bar";
import React, { useEffect, useState } from "react";
import { useGetCasesQuery } from "../../../redux/slices/api/cases/caseApi";
import { useTranslation } from "react-i18next";

const MiniCharts = ({ type }) => {
	const colors = type === "thirdSection" ? "dark2" : "paired";
	const [count, setCount] = useState([{}]);
	const { t } = useTranslation();

	const ongoingCases = useGetCasesQuery({ status: "ongoing", data: false }, { refetchOnMountOrArgChange: true });
	const closedCases = useGetCasesQuery({ status: "closed", data: false }, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (ongoingCases.data && closedCases.data) {
			setCount([
				{ Status: t("Ongoing"), Count: ongoingCases.data.totalRecords },
				{ Status: t("Closed"), Count: closedCases.data.totalRecords },
			]);
		}
	}, [ongoingCases, closedCases]);

	return (
		<ResponsiveBar
			data={count}
			keys={["Count"]}
			indexBy="Status"
			margin={{ top: 30, right: 50, bottom: 50, left: 70 }}
			padding={0.3}
			valueScale={{ type: "linear" }}
			indexScale={{ type: "band", round: true }}
			yScale={{
				type: "linear",
				min: "auto",
				max: "auto",
				stacked: true,
				reverse: false,
			}}
			enableLabel={false}
			colors={{ scheme: colors }}
			borderColor={{
				from: "color",
				modifiers: [["darker", 1.6]],
			}}
			axisTop={null}
			axisRight={null}
			axisBottom={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: type === "thirdSection" ? "" : t("Status"),
				legendPosition: "middle",
				legendOffset: 32,
				truncateTickAt: 0,
			}}
			axisLeft={{
				tickSize: 5,
				tickPadding: 20,
				tickRotation: 5,
				legend: t("Count"),
				legendPosition: "middle",
				legendOffset: -60,
				truncateTickAt: 0,
			}}
			labelSkipWidth={12}
			labelSkipHeight={12}
			labelTextColor={{
				from: "color",
				modifiers: [["darker", 1.6]],
			}}
			role="application"
			ariaLabel="Nivo bar chart demo"
			barAriaLabel={(e) => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
		/>
	);
};

export default MiniCharts;
