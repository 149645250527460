import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Stack, Tooltip, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useSelector } from "react-redux";
import SectionHeader from "../Common/SectionHeader";
import CustomTextField from "../Common/CustomTextField";
import CustomCheckbox from "../Common/CustomCheckBox";
import { useGetAllUsersQuery, useGetByUserNameQuery } from "../../redux/slices/api/user/userApi";
import { FaUserPlus } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const UserSelectDialog = ({ users, setUsers, open, handleClose }) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);
	const { data, isLoading, isError } = useGetAllUsersQuery({}, { refetchOnMountOrArgChange: true });
	const [rows, setRows] = useState(null);
	const [originalRows, setOriginalRows] = useState(null);

	useEffect(() => {
		if (isError) {
			return;
		}
		if (data) {
			const checkedUsers = users?.map((user) => user.id);
			const filteredUsers = data?.data?.filter((user) => !checkedUsers?.includes(user.id));
			setRows(filteredUsers);
			setOriginalRows(filteredUsers);
		}
	}, [data, users, rows, originalRows, isLoading, isError]);

	const [searchValue, setSearchValue] = useState("");
	const [isSearching, setIsSearching] = useState(false);
	const [filteredRows, setFilteredRows] = useState([]);

	const nameQuery = searchValue?.length >= 3 && searchValue !== "" ? { name: searchValue } : {};

	const searchByName = useGetByUserNameQuery(nameQuery, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (searchValue?.length > 2 && searchValue !== "") {
			setIsSearching(true);
			const checkedUsers = users?.map((user) => user?.id);
			const filteredUsers = searchByName?.data?.data?.filter((user) => !checkedUsers?.includes(user?.id));
			setFilteredRows(filteredUsers);
		} else {
			setIsSearching(false);
		}
	}, [searchValue]);

	const handleSearchInput = (e) => {
		setSearchValue(e.target.value);
		if (e.target.value === "") {
			setIsSearching(false);
		}
	};

	const clearSearch = () => {
		setSearchValue("");
		setIsSearching(false);
	};

	const handleToggle = (user) => {
		const currentIndex = users.indexOf(user);
		const newUsersToAdd = [...users];

		if (currentIndex === -1) {
			newUsersToAdd.push(user);
		}

		setUsers(newUsersToAdd);

		if (isSearching) {
			clearSearch();
		}
	};

	const handleRemoveUsers = (user) => {
		const newUsersToAdd = users.filter((c) => c !== user);
		setUsers(newUsersToAdd);
	};

	return (
		<Dialog
			open={open}
			fullWidth
			maxWidth="sm"
			onClose={handleClose}
			TransitionComponent={Transition}
			keepMounted
			aria-describedby="add-users-dialog"
			sx={{ height: "100%" }}
		>
			<DialogTitle sx={{ pb: 2, borderBottom: "1px solid #aaaaaa6b", backgroundColor: "#fff" }}>
				<SectionHeader icon={<FaUserPlus color="#164c68" size={18} />} title={t("ManageUsers")} type={"dialog"} />
			</DialogTitle>
			<DialogContent sx={{ backgroundColor: "#fff", overflowY: "scroll" }}>
				<DialogContentText id="add-users-dialog-description" sx={{ p: 4 }}>
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={2}>
						<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ ml: `${isRtl ? "8px" : "0px"} !important` }}>
							{t("SelectedUsers")}:
						</Typography>
						{users?.length > 0 &&
							users?.map((user) => (
								<Box
									key={user.id}
									display={"flex"}
									justifyContent={"center"}
									alignItems={"center"}
									p={0.5}
									border={".5px solid #164c68"}
									borderRadius={1}
									onClick={() => {
										handleRemoveUsers(user);
									}}
									sx={{
										"&:hover": {
											cursor: "pointer",
										},
									}}
								>
									<Tooltip title={`Remove ${user?.firstName} ${user?.lastName}?`} placement="top">
										<Typography variant={"caption"} fontWeight={600} color={"#164c68"}>
											{user?.user?.firstName || user?.firstName} {user?.user?.lastName || user?.lastName}
										</Typography>
									</Tooltip>
								</Box>
							))}
					</Stack>
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
						<Typography variant={"h4"} fontWeight={600} color={"#164c68"}>
							{t("Users")}
						</Typography>
						<CustomTextField
							label={t("Search")}
							name={"searchValue"}
							value={searchValue}
							type={"text"}
							handleInputChange={handleSearchInput}
							isSearching={isSearching}
							clearSearch={clearSearch}
							required={false}
						/>
					</Stack>
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					{data?.data?.length >= 1 && !isLoading && !isError ? (
						<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={2} py={2} mb={2}>
							{isSearching
								? filteredRows?.map((user) => (
										<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} key={user.id} px={3}>
											<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={0.5}>
												<Typography variant={"h4"} color={"#164c68"}>
													{user?.user?.firstName || user?.firstName} {user?.user?.lastName || user?.lastName}
												</Typography>
											</Stack>

											<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} width={"100%"} spacing={0.5}>
												<CustomCheckbox toggle={() => handleToggle(user)} />
											</Stack>
										</Stack>
								  ))
								: rows?.map((user) => (
										<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} key={user.id} px={3}>
											<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={0.5}>
												<Typography variant={"h4"} color={"#164c68"}>
													{user?.user?.firstName || user?.firstName} {user?.user?.lastName || user?.lastName}
												</Typography>
											</Stack>

											<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} width={"100%"} spacing={0.5}>
												<CustomCheckbox toggle={() => handleToggle(user)} />
											</Stack>
										</Stack>
								  ))}
						</Stack>
					) : (
						<Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
							{/* <CircularProgress /> */}
							<Typography variant={"h5"} fontWeight={600} color={"#164c68"}>
								Loading...
							</Typography>
						</Stack>
					)}
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: "#fff" }}>
				<Button
					onClick={handleClose}
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						"&:hover": {
							bgcolor: "#00a3b1",
							color: "#fff",
						},
						transition: "all 1s ease",
					}}
				>
					{t("Done")}
				</Button>
				<Button
					onClick={() => {
						setRows(originalRows);
						handleClose();
					}}
					sx={{
						color: "red",
						mr: `${isRtl ? "8px" : "0px"} !important`,
						"&:hover": {
							color: "#fff",
							backgroundColor: "red",
						},
						transition: "all 1s ease",
					}}
				>
					{t("Close")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default UserSelectDialog;
