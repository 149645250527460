import { ListItemIcon, MenuItem, Typography } from "@mui/material";
import { CiEdit } from "react-icons/ci";
import { FaUserMinus } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import React, { useState } from "react";
import { Menu } from "./MenuCommon";
import ContactDialog from "../../Contacts/ContactDialog";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../../redux/slices/app";
import { useDeleteContactMutation } from "../../../redux/slices/api/contacts/contactApi";
import { useTranslation } from "react-i18next";

const ContactMenu = ({ open, anchorEl, handleClose, contact }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [mode, setMode] = useState(null);
	const [setting, setSetting] = useState(false);

	const userDetails = useSelector((state) => state.app.userDetails);
	const isRtl = useSelector((state) => state.app.isRtl);

	const [deleteContact] = useDeleteContactMutation();

	const handleDeleteContact = async (e) => {
		e.preventDefault();
		try {
			await deleteContact(contact.id);
			dispatch(setSnackbar({ open: true, message: t("ContactDeletedSuccessfully"), severity: "success" }));
			handleClose();
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
		}
	};

	return (
		<>
			<Menu
				id="contact-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "contact-menu-button",
				}}
			>
				<MenuItem
					onClick={() => {
						setSetting(true);
						setMode("view");
					}}
				>
					<ListItemIcon>
						<FaUser size={22} color={"#fff"} />
					</ListItemIcon>
					<Typography variant="h6" color={"#fff"}>
						{t("View")}
					</Typography>
				</MenuItem>
				{userDetails.role !== "Employee" && (
					<>
						<MenuItem
							onClick={() => {
								setSetting(true);
								setMode("editing");
							}}
						>
							<ListItemIcon>
								<CiEdit size={22} color={"#fff"} />
							</ListItemIcon>
							<Typography variant="h6" color={"#fff"}>
								{t("Edit")}
							</Typography>
						</MenuItem>
						<MenuItem onClick={handleDeleteContact}>
							<ListItemIcon>
								<FaUserMinus size={22} color={"#fff"} />
							</ListItemIcon>
							<Typography variant="h6" color={"#fff"}>
								{t("Delete")}
							</Typography>
						</MenuItem>
					</>
				)}
			</Menu>
			{setting && <ContactDialog open={setting} handleClose={() => setSetting(false)} mode={mode} contact={contact} />}
		</>
	);
};

export default ContactMenu;
