import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	userAccess: localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : null,
	isAuthorized: false,
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setUserAccess: (state, action) => {
			state.user = action.payload;
			localStorage.setItem("userInfo", JSON.stringify(action.payload));
		},
		setIsAuthorized: (state, action) => {
			state.isAuthorized = action.payload;
		},
		logout: (state, action) => {
			state.userInfo = null;
			localStorage.removeItem("userInfo");
		},
	},
});

export const { setUserAccess, setIsAuthorized, logout } = authSlice.actions;

export default authSlice.reducer;
