import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import SectionHeader from "../../../Common/SectionHeader";
import { IoMdAdd, IoMdAddCircleOutline } from "react-icons/io";
import { Box, Divider, Stack, Typography } from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Animate from "../../../Common/Animate";
import CustomBreadcrumbs from "../../../Common/Breadcrumbs";
import FirstSection from "./FirstSection";
import SecondSection from "./SecondSection";
import { LoadingButton } from "@mui/lab";
import CancellingDialog from "../../../Common/CancellingDialog";
import ThirdSection from "./ThirdSection";
import { useAddHearingMutation, useUpdateHearingMutation } from "../../../../redux/slices/api/hearing/hearingApi";
import { useNavigate } from "react-router-dom";
import { setSnackbar } from "../../../../redux/slices/app";
import { CiEdit } from "react-icons/ci";
import { FaSave } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import AddConstraintDialog from "../../CreateCaseFile/AddConstraintDialog";
import axiosInstance from "../../../../utils/axios";
import { setCurrentHearing } from "../../../../redux/slices/case";

const CreateHearing = () => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const currentCase = useSelector((state) => state.case.currentCase);
	const currentHearing = useSelector((state) => state.case.currentHearing);
	const isEditingHearing = useSelector((state) => state.case.isEditingHearing);

	const [hearingDate, setHearingDate] = useState(isEditingHearing ? moment(currentHearing.date) : moment().add(1, "hour"));
	const [invalidHearingDate, setInvalidHearingDate] = useState(false);
	const [invalidDateErrorText, setInvalidDateErrorText] = useState("");

	const [notes, setNotes] = useState(isEditingHearing ? currentHearing.note : "");
	const [requests, setRequests] = useState(isEditingHearing ? currentHearing.requests : "");
	const [decision, setDecision] = useState(isEditingHearing ? (currentHearing.decision ? currentHearing.decision.id : null) : null);
	const [decisionDetails, setDecisionDetails] = useState(isEditingHearing ? currentHearing.decisionDetails : null);
	const [additionalDecision, setAdditionalDecision] = useState(null);
	const [additionalDecisionDetails, setAdditionalDecisionDetails] = useState("");

	const [addHearingNote, setAddHearingNote] = useState(false);
	const [additionalDate, setAdditionalDate] = useState(moment().add(2, "day"));
	const [additionalHearingNotes, setAdditionalHearingNotes] = useState("");
	const [additionalHearingRequests, setAdditionalHearingRequests] = useState("");

	const [loading, setLoading] = useState(false);
	const [cancelling, setCancelling] = useState(false);
	const [invalidAdditionalDate, setInvalidAdditionalDate] = useState("");
	const [invalidAdditionalDateError, setInvalidAdditionalDateError] = useState("");

	const [addConstraint, setAddConstraint] = useState(false);
	const [constraintReference, setConstraintReference] = useState(null);

	const [createHearing] = useAddHearingMutation();
	const [updateHearing] = useUpdateHearingMutation();

	const handleCreateHearing = async (e) => {
		e.preventDefault();
		setLoading(true);
		if (!hearingDate) {
			setInvalidHearingDate(true);
			setInvalidDateErrorText(t("SelectHearingDate"));
			setLoading(false);
			return;
		}
		if (invalidHearingDate) {
			setLoading(false);
			return;
		}

		if (decision && !decisionDetails) {
			dispatch(setSnackbar({ open: true, message: t("ProvideDecisionDetails"), status: "error" }));
			setLoading(false);
			return;
		}

		if (addHearingNote) {
			if (!additionalDate) {
				setInvalidAdditionalDate(true);
				setInvalidAdditionalDateError(t("SelectHearingDate"));
				setLoading(false);
				return;
			}

			if (additionalDate.diff(hearingDate, "days") < 0) {
				setInvalidAdditionalDate(true);
				setInvalidAdditionalDateError(t("AdditionalDateBeforeDialog"));
				setLoading(false);
				return;
			}

			if (invalidAdditionalDate) {
				setLoading(false);
				return;
			}
		}

		let formData = {
			decision: decision,
			decisionDetails: decisionDetails,
		};

		if (!isEditingHearing) {
			try {
				formData = { ...formData, date: hearingDate, caseFile: currentCase.id, note: notes, requests: requests };
				for (const key in formData) {
					if (formData[key] === null || formData[key] === "") {
						delete formData[key];
					}
				}

				const res = await createHearing(formData).unwrap();
				const newHearingData = await axiosInstance.get(`/hearing/${res.id || res._id}`);
				dispatch(setCurrentHearing(newHearingData.data.data));

				if (addHearingNote) {
					if (!additionalHearingNotes && additionalHearingNotes === "") {
						dispatch(setSnackbar({ open: true, message: t("ProvideAdditionalHearingNotes"), severity: "error" }));
						setLoading(false);
						return;
					}

					if (!additionalHearingRequests && additionalHearingRequests === "") {
						dispatch(setSnackbar({ open: true, message: t("ProvideAdditionalHearingRequests"), severity: "error" }));
						setLoading(false);
						return;
					}

					let newFormData = {
						date: additionalDate,
						caseFile: currentCase.id,
						note: additionalHearingNotes,
						requests: additionalHearingRequests,
					};

					if (additionalDecision) {
						newFormData = { ...newFormData, decision: additionalDecision };
					}
					if (additionalDecisionDetails) {
						newFormData = { ...newFormData, decisionDetails: additionalDecisionDetails };
					}

					try {
						const res = await createHearing(newFormData).unwrap();
						const newHearingData = await axiosInstance.get(`/hearing/${res.id || res._id}`);
						dispatch(setCurrentHearing(newHearingData.data.data));
					} catch (error) {
						dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
						setLoading(false);
					}
				}

				dispatch(setSnackbar({ open: true, message: t("HearingCreatedSuccessfully"), severity: "success" }));
				setLoading(false);
				navigate("/dashboard/case/hearing-details");
			} catch (error) {
				dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
				setLoading(false);
			}
		} else {
			try {
				formData = { ...formData, date: hearingDate, note: notes, requests: requests };
				for (const key in formData) {
					if (formData[key] === null || formData[key] === "") {
						delete formData[key];
					}
				}
				if (addHearingNote) {
					if (!additionalHearingNotes && additionalHearingNotes === "") {
						dispatch(setSnackbar({ open: true, message: t("ProvideAdditionalHearingNotes"), severity: "error" }));
						setLoading(false);
						return;
					}

					if (!additionalHearingRequests && additionalHearingRequests === "") {
						dispatch(setSnackbar({ open: true, message: t("ProvideAdditionalHearingRequests"), severity: "error" }));
						setLoading(false);
						return;
					}

					let newFormData = {
						date: additionalDate,
						caseFile: currentCase.id,
						note: additionalHearingNotes,
						requests: additionalHearingRequests,
					};

					if (additionalDecision) {
						newFormData = { ...newFormData, decision: additionalDecision };
					}
					if (additionalDecisionDetails) {
						newFormData = { ...newFormData, decisionDetails: additionalDecisionDetails };
					}

					try {
						const res = await createHearing(newFormData).unwrap();
						const newHearingData = await axiosInstance.get(`/hearing/${currentHearing.id}`);
						dispatch(setCurrentHearing(newHearingData.data.data));
					} catch (error) {
						dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
						setLoading(false);
					}
				}
				await updateHearing({ hearingId: currentHearing.id, data: formData }).unwrap();
				const newHearingData = await axiosInstance.get(`/hearing/${currentHearing.id}`);
				dispatch(setCurrentHearing(newHearingData.data.data));
				dispatch(setSnackbar({ open: true, message: t("HearingUpdatedSuccessfully"), severity: "success" }));
				setLoading(false);
				navigate("/dashboard/case/hearing-details");
			} catch (error) {
				dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
				setLoading(false);
			}
		}
	};

	return (
		<Animate type="fade" delay={0.5}>
			<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={2} px={6} py={4}>
				<CustomBreadcrumbs section={t("Cases")} />
				<Typography variant={"h2"} fontWeight={600} color={"#164c68"}>
					{t("CreateHearing")}
				</Typography>
			</Stack>
			<Box
				width={"100%"}
				height={"max-content"}
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
				gap={4}
				sx={{ overflowY: "auto", overflowX: "hidden" }}
				p={4}
				mb={15}
			>
				<Box
					width={isSmallScreen ? "100%" : "90%"}
					height={"auto"}
					p={2}
					borderRadius={1.5}
					bgcolor={"#fff"}
					sx={{
						boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;",
					}}
				>
					<SectionHeader
						icon={isEditingHearing ? <CiEdit color="#164c68" size={18} /> : <IoMdAdd color="#164c68" size={18} />}
						title={isEditingHearing ? t("EditingHearing") : t("CreateHearing")}
						type={"dialog"}
					/>
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<Stack direction={isSmallScreen ? "column" : "row"} mt={2} spacing={1.5} height={"100%"} width={"100%"}>
						<FirstSection
							isSmallScreen={isSmallScreen}
							hearingDate={hearingDate}
							setHearingDate={setHearingDate}
							invalidHearingDate={invalidHearingDate}
							setInvalidHearingDate={setInvalidHearingDate}
							invalidDateErrorText={invalidDateErrorText}
							setInvalidDateErrorText={setInvalidDateErrorText}
							notes={notes}
							setNotes={setNotes}
							requests={requests}
							setRequests={setRequests}
						/>
						<SecondSection
							isSmallScreen={isSmallScreen}
							decision={decision}
							setDecision={setDecision}
							decisionDetails={decisionDetails}
							setDecisionDetails={setDecisionDetails}
							setAddConstraint={setAddConstraint}
							setConstraintReference={setConstraintReference}
						/>
						{!addHearingNote ? (
							<Box
								width={"100%"}
								height={"100%"}
								minHeight={"300px"}
								display={"flex"}
								justifyContent={"center"}
								alignItems={"center"}
								bgcolor={"#fff"}
							>
								<Button
									variant="contained"
									loadingPosition="start"
									startIcon={!isRtl && <IoMdAddCircleOutline />}
									size="large"
									sx={{
										bgcolor: "#164c68",
										color: "#fff",
									}}
									onClick={() => setAddHearingNote(true)}
								>
									{t("NextHearing")}
								</Button>
							</Box>
						) : (
							<Animate type="fade" delay={0.5}>
								<ThirdSection
									hearingDate={hearingDate}
									date={additionalDate}
									setDate={setAdditionalDate}
									invalidHearingDate={invalidAdditionalDate}
									setInvalidHearingDate={setInvalidAdditionalDate}
									invalidDateErrorText={invalidAdditionalDateError}
									setInvalidDateErrorText={setInvalidAdditionalDateError}
									notes={additionalHearingNotes}
									setNotes={setAdditionalHearingNotes}
									requests={additionalHearingRequests}
									setRequests={setAdditionalHearingRequests}
									additionalDecision={additionalDecision}
									setAdditionalDecision={setAdditionalDecision}
									setAddConstraint={setAddConstraint}
									setConstraintReference={setConstraintReference}
									additionalDecisionDetails={additionalDecisionDetails}
									setAdditionalDecisionDetails={setAdditionalDecisionDetails}
								/>
							</Animate>
						)}
					</Stack>
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1}>
						<LoadingButton
							loading={loading}
							variant="contained"
							loadingPosition="start"
							startIcon={!isRtl && <FaSave />}
							size="medium"
							sx={{
								bgcolor: "#164c68",
								color: "#fff",
							}}
							onClick={handleCreateHearing}
						>
							{t("Save")}
						</LoadingButton>
						<Button
							onClick={() => setCancelling(true)}
							disabled={loading}
							sx={{
								color: "#fff",
								backgroundColor: "#fc6739",
								marginRight: `${isRtl ? "8px" : "0px"} !important`,
								"&:hover": {
									color: "#fff",
									backgroundColor: "red",
								},
								transition: "all 1s ease",
							}}
						>
							{t("Cancel")}
						</Button>
					</Stack>
				</Box>
			</Box>
			{cancelling && <CancellingDialog open={cancelling} handleClose={() => setCancelling(false)} path={"hearing"} />}
			{addConstraint && (
				<AddConstraintDialog
					open={addConstraint}
					handleClose={() => setAddConstraint(false)}
					reference={constraintReference}
					setDecision={setDecision}
				/>
			)}
		</Animate>
	);
};

export default CreateHearing;
