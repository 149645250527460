import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Slide, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { useSetTableViewMutation } from "../../redux/slices/api/tableView/tableViewApi";
import { setSnackbar } from "../../redux/slices/app";
import SectionHeader from "./SectionHeader";
import { FaSave } from "react-icons/fa";
import CustomTextField from "./CustomTextField";
import CustomCheckbox from "./CustomCheckBox";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const SaveTableViewDialog = ({
	open,
	handleClose,
	allColumns,
	visibleColumns,
	setColumnVisibility,
	columnOrder,
	setColumnOrder,
	tableType,
	setIsLoading,
	setSelectedTemplate,
	setDefaultView,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const isRtl = useSelector((state) => state.app.isRtl);
	const [loading, setLoading] = useState(false);
	const [templateName, setTemplateName] = useState("");
	const [isDefault, setIsDefault] = useState(false);

	const [SaveTableView] = useSetTableViewMutation();

	const handleSetTableView = async (allColumns, visibleColumns) => {
		setIsLoading(true);
		const defaultVisibleColumns = allColumns.reduce((acc, key) => {
			acc[key] = visibleColumns.includes(key);
			return acc;
		}, {});
		const templateToSave = {
			name: templateName,
			type: tableType,
			column: JSON.stringify(defaultVisibleColumns),
			columnOrder: JSON.stringify(columnOrder),
			default: isDefault,
		};

		try {
			await SaveTableView(templateToSave).unwrap();
			dispatch(setSnackbar({ open: true, message: t("TableViewSaved"), severity: "success" }));
			setSelectedTemplate(templateToSave);
			if (isDefault) {
				setDefaultView(templateToSave);
			}
			setColumnVisibility(defaultVisibleColumns);
			setColumnOrder(columnOrder);
			setIsLoading(false);
			handleClose();
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setIsLoading(false);
		}
	};

	return (
		<Dialog open={open} fullWidth TransitionComponent={Transition} keepMounted aria-describedby="add-user-dialog" sx={{ height: "100%" }}>
			<DialogTitle sx={{ pb: 2, borderBottom: "1px solid #aaaaaa6b", backgroundColor: "#fff" }}>
				<SectionHeader icon={<FaSave size={18} color={"#164c68"} />} title={`${t("SaveAsTemplate")}?`} type={"dialog"} />
			</DialogTitle>
			<DialogContent sx={{ backgroundColor: "#fff" }}>
				<DialogContentText id="cancelling-dialog-description" sx={{ p: 4 }}>
					<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={2} width={"100%"} sx={{ mb: 2 }}>
						<CustomTextField
							type={"text"}
							label={`${t("TableViewName")}: `}
							value={templateName}
							resetValue={() => setTemplateName("")}
							name={"firstName"}
							handleInputChange={(e) => setTemplateName(e.target.value)}
							error={templateName.length === 0 || !templateName}
							errorText={t("TemplateNameRequired")}
							required={true}
						/>
						<CustomCheckbox
							desc={`${t("MakeDefault")}?`}
							checked={isDefault}
							toggle={() => {
								setIsDefault(!isDefault);
							}}
						/>
					</Stack>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: "#fff" }}>
				<Button
					onClick={handleClose}
					disabled={loading}
					sx={{
						color: "#fff",
						backgroundColor: "#fc6739",
						"&:hover": {
							color: "#fff",
							backgroundColor: "red",
						},
						transition: "all 1s ease",
					}}
				>
					{t("No")}
				</Button>
				<LoadingButton
					loading={loading}
					variant="contained"
					size="medium"
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
					}}
					onClick={() => {
						const allTableColumns = allColumns?.map((column) => column.id);
						const allVisibleColumns = visibleColumns?.map((column) => column.id);
						handleSetTableView(allTableColumns, allVisibleColumns);
					}}
				>
					{t("Save")}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default SaveTableViewDialog;
