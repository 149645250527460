import React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useTranslation } from "react-i18next";

const CustomDateTimePicker = ({ title, value, setDate }) => {
	const { t } = useTranslation();
	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<DemoContainer components={["DateTimePicker"]}>
				<DateTimePicker
					label={`${t(title)}`}
					inputFormat="DD/MM/YYYY hh:mm a"
					format="DD/MM/YYYY hh:mm a"
					showTodayButton
					sx={{
						".MuiFormLabel-root": {
							color: "black", 
						},
						".MuiInputBase-root": {
							color: "#164c68",
							backgroundColor: "#fff",
						},
						".MuiIconButton-root": {
							color: "#164c68",
						},
						"& .MuiOutlinedInput-root": {
							"& fieldset": {
								borderColor: "#00a3b1",
								border: "1px solid",
								width: "100%",
							},
							"&:hover fieldset": {
								borderColor: "#00a3b1",
							},
							"&.Mui-focused fieldset": {
								borderColor: "#00a3b1",
							},
							"& .MuiInputBase-input": {
								color: "#575757",
							},
						},
					}}
					value={value}
					onChange={(newValue) => {
						setDate(newValue);
					}}
				/>
			</DemoContainer>
		</LocalizationProvider>
	);
};

export default CustomDateTimePicker;
