import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Button, CircularProgress, Divider, Grid, Stack, Typography } from "@mui/material";
import { CgTemplate } from "react-icons/cg";
import SectionHeader from "../../../Common/SectionHeader";
import { useNavigate } from "react-router-dom";
import { images } from "../../../Assets";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useGetByCriteriaEmailTemplatesQuery } from "../../../../redux/slices/api/email/templateApi";
import TemplateCard from "./TemplateCard";

const EmailTemplates = ({ createEmail, setCreateEmail }) => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const navigate = useNavigate();

	const [emailTemplates, setEmailTemplates] = useState([]);

	const getEmailTemplates = useGetByCriteriaEmailTemplatesQuery({}, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (getEmailTemplates.isError) {
			console.log(getEmailTemplates.error);
			return;
		}
		if (getEmailTemplates.data) {
			setEmailTemplates(getEmailTemplates.data?.data);
		}
	}, [getEmailTemplates]);

	return (
		<Box
			width={isSmallScreen ? "100%" : "65%"}
			height={"auto"}
			p={2}
			borderRadius={1.5}
			sx={{
				boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
			}}
			bgcolor={"#fff"}
		>
			<SectionHeader icon={<CgTemplate color="#164c68" size={18} />} title={t("EmailTemplate")} type={"dialog"} />
			<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
			<Box
				width={"100%"}
				height={"100%"}
				maxHeight={"800px"}
				overflow={"auto"}
				display={"flex"}
				flexDirection={"column"}
				justifyContent={"center"}
				alignItems={"center"}
				p={1}
			>
				{getEmailTemplates.isLoading && !getEmailTemplates.isError ? (
					<CircularProgress />
				) : emailTemplates.length >= 1 ? (
					<Grid container spacing={2}>
						{emailTemplates.map((template) => (
							<Grid item xs={12} sm={4} key={template.id}>
								<TemplateCard template={template} createEmail={createEmail} setCreateEmail={setCreateEmail} section={"email"} />
							</Grid>
						))}
					</Grid>
				) : (
					<>
						<img src={images.notFound} alt="Not Found" width={100} height={100} />
						<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ my: 1 }}>
							{t("NoEmailTemplate")}!
						</Typography>
						<Typography variant={"subtitle1"} fontWeight={500} color={"#51505099"}>
							{t("AddEmailTemplate")}!
						</Typography>
					</>
				)}
			</Box>
			<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
			<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} width={"100%"}>
				<Button
					variant="contained"
					startIcon={!isRtl && <CgTemplate />}
					size="medium"
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
					}}
					onClick={() => navigate("/dashboard/email/template")}
				>
					{t("Create")}
				</Button>
			</Stack>
		</Box>
	);
};

export default EmailTemplates;
