import { Box, Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { images } from "../../../Assets/index";
import CustomTextField from "../../../Common/CustomTextField";
import CustomDatePicker from "../../../Common/CustomDatePicker";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CustomConstraintSelect from "../../../Common/CustomConstraintSelect";
import { IoAddCircle } from "react-icons/io5";

const ThirdSection = ({
	hearingDate,
	date,
	setDate,
	invalidHearingDate,
	setInvalidHearingDate,
	invalidDateErrorText,
	setInvalidDateErrorText,
	notes,
	setNotes,
	requests,
	setRequests,
	additionalDecision,
	setAdditionalDecision,
	setAddConstraint,
	setConstraintReference,
	additionalDecisionDetails,
	setAdditionalDecisionDetails,
}) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);
	return (
		<Box width={"100%"} height={"100%"} minHeight={"300px"} p={2} bgcolor={"#fff"}>
			<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
				<img src={images.notes} alt="notes" width={25} height={25} style={{ marginLeft: `${isRtl ? "8px" : "0px"}` }} />
				<Typography variant="h4" color="#164c68" fontWeight="500">
					{t("AddHearingNotes")}:
				</Typography>
			</Stack>
			<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
			<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={2} mb={2}>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2}>
					<Typography variant="h5" color="#164c68" fontWeight="500" sx={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }}>
						{t("HearingDate")}:
					</Typography>
					<CustomDatePicker
						title={t("Hearing")}
						setInvalidDate={setInvalidHearingDate}
						setErrorText={setInvalidDateErrorText}
						value={date}
						setDate={setDate}
					/>
					{invalidHearingDate && (
						<Typography variant={"title1"} color={"red"} fontWeight={500}>
							{invalidDateErrorText}
						</Typography>
					)}
				</Stack>
			</Stack>

			<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={5} width={"100%"}>
				<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={1} width={"100%"}>
					<Typography variant="h5" color="#164c68" fontWeight="500">
						{t("HearingNotes")}:
					</Typography>
					<CustomTextField
						type={"text"}
						value={notes}
						resetValue={() => setNotes("")}
						name={"notes"}
						handleInputChange={(e) => {
							setNotes(e.target.value);
						}}
						required={true}
						multiline={true}
					/>
				</Stack>
				<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={1} width={"100%"}>
					<Typography variant="h5" color="#164c68" fontWeight="500">
						{t("HearingRequests")}:
					</Typography>
					<CustomTextField
						type={"text"}
						value={requests}
						resetValue={() => setRequests("")}
						name={"requests"}
						handleInputChange={(e) => {
							setRequests(e.target.value);
						}}
						required={true}
						multiline={true}
					/>
				</Stack>
				<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={2} mb={2} width={"100%"}>
					<Typography variant="h5" color="#164c68" fontWeight="500">
						{t("ChooseDecision")}:
					</Typography>
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
						<CustomConstraintSelect reference={"decision"} dataSet={additionalDecision} setData={setAdditionalDecision} />
						<Tooltip title={`${t("Add")}  ${t("Decision")}`}>
							<IconButton
								onClick={() => {
									setAddConstraint(true);
									setConstraintReference("decision");
								}}
							>
								<IoAddCircle color={"#164c68"} size={25} />
							</IconButton>
						</Tooltip>
					</Stack>
					<Typography variant="h5" color="#164c68" fontWeight="500">
						{t("DecisionDetails")}:
					</Typography>
					<CustomTextField
						type={"text"}
						value={additionalDecisionDetails}
						resetValue={() => setAdditionalDecisionDetails("")}
						name={"decisionDetails"}
						handleInputChange={(e) => {
							setAdditionalDecisionDetails(e.target.value);
						}}
						required={true}
						multiline={true}
					/>
				</Stack>
			</Stack>
		</Box>
	);
};

export default ThirdSection;
