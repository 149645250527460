import { Box } from "@mui/material";
import React, { useEffect } from "react";

const ScrollableContainer = ({ children }) => {
	useEffect(() => {
		const scrollableDiv = document.getElementById("table-container");

		let isDown = false;
		let startX;
		let scrollLeft;

		const handleMouseDown = (e) => {
			isDown = true;
			scrollableDiv.style.cursor = "grabbing";
			startX = e.pageX - scrollableDiv.offsetLeft;
			scrollLeft = scrollableDiv.scrollLeft;
		};

		const handleMouseLeave = () => {
			isDown = false;
			scrollableDiv.style.cursor = "grab";
		};

		const handleMouseUp = () => {
			isDown = false;
			scrollableDiv.style.cursor = "grab";
		};

		const handleMouseMove = (e) => {
			if (!isDown) return;
			e.preventDefault();
			const x = e.pageX - scrollableDiv.offsetLeft;
			const walk = x - startX;
			scrollableDiv.scrollLeft = scrollLeft - walk;
		};

		scrollableDiv.addEventListener("mousedown", handleMouseDown);
		scrollableDiv.addEventListener("mouseleave", handleMouseLeave);
		scrollableDiv.addEventListener("mouseup", handleMouseUp);
		scrollableDiv.addEventListener("mousemove", handleMouseMove);

		return () => {
			scrollableDiv.removeEventListener("mousedown", handleMouseDown);
			scrollableDiv.removeEventListener("mouseleave", handleMouseLeave);
			scrollableDiv.removeEventListener("mouseup", handleMouseUp);
			scrollableDiv.removeEventListener("mousemove", handleMouseMove);
		};
	}, []);

	return (
		<Box
			id="table-container"
			sx={{
				width: "100%",
				height: "100%",
				overflow: "auto",
				boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
				cursor: "grab",
			}}
		>
			{children}
		</Box>
	);
};

export default ScrollableContainer;
