import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import SectionHeader from "../../../Common/SectionHeader";
import { FaRegFileImage, FaRegFileVideo } from "react-icons/fa";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { fetchMedia } from "../../../Common/utils";
import ReactPlayer from "react-player/lazy";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const FilePreview = ({ open, handleClose, file, fileType }) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [isPlaying, setIsPlaying] = useState(true);
	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			const data = await fetchMedia(file.id);
			if (data?.dataUrl) {
				setSource(data?.dataUrl);
				setIsLoading(false);
			}
		};

		fetchData();
	}, [file]);

	const [source, setSource] = useState(null);

	const videoRef = useRef(null);
	const closeDialog = () => {
		if (fileType === "mp4" || fileType === "mpeg") {
			setIsPlaying(false);
		}
		handleClose();
	};

	return (
		<Dialog
			open={open}
			onClose={closeDialog}
			fullWidth
			TransitionComponent={Transition}
			keepMounted
			aria-describedby="add-user-dialog"
			sx={{ height: "100%" }}
		>
			<DialogTitle sx={{ pb: 2, borderBottom: "1px solid #aaaaaa6b", backgroundColor: "#fff" }}>
				<SectionHeader
					icon={
						fileType === "png" || fileType === "jpeg" || fileType === "jpg" || fileType === "mp4" || fileType === "mpeg" ? (
							<FaRegFileImage color="#164c68" size={18} />
						) : (
							<FaRegFileVideo color="#164c68" size={18} />
						)
					}
					title={t("FilePreview")}
					type={"dialog"}
				/>
			</DialogTitle>
			<DialogContent sx={{ backgroundColor: "#fff" }}>
				<DialogContentText id="add-user-dialog-description" sx={{ p: 4 }}></DialogContentText>
				<Stack width={"100%"} height={"100%"} justifyContent={"center"} alignItems={"center"} spacing={2}>
					{isLoading ? (
						<CircularProgress color="primary" />
					) : (file && fileType === "png") || fileType === "jpeg" || fileType === "jpg" ? (
						<img src={source} alt="file" width={"50%"} height={"50%"} style={{ objectFit: "contain" }} />
					) : fileType === "mp4" || fileType === "mpeg" ? (
						<ReactPlayer ref={videoRef} url={source} width="100%" height="500px" controls={true} loop playing={isPlaying} />
					) : null}
					<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ mb: 2 }}>
						{t("FileName")}: {file.originalname}
					</Typography>
					<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ mb: 2 }}>
						{t("FileSize")}: {file.size / 1000} KB
					</Typography>
				</Stack>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: "#fff" }}>
				<Button
					onClick={closeDialog}
					sx={{
						color: "red",
						"&:hover": {
							color: "#fff",
							backgroundColor: "red",
						},
						transition: "all 1s ease",
					}}
				>
					{t("Close")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default FilePreview;
