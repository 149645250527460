import { Box, CircularProgress, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import StatsList from "../../../components/Dashboard/SecondSection/StatsList";
import SectionHeader from "../../../components/Common/SectionHeader";
import { ImStatsDots } from "react-icons/im";
import { useGetCasesQuery } from "../../../redux/slices/api/cases/caseApi";
import CaseLineChart from "../../../components/Common/Charts/LineChart";
import { useTranslation } from "react-i18next";

const SecondSection = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	const [caseData, setCaseData] = useState([]);
	const [totalCases, setTotalCases] = useState(0);

	const { data, isLoading, isError } = useGetCasesQuery({}, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (isError) return;

		if (data) {
			setTotalCases(data?.totalRecords);
			setCaseData(data?.data);
		}
	}, [data]);

	return (
		<Box
			sx={{ width: "100%", height: "auto", boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;" }}
			p={2}
			borderRadius={1.5}
			bgcolor={"#fff"}
		>
			<SectionHeader icon={<ImStatsDots color="#164c68" size={18} />} title={t("CaseStatistics")} />
			<Stack direction={isSmallScreen ? "column" : "row"} justifyContent={"center"} alignItems={"flex-start"}>
				<Box width={isSmallScreen ? "100%" : "70%"} height={"500px"} display={"flex"} justifyContent={"center"} alignItems={"center"} p={2}>
					{isLoading ? <CircularProgress /> : <CaseLineChart page={"dashboard"} cases={caseData} isSmallScreen={isSmallScreen} />}
				</Box>
				<Box width={isSmallScreen ? "100%" : "30%"} height={"100%"} p={2}>
					<StatsList cases={totalCases} />
				</Box>
			</Stack>
		</Box>
	);
};

export default SecondSection;
