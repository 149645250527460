import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetConstraintsQuery } from "../../redux/slices/api/constraints/constraintsApi";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";

const CustomConstraintSelect = ({ reference, dataSet, setData, classification }) => {
	const isRtl = useSelector((state) => state.app.isRtl);
	const [constraintValue, setConstraintValue] = useState(null);
	const [constraintName, setConstraintName] = useState("");

	const { data, isLoading, isError } = useGetConstraintsQuery(
		{ reference },
		{ refetchOnMountOrArgChange: true, refetchOnReconnect: true, refetchOnFocus: true }
	);

	const [constraints, setConstraints] = useState([]);

	useEffect(() => {
		if (isError) {
			// dispatch(setSnackbar({ open: true, message: "Error fetching data", severity: "error" }));
			return;
		} else if (data && !isError && !isLoading) {
			const filteredData = data?.data
				.filter((constraint) => constraint.status === true)
				.map((constraint) => {
					return {
						id: constraint.id,
						constraintName: isRtl ? constraint.nameAr : constraint.nameEn ? constraint.nameEn : constraint.nameAr,
					};
				});
			setConstraints(filteredData);
			if (dataSet) {
				const foundConstraint = filteredData.find((item) => item.id === dataSet);
				setConstraintValue(foundConstraint);
			}
		}
	}, [data, reference]);

	useEffect(() => {
		if (dataSet) {
			const foundConstraint = constraints.find((item) => item.id === dataSet);
			setConstraintValue(foundConstraint);
		} else {
			setConstraintValue(null);
		}
	}, [dataSet, constraints]);

	return (
		<Autocomplete
			id="constraint-select"
			fullWidth={reference === "caseRole"}
			clearOnEscape
			options={constraints}
			value={constraintValue}
			inputValue={constraintName}
			autoHighlight
			size="small"
			autoComplete
			disabled={classification === "consultation" && ["caseStage", "caseType", "court"].includes(reference)}
			onChange={(event, value) => {
				setData(value?.id);
			}}
			onInputChange={(event, value) => {
				setConstraintName(value);
			}}
			getOptionLabel={(option) => option?.constraintName}
			renderOption={(props, option) => {
				const returnValue = option?.constraintName;
				return (
					<Box width={"100%"} component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
						<Typography variant="h6" sx={{ color: "#fff", ml: 1 }}>
							{returnValue}
						</Typography>
					</Box>
				);
			}}
			renderInput={(params) => (
				<TextField
					size="small"
					{...params}
					autoComplete="off"
					inputProps={{
						...params.inputProps,
						autoComplete: "off",
					}}
					sx={{ p: 0, color: "#164c68", width: "100%" }}
				/>
			)}
		/>
	);
};

export default CustomConstraintSelect;
