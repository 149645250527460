import React, { useState } from "react";
import { FormControl, IconButton, InputLabel, MenuItem, Select, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { FaCheck } from "react-icons/fa6";

const PositionSelect = ({ user, rows, setRows }) => {
	const { t } = useTranslation();

	const [userPosition, setUserPosition] = useState(user.position);
	const [isEditingPosition, setIsEditingPosition] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleUpdatePosition = () => {
		setIsLoading(true);

		const updatedUser = { ...user, position: userPosition };

		// Update the rows
		const updatedRows = rows.map((u) => {
			if (u.id === user.id) {
				return updatedUser;
			}
			return u;
		});

		setRows(updatedRows);
		setIsEditingPosition(false);
		setIsLoading(false);
	};

	return (
		<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={0.5}>
			<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={0.5}>
					{!isEditingPosition ? (
						<>
							<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mx: `8px  !important` }}>
								{t("Position")}: {userPosition ? userPosition : "N/A"}
							</Typography>
						</>
					) : (
						<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
							<InputLabel id="role-select-small-label">{t("Position")}</InputLabel>
							<Select
								labelId="position-select-small-label"
								id="position-select-small"
								value={userPosition}
								label="Position"
								onChange={(e) => setUserPosition(e.target.value)}
							>
								<MenuItem value={"Admin"}>{t("Admin")}</MenuItem>
								<MenuItem value={"Expert"}>{t("Expert")}</MenuItem>
								<MenuItem value={"Expert Assistant"}>{t("ExpertAssistant")}</MenuItem>
								<MenuItem value={"Auditor"}>{t("Auditor")}</MenuItem>
								<MenuItem value={"Translator"}>{t("Translator")}</MenuItem>
								<MenuItem value={"Accountant"}>{t("Accountant")}</MenuItem>
								<MenuItem value={"Secretary"}>{t("Secretary")}</MenuItem>
								<MenuItem value={"Administrative"}>{t("Administrative")}</MenuItem>
							</Select>
						</FormControl>
					)}
				</Stack>
				{!isEditingPosition ? (
					<Tooltip title={"Update Position"} arrow placement={"top"}>
						<IconButton
							disableRipple
							onClick={() => {
								setIsEditingPosition(true);
							}}
							sx={{ p: 0 }}
						>
							<CiEdit color="#164c68" size={22} />
						</IconButton>
					</Tooltip>
				) : (
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
						<Tooltip title={"Save"} arrow placement={"top"}>
							<IconButton disableRipple disabled={isLoading} onClick={handleUpdatePosition} sx={{ p: 0 }}>
								<FaCheck color="#2abe5d" size={22} />
							</IconButton>
						</Tooltip>
						<Tooltip title={"Cancel"} arrow placement={"top"}>
							<IconButton
								disableRipple
								disabled={isLoading}
								onClick={() => {
									setIsEditingPosition(false);
								}}
								sx={{ p: 0 }}
							>
								<FaTimes color="#164c68" size={22} />
							</IconButton>
						</Tooltip>
					</Stack>
				)}
			</Stack>
		</Stack>
	);
};

export default PositionSelect;
