import React, { useEffect, useState } from "react";
import Animate from "../../components/Common/Animate";
import { Box, Stack, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../components/Common/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { images } from "../../components/Assets";
import { useGetEmailsQuery } from "../../redux/slices/api/email/emailMessageApi";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../redux/slices/app";
import EmailDetails from "../../components/Emails/EmailDetails";
import EmailList from "../../components/Emails/EmailList";

const Emails = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [selectedEmail, setSelectedEmail] = useState(null);
	const [emails, setEmails] = useState([]);

	const { data, error, isError } = useGetEmailsQuery({}, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (isError) {
			console.log(error);
			dispatch(setSnackbar({ open: true, message: error.message, severity: "error" }));
			return;
		}
		if (data) {
			setEmails(data.data);
		}
	}, [data, emails]);

	return (
		<Animate type="fade" delay={0.5}>
			<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={2} px={6} pt={4}>
				<CustomBreadcrumbs section={t("Emails")} />
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<img src={images.email} alt={"email"} width={40} height={40} />
					<Typography variant={"h2"} fontWeight={600} color={"#164c68"}>
						{t("MyEmails")}
					</Typography>
				</Stack>
			</Stack>

			<Box
				width={"100%"}
				height={"max-content"}
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
				gap={4}
				px={20}
				py={3}
			>
				<Box
					width={"100%"}
					height={"100%"}
					minHeight={"700px"}
					maxHeight={"700px"}
					overflow={"auto"}
					borderRadius={selectedEmail ? 4 : `16px 0px 0px 16px`}
					bgcolor={"#fff"}
					sx={{
						boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;",
					}}
				>
					{selectedEmail ? (
						<EmailDetails email={selectedEmail} setSelectedEmail={setSelectedEmail} />
					) : (
						<Box
							display={"flex"}
							flexDirection={"column"}
							justifyContent={"flex-start"}
							alignItems={"flex-start"}
							width={"100%"}
							height={"100%"}
							overflow={"auto"}
						>
							{emails.map((email) => (
								<EmailList key={email.id} email={email} setSelectedEmail={setSelectedEmail} />
							))}
						</Box>
					)}
				</Box>
			</Box>
		</Animate>
	);
};

export default Emails;
