import { queryBuilder } from "../../../../utils/queryBuilder";
import { apiSlice } from "../apiSlice";

export const userApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getMine: builder.query({
			query: () => ({
				url: "/user/mine",
				method: "GET",
			}),
			providesTags: ["User"],
		}),
		getAllUsers: builder.query({
			query: () => ({
				url: "/user?total=true",
				method: "GET",
			}),
			providesTags: ["Users"],
		}),
		updateMine: builder.mutation({
			query: (credentials) => ({
				url: "/user/mine",
				method: "PATCH",
				body: credentials,
			}),
			invalidatesTags: ["User"],
		}),
		updateMyAvatar: builder.mutation({
			query: (data) => ({
				url: "/user/mine/file",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["User"],
		}),
		addUser: builder.mutation({
			query: (data) => ({
				url: "/user",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["Users"],
		}),
		activateUser: builder.mutation({
			query: (userId) => ({
				url: `/user/${userId}/activate`,
				method: "POST",
			}),
			invalidatesTags: ["Users"],
		}),
		deactivateUser: builder.mutation({
			query: (userId) => ({
				url: `/user/${userId}/deactivate`,
				method: "POST",
			}),
			invalidatesTags: ["Users"],
		}),
		updateUser: builder.mutation({
			query: ({ userId, data }) => ({
				url: `/user/${userId}`,
				method: "PATCH",
				body: data,
			}),
			invalidatesTags: ["Users"],
		}),
		deleteUser: builder.mutation({
			query: (userId) => ({
				url: `/user/${userId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Users"],
		}),
		updatePassword: builder.mutation({
			query: (credentials) => ({
				url: "/user/password",
				method: "PATCH",
				body: credentials,
			}),
			invalidatesTags: ["User"],
		}),
		updateUserPassword: builder.mutation({
			query: ({userId, credentials}) => ({
				url: `/user/${userId}/password`,
				method: "PATCH",
				body: credentials,
			}),
			invalidatesTags: ["Users"],
		}),
		getByUserId: builder.query({
			query: (userId) => ({
				url: `/user/${userId}`,
				method: "GET",
			}),
		}),
		getUserMetadata: builder.query({
			query: () => ({
				url: "/user/metadata",
				method: "GET",
			}),
		}),
		uploadUserFile: builder.mutation({
			query: ({ userId, data }) => ({
				url: `/user/${userId}/file`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["Users"],
		}),
		getByUserName: builder.query({
			query: (queryData) => ({
				url: `/user?${queryBuilder(queryData)}`,
				method: "GET",
			}),
		}),
	}),
});

export const {
	useGetMineQuery,
	useAddUserMutation,
	useActivateUserMutation,
	useDeactivateUserMutation,
	useUpdateUserMutation,
	useGetAllUsersQuery,
	useUpdateMineMutation,
	useDeleteUserMutation,
	useUpdatePasswordMutation,
	useUpdateUserPasswordMutation,
	useGetByUserIdQuery,
	useGetUserMetadataQuery,
	useUploadUserFileMutation,
	useGetByUserNameQuery,
	useUpdateMyAvatarMutation,
} = userApi;
