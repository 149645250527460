import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Animate from "../../Common/Animate";
import { Box, Stack, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../Common/Breadcrumbs";
import { images } from "../../Assets";
import SectionHeader from "../../Common/SectionHeader";
import { TbVariable } from "react-icons/tb";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ConstraintsList from "./ConstraintsList";

const ConstraintsSettings = () => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
	const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));

	return (
		<Animate type="fade" delay={0.5}>
			<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={2} px={6} py={4}>
				<CustomBreadcrumbs section={t("Constraints")} />
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<img src={images.variable} alt={"constraints"} width={35} height={35} />
					<Typography variant={"h2"} fontWeight={600} color={"#164c68"}>
						{t("ConstraintsSettings")}
					</Typography>
				</Stack>
			</Stack>

			<Box
				width={"100%"}
				height={"max-content"}
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
				gap={4}
				sx={{ overflowY: "auto", overflowX: "hidden" }}
				py={4}
				px={isSmallScreen ? 4 : 30}
				mb={2}
			>
				<Stack
					width={"100%"}
					height={"100%"}
					spacing={2}
					justifyContent={"flex-start"}
					alignItems={"start"}
					borderRadius={2}
					p={2}
					sx={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;", marginLeft: `${isRtl && 0} !important` }}
					bgcolor={"#fff"}
				>
					<SectionHeader icon={<TbVariable color="#164c68" size={18} />} title={t("Constraints")} />
					<ConstraintsList />
				</Stack>
			</Box>
		</Animate>
	);
};

export default ConstraintsSettings;
