import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Animate from "../../Common/Animate";
import { Box, Button, Divider, FormControl, MenuItem, Select, Stack, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../Common/Breadcrumbs";
import { FaBriefcase } from "react-icons/fa6";
import CaseInformation from "./CaseInformation";
import moment from "moment";
import CaseDetails from "./CaseDetails";
import Parties from "./Parties";
import AddPartyDialog from "./AddPartyDialog";
import Users from "./Users";
import UserSelect from "./UserSelect";
import CustomTextField from "../../Common/CustomTextField";
import CancellingDialog from "../../Common/CancellingDialog";
import { useCreateCaseFileMutation, useGetCaseFileMetadataQuery, useUpdateCaseFileMutation } from "../../../redux/slices/api/cases/caseApi";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../../redux/slices/app";
import { useNavigate } from "react-router-dom";
import CustomBackdrop from "../../Common/CustomBackdrop";
import { useTranslation } from "react-i18next";
import AddConstraintDialog from "./AddConstraintDialog";
import axiosInstance from "../../../utils/axios";
import { setCurrentCase, setIsViewingCase } from "../../../redux/slices/case";
import NewContact from "../../Contacts/NewContact";

const CreateCase = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const isRtl = useSelector((state) => state.app.isRtl);
	const currentCase = useSelector((state) => state.case.currentCase);
	const isEditingCase = useSelector((state) => state.case.isEditingCase);

	const { data, isLoading } = useGetCaseFileMetadataQuery({}, { refetchOnMountOrArgChange: true });
	const [classification, setClassification] = useState(isEditingCase && currentCase ? currentCase?.classification : "consultation");

	useEffect(() => {
		if (!isEditingCase && !currentCase && data) {
			const consultationFileNumber = data?.data.lastConsultationFile + 1;
			const litigationFileNumber = data?.data.lastLitigationFile + 1;
			setFileNumber(classification === "consultation" ? consultationFileNumber : litigationFileNumber);
		}
	}, [data, isLoading, classification]);

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	const [fileNumber, setFileNumber] = useState(
		isEditingCase ? currentCase?.fileNumber : classification === "consultation" ? data?.data.lastConsultationFile + 1 : data?.data.lastLitigationFile + 1
	);
	const [caseNumber, setCaseNumber] = useState(isEditingCase && currentCase ? currentCase?.caseNumber : 0);
	const [caseYear, setCaseYear] = useState(isEditingCase && currentCase ? currentCase?.caseYear : moment().format("YYYY"));
	const [subject, setSubject] = useState(isEditingCase && currentCase ? currentCase?.subject : "");
	const [note, setNote] = useState(isEditingCase && currentCase ? currentCase?.note : "");
	const [openDate, setOpenDate] = useState(isEditingCase && currentCase ? moment(currentCase?.openDate) : moment().add(1, "hour"));
	const [expertAssignmentDate, setExpertAssignmentDate] = useState(isEditingCase && currentCase ? moment(currentCase?.expertAssignmentDate) : moment());
	const [closeDate, setCloseDate] = useState(isEditingCase && currentCase ? moment(currentCase?.closeDate) : moment().add(1, "day"));
	const [fileType, setFileType] = useState(isEditingCase && currentCase ? currentCase?.fileType?.id : null);
	const [status, setStatus] = useState(isEditingCase && currentCase ? currentCase?.status : "ongoing");
	const [caseStage, setCaseStage] = useState(isEditingCase && currentCase ? currentCase?.caseStage?.id : null);
	const [caseType, setCaseType] = useState(isEditingCase && currentCase ? currentCase?.caseType?.id : null);
	const [court, setCourt] = useState(isEditingCase && currentCase ? currentCase?.court?.id : null);
	const [commercialBroker, setCommercialBroker] = useState(isEditingCase && currentCase ? currentCase?.commercialBroker?.id : null);
	const [closeReason, setCloseReason] = useState(isEditingCase && currentCase ? currentCase?.closeReason?.id : null);
	const [parties, setParties] = useState([]);
	const [addingParty, setAddingParty] = useState(false);
	const [editParty, setEditParty] = useState(false);
	const [selectedParty, setSelectedParty] = useState(null);
	const [users, setUsers] = useState(isEditingCase && currentCase ? currentCase?.users : []);
	const [addingUsers, setAddingUsers] = useState(false);
	const [linkedCase, setLinkedCase] = useState(isEditingCase && currentCase.linkedCase ? currentCase.linkedCase?.id : null);

	const [error, setError] = useState(false);

	const [addConstraint, setAddConstraint] = useState(false);
	const [constraintReference, setConstraintReference] = useState(null);
	const [loading, setLoading] = useState(false);
	const [cancelling, setCancelling] = useState(false);
	const [openAddContact, setOpenAddContact] = useState(false);

	useEffect(() => {
		if (isEditingCase) {
			let parties = currentCase.parties?.map((party) => {
				return {
					party: party?.party?.id,
					caseRole: party?.caseRole?.id,
					lawyer: party?.lawyer ? party?.lawyer?.id : null,
				};
			});
			setParties(parties);
		}
	}, []);

	const [createCaseFile] = useCreateCaseFileMutation();

	const handleCreateCaseFile = async (e) => {
		e.preventDefault();
		setLoading(true);
		if (fileNumber === "" || !fileNumber) {
			dispatch(setSnackbar({ open: true, message: t("FileNumberIsRequired"), severity: "error" }));
			setLoading(false);
			return;
		}

		if (!fileType || fileType === "") {
			dispatch(setSnackbar({ open: true, message: t("FileTypeRequired"), severity: "error" }));
			setLoading(false);
			return;
		}
		if (!status || status === "") {
			dispatch(setSnackbar({ open: true, message: t("StatusRequired"), severity: "error" }));
			setLoading(false);
			return;
		}

		if (!classification || classification === "") {
			dispatch(setSnackbar({ open: true, message: t("ClassificationRequired"), severity: "error" }));
			setLoading(false);
			return;
		}

		if (classification === "litigation" && (!caseNumber || caseNumber === "")) {
			dispatch(setSnackbar({ open: true, message: t("CaseNumberRequired"), severity: "error" }));
			setLoading(false);
			return;
		}
		if (classification === "litigation" && (!caseYear || caseYear === "")) {
			dispatch(setSnackbar({ open: true, message: t("CaseYearRequired"), severity: "error" }));
			setLoading(false);
			return;
		}

		if (classification === "litigation" && !caseStage) {
			dispatch(setSnackbar({ open: true, message: t("CaseStageRequired"), severity: "error" }));
			setLoading(false);
			return;
		}

		if (classification === "litigation" && !caseType) {
			dispatch(setSnackbar({ open: true, message: t("CaseTypeRequired"), severity: "error" }));
			setLoading(false);
			return;
		}
		if (classification === "litigation" && !court) {
			dispatch(setSnackbar({ open: true, message: t("CourtRequired"), severity: "error" }));
			setLoading(false);
			return;
		}

		if (!openDate) {
			dispatch(setSnackbar({ open: true, message: t("OpenDateRequired"), severity: "error" }));
			setLoading(false);
			return;
		}
		if (!parties || parties.length < 1) {
			dispatch(setSnackbar({ open: true, message: t("PartyRequirements"), severity: "error" }));
			setLoading(false);
			return;
		}

		if (!users || users.length < 1) {
			dispatch(setSnackbar({ open: true, message: t("UserRequirements"), severity: "error" }));
			setLoading(false);
			return;
		}

		if (status === "closed" && !closeDate && !closeReason) {
			dispatch(setSnackbar({ open: true, message: t("CloseDateRequired"), severity: "error" }));
			setLoading(false);
			return;
		}

		const formattedUsers = users.map((user) => {
			return {
				user: user?.user ? user?.user?.id : user?.id,
				position: user.position,
			};
		});

		const formattedParties = parties.map((obj) => {
			Object.keys(obj).forEach((key) => {
				if (obj[key] === null) {
					delete obj[key];
				}
			});
			return obj;
		});

		let newCase = {
			users: formattedUsers,
			parties: formattedParties,
			classification: classification,
			fileType: fileType,
			fileNumber: fileNumber,
			openDate: openDate,
			status: status,
			expertAssignmentDate: expertAssignmentDate,
		};

		if (status === "closed") {
			newCase = {
				...newCase,
				closeDate: closeDate,
				closeReason: closeReason,
			};
		}

		if (classification === "litigation") {
			newCase = {
				...newCase,
				caseNumber: caseNumber,
				caseYear: caseYear.toString(),
				caseStage: caseStage,
				caseType: caseType,
				court: court,
			};
		}

		if (subject) {
			newCase = {
				...newCase,
				subject: subject,
			};
		}
		if (note) {
			newCase = {
				...newCase,
				note: note,
			};
		}
		if (linkedCase) {
			newCase = {
				...newCase,
				linkedCase: linkedCase,
			};
		}

		if (commercialBroker) {
			newCase = {
				...newCase,
				commercialBroker: commercialBroker,
			};
		}


		try {
			const res = await createCaseFile(newCase).unwrap();
			const caseFileData = await axiosInstance.get(`/case-file/${res.id || res._id}`);
			dispatch(setCurrentCase(caseFileData.data.data));
			dispatch(setIsViewingCase(true));
			dispatch(setSnackbar({ open: true, message: t("CaseFileSuccessfully"), severity: "success" }));
			setLoading(false);
			navigate("/dashboard/case/details");
		} catch (error) {
			if (error?.data?.ar || error?.data?.en) {
				dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			}else{
				dispatch(setSnackbar({ open: true, message: t("AnErrorOccurred"), severity: "error" }));
			}
			setLoading(false);
		}
	};

	const [updateCaseFile] = useUpdateCaseFileMutation();

	const handleUpdateCaseFile = async (e) => {
		e.preventDefault();
		setLoading(true);

		if (fileNumber === "" || !fileNumber) {
			dispatch(setSnackbar({ open: true, message: t("FileNumberIsRequired"), severity: "error" }));
			setLoading(false);
			return;
		}
		if (!status || status === "") {
			dispatch(setSnackbar({ open: true, message: t("StatusRequired"), severity: "error" }));
			setLoading(false);
			return;
		}

		if (!classification || classification === "") {
			dispatch(setSnackbar({ open: true, message: t("ClassificationRequired"), severity: "error" }));
			setLoading(false);
			return;
		}

		if (classification === "litigation" && (!caseNumber || caseNumber === "")) {
			dispatch(setSnackbar({ open: true, message: t("CaseNumberRequired"), severity: "error" }));
			setLoading(false);
			return;
		}
		if (classification === "litigation" && (!caseYear || caseYear === "")) {
			dispatch(setSnackbar({ open: true, message: t("CaseYearRequired"), severity: "error" }));
			setLoading(false);
			return;
		}

		if (classification === "litigation" && !caseStage) {
			dispatch(setSnackbar({ open: true, message: t("CaseStageRequired"), severity: "error" }));
			setLoading(false);
			return;
		}

		if (classification === "litigation" && !caseType) {
			dispatch(setSnackbar({ open: true, message: t("CaseTypeRequired"), severity: "error" }));
			setLoading(false);
			return;
		}
		if (classification === "litigation" && !court) {
			dispatch(setSnackbar({ open: true, message: t("CourtRequired"), severity: "error" }));
			setLoading(false);
			return;
		}

		if (!openDate) {
			dispatch(setSnackbar({ open: true, message: t("OpenDateRequired"), severity: "error" }));
			setLoading(false);
			return;
		}
		if (!parties || parties.length < 1) {
			dispatch(setSnackbar({ open: true, message: t("PartyRequirements"), severity: "error" }));
			setLoading(false);
			return;
		}

		if (!users || users.length < 1) {
			dispatch(setSnackbar({ open: true, message: t("UserRequirements"), severity: "error" }));
			setLoading(false);
			return;
		}

		if (status === "closed" && (!closeDate || !closeReason)) {
			dispatch(setSnackbar({ open: true, message: t("CloseDateRequired"), severity: "error" }));
			setLoading(false);
			return;
		}

		const formattedUsers = users.map((user) => {
			return {
				user: user?.user ? user?.user?.id : user?.id,
				position: user.position,
			};
		});

		const formattedParties = parties.map((obj) => {
			Object.keys(obj).forEach((key) => {
				if (obj[key] === null) {
					delete obj[key];
				}
			});
			return obj;
		});

		let newCase = {
			users: formattedUsers,
			parties: formattedParties,
			classification: classification,
			fileType: fileType,
			fileNumber: fileNumber,
			openDate: openDate,
			status: status,
			expertAssignmentDate: expertAssignmentDate,
			// subject: subject,
			// note: note,
		};

		if (status === "closed") {
			newCase = {
				...newCase,
				closeDate: closeDate,
				closeReason: closeReason,
			};
		}

		if (classification === "litigation") {
			newCase = {
				...newCase,
				caseNumber: caseNumber,
				caseYear: caseYear.toString(),
				caseStage: caseStage,
				caseType: caseType,
				court: court,
			};
		}

		if (subject) {
			newCase = {
				...newCase,
				subject: subject,
			};
		}
		if (note) {
			newCase = {
				...newCase,
				note: note,
			};
		}
		if (linkedCase) {
			newCase = {
				...newCase,
				linkedCase: linkedCase,
			};
		}
		if (commercialBroker) {
			newCase = {
				...newCase,
				commercialBroker: commercialBroker,
			};
		}

		try {
			await updateCaseFile({ caseId: currentCase.id, data: newCase }).unwrap();
			const caseFileData = await axiosInstance.get(`/case-file/${currentCase.id}`);
			dispatch(setCurrentCase(caseFileData.data.data));
			dispatch(setIsViewingCase(true));
			dispatch(setSnackbar({ open: true, message: t("CaseFileUpdatedSuccessfully"), severity: "success" }));
			setLoading(false);
			navigate(-1);
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setLoading(false);
		}
	};

	return (
		<Animate type="fade" delay={0.5}>
			<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={2} px={6} py={4}>
				<CustomBreadcrumbs section={t("Cases")} />
				<Typography variant={"h2"} fontWeight={600} color={"#164c68"}>
					{isEditingCase ? t("Editing") : t("Creating")} {t("Case")}
				</Typography>
			</Stack>

			<Box
				width={"100%"}
				height={"max-content"}
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
				gap={4}
				sx={{ overflowY: "auto", overflowX: "hidden" }}
				p={4}
			>
				<Box
					width={isSmallScreen ? "100%" : "90%"}
					height={"auto"}
					p={2}
					bgcolor={"#fff"}
					borderRadius={1.5}
					sx={{
						boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
					}}
				>
					<Stack direction={"row"} width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
						<Stack width={"100%"} direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
							<Box p={1} display={"flex"} justifyContent={"center"} alignItems={"center"} bgcolor={"#e9e7e770"} borderRadius={1}>
								<FaBriefcase color="#164c68" size={18} />
							</Box>
							<Typography variant={"h4"} fontWeight={700} color={"#595858"}>
								{isEditingCase ? t("Editing") : t("New")} {t("File")}
							</Typography>
						</Stack>
						{!isSmallScreen && (
							<>
								<Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={1} width={"100%"}>
									<Typography variant={"h5"} fontWeight={500} color={"#164c68"} sx={{ mb: 3, ml: `${isRtl ? "8px" : "0px"} !important` }}>
										{t("FileNumber")}:
									</Typography>
									<CustomTextField
										type={"number"}
										name={"fileNumber"}
										value={fileNumber}
										resetValue={() => setFileNumber("")}
										handleInputChange={(e) => {
											if (e.target.value < 0) {
												setError(true);
												return;
											}
											setFileNumber(e.target.value);
										}}
										error={error}
										errorText={"File Number cannot be less that 0."}
										required={true}
									/>
								</Stack>
								<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1} width={"100%"}>
									<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1, ml: `${isRtl ? "8px" : "0px"} !important` }}>
										{t("Classification")}:
									</Typography>
									<FormControl sx={{ m: 1, minWidth: 100 }} size="small">
										<Select
											id="select-classification"
											value={classification}
											onChange={(e) => setClassification(e.target.value)}
											sx={{ color: "#164c68" }}
											displayEmpty
										>
											<MenuItem value={"litigation"}>{t("Litigation")}</MenuItem>
											<MenuItem value={"consultation"}>{t("Consultation")}</MenuItem>
										</Select>
									</FormControl>
								</Stack>
							</>
						)}
					</Stack>

					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<CaseInformation
						isSmallScreen={isSmallScreen}
						fileNumber={fileNumber}
						setFileNumber={setFileNumber}
						fileError={error}
						setError={setError}
						classification={classification}
						setClassification={setClassification}
						status={status}
						setStatus={setStatus}
						subject={subject}
						setSubject={setSubject}
						note={note}
						setNote={setNote}
						openDate={openDate}
						setOpenDate={setOpenDate}
						endDate={closeDate}
						setEndDate={setCloseDate}
						fileType={fileType}
						setFileType={setFileType}
						closeReason={closeReason}
						setCloseReason={setCloseReason}
						linkedCase={linkedCase}
						setLinkedCase={setLinkedCase}
						setAddConstraint={setAddConstraint}
						setConstraintReference={setConstraintReference}
						expertAssignmentDate={expertAssignmentDate}
						setExpertAssignmentDate={setExpertAssignmentDate}
						commercialBroker={commercialBroker}
						setCommercialBroker={setCommercialBroker}
					/>
					{classification === "litigation" && (
						<>
							<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
							<CaseDetails
								classification={classification}
								isSmallScreen={isSmallScreen}
								caseNumber={caseNumber}
								setCaseNumber={setCaseNumber}
								caseYear={caseYear}
								setCaseYear={setCaseYear}
								caseStage={caseStage}
								setCaseStage={setCaseStage}
								caseType={caseType}
								setCaseType={setCaseType}
								court={court}
								setCourt={setCourt}
								setAddConstraint={setAddConstraint}
								setConstraintReference={setConstraintReference}
							/>
						</>
					)}
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<Parties
						isSmallScreen={isSmallScreen}
						parties={parties}
						setParties={setParties}
						setAddingParty={setAddingParty}
						setEditParty={setEditParty}
						setSelectedParty={setSelectedParty}
						setOpenAddContact={setOpenAddContact}
					/>
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<Users isSmallScreen={isSmallScreen} users={users} setUsers={setUsers} setAddingUsers={setAddingUsers} />
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1.5}>
						<Button
							disabled={loading}
							variant="contained"
							startIcon={!isRtl && <FaBriefcase />}
							size="medium"
							sx={{
								bgcolor: "#164c68",
								color: "#fff",
								ml: `${isRtl ? "8px" : "0px"} !important`,
							}}
							onClick={isEditingCase ? handleUpdateCaseFile : handleCreateCaseFile}
						>
							{isEditingCase ? t("Update") : t("Save")}
						</Button>
						<Button
							onClick={() => setCancelling(true)}
							disabled={loading}
							sx={{
								color: "#fff",
								backgroundColor: "#fc6739",
								"&:hover": {
									color: "#fff",
									backgroundColor: "red",
								},
								transition: "all 1s ease",
							}}
						>
							{t("Cancel")}
						</Button>
					</Stack>
				</Box>
			</Box>
			{addingParty && (
				<AddPartyDialog
					open={addingParty}
					handleClose={() => setAddingParty(false)}
					classification={classification}
					parties={parties}
					setParties={setParties}
					setAddConstraint={setAddConstraint}
					setConstraintReference={setConstraintReference}
				/>
			)}
			{addingUsers && <UserSelect open={addingUsers} handleClose={() => setAddingUsers(false)} users={users} setUsers={setUsers} />}
			{cancelling && <CancellingDialog open={cancelling} handleClose={() => setCancelling(false)} path={"case"} />}
			{loading && <CustomBackdrop open={loading} />}
			{addConstraint && (
				<AddConstraintDialog
					open={addConstraint}
					handleClose={() => setAddConstraint(false)}
					reference={constraintReference}
					setFileType={setFileType}
					setCaseStage={setCaseStage}
					setCaseType={setCaseType}
					setCourt={setCourt}
				/>
			)}
			{openAddContact && <NewContact open={openAddContact} handleClose={() => setOpenAddContact(false)} />}
		</Animate>
	);
};

export default CreateCase;
