import { useDraggable } from "@dnd-kit/core";
import IndividualTask from "./IndividualTask";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedTask } from "../../redux/slices/task";

const DraggableTask = ({ task, isSmallScreen, isDragging }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { attributes, listeners, setNodeRef, transform } = useDraggable({
		id: task.id,
		data: { task },
	});

	const style = {
		transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
		width: "max-content",
		touchAction: "none",
	};

	let dragStarted = false;
	let initialPosition = null;

	const handleMouseDown = (event) => {
		dragStarted = false;
		initialPosition = { x: event.clientX, y: event.clientY };
	};

	const handleMouseMove = (event) => {
		if (initialPosition) {
			const currentPosition = { x: event.clientX, y: event.clientY };
			const movedDistance = Math.sqrt(Math.pow(currentPosition.x - initialPosition.x, 2) + Math.pow(currentPosition.y - initialPosition.y, 2));
			if (movedDistance > 10) {
				// Change this value to adjust the sensitivity
				dragStarted = true;
			}
		}
	};

	const handleMouseUp = () => {
		if (!dragStarted && !isDragging) {
			dispatch(setSelectedTask(task));
			navigate("/dashboard/task/details");
		}
		initialPosition = null;
	};

	return (
		<div
			ref={setNodeRef}
			style={style}
			{...listeners}
			{...attributes}
			className="draggable-task"
			onMouseDown={handleMouseDown}
			onMouseMove={handleMouseMove}
			onMouseUp={handleMouseUp}
		>
			<IndividualTask key={task.id} task={task} isSmallScreen={isSmallScreen} />
		</div>
	);
};

export default DraggableTask;
