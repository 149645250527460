import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from "moment";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../../redux/slices/app";
import { setCurrentMeeting, setIsCreatingMeeting, setIsEditingMeeting } from "../../../redux/slices/meetings";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MdOutlineAdd } from "react-icons/md";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const EventComponent = ({ event }) => (
	<Typography variant="subtitle1" fontWeight={600}>
		{event.title}
	</Typography>
);

const MyCalendar = ({ events }) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [myEvents, setMyEvents] = useState(events);

	return (
		<Box
			width={"100%"}
			height={"600px"}
			display={"flex"}
			justifyContent={"center"}
			alignItems={"center"}
			flexDirection={"column"}
			gap={2}
			p={2}
			borderRadius={1.5}
			bgcolor={"#fff"}
			sx={{
				boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
			}}
		>
			<Stack width={"100%"} direction={"row"} justifyContent={"flex-start"} alignItems={"center"}>
				<Button
					size="small"
					startIcon={!isRtl && <MdOutlineAdd color="#fff" />}
					onClick={() => {
						dispatch(setIsCreatingMeeting(true));
						dispatch(setIsEditingMeeting(false));
						dispatch(setCurrentMeeting(null));
						navigate("/dashboard/meeting");
					}}
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						padding: 1,
						"&:hover": {
							bgcolor: "#00a3b1",
						},
						transition: "all 0.5s ease",
					}}
				>
					{t("CreateNew")}
				</Button>
			</Stack>
			<DnDCalendar
				localizer={localizer}
				events={myEvents}
				startAccessor="start"
				endAccessor="end"
				selectable
				onSelectEvent={(event) => {
					dispatch(setCurrentMeeting(event));
					navigate(`/dashboard/meeting-detail`);
				}}
				onSelectSlot={(slotInfo) => {
					if (
						!myEvents.find(
							(event) =>
								new Date(event.start).getTime() === new Date(slotInfo.start).getTime() &&
								new Date(event.end).getTime() === new Date(slotInfo.end).getTime()
						)
					) {
						const now = moment();
						const selectedDate = moment(slotInfo?.start);
						const diffFromNow = selectedDate.diff(now, "seconds");
						if (diffFromNow < -1) {
							dispatch(
								setSnackbar({
									open: true,
									message: "You cannot create an event in the past.",
									severity: "error",
								})
							);
							return;
						}
						dispatch(setIsCreatingMeeting(true));
						dispatch(setIsEditingMeeting(false));
						dispatch(setCurrentMeeting(null));
						navigate("/dashboard/meeting");
					}
				}}
				components={{
					event: (event) => <EventComponent event={event} />,
				}}
				style={{ height: 500, width: "100%" }}
			/>
		</Box>
	);
};

export default MyCalendar;
