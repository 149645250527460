import { Box, CircularProgress, LinearProgress, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCloud } from "react-icons/fa6";
import { images } from "../../Assets";
import { useGetStorageQuery } from "../../../redux/slices/api/storage/storageApi";

const StorageBox = ({ isSmallScreen }) => {
	const { t } = useTranslation();

	const fileStorage = useGetStorageQuery({}, { refetchOnMountOrArgChange: true });
	const [storageUsed, setStorageUsed] = useState(0);
	const [storageLimit, setStorageLimit] = useState("");
	const [usedStorage, setUsedStorage] = useState(0);

	useEffect(() => {
		if (fileStorage.data && !fileStorage.isLoading && !fileStorage.isError) {
			const maxSize = fileStorage.data.storageLimit;
			const usedSize = parseInt(fileStorage.data.usedStorage);
			const maxSizeInTB = maxSize / 1048576;
			const maxSizeInGB = maxSize / 1024;
			const usedSizeInTB = usedSize / 1048576;
			const usedSizeInGB = usedSize / 1024;

			if (maxSizeInTB >= 1) {
				setStorageLimit(`${maxSizeInTB.toFixed(2)}TB`);
				setUsedStorage(`${usedSizeInTB.toFixed(2)}TB of ${maxSizeInTB.toFixed(2)}TB`);
			} else if (maxSizeInTB < 1 && maxSizeInGB >= 1) {
				setStorageLimit(`${maxSizeInGB.toFixed(2)}GB`);
				setUsedStorage(`${usedSizeInGB.toFixed(2)}GB of ${maxSizeInGB.toFixed(2)}GB`);
			} else {
				setStorageLimit(`${maxSize}MB`);
				setUsedStorage(`${usedSize}MB of ${maxSize}MB`);
			}

			setStorageUsed((usedSize / maxSize) * 100);
		}
	}, [fileStorage]);

	return (
		<Box
			p={3.5}
			width={isSmallScreen ? "100%" : "25%"}
			height={"auto"}
			minHeight={"353px"}
			borderRadius={1.5}
			sx={{
				boxShadow: "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;",
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat",
				backgroundImage: `url(${images.boxBg})`,
			}}
		>
			<Stack direction={"row"} width={"100%"} justifyContent={"space-between"} alignItems={"start"}>
				<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={6}>
					<Typography variant={"h2"} fontWeight={500} color={"#fff"}>
						{t("Storage")}
					</Typography>
					<Box p={1} display={"flex"} justifyContent={"center"} alignItems={"center"} borderRadius={1.5} bgcolor={"#012424"}>
						<FaCloud size={25} color="#fff" />
					</Box>
					<Typography
						variant="body1"
						fontWeight={500}
						color={"#fff"}
						sx={{
							fontFamily: '"Montserrat", "sans-serif"',
						}}
					>
						{fileStorage.isLoading ? <CircularProgress /> : `${t("Total")}: ${usedStorage}`}
					</Typography>
					<Box sx={{ width: "100%" }}>
						<Tooltip title={`${storageUsed.toFixed(2)}% ${t("Used")}`} placement="top" arrow>
							<LinearProgress variant="determinate" value={storageUsed} />
						</Tooltip>
					</Box>
				</Stack>
				<Typography
					variant="h2"
					fontWeight={500}
					color={"#fff"}
					sx={{
						fontFamily: '"Montserrat", "sans-serif"',
					}}
				>
					{storageLimit}
				</Typography>
			</Stack>
		</Box>
	);
};

export default StorageBox;
