import { apiSlice } from "../apiSlice";

export const storageApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getStorage: builder.query({
			query: () => ({
				url: "/static/storage",
				method: "GET",
			}),
			providesTags: ["Storage"],
		}),
	}),
});

export const { useGetStorageQuery } = storageApi;
