import { queryBuilder } from "../../../../utils/queryBuilder";
import { apiSlice } from "../apiSlice";

export const caseApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getCases: builder.query({
			query: (query) => ({
				url: `/case-file?total=true&${queryBuilder(query)}`,
				method: "GET",
			}),
			providesTags: ["Cases"],
		}),
		getCaseById: builder.query({
			query: (caseId) => ({
				url: `/case-file/${caseId}`,
				method: "GET",
			}),
		}),
		getCaseFileMetadata: builder.query({
			query: () => ({
				url: "/case-file/metadata",
				method: "GET",
			}),
		}),
		createCaseFile: builder.mutation({
			query: (data) => ({
				url: "/case-file",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["Cases"],
		}),
		updateCaseFile: builder.mutation({
			query: ({ caseId, data }) => ({
				url: `/case-file/${caseId}`,
				method: "PATCH",
				body: data,
			}),
			invalidatesTags: ["Cases"],
		}),
		deleteCaseFile: builder.mutation({
			query: (caseId) => ({
				url: `/case-file/${caseId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Cases"],
		}),
	}),
});

export const {
	useGetCasesQuery,
	useGetCaseByIdQuery,
	useGetCaseFileMetadataQuery,
	useCreateCaseFileMutation,
	useUpdateCaseFileMutation,
	useDeleteCaseFileMutation,
} = caseApi;
