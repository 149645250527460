import React, { useEffect, useState } from "react";
import { Autocomplete, Box, CircularProgress, TextField, Typography } from "@mui/material";
import { useGetAllHearingsQuery } from "../../../redux/slices/api/hearing/hearingApi";
import { useGetAllMeetingsQuery } from "../../../redux/slices/api/meetings/meetingsApi";
import { useGetAllMemosQuery } from "../../../redux/slices/api/memo/memoApi";

const ClassificationDataSelection = ({ classification, classificationData, setClassificationData, caseFileData }) => {
	const [menus, setMenus] = useState([]);
	const [selectedValue, setSelectedValue] = useState(null);

	const hearing = useGetAllHearingsQuery({}, { refetchOnMountOrArgChange: true });
	const meeting = useGetAllMeetingsQuery({}, { refetchOnMountOrArgChange: true });
	const memo = useGetAllMemosQuery({}, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		setSelectedValue(null);
		setClassificationData(null);
		if (caseFileData && classification) {
			if (classification === "hearing" && caseFileData) {
				if (hearing.isError) {
					console.error("Error: ", hearing.error);
					return;
				}
				if (hearing.data) {
					const filteredData = hearing.data?.data
						.filter((item) => item.caseFile.id === caseFileData)
						.map((item) => {
							return {
								id: item.id,
								display: item.note,
							};
						});
					setMenus(filteredData);
				}
			} else if (classification === "Meeting" && caseFileData) {
				if (meeting.isError) {
					console.error("Error: ", meeting.error);
					return;
				}
				if (meeting.data) {
					const filteredData = meeting.data?.data
						.filter((item) => item.caseFile?.id === caseFileData)
						.map((item) => {
							return {
								id: item.id,
								display: item.subject,
							};
						});
					setMenus(filteredData);
				}
			} else if (classification === "memo" && caseFileData) {
				if (memo.isError) {
					console.error("Error: ", memo.error);
					return;
				}
				if (memo.data) {
					const filteredData = memo?.data?.data
						.filter((item) => item?.caseFile?.id === caseFileData)
						.map((item) => {
							const formattedContent = item?.content.replace(/<[^>]*>/g, "");
							return {
								id: item?.id,
								display: formattedContent,
							};
						});
					setMenus(filteredData);
				}
			}
		}
	}, [classification, caseFileData, hearing.data, meeting.data, memo.data, setClassificationData]);

	useEffect(() => {
		// Update local selectedValue based on classificationData
		const found = menus.find((option) => option.id === classificationData);
		setSelectedValue(found);
	}, [classificationData, menus]);

	return (
		<Autocomplete
			disablePortal
			disableClearable
			id="combo-box-demo"
			options={menus}
			value={selectedValue}
			onChange={(event, value) => setClassificationData(value.id)}
			getOptionLabel={(option) => {
				return option.display;
			}}
			renderOption={(props, option) => {
				return hearing.isLoading || meeting.isLoading || memo.isLoading ? (
					<CircularProgress />
				) : (
					<Box width={"100%"} component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
						<Typography variant="h6" sx={{ color: "#fff", ml: 1 }}>
							{option.display ? option.display : "-"}
						</Typography>
					</Box>
				);
			}}
			sx={{ width: 300, padding: "8.5px 14px" }}
			renderInput={(params) => <TextField {...params} autoComplete="off" />}
		/>
	);
};

export default ClassificationDataSelection;
