import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { images } from "../../../Assets";
import { useTranslation } from "react-i18next";

const FirstSection = ({ category }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const isRtl = useSelector((state) => state.app.isRtl);
	const currentCase = useSelector((state) => state.case.currentCase);
	const currentMeeting = useSelector((state) => state.meetings.currentMeeting);
	const selectedTask = useSelector((state) => state.task.selectedTask);
	const currentHearing = useSelector((state) => state.case.currentHearing);
	const location = useLocation();

	return (
		<Box
			width={"100%"}
			height={"auto"}
			p={2}
			borderRadius={6}
			display={"flex"}
			justifyContent={"space-between"}
			flexDirection={isRtl ? "row-reverse" : "row"}
			alignItems={"center"}
			bgcolor={"#fff"}
			sx={{
				boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;",
			}}
		>
			<Tooltip title={t("Back")} placement="top" arrow>
				<IconButton
					onClick={() => {
						if (category === "hearing") {
							if(currentCase){
								navigate("/dashboard/case/details");
							}else{
								navigate(-1)
							}
						} else if (category === "memo") {
							if(currentCase){
								navigate("/dashboard/case/details/");
							}else{
								navigate(-1)
							}
						} else if (category === "task") {
							if (currentCase && selectedTask) {
								navigate(`/dashboard/case/details/tasks`);
							} else if (currentCase && !selectedTask) {
								navigate(`/dashboard/case/details/`);
							} else {
								navigate(`/dashboard/tasks/`);
							}
						} else if (category === "meeting") {
							if (currentCase && currentMeeting) {
								navigate(`/dashboard/case/details/meetings`);
							} else if (currentCase && !currentMeeting) {
								navigate(`/dashboard/case/details`);
							} else {
								navigate(`/dashboard/meetings/`);
							}
						} else if (category === "case") {
							if (currentCase) {
								navigate(`/dashboard/case/`);
							} else {
								navigate(`/dashboard/`);
							}
						} else if (category === "fees") {
							navigate("/dashboard/case/details/");
						} else {
							navigate(-1);
						}
					}}
					sx={{ p: 0 }}
				>
					<IoMdArrowRoundBack color="#164c68" size={18} />
				</IconButton>
			</Tooltip>
			<Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={1}>
				<Typography variant="h3" fontWeight={600} sx={{ ml: `${isRtl ? "8px" : "0px"} !important`, fontFamily: '"Montserrat", "sans-serif"' }}>
					{category === "case" || category === "hearing" || category === "meetings" || category === "task"
						? `${t("CaseFileNumber")}: ${
								currentCase
									? currentCase?.fileNumber
									: currentHearing
									? currentHearing?.caseFile?.fileNumber
									: currentMeeting
									? currentMeeting?.caseFile?.fileNumber
									: selectedTask
									? selectedTask?.caseFile ? selectedTask?.caseFile?.fileNumber : "N/A"
									: "N/A"
						  }`
						: category === "tasks"
						? `${t("TaskTitle")}: ${
								selectedTask.taskTitle
									? isRtl
										? selectedTask.taskTitle.nameAr
										: selectedTask.taskTitle.nameEn
										? selectedTask.taskTitle.nameEn
										: selectedTask.taskTitle.nameAr
									: selectedTask.title
						  }`
						: null}
				</Typography>
				{category === "task" && location.pathname === "/dashboard/task/details" && (
					<Tooltip title={`${t("Priority")}: ${t(selectedTask?.priority)}`} placement="top" arrow>
						{selectedTask?.priority === "Low" ? (
							<img src={images.low} alt="low" width={20} height={20} />
						) : selectedTask?.priority === "Medium" ? (
							<img src={images.medium} alt="medium" width={20} height={20} />
						) : (
							<img src={images.high} alt="high" width={20} height={20} />
						)}
					</Tooltip>
				)}
			</Stack>
			{category === "case" || category === "hearing" || category === "meetings" || category === "task" ? (
				<Box
					sx={{
						color: "#fff",
						borderRadius: 6,
						padding: 0.5,
						backgroundColor: currentCase?.status === "ongoing" ? "#74c193" : "#fc6b77",
						textAlign: "center",
						textTransform: "capitalize",
						minWidth: "65px",
					}}
				>
					<Typography variant={"subtitle2"} fontWeight={600} color={"#fff"}>
						{currentCase?.status === "ongoing" ? t("Ongoing") : t("Closed")}
					</Typography>
				</Box>
			) : category === "tasks" ? (
				<Box
					sx={{
						color: "#fff",
						borderRadius: 6,
						padding: 1,
						backgroundColor:
							selectedTask?.status === "New"
								? "#edaf41"
								: selectedTask?.status === "In Progress"
								? "#116ee9"
								: selectedTask?.status === "Completed"
								? "#74c193"
								: "#fc6b77",
						textAlign: "center",
						textTransform: "capitalize",
						minWidth: "65px",
					}}
				>
					<Typography variant={"subtitle2"} fontWeight={600} color={"#fff"}>
						{selectedTask?.status === "In Progress" ? t("InProgress") : t(selectedTask?.status)}
					</Typography>
				</Box>
			) : null}
		</Box>
	);
};

export default FirstSection;
