import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { HiOutlineDotsVertical } from "react-icons/hi";
import MemoMenu from "./Menu/MemoMenu";
import { useDispatch } from "react-redux";
import { setCurrentSectionMenu } from "../../redux/slices/app";

const SectionHeader = ({ icon, title, type, section }) => {
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClose = () => {
		setAnchorEl(null);
	};
	return (
		<>
			<Stack direction={"row"} width={"100%"} justifyContent={"space-between"} alignItems={"center"} bgcolor={"#fff"}>
				<Box width={"100%"} display={"flex"} alignItems={"center"} gap={1}>
					<Box p={1} display={"flex"} justifyContent={"center"} alignItems={"center"} bgcolor={"#e9e7e770"} borderRadius={1}>
						{icon}
					</Box>
					<Typography variant={"h4"} fontWeight={700} color={"#595858"}>
						{title}
					</Typography>
				</Box>
				{section !== "cases" ||
					(section !== "hearing" && (
						<Box
							p={1}
							display={type === "dialog" || type === "editable" ? "none" : "flex"}
							justifyContent={"center"}
							alignItems={"center"}
							bgcolor={"#e9e7e770"}
							borderRadius={1}
						>
							<IconButton
								sx={{ p: 0 }}
								onClick={(e) => {
									dispatch(setCurrentSectionMenu(title));
									setAnchorEl(e.currentTarget);
								}}
								id="menu-button"
								aria-controls={open ? "basic-menu" : undefined}
								aria-haspopup="true"
								aria-expanded={open ? "true" : undefined}
							>
								<HiOutlineDotsVertical color="#164c68" size={18} />
							</IconButton>
						</Box>
					))}
			</Stack>
			{open && <MemoMenu open={open} anchorEl={anchorEl} handleClose={handleClose} section={section} />}
		</>
	);
};

export default SectionHeader;
