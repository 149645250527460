import { apiSlice } from "../apiSlice";

export const authApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		login: builder.mutation({
			query: (credentials) => ({
				url: "/auth/sign-in",
				method: "POST",
				body: credentials,
			}),
			providesTags: ["Auth"],
			invalidatesTags: ["User"],
		}),
		verifyType: builder.mutation({
			query: (type) => ({
				url: "/auth/send-verify",
				method: "POST",
				body: type,
			}),
			invalidatesTags: ["User"],
		}),
		verifyCode: builder.mutation({
			query: (code) => ({
				url: "/auth/verify",
				method: "POST",
				body: code,
			}),
			invalidatesTags: ["User"],
		}),
		forgotPassword: builder.mutation({
			query: (data) => ({
				url: "/auth/forgot-password",
				method: "POST",
				body: data,
			}),
		}),
		resetPassword: builder.mutation({
			query: (data) => ({
				url: "/auth/reset-password",
				method: "POST",
				body: data,
			}),
		}),
	}),
});

export const { useLoginMutation, useVerifyTypeMutation, useVerifyCodeMutation, useForgotPasswordMutation, useResetPasswordMutation } = authApi;
