import React, { useEffect, useState } from "react";
import { useGetCasesQuery } from "../../../redux/slices/api/cases/caseApi";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const ClassificationSelection = ({ classification, classificationData, setClassificationData, caseFileData, setCaseFile }) => {
	const [menus, setMenus] = useState([]);
	const [selectedValue, setSelectedValue] = useState(null);

	const currentCase = useSelector((state) => state.case.currentCase);

	const caseFile = useGetCasesQuery({}, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (caseFile.isError) {
			console.error("Error: ", caseFile.error);
			return;
		}
		if (caseFile.data && !currentCase) {
			const formattedData = caseFile.data?.data.map((item) => {
				return {
					id: item.id,
					fileNumber: item.fileNumber,
				};
			});
			setMenus(formattedData);
		}
	}, [caseFile]);

	useEffect(() => {
		const found = menus.find((option) => option.id === caseFileData);
		setSelectedValue(found);
	}, [caseFileData, menus]);

	return (
		<Autocomplete
			disablePortal
			disableClearable
			id="combo-box-demo"
			options={menus}
			value={selectedValue}
			getOptionLabel={(option) => {
				const fileNumber = option?.fileNumber?.toString();
				return fileNumber;
			}}
			onChange={(event, value) => {
				if (currentCase) return;
				if (classification === "caseFile") {
					setClassificationData(value.id);
				}
				setCaseFile(value.id);
			}}
			renderOption={(props, option) => {
				return (
					<Box width={"100%"} component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
						<Typography variant="h6" sx={{ color: "#fff", ml: 1 }}>
							{option.fileNumber ? option.fileNumber : "-"}
						</Typography>
					</Box>
				);
			}}
			sx={{ width: 150, padding: "8.5px 14px" }}
			renderInput={(params) => <TextField {...params} autoComplete="off" />}
		/>
	);
};

export default ClassificationSelection;
