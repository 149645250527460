import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import SectionHeader from "../../Common/SectionHeader";
import { FaUserPlus } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import { Divider, Tooltip, Select as MuiSelect, FormControl, InputLabel, MenuItem, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import CustomTextField from "../../Common/CustomTextField";
import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../../redux/slices/app";
import { generateRandomString } from "../../Common/utils";
import { useAddUserMutation } from "../../../redux/slices/api/user/userApi";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import PhoneSelect from "../../Contacts/PhoneSelect";
import { isValidPhoneNumber } from "libphonenumber-js";
import { LuCheckCircle } from "react-icons/lu";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const Select = styled(MuiSelect)({
	color: "#164c68",
	"& .MuiSvgIcon-root": { color: "#164c68" },
	"& .MuiFormLabel-root": { color: "#164c68" },
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: "#164c68",
		},
		"&:hover fieldset": {
			borderColor: "#164c68",
		},
		"&.Mui-focused fieldset": {
			borderColor: "#164c68",
		},
	},
});

const AddUser = ({ open, handleClose }) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);
	const userDetails = useSelector((state) => state.app.userDetails);
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);

	const [role, setRole] = useState("Employee");
	const [position, setPosition] = useState("Admin");

	const [formData, setFormData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		password: "",
	});
	const [userPhone, setUserPhone] = useState("");
	const [showPassword, setShowPassword] = useState(false);

	const [hasError, setHasError] = useState({
		firstName: false,
		lastName: false,
		email: false,
	});
	const [errorText, setErrorText] = useState({
		firstName: "",
		lastName: "",
		email: "",
	});

	const [passwordRequirement, setPasswordRequirement] = useState({
		length: false,
		caseSensitive: false,
		specialChar: false,
		number: false,
	});

	const handleInput = (e) => {
		if (e.target.name === "password") {
			if (e.target.value.length >= 8) {
				setPasswordRequirement((prev) => ({ ...prev, length: true }));
			}
			if (e.target.value.match(/[a-z]/) && e.target.value.match(/[A-Z]/)) {
				setPasswordRequirement((prev) => ({ ...prev, caseSensitive: true }));
			}
			if (e.target.value.match(/[0-9]/)) {
				setPasswordRequirement((prev) => ({ ...prev, number: true }));
			}
			if (e.target.value.match(/[!@#$%^&*-]/)) {
				setPasswordRequirement((prev) => ({ ...prev, specialChar: true }));
			}
		}
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	useEffect(() => {
		if (formData.password.length < 8) {
			setPasswordRequirement((prev) => ({ ...prev, length: false }));
		}
		if (!formData.password.match(/[a-z]/) || !formData.password.match(/[A-Z]/)) {
			setPasswordRequirement((prev) => ({ ...prev, caseSensitive: false }));
		}
		if (!formData.password.match(/[0-9]/)) {
			setPasswordRequirement((prev) => ({ ...prev, number: false }));
		}
		if (!formData.password.match(/[!@#$%^&*]/)) {
			setPasswordRequirement((prev) => ({ ...prev, specialChar: false }));
		}
	}, [formData.password]);

	const generate = () => {
		const randomStr = generateRandomString(10);

		setFormData({ ...formData, password: randomStr });
		setPasswordRequirement({
			length: true,
			caseSensitive: true,
			specialChar: true,
			number: true,
		});
	};

	const inputRef = useRef(null);

	const [addUser] = useAddUserMutation();

	const handleCreateUser = async (e) => {
		e.preventDefault();
		setLoading(true);
		const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
		const { firstName, lastName, email, password } = formData;
		if (email === "" || firstName === "" || lastName === "" || password === "") {
			setHasError({ ...hasError, email: email === "", firstName: firstName === "", lastName: lastName === "", password: password === "" });
			setErrorText({
				...errorText,
				email: email === "" ? t("EmailIsRequired") : "",
				firstName: firstName === "" ? t("FirstNameRequired") : "",
				lastName: lastName === "" ? t("LastNameRequired") : "",
				password: password === "" ? t("PasswordRequired") : "",
			});
			setLoading(false);
			dispatch(setSnackbar({ open: true, message: t("FillAllRequiredFields"), severity: "error" }));
			return;
		}

		if (!emailRegex.test(email)) {
			setLoading(false);
			setHasError({ ...hasError, email: true });
			setErrorText({ ...errorText, email: t("InvalidEmail") });
			return;
		}

		let data = {
			firstName: firstName,
			lastName: lastName,
			email: email,
			password: password,
			role: role,
		};

		const formattedPhone = userPhone?.replace(/\s+/g, "");

		if (!isValidPhoneNumber(formattedPhone)) {
			setLoading(false);
			dispatch(setSnackbar({ open: true, message: t("InvalidPhoneNumber"), severity: "error" }));
			return; // Exit the entire function
		} else if (isValidPhoneNumber(formattedPhone) && formattedPhone.length >= 1) {
			data = { ...data, phone: formattedPhone };
		}

		if (position !== "" || position) {
			data = {
				...data,
				position: position,
			};
		}

		try {
			await addUser(data).unwrap();
			dispatch(setSnackbar({ open: true, message: t("UserCreatedSuccessfully"), severity: "success" }));
			setLoading(false);
			handleClose();
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setLoading(false);
		}
	};

	return (
		<Dialog open={open} fullWidth TransitionComponent={Transition} keepMounted aria-describedby="add-user-dialog" sx={{ height: "100%" }}>
			<DialogTitle sx={{ pb: 2, borderBottom: "1px solid #aaaaaa6b", backgroundColor: "#fff" }}>
				<SectionHeader icon={<FaUserPlus color="#164c68" size={18} />} title={`${t("NewUser")}`} type={"dialog"} />
			</DialogTitle>
			<DialogContent sx={{ backgroundColor: "#fff" }}>
				<DialogContentText id="add-user-dialog-description" sx={{ py: 4, px: 1 }}>
					<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ mb: 2 }}>
						{t("UserInformation")}
					</Typography>
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />

					<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={3} width={"100%"} sx={{ mb: 2 }}>
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
							<CustomTextField
								type={"text"}
								label={`${t("FirstName")}: `}
								value={formData.firstName}
								resetValue={() => setFormData({ ...formData, firstName: "" })}
								name={"firstName"}
								handleInputChange={handleInput}
								error={hasError.firstName}
								errorText={errorText.firstName}
								required={true}
							/>
							<CustomTextField
								type={"text"}
								label={`${t("LastName")}: `}
								value={formData.lastName}
								resetValue={() => setFormData({ ...formData, lastName: "" })}
								name={"lastName"}
								handleInputChange={handleInput}
								error={hasError.lastName}
								errorText={errorText.lastName}
								required={true}
							/>
						</Stack>
						<PhoneSelect formData={userPhone} id={"phone"} setFormData={(e) => setUserPhone(e.target.value)} />
						<CustomTextField
							type={"text"}
							label={`${t("EmailAddress")}: `}
							value={formData.email}
							resetValue={() => setFormData({ ...formData, email: "" })}
							name={"email"}
							handleInputChange={handleInput}
							error={hasError.email}
							errorText={errorText.email}
						/>
						<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={1} width={"100%"}>
							<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
								<CustomTextField
									type={"password"}
									label={`${t("Password")}: `}
									value={formData.password}
									name={"password"}
									handleInputChange={handleInput}
									showPassword={showPassword}
									setShowPassword={setShowPassword}
									required={true}
								/>
								<Tooltip title={"Generate?"} placement="top" arrow>
									<IconButton onClick={() => generate()}>
										<HiRefresh size={22} color="#164c68" />
									</IconButton>
								</Tooltip>
							</Stack>
							<Typography variant={isRtl ? "title" : "body2"} fontWeight={700} color={"#51505099"}>
								{t("PasswordRequirements")}:
							</Typography>
							<Grid container spacing={0.5}>
								<Grid item xs={12} sm={6}>
									<Stack direction="row" spacing={0.5} alignItems="center">
										<LuCheckCircle color={passwordRequirement.length ? "#00cb08" : "grey"} size={18} />
										<Typography variant="subtitle2" fontWeight="500" sx={{ color: passwordRequirement.length ? "#00cb08" : "grey" }}>
											{t("PasswordCharLengthReq")}
										</Typography>
									</Stack>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Stack direction="row" spacing={0.5} alignItems="center">
										<LuCheckCircle color={passwordRequirement.caseSensitive ? "#00cb08" : "grey"} size={18} />
										<Typography variant="subtitle2" fontWeight="500" sx={{ color: passwordRequirement.caseSensitive ? "#00cb08" : "grey" }}>
											{t("PasswordCaseSensitiveReq")}
										</Typography>
									</Stack>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Stack direction="row" spacing={0.5} alignItems="center">
										<LuCheckCircle color={passwordRequirement.specialChar ? "#00cb08" : "grey"} size={18} />
										<Typography variant="subtitle2" fontWeight="500" sx={{ color: passwordRequirement.specialChar ? "#00cb08" : "grey" }}>
											{t("PasswordSpecialCharReq")}
										</Typography>
									</Stack>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Stack direction="row" spacing={0.5} alignItems="center">
										<LuCheckCircle color={passwordRequirement.number ? "#00cb08" : "grey"} size={18} />
										<Typography variant="subtitle2" fontWeight="500" sx={{ color: passwordRequirement.number ? "#00cb08" : "grey" }}>
											{t("PasswordNumberReq")}
										</Typography>
									</Stack>
								</Grid>
							</Grid>
						</Stack>
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2} width={"100%"}>
							<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
								<InputLabel id="role-select-small-label">{t("Role")}</InputLabel>
								<Select
									labelId="role-select-small-label"
									id="role-select-small"
									value={role}
									label="Role"
									onChange={(e) => setRole(e.target.value)}
								>
									<MenuItem value={"Employee"}>{t("Employee")}</MenuItem>
									{userDetails.role === "Owner" && <MenuItem value={"Admin"}>{t("Admin")}</MenuItem>}
									{userDetails.role === "Owner" && <MenuItem value={"Owner"}>{t("Owner")}</MenuItem>}
								</Select>
							</FormControl>
							<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
								<InputLabel id="role-select-small-label">{t("Position")}</InputLabel>
								<Select
									labelId="position-select-small-label"
									id="position-select-small"
									value={position}
									label="Position"
									onChange={(e) => setPosition(e.target.value)}
								>
									<MenuItem value={"Admin"}>{t("Admin")}</MenuItem>
									<MenuItem value={"Expert"}>{t("Expert")}</MenuItem>
									<MenuItem value={"Expert Assistant"}>{t("ExpertAssistant")}</MenuItem>
									<MenuItem value={"Auditor"}>{t("Auditor")}</MenuItem>
									<MenuItem value={"Translator"}>{t("Translator")}</MenuItem>
									<MenuItem value={"Accountant"}>{t("Accountant")}</MenuItem>
									<MenuItem value={"Secretary"}>{t("Secretary")}</MenuItem>
									<MenuItem value={"Administrative"}>{t("Administrative")}</MenuItem>
								</Select>
							</FormControl>
						</Stack>
					</Stack>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: "#fff" }}>
				<Button
					onClick={handleClose}
					sx={{
						color: "red",
						"&:hover": {
							color: "#fff",
							backgroundColor: "red",
						},
						transition: "all 1s ease",
					}}
				>
					{t("Cancel")}
				</Button>
				<LoadingButton
					loading={loading}
					disabled={
						!(passwordRequirement.length || passwordRequirement.caseSensitive || passwordRequirement.specialChar || passwordRequirement.number) ||
						formData.firstName === "" ||
						formData.lastName === "" ||
						formData.email === "" ||
						formData.password === "" ||
						userPhone === ""
					}
					variant="contained"
					size="medium"
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						mr: `${isRtl ? "8px" : "0px"} !important`,
					}}
					onClick={handleCreateUser}
				>
					{t("Save")}
				</LoadingButton>
			</DialogActions>
			<input ref={inputRef} type="file" hidden accept="image/*" />
		</Dialog>
	);
};

export default AddUser;
