import { Box, Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { images } from "../Assets";
import Animate from "../Common/Animate";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomTextField from "../Common/CustomTextField";
import { LoadingButton } from "@mui/lab";
import { useResetPasswordMutation } from "../../redux/slices/api/auth/authApi";
import { setSnackbar } from "../../redux/slices/app";
import { LuCheckCircle } from "react-icons/lu";
import { useTheme } from "@emotion/react";
import ResetPasswordConfirmation from "./ResetPasswordConfirmation";

const ResetPassword = () => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
	const resetPasswordType = useSelector((state) => state.app.resetPasswordType);
	const resetPasswordData = useSelector((state) => state.app.resetPasswordData);
	const resetPasswordCode = useSelector((state) => state.app.resetPasswordCode);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const [passwordRequirement, setPasswordRequirement] = useState({
		length: false,
		caseSensitive: false,
		specialChar: false,
		number: false,
	});
	const [isLoading, setIsLoading] = useState(false);
	const [openOtpDialog, setOpenOtpDialog] = useState(false);

	const [resetPassword] = useResetPasswordMutation();

	const handleResetPassword = async (e) => {
		e.preventDefault();
		setIsLoading(true);

		if (newPassword === "") {
			dispatch(setSnackbar({ open: true, message: t("PasswordEmptyError"), severity: "error" }));
			setIsLoading(false);
			return;
		}

		if (newPassword.length >= 1 && confirmPassword === "") {
			dispatch(setSnackbar({ open: true, message: t("ConfirmPasswordEmptyError"), severity: "error" }));
			setIsLoading(false);
			return;
		}

		if (newPassword !== confirmPassword) {
			dispatch(setSnackbar({ open: true, message: t("PasswordsDoNotMatch"), severity: "error" }));
			setIsLoading(false);
			return;
		}

		let data = {
			code: resetPasswordCode,
			password: newPassword,
		};

		if (resetPasswordType === "email") {
			data["email"] = resetPasswordData;
		} else {
			data["phone"] = resetPasswordData;
		}

		try {
			await resetPassword(data).unwrap();
			dispatch(setSnackbar({ open: true, message: t("PasswordResetSuccessfully"), severity: "success" }));
			navigate("/");
		} catch (error) {
			// dispatch(setSnackbar({ open: true, message: isRtl ? error.data.ar : error.data.en, severity: "error" }));
			if (error?.data?.en === "Invalid reset code") {
				dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
				setOpenOtpDialog(true);
			} else {
				dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			}
		}

		setIsLoading(false);
	};

	useEffect(() => {
		if (newPassword.length < 8) {
			setPasswordRequirement((prev) => ({ ...prev, length: false }));
		}
		if (!newPassword.match(/[a-z]/) || !newPassword.match(/[A-Z]/)) {
			setPasswordRequirement((prev) => ({ ...prev, caseSensitive: false }));
		}
		if (!newPassword.match(/[0-9]/)) {
			setPasswordRequirement((prev) => ({ ...prev, number: false }));
		}
		if (!newPassword.match(/[!@#$%^&*]/)) {
			setPasswordRequirement((prev) => ({ ...prev, specialChar: false }));
		}
	}, [newPassword]);

	return (
		<Box
			width={"100%"}
			height={"100%"}
			display={"flex"}
			justifyContent={"center"}
			alignItems={"center"}
			flexDirection={"column"}
			gap={2}
			sx={{ backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundImage: `url(${images.loginBg})` }}
		>
			<Animate type="fade" delay={1}>
				<Stack direction={"row"} width={"100%"} justifyContent={"center"} alignItems={"center"}>
					<img src={images.logo} alt="logo" width={100} height={100} />
				</Stack>
			</Animate>
			<Animate>
				<Box
					p={3}
					borderRadius={3}
					height={"auto"}
					width={"100%"}
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
					flexDirection={"column"}
					bgcolor={isSmallScreen && "#fff"}
					sx={{ boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;" }}
				>
					<Typography variant={"h3"} fontWeight={600} sx={{ mb: 4 }}>
						{t("SetPassword")}
					</Typography>

					<Stack spacing={3} width={"80%"} mb={2}>
						<CustomTextField
							type={"password"}
							name={"password"}
							value={newPassword}
							label={t("Password")}
							handleInputChange={(e) => {
								if (e.target.value.length >= 8) {
									setPasswordRequirement((prev) => ({ ...prev, length: true }));
								}
								if (e.target.value.match(/[a-z]/) && e.target.value.match(/[A-Z]/)) {
									setPasswordRequirement((prev) => ({ ...prev, caseSensitive: true }));
								}
								if (e.target.value.match(/[0-9]/)) {
									setPasswordRequirement((prev) => ({ ...prev, number: true }));
								}
								if (e.target.value.match(/[!@#$%^&*-]/)) {
									setPasswordRequirement((prev) => ({ ...prev, specialChar: true }));
								}

								setNewPassword(e.target.value);
							}}
							showPassword={showPassword}
							setShowPassword={setShowPassword}
							required={true}
						/>
						{(newPassword || newPassword.length >= 1) && (
							<CustomTextField
								type={"password"}
								name={"password"}
								value={confirmPassword}
								label={t("ConfirmPassword")}
								handleInputChange={(e) => {
									if (newPassword !== e.target.value) {
										setPasswordError(true);
									} else {
										setPasswordError(false);
									}
									setConfirmPassword(e.target.value);
								}}
								showPassword={showPassword}
								setShowPassword={setShowPassword}
								error={passwordError}
								errorText={t("PasswordsDoNotMatch")}
								required={true}
							/>
						)}
						<Typography variant={isRtl ? "title" : "body2"} fontWeight={700} color={"#51505099"}>
							{t("PasswordRequirements")}:
						</Typography>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={6}>
								<Stack direction="row" spacing={0.5} alignItems="center">
									<LuCheckCircle color={passwordRequirement.length ? "#00cb08" : "grey"} size={20} />
									<Typography variant="subtitle1" fontWeight="500" sx={{ color: passwordRequirement.length ? "#00cb08" : "grey" }}>
										8 Characters
									</Typography>
								</Stack>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Stack direction="row" spacing={0.5} alignItems="center">
									<LuCheckCircle color={passwordRequirement.caseSensitive ? "#00cb08" : "grey"} size={20} />
									<Typography variant="subtitle1" fontWeight="500" sx={{ color: passwordRequirement.caseSensitive ? "#00cb08" : "grey" }}>
										One Lower Case & One Upper Case
									</Typography>
								</Stack>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Stack direction="row" spacing={0.5} alignItems="center">
									<LuCheckCircle color={passwordRequirement.specialChar ? "#00cb08" : "grey"} size={20} />
									<Typography variant="subtitle1" fontWeight="500" sx={{ color: passwordRequirement.specialChar ? "#00cb08" : "grey" }}>
										One Special Characters
									</Typography>
								</Stack>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Stack direction="row" spacing={0.5} alignItems="center">
									<LuCheckCircle color={passwordRequirement.number ? "#00cb08" : "grey"} size={20} />
									<Typography variant="subtitle1" fontWeight="500" sx={{ color: passwordRequirement.number ? "#00cb08" : "grey" }}>
										One Number
									</Typography>
								</Stack>
							</Grid>
						</Grid>
						<LoadingButton
							loading={isLoading}
							variant="contained"
							size="small"
							fullWidth
							disabled={
								!passwordRequirement.number ||
								!passwordRequirement.specialChar ||
								!passwordRequirement.caseSensitive ||
								!passwordRequirement.length ||
								confirmPassword === "" ||
								passwordError
							}
							sx={{
								bgcolor: "#164c68",
								color: "#fff",
								"&:hover": {
									bgcolor: "#00a3b1",
								},
								transition: "all 0.5s ease",
							}}
							onClick={handleResetPassword}
						>
							{t("Reset")}
						</LoadingButton>
					</Stack>
				</Box>
			</Animate>
			{openOtpDialog && <ResetPasswordConfirmation open={openOtpDialog} handleClose={() => setOpenOtpDialog(false)} />}
		</Box>
	);
};

export default ResetPassword;
