import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { images } from "../Assets";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Footer = () => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
	const year = new Date().getFullYear();
	return (
		<Box
			p={2}
			display={"flex"}
			justifyContent={"space-between"}
			alignItems={"center"}
			width={"100%"}
			bgcolor={"#fff"}
			sx={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;" }}
			position={"sticky"}
		>
			<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
				<Typography variant={"caption"} fontWeight={500} color={"#164c68"} sx={{ ml: `${isRtl ? "8px" : "0px"} !important` }}>
					© {year} {t("AllRightsReserved")},
				</Typography>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<img src={images.logo} alt={"logo"} width={20} height={20} />
					<Typography variant={"caption"} fontWeight={500} color={"#164c68"} sx={{ mr: `${isRtl ? "4px" : "0px"} !important` }}>
						The Future Vision for Computer Systems and Networks
					</Typography>
				</Stack>
			</Stack>
			{!isSmallScreen && (
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2}>
					<Typography
						variant={"caption"}
						fontWeight={500}
						color={"#164c68"}
						sx={{
							"&:hover": {
								cursor: "pointer",
							},
						}}
					>
						{t("PrivacyPolicy")}
					</Typography>
					<Typography
						variant={"caption"}
						fontWeight={500}
						color={"#164c68"}
						sx={{
							"&:hover": {
								cursor: "pointer",
							},
							mr: `${isRtl ? "8px" : "0px"} !important`,
						}}
					>
						{t("TermsOfService")}
					</Typography>
				</Stack>
			)}
		</Box>
	);
};

export default Footer;
