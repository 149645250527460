import React, { useEffect, useState } from "react";
import Animate from "../Common/Animate";
import { Box, Button, Divider, IconButton, Stack, Tab, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { images } from "../Assets";
import { setCurrentContact, setIsAddingContact, setIsEditingContact, setIsViewingContact } from "../../redux/slices/contact";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { CiCalendarDate, CiEdit } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import NewContact from "./NewContact";
import { IoMdArrowRoundBack } from "react-icons/io";
import { fetchMedia } from "../Common/utils";
import Link from "@mui/material/Link";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { TfiFiles } from "react-icons/tfi";
import { PiGavelFill } from "react-icons/pi";
import FileSection from "./FileSection";
import { useTranslation } from "react-i18next";
import DeleteDialog from "./DeleteDialog";

const ContactDetails = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const currentContact = useSelector((state) => state.contacts.currentContact);
	const isRtl = useSelector((state) => state.app.isRtl);

	const [openContactForm, setOpenContactForm] = useState(false);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

	const [avatar, setAvatar] = useState(null);
	const [isLoadingAvatar, setIsLoadingAvatar] = useState(false);
	const [tabValue, setTabValue] = useState("files");

	useEffect(() => {
		if (currentContact && currentContact.avatar) {
			const fetchData = async () => {
				setIsLoadingAvatar(true);
				const data = await fetchMedia(currentContact.avatar);
				console.log(data);
				setAvatar(data?.dataUrl);
				setIsLoadingAvatar(false);
			};
			fetchData();
		}
	}, [currentContact]);

	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue);
	};

	const contactMenus = [
		{ id: 1, name: t("CaseFiles"), icon: images.case, path: "caseFiles" },
		{ id: 2, name: t("Meetings"), icon: images.calendar, path: "meetings" },
		{ id: 3, name: t("Files"), icon: images.file, path: "files" },
	];
	return (
		<Animate type="fade" delay={0.5}>
			<Box
				width={"100%"}
				height={"max-content"}
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
				sx={{ overflowY: "auto", overflowX: "hidden" }}
				px={6}
				py={2}
				gap={4}
			>
				<Box
					width={"100%"}
					height={"100vh"}
					gap={6}
					bgcolor={"#fff"}
					sx={{
						overflowY: "auto",
						boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;",
						padding: "32px 32px 0px 32px",
					}}
					borderRadius={1.5}
				>
					<Stack
						direction={"row"}
						flexDirection={isRtl && "row-reverse"}
						justifyContent={"space-between"}
						alignItems={"center"}
						spacing={1}
						width={"100%"}
					>
						<Stack
							direction={"row"}
							flexDirection={isRtl && "row-reverse"}
							justifyContent={"flex-start"}
							alignItems={"center"}
							spacing={1.5}
							width={"100%"}
						>
							<Tooltip title={t("Back")} placement="top" arrow>
								<IconButton
									onClick={() => {
										dispatch(setIsViewingContact(false));
										navigate(-1);
									}}
									sx={{ p: 1 }}
								>
									<IoMdArrowRoundBack color="#164c68" size={18} />
								</IconButton>
							</Tooltip>
							<img src={images.contacts} alt="contacts" width={25} height={25} />
							<Typography variant={"h3"} fontWeight={600} color={"#164c68"}>
								{t("ContactDetails")}
							</Typography>
						</Stack>
						<Stack
							direction={"row"}
							flexDirection={isRtl && "row-reverse"}
							justifyContent={"flex-end"}
							alignItems={"center"}
							spacing={1}
							width={"100%"}
						>
							<Button
								startIcon={!isRtl && <CiEdit />}
								sx={{
									bgcolor: "#164c68",
									color: "#fff",
									"&:hover": {
										bgcolor: "#00a3b1",
									},
									transition: "all 1s ease",
								}}
								onClick={() => {
									dispatch(setIsEditingContact(true));
									dispatch(setCurrentContact(currentContact));
									dispatch(setIsAddingContact(false));
									setOpenContactForm(true);
								}}
							>
								{t("Edit")}
							</Button>
							<Button
								startIcon={!isRtl && <RiDeleteBin6Fill />}
								onClick={() => setOpenDeleteDialog(true)}
								sx={{
									color: "#fff",
									backgroundColor: "#fc6739",
									"&:hover": {
										color: "#fff",
										backgroundColor: "red",
									},
									transition: "all 1s ease",
									marginRight: `${isRtl ? "8px" : "0"} !important`,
								}}
							>
								{t("Delete")}
							</Button>
						</Stack>
					</Stack>
					<Divider sx={{ mt: 2, bgcolor: "#164c6866" }} />
					<Stack
						direction={isSmallScreen ? "column" : "row"}
						justifyContent={isSmallScreen ? "center" : "flex-start"}
						alignItems={isSmallScreen ? "center" : "flex-start"}
						width={"100%"}
						height={"100%"}
						px={isSmallScreen ? 0 : 6}
						spacing={4}
					>
						<Stack
							justifyContent={"flex-start"}
							alignItems={"flex-start"}
							spacing={6}
							width={isSmallScreen ? "100%" : "40%"}
							height={"100%"}
							py={2}
						>
							<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"flex-start"} spacing={3} width={"100%"}>
								<Box
									width={"120px"}
									height={"120px"}
									p={0}
									borderRadius={"50%"}
									bgcolor={"#bdbdbd"}
									display={"flex"}
									justifyContent={"center"}
									alignItems={"center"}
									border={"3px solid #164c68"}
									sx={{
										marginLeft: `${isRtl ? "8px" : "0px"} !important`,
									}}
								>
									<img
										src={avatar ? avatar : images.avatar}
										alt="avatar"
										width={avatar ? "100%" : "80%"}
										height={avatar ? "100%" : "80%"}
										style={{ objectFit: "cover", borderRadius: "50%" }}
									/>
								</Box>
								<Stack justifyContent={"flex-start"} alignItems={"flex-start"} spacing={2} pt={2}>
									<Typography variant={"h2"} fontWeight={600} color={"#164c68"} textTransform={"capitalize"}>
										{currentContact?.nameAr}
									</Typography>
									<Typography variant={"h5"} fontWeight={500} color={"#164c68"}>
										{currentContact?.email.length > 0 ? (
											<Link href={`mailto:${currentContact?.email[0]}`}>{currentContact?.email[0]}</Link>
										) : (
											"N/A"
										)}
									</Typography>
								</Stack>
							</Stack>
							<Stack direction={"row"} justifyContent={"space-evenly"} alignItems={"center"} spacing={5} width={"100%"}>
								{contactMenus.map((menu) => (
									<Stack
										justifyContent={"center"}
										alignItems={"center"}
										spacing={2}
										key={menu.id}
										sx={{
											"&:hover": {
												cursor: "pointer",
											},
											marginLeft: `${isRtl ? "0" : "8px"} !important`,
										}}
										onClick={() => {
											handleChangeTab(null, menu.path);
										}}
									>
										<Box
											width={"50px"}
											height={"50px"}
											p={0}
											borderRadius={"50%"}
											bgcolor={"#f3f7ff"}
											display={"flex"}
											justifyContent={"center"}
											alignItems={"center"}
										>
											<img src={menu.icon} alt="avatar" width={"65%"} height={"65%"} style={{ objectFit: "contain" }} />
										</Box>
										<Typography variant={"h6"} fontWeight={700} color={"#164c68"} textTransform={"uppercase"}>
											{menu.name}
										</Typography>
									</Stack>
								))}
							</Stack>
							<Box width={"100%"} height={"max-content"} my={4} border={"2px solid #164c6866"} />
							<Stack justifyContent={"flex-start"} alignItems={"flex-start"} spacing={4} width={"100%"} pl={4}>
								<Typography variant={"h3"} fontWeight={700} color={"#164c68"} sx={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }}>
									{t("AboutContact")}:
								</Typography>
								{currentContact.email.length > 0 ? (
									currentContact.email?.map((email, index) => (
										<Stack key={index} direction={"row"} justifyContent={"flex-start"} alignItems={"flex-start"} spacing={1} width={"100%"}>
											<Typography
												variant={"h5"}
												fontWeight={700}
												color={"#164c68"}
												sx={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }}
											>
												{t("EmailAddress")}:
											</Typography>

											<a href={"mailto:" + email} style={{ textDecoration: "none" }}>
												<Typography
													variant={"h5"}
													fontWeight={600}
													color={"blue"}
													sx={{ "&:hover": { cursor: "pointer", textDecoration: "underline" } }}
												>
													{email}
												</Typography>
											</a>
										</Stack>
									))
								) : (
									<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"flex-start"} spacing={1} width={"100%"}>
										<Typography
											variant={"h5"}
											fontWeight={700}
											color={"#164c68"}
											sx={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }}
										>
											{t("EmailAddress")}:
										</Typography>
										<Typography variant={"h5"} fontWeight={500} color={"#164c68"}>
											N/A
										</Typography>
									</Stack>
								)}
								{currentContact.phone.length > 0 ? (
									currentContact.phone?.map((phone, index) => (
										<Stack key={index} direction={"row"} justifyContent={"flex-start"} alignItems={"flex-start"} spacing={1} width={"100%"}>
											<Typography
												variant={"h5"}
												fontWeight={700}
												color={"#164c68"}
												sx={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }}
											>
												{t("PhoneNumber")}:
											</Typography>

											<a href={"tel:" + phone} style={{ textDecoration: "none" }}>
												<Typography
													variant={"h5"}
													fontWeight={600}
													color={"blue"}
													sx={{
														"&:hover": { cursor: "pointer", textDecoration: "underline" },
														fontFamily: '"Montserrat", "sans-serif"',
													}}
												>
													{phone}
												</Typography>
											</a>
										</Stack>
									))
								) : (
									<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"flex-start"} spacing={1} width={"100%"}>
										<Typography
											variant={"h5"}
											fontWeight={700}
											color={"#164c68"}
											sx={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }}
										>
											{t("PhoneNumber")}:
										</Typography>
										<Typography variant={"h5"} fontWeight={500} color={"#164c68"}>
											N/A
										</Typography>
									</Stack>
								)}

								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"flex-start"} spacing={1} width={"100%"}>
									<Typography variant={"h5"} fontWeight={700} color={"#164c68"} sx={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }}>
										{t("Address")}:
									</Typography>
									<Typography variant={"h5"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
										{currentContact?.address ? currentContact?.address : "N/A"}
									</Typography>
								</Stack>
								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"flex-start"} spacing={1} width={"100%"}>
									<Typography variant={"h5"} fontWeight={700} color={"#164c68"} sx={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }}>
										{t("Type")}:
									</Typography>
									<Typography variant={"h5"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
										{currentContact?.type ? currentContact?.type : "N/A"}
									</Typography>
								</Stack>
								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"flex-start"} spacing={1} width={"100%"}>
									<Typography variant={"h5"} fontWeight={700} color={"#164c68"} sx={{ mariLeft: `${isRtl ? "8px" : "0px"}` }}>
										{t("Category")}:
									</Typography>
									<Typography variant={"h5"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
										{currentContact?.category ? currentContact?.category : "N/A"}
									</Typography>
								</Stack>
							</Stack>
						</Stack>
						<Box
							height={"100%"}
							width={"100%"}
							px={2}
							sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", overflowY: "scroll", overflowX: "hidden" }}
						>
							<TabContext value={tabValue}>
								<Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
									<TabList onChange={handleChangeTab} aria-label="lab API tabs example">
										<Tab
											icon={
												<PiGavelFill
													color={tabValue === "caseFiles" ? "#0d6666" : "#1f2021"}
													size={20}
													style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }}
												/>
											}
											iconPosition="start"
											label={t("CaseFiles")}
											value="caseFiles"
										/>
										<Tab
											icon={
												<CiCalendarDate
													color={tabValue === "meetings" ? "#0d6666" : "#1f2021"}
													size={20}
													style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }}
												/>
											}
											iconPosition="start"
											label={t("Meetings")}
											value="meetings"
										/>
										<Tab
											icon={
												<TfiFiles
													color={tabValue === "files" ? "#0d6666" : "#1f2021"}
													size={20}
													style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }}
												/>
											}
											iconPosition="start"
											label={t("Files")}
											value="files"
										/>
									</TabList>
								</Box>
								<Divider sx={{ my: 1, bgcolor: "#164c6866" }} />
								<TabPanel value="caseFiles"></TabPanel>
								<TabPanel value="meetings"></TabPanel>
								<TabPanel value="files">
									<FileSection criteria={"contact"} criteriaId={currentContact.id} />
								</TabPanel>
							</TabContext>
						</Box>
					</Stack>
				</Box>
			</Box>
			{openContactForm && <NewContact open={openContactForm} handleClose={() => setOpenContactForm(false)} />}
			{openDeleteDialog && <DeleteDialog open={openDeleteDialog} handleClose={() => setOpenDeleteDialog(false)} />}
		</Animate>
	);
};

export default ContactDetails;
