import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DraggableTask from "./DraggableSection";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const TaskSection = ({ isSmallScreen, category, imgSrc, rows, isLoading, isError, isDragging }) => {
	const [filteredRows, setFilteredRows] = useState([]);
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	useEffect(() => {
		if (rows.length > 0) {
			const filteredData = rows?.filter((task) => task.status === category);
			setFilteredRows(filteredData);
		}
	}, [rows, category]);

	return (
		<Stack justifyContent={"flex-start"} alignItems={"center"} spacing={4} height={"max-content"} width={"100%"}>
			<Stack direction={"row"} justifyContent={"center"} alignItems={"flex-start"} spacing={1} height={"100%"} width={"100%"}>
				<img src={imgSrc} alt={category} width={30} height={30} />
				<Typography variant={"h2"} fontWeight={600} color={"#164c68"} sx={{ mr: `${isRtl ? "8px" : "0px"} !important` }}>
					{category === "In Progress" ? t("InProgress") : t(category)}
				</Typography>
			</Stack>
			<Stack justifyContent={"center"} alignItems={"center"} spacing={5} height={"100%"} width={"100%"}>
				{isLoading && !isError ? (
					<Box width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
						<CircularProgress />
					</Box>
				) : (
					filteredRows.map((task) => {
						return <DraggableTask key={task.id} task={task} isSmallScreen={isSmallScreen} isDragging={isDragging} />;
					})
				)}
			</Stack>
		</Stack>
	);
};

export default TaskSection;
