import { apiSlice } from "../apiSlice";
import { queryBuilder } from "../../../../utils/queryBuilder";

export const tableViewApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getTableView: builder.query({
			query: (query) => ({
				url: `/table-view?${queryBuilder(query)}`,
				method: "GET",
			}),
			providesTags: ["TableView"],
		}),
		setTableView: builder.mutation({
			query: (data) => ({
				url: "/table-view",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["TableView"],
		}),
		updateTableView: builder.mutation({
			query: ({ templateId, data }) => ({
				url: `/table-view/${templateId}`,
				method: "PATCH",
				body: data,
			}),
			invalidatesTags: ["TableView"],
		}),
		deleteTableView: builder.mutation({
			query: (templateId) => ({
				url: `/table-view/${templateId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["TableView"],
		}),
	}),
});

export const { useGetTableViewQuery, useSetTableViewMutation, useUpdateTableViewMutation, useDeleteTableViewMutation } = tableViewApi;
