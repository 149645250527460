import React, { useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { setSelectedTask } from "../../../redux/slices/task";
import { useGetConstraintsQuery } from "../../../redux/slices/api/constraints/constraintsApi";
import { useGetAllUsersQuery } from "../../../redux/slices/api/user/userApi";
import { useGetCasesQuery } from "../../../redux/slices/api/cases/caseApi";
import { AiOutlineFilePdf } from "react-icons/ai";
import { excelExport, handleConvertToPdf, taskFilePreProcessor } from "../../../utils/exportHelper";
import { SiMicrosoftexcel } from "react-icons/si";
import SaveTableView from "../../Common/SaveTableView";
import { setCurrentCase } from "../../../redux/slices/case";

const TaskTableReport = ({
	data,
	pagination,
	setPagination,
	pageCount,
	colFilter,
	setColFilter,
	columnVisibility,
	setColumnVisibility,
	columnSizing,
	setColumnSizing,
	columnOrder,
	setColumnOrder,
	setDefaultView,
	selectedTemplate,
	setSelectedTemplate,
	setHandleDeleteView,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const isRtl = useSelector((state) => state.app.isRtl);

	const taskTitle = useGetConstraintsQuery({ reference: "taskTitle" }, { refetchOnMountOrArgChange: true });
	const users = useGetAllUsersQuery({}, { refetchOnMountOrArgChange: true });
	const cases = useGetCasesQuery({}, { refetchOnMountOrArgChange: true });

	const [isLoading, setIsLoading] = useState(false);

	const statusOptions = [
		{ label: t("New"), value: "New" },
		{ label: t("InProgress"), value: "In Progress" },
		{ label: t("Completed"), value: "Completed" },
		{ label: t("Canceled"), value: "Canceled" },
	];

	const columns = useMemo(
		() => [
			{
				accessorFn: (row) => (row.caseFile ? row.caseFile.id : "N/A"),
				id: "caseFile",
				header: t("FileNumber"),
				filterVariant: "autocomplete",
				filterSelectOptions: cases.data?.data?.map((c) => ({ value: c.id, label: c.fileNumber.toString() })),
				size: columnSizing?.caseFile || 300,
				Cell: ({ renderedCellValue, row }) => {
					const caseFileNumber = row?.original?.caseFile ? row?.original?.caseFile?.fileNumber.toString() : "N/A";
					return (
						<Box
							sx={{
								borderRadius: "5px",
								padding: "5px",
								fontWeight: "bold",
							}}
						>
							<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
								{caseFileNumber}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessorFn: (row) => (row.caseFile ? row.caseFile.id : "N/A"),
				id: "caseFile.caseNumber",
				header: t("CaseFileFullName"),
				filterVariant: "autocomplete",
				filterSelectOptions: cases.data?.data?.map((c) => ({ value: c.id, label: c.caseNumber })),
				size: columnSizing?.["caseFile.caseNumber"] || 300,
				Cell: ({ renderedCellValue, row }) => {
					const caseFileNumber = row?.original?.caseFile
						? row?.original?.caseFile?.classification === "litigation"
							? `${row?.original?.caseFile?.caseNumber}/${row?.original?.caseFile?.caseYear}`
							: "N/A"
						: "N/A";
					return (
						<Box
							sx={{
								borderRadius: "5px",
								padding: "5px",
								fontWeight: "bold",
							}}
						>
							<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
								{caseFileNumber}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessorFn: (row) => new Date(row?.dueDate).toLocaleDateString(),
				id: "dueDate",
				header: t("DueDate"),
				enableColumnFilter: false,
				size: columnSizing?.dueDate || 300,
				Cell: ({ renderedCellValue, row }) => {
					const date = row?.original?.dueDate ? moment(row?.original?.dueDate).format("YYYY/MM/DD") : "N/A";
					return (
						<Box
							sx={{
								borderRadius: "5px",
								padding: "5px",
								fontWeight: "bold",
							}}
						>
							<Typography variant={"h6"} fontWeight={500} color={"#164c68"} textTransform={"capitalize"}>
								{date}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessorKey: "status",
				header: t("Status"),
				filterVariant: "select",
				filterSelectOptions: statusOptions,
				size: columnSizing?.status || 300,
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							borderRadius: 6,
							padding: 0.5,
							backgroundColor:
								renderedCellValue === "New"
									? "#edaf41"
									: renderedCellValue === "In Progress"
									? "#116ee9"
									: renderedCellValue === "Completed"
									? "#74c193"
									: "#fc6b77",
							textAlign: "center",
							textTransform: "capitalize",
							minWidth: "85px",
						}}
					>
						<Typography variant={"subtitle2"} fontWeight={500} color={"#fff"}>
							{t(renderedCellValue)}
						</Typography>
					</Box>
				),
			},
			{
				accessorKey: "priority",
				header: t("Priority"),
				filterVariant: "select",
				filterSelectOptions: [
					{ label: t("Low"), value: "Low" },
					{ label: t("Medium"), value: "Medium" },
					{ label: t("High"), value: "High" },
				],
				size: columnSizing?.priority || 300,
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							borderRadius: 6,
							padding: 0.5,
							backgroundColor: renderedCellValue === "Low" ? "#299e7b" : renderedCellValue === "Medium" ? "#edaf41" : "#fc6b77",
							textAlign: "center",
							textTransform: "capitalize",
							minWidth: "85px",
						}}
					>
						<Typography variant={"subtitle2"} fontWeight={500} color={"#fff"}>
							{t(renderedCellValue)}
						</Typography>
					</Box>
				),
			},
			{
				accessorFn: (row) =>
					row?.taskTitle
						? isRtl
							? row?.taskTitle?.nameAr
							: row?.taskTitle?.nameEn
							? row?.taskTitle?.nameEn
							: row?.taskTitle?.nameAr
						: row?.title
						? row?.title
						: "N/A",
				header: t("TaskTitle"),
				accessorKey: "taskTitle",
				filterVariant: "select",
				filterSelectOptions: taskTitle?.data?.data?.map((constraint) => ({
					value: constraint?.id,
					label: isRtl ? constraint?.nameAr : constraint?.nameEn ? constraint?.nameEn : constraint?.nameAr,
				})),
				size: columnSizing?.taskTitle || 300,
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							borderRadius: "5px",
							padding: "5px",
							fontWeight: "bold",
						}}
					>
						<Typography variant={"h6"} fontWeight={500} color={"#164c68"}>
							{renderedCellValue}
						</Typography>
					</Box>
				),
			},
			{
				accessorFn: (row) => row.createdBy?.id,
				accessorKey: "createdBy",
				header: t("CreatedBy"),
				size: columnSizing?.createdBy || 300,
				filterVariant: "autocomplete",
				filterSelectOptions: users?.data?.data?.map((user) => ({
					value: user?.id,
					label: user?.fullName,
				})),
				Cell: ({ renderedCellValue, row }) => {
					const createdBy = row?.original?.createdBy?.fullName || "N/A";
					return (
						<Box
							sx={{
								borderRadius: "5px",
								padding: "5px",
								fontWeight: "bold",
							}}
						>
							<Typography variant={"h6"} fontWeight={500} color={"#164c68"}>
								{createdBy}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessorFn: (row) => row.assignedTo?.map((user) => user.id).join(", "),
				header: t("AssignedTo"),
				accessorKey: "assignedTo",
				filterVariant: "autocomplete",
				filterSelectOptions: users?.data?.data?.map((user) => ({
					value: user?.id,
					label: user?.fullName,
				})),
				size: columnSizing?.assignedTo || 300,
				Cell: ({ renderedCellValue, row }) => {
					const assignedTo = row?.original?.assignedTo?.map((user) => user?.fullName).join(", ") || "N/A";
					return (
						<Box
							sx={{
								borderRadius: "5px",
								padding: "5px",
								fontWeight: "bold",
							}}
						>
							<Typography variant={"h6"} fontWeight={500} color={"#164c68"}>
								{assignedTo}
							</Typography>
						</Box>
					);
				},
			},
		],
		[]
	);

	const table = useMaterialReactTable({
		columns,
		data,
		columnFilterDisplayMode: "popover",
		muiTableHeadCellProps: {
			align: "center",
			sx: {
				color: "#164c68",
				"& .css-d0cm6k .MuiTableSortLabel-icon": {
					color: "#164c68 !important",
				},
				"& .MuiTableSortLabel-icon": {
					color: "#164c68 !important",
				},
				"& .MuiTableSortLabel-iconDirectionAsc": {
					color: "#164c68 !important",
				},
				"& .MuiTableSortLabel-iconDirectionDesc": {
					color: "#164c68 !important",
				},
			},
		},
		muiTableBodyCellProps: {
			align: "center",
		},
		enableStickyHeader: true,
		enableGlobalFilter: false,
		enableColumnResizing: true,
		enableColumnDragging: true,
		enableColumnOrdering: true,
		initialState: { density: "compact" },
		enableFullScreenToggle: false,
		enableRowActions: false,
		paginationDisplayMode: "pages",
		muiTableBodyRowProps: ({ row }) => ({
			sx: {
				backgroundColor: "#fff !important",
			},
			onClick: () => {
				dispatch(setSelectedTask(row.original));
				dispatch(setCurrentCase(row.original.caseFile));
				navigate("/dashboard/task/details");
			},
		}),
		manualPagination: true,
		manualFiltering: true,
		muiPaginationProps: {
			color: "primary",
			rowsPerPageOptions: [10, 20, 30, 50, 100],
			shape: "rounded",
			variant: "outlined",
		},
		rowCount: pagination.pageSize,
		pageCount: pageCount,
		onPaginationChange: setPagination,
		onColumnFiltersChange: setColFilter,
		onColumnVisibilityChange: setColumnVisibility,
		onColumnOrderChange: setColumnOrder,
		onColumnSizingChange: setColumnSizing,
		state: {
			pagination,
			colFilter,
			columnVisibility,
			columnOrder,
			columnSizing,
		},
		renderTopToolbarCustomActions: ({ table }) => (
			<Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} gap={1}>
				<Button
					disabled={isLoading}
					size="small"
					startIcon={!isRtl && <AiOutlineFilePdf color="#fff" />}
					onClick={() => {
						const visibleColumns = table.getVisibleFlatColumns();
						const notReorderedColumns = visibleColumns?.map((column) => column.id);
						const reorderedColumns = columnOrder.filter((column) => notReorderedColumns.includes(column) && column !== "mrt-row-spacer");
						const formattedVisibleColumns = reorderedColumns.map((column) => {
							let columnHeaderName = column;
							if (columnHeaderName === "caseFile") {
								columnHeaderName = t("FileNumber");
							}
							if (columnHeaderName === "caseFile.caseNumber") {
								columnHeaderName = t("CaseFileFullName");
							}
							if (columnHeaderName === "dueDate") {
								columnHeaderName = t("DueDate");
							}
							if (columnHeaderName === "status") {
								columnHeaderName = t("Status");
							}
							if (columnHeaderName === "priority") {
								columnHeaderName = t("Priority");
							}
							if (columnHeaderName === "taskTitle") {
								columnHeaderName = t("TaskTitle");
							}
							if (columnHeaderName === "createdBy") {
								columnHeaderName = t("CreatedBy");
							}
							if (columnHeaderName === "assignedTo") {
								columnHeaderName = t("AssignedTo");
							}
							return { title: columnHeaderName, dataKey: columnHeaderName };
						});
						const rows = table.getPrePaginationRowModel().rows.map((row) => row.original);
						const dataToConvert = taskFilePreProcessor(rows, visibleColumns, isRtl);
						const reorderedData = dataToConvert.map((obj) => {
							let reorderedObj = {};
							reorderedColumns.forEach((key) => {
								reorderedObj[key] = obj[key];
							});
							return reorderedObj;
						});
						handleConvertToPdf(formattedVisibleColumns, reorderedData, "Task Report.pdf", isRtl);
					}}
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						padding: 1,
						"&:hover": {
							bgcolor: "#00a3b1",
						},
						transition: "all 0.5s ease",
						marginLeft: `${isRtl ? "16px" : "0px"} !important`,
					}}
				>
					{t("ExportPdf")}
				</Button>
				<Button
					disabled={isLoading}
					size="small"
					startIcon={!isRtl && <SiMicrosoftexcel color="#fff" />}
					onClick={() => {
						const visibleColumns = table.getVisibleFlatColumns();
						const notReorderedColumns = visibleColumns?.map((column) => column.id);
						const reorderedColumns = columnOrder.filter((column) => notReorderedColumns.includes(column) && column !== "mrt-row-spacer");
						const formattedVisibleColumns = reorderedColumns.map((column) => {
							let columnHeaderName = column;
							if (columnHeaderName === "caseFile") {
								columnHeaderName = t("FileNumber");
							}
							if (columnHeaderName === "caseFile.caseNumber") {
								columnHeaderName = t("CaseFileFullName");
							}
							if (columnHeaderName === "dueDate") {
								columnHeaderName = t("DueDate");
							}
							if (columnHeaderName === "status") {
								columnHeaderName = t("Status");
							}
							if (columnHeaderName === "priority") {
								columnHeaderName = t("Priority");
							}
							if (columnHeaderName === "taskTitle") {
								columnHeaderName = t("TaskTitle");
							}
							if (columnHeaderName === "createdBy") {
								columnHeaderName = t("CreatedBy");
							}
							if (columnHeaderName === "assignedTo") {
								columnHeaderName = t("AssignedTo");
							}
							return columnHeaderName;
						});
						const rows = table.getPrePaginationRowModel().rows.map((row) => row.original);
						const dataToConvert = taskFilePreProcessor(rows, visibleColumns, isRtl);
						const formattedData = dataToConvert.map((row) => {
							let formattedRow = {};
							for (let key in row) {
								let newKey = key;
								if (key === "caseFile") {
									newKey = t("FileNumber");
								}
								if (key === "caseFile.caseNumber") {
									newKey = t("CaseFileFullName");
								}
								if (key === "dueDate") {
									newKey = t("DueDate");
								}
								if (key === "status") {
									newKey = t("Status");
								}
								if (key === "priority") {
									newKey = t("Priority");
								}
								if (key === "taskTitle") {
									newKey = t("TaskTitle");
								}
								if (key === "createdBy") {
									newKey = t("CreatedBy");
								}
								if (key === "assignedTo") {
									newKey = t("AssignedTo");
								}
								formattedRow[newKey] = row[key];
							}
							return formattedRow;
						});
						excelExport(formattedData, formattedVisibleColumns, "Task Report", "Task Report.xlsx");
					}}
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						padding: 1,
						"&:hover": {
							bgcolor: "#00a3b1",
						},
						transition: "all 0.5s ease",
					}}
				>
					{t("ExportExcel")}
				</Button>
				<SaveTableView
					allColumns={table.getAllFlatColumns()}
					visibleColumns={table.getVisibleFlatColumns()}
					setColumnVisibility={setColumnVisibility}
					selectedColumnOrder={columnOrder}
					setColumnOrder={setColumnOrder}
					tableType={"task"}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
					selectedTemplate={selectedTemplate}
					setSelectedTemplate={setSelectedTemplate}
					setDefaultView={setDefaultView}
					mode={"update"}
				/>

				<SaveTableView
					allColumns={table.getAllFlatColumns()}
					visibleColumns={table.getVisibleFlatColumns()}
					setColumnVisibility={setColumnVisibility}
					selectedColumnOrder={columnOrder}
					setColumnOrder={setColumnOrder}
					tableType={"task"}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
					selectedTemplate={selectedTemplate}
					setSelectedTemplate={setSelectedTemplate}
					setDefaultView={setDefaultView}
					mode={"save"}
				/>
				<Button
					onClick={() => setHandleDeleteView(true)}
					disabled={isLoading}
					sx={{
						color: "#fff",
						backgroundColor: "#fc6739",
						"&:hover": {
							color: "#fff",
							backgroundColor: "red",
						},
						transition: "all 1s ease",
						padding: 1,
					}}
				>
					{t("DeleteTableView")}
				</Button>
				{colFilter?.length > 0 && (
					<Button
						onClick={() => setColFilter([])}
						disabled={isLoading}
						sx={{
							color: "#fff",
							backgroundColor: "#fc6739",
							"&:hover": {
								color: "#fff",
								backgroundColor: "red",
							},
							transition: "all 1s ease",
							padding: 1,
						}}
					>
						{t("ClearFilter")}
					</Button>
				)}
			</Box>
		),
	});

	return <MaterialReactTable table={table} />;
};

export default TaskTableReport;
