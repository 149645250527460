import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Fade, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { IoCaretDownOutline } from "react-icons/io5";
import { images } from "../../Assets";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import UserCard from "../../Case/CaseDetails/SecondSection/UserCard";

const UsersSection = ({ isSmallScreen, assignedTo, setAssigningUsers }) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);
	const [expanded, setExpanded] = useState(false);

	const handleExpansion = () => {
		setExpanded((prevExpanded) => !prevExpanded);
	};
	return (
		<Accordion
			expanded={expanded}
			onChange={handleExpansion}
			slots={{ transition: Fade }}
			slotProps={{ transition: { timeout: 1500 } }}
			sx={{
				bgcolor: "#fff",
				boxShadow: "none",
				"& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
				"& .MuiAccordionDetails-root": { display: expanded ? "block" : "none" },
				width: "100%",
			}}
		>
			<AccordionSummary expandIcon={<IoCaretDownOutline size={20} color={"#164c68"} />} aria-controls="panel1-content" id="panel1-header">
				<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={1}>
						<Box p={1} display={"flex"} justifyContent={"center"} alignItems={"center"} bgcolor={"#e9e7e770"} borderRadius={1}>
							<img src={images.team} alt="users" width={23} height={23} />
						</Box>
						<Typography variant={"h3"} fontWeight={500} color={"#164c68"} sx={{ mr: `${isRtl ? "8px" : "0px"} !important` }}>
							{t("Users")}
						</Typography>
					</Stack>
					<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} width={"100%"} mr={1}>
						<Button
							onClick={() => {
								setAssigningUsers(true);
							}}
							sx={{
								bgcolor: "#164c68",
								color: "#fff",
								"&:hover": {
									bgcolor: "#00a3b1",
									color: "#fff",
								},
								transition: "all 1s ease",
							}}
						>
							{t("ManageUsers")}
						</Button>
					</Stack>
				</Stack>
			</AccordionSummary>
			<AccordionDetails>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2} p={2} width={"100%"}>
					{assignedTo.length > 0 ? (
						<Grid container spacing={2}>
							{assignedTo?.map((user, index) => (
								<Grid item xs={12} sm={3} key={index}>
									<UserCard user={user} />
								</Grid>
							))}
						</Grid>
					) : (
						<Box
							width={"100%"}
							height={"100%"}
							display={"flex"}
							flexDirection={"column"}
							justifyContent={"center"}
							alignItems={"center"}
							m={"0 auto"}
							p={2}
						>
							<img src={images.notFound} alt="Not Found" width={200} height={200} />
							<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ my: 2 }}>
								{t("NoUsers")}!
							</Typography>
							<Typography variant={"subtitle1"} fontWeight={500} color={"#51505099"}>
								{t("AddUser")}!
							</Typography>
						</Box>
					)}
				</Stack>
			</AccordionDetails>
		</Accordion>
	);
};

export default UsersSection;
