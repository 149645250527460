import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import Animate from "../../../components/Common/Animate";
import { Box, Fab, Tooltip } from "@mui/material";
import SecondSection from "../../../components/Case/CaseDetails/SecondSection";
import FirstSection from "../../../components/Case/CaseDetails/FirstSection";
import { MdEmail } from "react-icons/md";
import CreateEmail from "../../../components/Settings/Email/CreateEmail";
import FileSection from "../../../components/Case/CaseDetails/FileSection";
import { setCurrentMemo } from "../../../redux/slices/memo";
import { setSelectedTask } from "../../../redux/slices/task";
import { setCurrentMeeting } from "../../../redux/slices/meetings";
import { setCurrentHearing } from "../../../redux/slices/case";

const CaseDetailsSection = () => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
	const [createEmail, setCreateEmail] = useState(false);
	const currentCase = useSelector((state) => state.case.currentCase);

	const dispatch = useDispatch();

	const [isMounted, setIsMounted] = useState(false);

	useEffect(() => {
		if (!isMounted) {
			setIsMounted(true);
		} else {
			dispatch(setCurrentMemo(null));
			dispatch(setCurrentMeeting(null));
			dispatch(setCurrentHearing(null));
			dispatch(setSelectedTask(null));
		}
	}, [isMounted]);
	return (
		<Animate type="fade" delay={0.5}>
			<Box
				width={"100%"}
				height={"max-content"}
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
				sx={{ overflowY: "auto", overflowX: "hidden" }}
				px={isSmallScreen ? 6 : 15}
				py={2}
				gap={2}
			>
				<FirstSection category={"case"} />
				<SecondSection />
				<FileSection criteria={"caseFile"} criteriaId={currentCase.id} />
			</Box>
			<Tooltip title="Send Email" placement="top" arrow>
				<Fab
					color="primary"
					sx={{
						position: "fixed",
						bottom: 80,
						right: "2%",
						transition: "all 0.3s ease-in-out",
					}}
					size="large"
					aria-label="Send Email"
					onClick={() => setCreateEmail(true)}
				>
					<MdEmail size={32} />
				</Fab>
			</Tooltip>
			{createEmail && <CreateEmail open={createEmail} handleClose={() => setCreateEmail(false)} />}
		</Animate>
	);
};

export default CaseDetailsSection;
