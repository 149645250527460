import { ResponsiveBar } from "@nivo/bar";
import React, { useEffect, useState } from "react";
import { useGetCasesQuery } from "../../../redux/slices/api/cases/caseApi";
import { useTranslation } from "react-i18next";

const ClassificationChart = () => {
	const { t } = useTranslation();
	const [count, setCount] = useState([{}]);

	const litigation = useGetCasesQuery({ classification: "litigation", data: false }, { refetchOnMountOrArgChange: true });
	const consultation = useGetCasesQuery({ classification: "consultation", data: false }, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (litigation.data && consultation.data) {
			setCount([
				{ Classification: t("Litigation"), Count: litigation.data.totalRecords },
				{ Classification: t("Consultation"), Count: consultation.data.totalRecords },
			]);
		}
	}, [litigation, consultation]);

	return (
		<ResponsiveBar
			data={count}
			keys={["Count"]}
			indexBy="Classification"
			margin={{ top: 30, right: 30, bottom: 50, left: 70 }}
			padding={0.3}
			valueScale={{ type: "linear" }}
			indexScale={{ type: "band", round: true }}
			yScale={{
				type: "linear",
				min: "auto",
				max: "auto",
				stacked: true,
				reverse: false,
			}}
			enableLabel={false}
			colors={{ scheme: "nivo" }}
			borderColor={{
				from: "color",
				modifiers: [["darker", 1.6]],
			}}
			axisTop={null}
			axisRight={null}
			axisBottom={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: t("Classification"),
				legendPosition: "middle",
				legendOffset: 32,
				truncateTickAt: 0,
			}}
			axisLeft={{
				tickSize: 5,
				tickPadding: 20,
				tickRotation: 0,
				legend: t("Count"),
				legendPosition: "middle",
				legendOffset: -60,
				truncateTickAt: 0,
			}}
			labelSkipWidth={12}
			labelSkipHeight={12}
			labelTextColor={{
				from: "color",
				modifiers: [["darker", 1.6]],
			}}
			role="application"
			ariaLabel="Nivo bar chart demo"
			barAriaLabel={(e) => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
		/>
	);
};

export default ClassificationChart;
