import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	selectedTask: null,
	isEditingTask: false,
	isCreatingTask: false,
};

export const taskSlice = createSlice({
	name: "task",
	initialState,
	reducers: {
		setSelectedTask: (state, action) => {
			state.selectedTask = action.payload;
		},
		setIsEditingTask: (state, action) => {
			state.isEditingTask = action.payload;
		},
		setIsCreatingTask: (state, action) => {
			state.isCreatingTask = action.payload;
		},
	},
});

export const { setSelectedTask, setIsEditingTask, setIsCreatingTask } = taskSlice.actions;

export default taskSlice.reducer;
