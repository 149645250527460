import { Box, IconButton, Popper, Stack, Tooltip, Typography } from "@mui/material";
import DOMPurify from "dompurify";
import React from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { images } from "../Assets";
import { useTranslation } from "react-i18next";
import { FaCaretDown } from "react-icons/fa";
import moment from "moment";
import { useDeleteEmailMessageMutation } from "../../redux/slices/api/email/emailMessageApi";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../redux/slices/app";

const EmailDetails = ({ email, setSelectedEmail }) => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [loading, setLoading] = React.useState(false);

	const handleClick = (event) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const cleanHTML = DOMPurify.sanitize(email.email.html);

	const open = Boolean(anchorEl);
	const id = open ? "simple-popper" : undefined;

	const [deleteEmail] = useDeleteEmailMessageMutation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const handleDeleteEmail = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			await deleteEmail(email.id);
			dispatch(setSnackbar({ open: true, message: t("EmailDeletedSuccessfully"), severity: "success" }));
			setLoading(false);
			setSelectedEmail(null);
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setLoading(false);
		}
	};

	return (
		<Stack justifyContent={"flex-start"} alignItems={"flex-start"}>
			<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} p={2} bgcolor={"#f5f4f4"}>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={3} width={"100%"}>
					<Tooltip title={t("Back")} arrow placement="top">
						<IconButton sx={{ p: 0 }} disabled={loading} onClick={() => setSelectedEmail(null)}>
							<IoMdArrowRoundBack color={"#164c68"} />
						</IconButton>
					</Tooltip>
				</Stack>
				<Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={2} width={"100%"}>
					<img src={images.emailSubject} alt={"email"} width={30} height={30} />
					<Typography variant={"h2"} fontWeight={600} color={"#164c68"}>
						{email.email.subject}
					</Typography>
				</Stack>
				<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={3} width={"100%"}>
					<Tooltip title={t("Delete")} arrow placement="top">
						<IconButton sx={{ p: 0 }} disabled={loading} onClick={handleDeleteEmail}>
							<RiDeleteBin6Fill color="red" />
						</IconButton>
					</Tooltip>
				</Stack>
			</Stack>
			<Stack justifyContent={"flex-start"} alignItems={"flex-start"} p={4} spacing={4} width={"100%"} height={"100%"} overflow={"auto"}>
				<Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-start"} width={"100%"}>
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"flex-start"} spacing={2} width={"100%"}>
						<img src={images.sender} alt={"sender"} width={40} height={40} />
						<Stack justifyContent={"flex-start"} alignItems={"flex-start"} spacing={1} width={"100%"}>
							<Typography variant={"h5"} fontWeight={500} color={"#164c68"}>
								{t("From")}: {email.destination.from}
							</Typography>
							<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={0.5}>
								<Typography variant={"h6"} fontWeight={500} color={"#164c68"}>
									{t("To")}: {email.destination.to[0]}
								</Typography>
								<IconButton sx={{ p: 0 }} onClick={handleClick}>
									<FaCaretDown color={"#164c68"} />
								</IconButton>
							</Stack>
							<Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
								<Box sx={{ p: 2, borderRadius: 1, bgcolor: "#f5f4f4", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
									<Stack justifyContent={"flex-start"} alignItems={"flex-start"} spacing={2}>
										<Typography variant={"title"} fontWeight={500} color={"#164c68"}>
											{t("From")}: {email.destination.from}
										</Typography>
										<Typography variant={"title"} fontWeight={500} color={"#164c68"}>
											{t("To")}:{" "}
											{email.destination.to.map((to, index) => (
												<React.Fragment key={to}>
													{to}
													{index < email.destination.to.length - 1 && (
														<React.Fragment>
															, <br />
														</React.Fragment>
													)}
												</React.Fragment>
											))}
										</Typography>
										<Typography variant={"title"} fontWeight={500} color={"#164c68"}>
											{t("Cc")}:{" "}
											{email.destination.cc.map((cc, index) => (
												<React.Fragment key={cc}>
													{cc}
													{index < email.destination.cc.length - 1 && (
														<React.Fragment>
															, <br />
														</React.Fragment>
													)}
												</React.Fragment>
											))}
										</Typography>
										<Typography variant={"title"} fontWeight={500} color={"#164c68"}>
											{t("Bcc")}:{" "}
											{email.destination.bcc.map((bcc, index) => (
												<React.Fragment key={bcc}>
													{bcc}
													{index < email.destination.bcc.length - 1 && (
														<React.Fragment>
															, <br />
														</React.Fragment>
													)}
												</React.Fragment>
											))}
										</Typography>
										<Typography
											variant={"title"}
											fontWeight={500}
											color={"#164c68"}
											sx={{
												fontFamily: '"Montserrat", "sans-serif"',
											}}
										>
											{t("SentAt")}: {moment(email.createdAt).format("MMM DD YYYY, hh:mm A")}
										</Typography>
										<Typography variant={"h5"} fontWeight={600} color={"#164c68"}>
											{t("Subject")}: {email.email.subject}
										</Typography>
									</Stack>
								</Box>
							</Popper>
						</Stack>
					</Stack>
					<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={2} width={"100%"}>
						<Typography
							variant={"h5"}
							fontWeight={500}
							color={"#164c68"}
							sx={{
								fontFamily: '"Montserrat", "sans-serif"',
							}}
						>
							{moment(email.createdAt).format("MMM DD YYYY, hh:mm A")}, {`${moment(email.createdAt).fromNow()}`}
						</Typography>
					</Stack>
				</Stack>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"flex-start"} spacing={2} width={"100%"}>
					<Stack justifyContent={"flex-start"} alignItems={"flex-start"} spacing={1} width={"100%"}>
						<Typography variant={"h5"} fontWeight={500} color={"#164c68"}>
							{email.email.text ? email.email.text : <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />}
						</Typography>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default EmailDetails;
