import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FcCancel } from "react-icons/fc";
import { Slide, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useDeleteContactMutation } from "../../redux/slices/api/contacts/contactApi";
import { setSnackbar } from "../../redux/slices/app";
import SectionHeader from "../Common/SectionHeader";
import { useNavigate } from "react-router-dom";
import { setCurrentCase } from "../../redux/slices/case";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteDialog = ({ open, handleClose }) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);
	const currentContact = useSelector((state) => state.contacts.currentContact);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = React.useState(false);

	const [deleteContact] = useDeleteContactMutation();

	const handleDelete = async (e) => {
		e.preventDefault();
		setIsLoading(true);

		try {
			await deleteContact(currentContact.id).unwrap();
			setIsLoading(false);
			dispatch(setCurrentCase(null));
			dispatch(setSnackbar({ open: true, message: t("ContactDeleted"), severity: "success" }));
			navigate("/dashboard/contacts/");
			handleClose();
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setIsLoading(false);
		}
	};

	return (
		<Dialog open={open} fullWidth TransitionComponent={Transition} keepMounted aria-describedby="add-user-dialog" sx={{ height: "100%" }}>
			<DialogTitle sx={{ pb: 2, borderBottom: "1px solid #aaaaaa6b", backgroundColor: "#fff" }}>
				<SectionHeader icon={<FcCancel size={18} />} title={`${t("DeleteContact")}?`} type={"dialog"} />
			</DialogTitle>
			<DialogContent sx={{ backgroundColor: "#fff" }}>
				<DialogContentText id="cancelling-dialog-description" sx={{ p: 4 }}>
					<Typography variant={"h5"} fontWeight={600} color={"#164c68"}>
						{t("DeleteContactDialog")}
					</Typography>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: "#fff" }}>
				<Button
					disabled={isLoading}
					onClick={handleClose}
					sx={{
						color: "#fff",
						backgroundColor: "#fc6739",
						"&:hover": {
							color: "#fff",
							backgroundColor: "red",
						},
						transition: "all 1s ease",
					}}
				>
					{t("No")}
				</Button>
				<Button
					disabled={isLoading}
					variant="contained"
					size="medium"
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						marginRight: `${isRtl ? "8px" : "0px"} !important`,
					}}
					onClick={handleDelete}
				>
					{t("Yes")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteDialog;
