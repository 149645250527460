import { Box, Button, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaUser, FaUsers } from "react-icons/fa";
import { IoMicOffCircle } from "react-icons/io5";
import { VscLaw } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentContact, setIsViewingContact } from "../../redux/slices/contact";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchMedia } from "../Common/utils";
import { images } from "../Assets";

const ContactCard = ({ contact, searchValue, isSearching }) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [avatarData, setAvatarData] = useState(null);
	const [avatarLoading, setAvatarLoading] = useState(false);
	const [contactEmail, setContactEmail] = useState([]);
	const [contactPhone, setContactPhone] = useState([]);

	useEffect(() => {
		if (contact.avatar) {
			const fetchData = async () => {
				setAvatarLoading(true);
				const data = await fetchMedia(contact.avatar);
				setAvatarData(data?.dataUrl);
				setAvatarLoading(false);
			};
			fetchData();
		}

		if (contact.email.length > 0) {
			const emails = contact.email.map((email) => email);
			setContactEmail(emails);
		}

		if (contact.phone.length > 0) {
			const phones = contact.phone.map((phone) => phone);
			setContactPhone(phones);
		}
	}, [contact]);

	return (
		<Box
			key={contact?.id}
			width={"100%"}
			p={4}
			height={"100%"}
			bgcolor={"#fff"}
			borderRadius={1.5}
			sx={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;" }}
		>
			<Stack justifyContent={"space-between"} height={"100%"} mb={2}>
				<Box>
					<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2}>
							{avatarLoading ? (
								<CircularProgress />
							) : (
								<Box
									width={"60px"}
									height={"60px"}
									borderRadius={"50%"}
									bgcolor={"#bdbdbd"}
									display={"flex"}
									justifyContent={"center"}
									alignItems={"center"}
									sx={{ ml: `${isRtl ? "8px" : "0px"} !important` }}
								>
									<img
										id="party-avatar"
										loading="lazy"
										width="100%"
										height="100%"
										srcSet={avatarData || images.avatar}
										src={avatarData || images.avatar}
										// srcSet={images.avatar}
										// src={images.avatar}
										alt="avatar"
										style={{ objectFit: "cover", borderRadius: "50%" }}
									/>
								</Box>
							)}

							<Stack justifyContent={"flex-start"} alignItems={"start"}>
								{isSearching ? (
									<Highlighter
										highlightClassName="YourHighlightClass"
										searchWords={[searchValue]}
										autoEscape={true}
										textToHighlight={contact.nameAr}
									/>
								) : (
									<Typography variant={"h5"} fontWeight={700} color={"#595858"}>
										{contact.nameAr}
									</Typography>
								)}
							</Stack>
						</Stack>
					</Stack>
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={1} mb={4}>
						{typeof contactEmail === "object" && contactEmail.length >= 1 ? (
							contactEmail?.map((email, index) => (
								<Stack key={index} direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
									<MdEmail color="#164c68" size={18} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />

									<a href={"mailto:" + email} style={{ textDecoration: "none" }}>
										<Typography
											variant={"h5"}
											fontWeight={600}
											color={"blue"}
											sx={{ "&:hover": { cursor: "pointer", textDecoration: "underline" } }}
										>
											{email}
										</Typography>
									</a>
								</Stack>
							))
						) : (
							<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
								<MdEmail color="#164c68" size={18} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
								<Typography variant={"h5"} fontWeight={600} color={"#595858"}>
									{"N/A"}
								</Typography>
							</Stack>
						)}
						{typeof contactPhone === "object" && contactPhone.length >= 1 ? (
							contactPhone?.map((phone, index) => (
								<Stack key={index} direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
									<FaPhone color="#164c68" size={15} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
									<a href={"tel:" + contact.phone} style={{ textDecoration: "none" }}>
										<Typography
											variant={"h5"}
											fontWeight={600}
											color={"blue"}
											sx={{ "&:hover": { cursor: "pointer", textDecoration: "underline" }, fontFamily: '"Montserrat", "sans-serif"' }}
										>
											{phone}
										</Typography>
									</a>
								</Stack>
							))
						) : (
							<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
								<FaPhone color="#164c68" size={15} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
								<Typography variant={"h5"} fontWeight={600} color={"#595858"}>
									{"N/A"}
								</Typography>
							</Stack>
						)}

						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
							{contact.type === "individual" ? (
								<FaUser color="#164c68" size={15} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
							) : (
								<FaUsers color="#164c68" size={15} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
							)}
							<Typography variant={"h5"} fontWeight={600} color={"#595858"} textTransform={"capitalize"}>
								{t(contact.type)}
							</Typography>
						</Stack>
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
							{contact.category === "client" ? (
								<FaUser color="#164c68" size={15} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
							) : contact.category === "lawyer" ? (
								<VscLaw color="#164c68" size={15} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
							) : (
								<IoMicOffCircle color="#164c68" size={15} style={{ marginLeft: `${isRtl ? "8px" : "0px"} !important` }} />
							)}
							<Typography variant={"h5"} fontWeight={600} color={"#595858"} textTransform={"capitalize"}>
								{t(contact.category)}
							</Typography>
						</Stack>
					</Stack>
				</Box>
				<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} width={"100%"}>
					<Button
						variant="contained"
						size="small"
						sx={{
							bgcolor: "#164c68",
							color: "#fff",
							"&:hover": {
								bgcolor: "#00a3b1",
							},
							transition: "all 0.5s ease",
						}}
						onClick={() => {
							dispatch(setCurrentContact(contact));
							dispatch(setIsViewingContact(true));
							navigate("/dashboard/contacts-details");
						}}
					>
						{t("MoreDetails")}
					</Button>
				</Stack>
			</Stack>
		</Box>
	);
};

export default ContactCard;
