import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Animate = ({ sx, ...props }) => {
	const isRtl = useSelector((state) => state.app.isRtl);
	const [translateY, setTranslateY] = useState(isRtl ? 1000 : -1000);
	const [opacity, setOpacity] = useState(0);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setTranslateY(0);
			setOpacity(1);
		});

		return () => {
			clearTimeout(timeout);
		};
	}, []);

	if (props.type === "fade") {
		return (
			<Box
				mt={10}
				width={"100%"}
				sx={{
					...sx,
					transition: "opacity 1s ease",
					opacity: opacity,
					transitionDelay: props.delay + "s" || "unset",
				}}
			>
				{props.children}
			</Box>
		);
	}

	return (
		<Box
			sx={{
				...sx,
				transform: `translateX(${translateY}px)`,
				transition: "transform 1s ease, opacity 2s ease",
				opacity: opacity,
				transitionDelay: props.delay + "s" || "unset",
				zIndex: 1000,
			}}
		>
			{props.children}
		</Box>
	);
};

export default Animate;
