import { apiSlice } from "../apiSlice";

export const constraintsApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getConstraints: builder.query({
			query: ({ reference }) => ({
				url: `/constraint?reference=${reference}`,
				method: "GET",
			}),
			providesTags: ["Constraints"],
		}),
		createConstraint: builder.mutation({
			query: ({ data }) => ({
				url: "/constraint",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["Constraints"],
		}),
		updateConstraint: builder.mutation({
			query: ({ id, data }) => ({
				url: `/constraint/${id}`,
				method: "PATCH",
				body: data,
			}),
			invalidatesTags: ["Constraints"],
		}),
		deleteConstraint: builder.mutation({
			query: (id) => ({
				url: `/constraint/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Constraints"],
		}),
	}),
});

export const { useGetConstraintsQuery, useCreateConstraintMutation, useUpdateConstraintMutation, useDeleteConstraintMutation } = constraintsApi;
