import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	mode: "dark",
	isRtl: false,
	snackbar: {
		open: null,
		message: null,
		severity: null,
	},
	currentSectionMenu: null,
	isDrawerOpen: false,
	activeRoute: "Dashboard",
	userDetails: null,
	resetPasswordType: "email",
	resetPasswordData: null,
	resetPasswordCode: null,
};

export const appSlice = createSlice({
	name: "app",
	initialState,
	reducers: {
		setMode: (state, action) => {
			state.mode = action.payload;
		},
		setIsRtl: (state, action) => {
			state.isRtl = action.payload;
		},
		setSnackbar: (state, action) => {
			state.snackbar = action.payload;
		},
		setCurrentSectionMenu: (state, action) => {
			state.currentSectionMenu = action.payload;
		},
		setIsDrawerOpen: (state, action) => {
			state.isDrawerOpen = action.payload;
		},
		setActiveRoute: (state, action) => {
			state.activeRoute = action.payload;
		},
		setUserDetails: (state, action) => {
			state.userDetails = action.payload;
		},
		setResetPasswordType: (state, action) => {
			state.resetPasswordType = action.payload;
		},
		setResetPasswordData: (state, action) => {
			state.resetPasswordData = action.payload;
		},
		setResetPasswordCode: (state, action) => {
			state.resetPasswordCode = action.payload;
		},
	},
});

export const {
	setMode,
	setIsRtl,
	setSnackbar,
	setCurrentSectionMenu,
	setIsDrawerOpen,
	setActiveRoute,
	setUserDetails,
	setResetPasswordType,
	setResetPasswordData,
	setResetPasswordCode,
} = appSlice.actions;

export default appSlice.reducer;
