import axios from "axios";

const axiosInstance = axios.create({
	baseURL: "https://vexpertsystem.ae/api",
});
axiosInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		return Promise.reject(error);
	}
);

axiosInstance.interceptors.request.use(
	function (request) {
		const userInfo = JSON.parse(localStorage.getItem("userInfo"));
		const token = userInfo?.accessToken;
		request.headers["authorization"] = `Bearer ${token}`;
		return request;
	},
	(error) => Promise.reject((error.request && error.request.data) || "Something went wrong!")
);

export default axiosInstance;
