import React from "react";
import { ResponsivePie } from "@nivo/pie";

const PieChart = ({ data }) => {
	return (
		<ResponsivePie
			data={data}
			margin={{ top: 40, right: 80, bottom: 50, left: 80 }}
			innerRadius={0.7}
			colors={({ data }) => data.color}
			startAngle={-180}
			padAngle={2}
			cornerRadius={3}
			activeOuterRadiusOffset={8}
			borderWidth={1}
			borderColor={{
				from: "color",
				modifiers: [["darker", 0.2]],
			}}
			arcLinkLabelsSkipAngle={10}
			arcLinkLabelsTextOffset={10}
			arcLinkLabelsTextColor="#333333"
			arcLinkLabelsThickness={10}
			arcLinkLabelsColor={{ from: "color" }}
			arcLabelsSkipAngle={10}
			arcLabelsTextColor={{
				from: "color",
				modifiers: [["darker", 2]],
			}}
			enableArcLinkLabels={false}
			enableArcLabels={true}
		/>
	);
};

export default PieChart;
