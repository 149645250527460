import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import { useCreateFeeMutation, useGetMetadataQuery, useUpdateFeeMutation } from "../../../../redux/slices/api/fees/feesApi";
import { FaMoneyBill1Wave } from "react-icons/fa6";
import SectionHeader from "../../../Common/SectionHeader";
import { Box, Checkbox, Stack, Typography } from "@mui/material";
import moment from "moment";
import { images } from "../../../Assets";
import CustomDatePicker from "../../../Common/CustomDatePicker";
import CustomTextField from "../../../Common/CustomTextField";
import { LoadingButton } from "@mui/lab";
import { setSnackbar } from "../../../../redux/slices/app";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../../utils/axios";
import { setCurrentCase } from "../../../../redux/slices/case";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const FeeFormDialog = ({ open, handleClose }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const isRtl = useSelector((state) => state.app.isRtl);
	const currentCase = useSelector((state) => state.case.currentCase);
	const selectedFee = useSelector((state) => state.fees.selectedFee);
	const isEditingFee = useSelector((state) => state.fees.isEditingFee);

	const getMetadata = useGetMetadataQuery({}, { refetchOnMountOrArgChange: true });

	const [feeTypes, setFeeTypes] = useState([]);
	const [dueDate, setDueDate] = useState(isEditingFee && selectedFee.dueDate ? moment(selectedFee.dueDate) : null);
	const [collectedDate, setCollectedDate] = useState(isEditingFee && selectedFee.collectionDate ? moment(selectedFee.collectionDate) : null);
	const [formData, setFormData] = useState({
		caseFile: currentCase ? currentCase?.id : selectedFee?.caseFile?.id,
		type: isEditingFee ? selectedFee.type : "basic",
		amount: isEditingFee && selectedFee.amount ? selectedFee.amount : 1,
		collected: isEditingFee && selectedFee.collected ? selectedFee.collected : false,
		notes: isEditingFee && selectedFee.note ? selectedFee.note : "",
	});
	const [error, setError] = useState({
		amount: false,
		notes: false,
		dueDate: false,
		collectedDate: false,
		collected: false,
		notes: false,
	});

	const [errorText, setErrorText] = useState({
		amount: "",
		notes: "",
		dueDate: "",
		collectedDate: "",
		collected: "",
		notes: "",
	});

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (getMetadata?.isError) {
			console.log(getMetadata?.error);
			return;
		}
		if (getMetadata?.data) {
			setFeeTypes(getMetadata?.data?.data?.TYPE);
		}
	}, [getMetadata, feeTypes]);

	const [createFee] = useCreateFeeMutation();

	const handleCreateFee = async (e) => {
		e.preventDefault();
		setLoading(true);

		if (!formData.caseFile) {
			dispatch(setSnackbar({ open: true, message: t("PleaseSelectACaseFile"), status: "error" }));
			setLoading(false);
			return;
		}

		if (!formData.type || formData.type === "") {
			setError({ ...error, type: true });
			setErrorText({ ...errorText, type: t("PleaseSelectAFeeType") });
			setLoading(false);
			return;
		}

		if (!dueDate) {
			setError({ ...error, dueDate: true });
			setErrorText({ ...errorText, dueDate: t("PleaseSelectADueDate") });
			setLoading(false);
			return;
		}

		if (dueDate && moment(dueDate).diff(moment(), "days") < 0) {
			setError({ ...error, dueDate: true });
			setErrorText({ ...errorText, dueDate: t("DueDateCannotBeInThePast") });
			setLoading(false);
			return;
		} else {
			setError({ ...error, dueDate: false });
			setErrorText({ ...errorText, dueDate: "" });
		}

		if (formData.collected && moment(collectedDate).diff(moment(dueDate), "days") < 0) {
			setError({ ...error, collectedDate: true });
			setErrorText({ ...errorText, collectedDate: t("CollectedDateCannotBeBeforeDueDate") });
			setLoading(false);
			return;
		}

		let data = {
			caseFile: formData.caseFile,
			type: formData.type,
			dueDate: dueDate,
			amount: formData.amount,
			collected: formData.collected,
			collectionDate: collectedDate,
			note: formData.notes,
		};

		data = Object.entries(data).reduce((acc, [key, value]) => {
			if (value !== "" && value !== null) {
				acc[key] = value;
			}
			return acc;
		}, {});

		try {
			await createFee({ data }).unwrap();
			const caseFileData = await axiosInstance.get(`/case-file/${currentCase.id}`);
			dispatch(setCurrentCase(caseFileData.data.data));
			dispatch(setSnackbar({ open: true, message: t("FeeCreatedSuccessfully"), severity: "success" }));
			handleClose();
			setLoading(false);
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setLoading(false);
		}
	};

	const [updateFee] = useUpdateFeeMutation();

	const handleUpdateFee = async (e) => {
		e.preventDefault();
		setLoading(true);

		if (!formData.type || formData.type === "") {
			setError({ ...error, type: true });
			setErrorText({ ...errorText, type: t("PleaseSelectAFeeType") });
			setLoading(false);
			return;
		}

		if (formData.collected && moment(collectedDate).diff(moment(dueDate), "days") < 0) {
			setError({ ...error, collectedDate: true });
			setErrorText({ ...errorText, collectedDate: t("CollectedDateCannotBeBeforeDueDate") });
			setLoading(false);
			return;
		}

		if (!formData.collected || formData.collected === false) {
			setCollectedDate(null);
		}

		let data = {
			type: formData.type,
			dueDate: dueDate,
			amount: formData.amount,
			collected: formData.collected,
			note: formData.notes,
		};

		if (collectedDate) {
			data = { ...data, collectionDate: collectedDate };
		}

		try {
			await updateFee({ id: selectedFee.id, data }).unwrap();
			dispatch(setSnackbar({ open: true, message: t("FeeUpdatedSuccessfully"), severity: "success" }));
			handleClose();
			setLoading(false);
			return;
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setLoading(false);
		}
	};

	return (
		<Dialog open={open} fullWidth maxWidth={"md"} TransitionComponent={Transition} keepMounted aria-describedby="add-fees-dialog">
			<DialogTitle sx={{ pb: 1, borderBottom: "1px solid #aaaaaa6b", backgroundColor: "#fff" }}>
				<SectionHeader
					icon={<FaMoneyBill1Wave color="#164c68" size={18} />}
					title={`${isEditingFee ? t("Update") : t("New")} ${t("Fee")}`}
					type={"dialog"}
				/>
			</DialogTitle>
			<DialogContent sx={{ backgroundColor: "#fff" }}>
				<DialogContentText id="add-contact-dialog-description" sx={{ p: 2 }}>
					<Stack justifyContent={"flex-start"} alignItems={"flex-start"} spacing={4} width={"100%"} height={"max-content"} my={2}>
						<Stack justifyContent={"flex-start"} alignItems={"flex-start"} spacing={2} width={"100%"} height={"max-content"}>
							<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"} height={"max-content"}>
								<img src={images.fees} alt={"fees"} width={25} height={25} />
								<Typography
									variant={"h3"}
									fontWeight={600}
									color={"#164c68"}
									textTransform={"capitalize"}
									sx={{ mx: `${isRtl ? "8px" : "0px"} !important` }}
								>
									{t("SelectPaymentType")}:
								</Typography>
							</Stack>
							<Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={1} width={"100%"} height={"max-content"}>
								{feeTypes?.map((type, index) => (
									<Box
										display={"flex"}
										justifyContent={"center"}
										alignItems={"center"}
										width={"100%"}
										height={"100%"}
										borderRadius={2}
										border={formData.type === type && "2px solid #164c68"}
										p={2}
										key={index}
										sx={{
											boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
											cursor: "pointer",
											transition: "all 0.3s ease-in-out",
										}}
										onClick={() => setFormData({ ...formData, type: type })}
									>
										<Stack
											direction={"row"}
											justifyContent={"center"}
											alignItems={"center"}
											spacing={1}
											width={"100%"}
											height={"max-content"}
										>
											<img src={type === "basic" ? images.basicFee : images.additionalFee} alt="fee type" width={25} height={25} />
											<Typography variant={"h4"} fontWeight={600} color={"#164c68"} textTransform={"capitalize"}>
												{t(type)}
											</Typography>
										</Stack>
									</Box>
								))}
							</Stack>
						</Stack>
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"}>
							<Stack justifyContent={"flex-start"} alignItems={"flex-start"} spacing={2} width={"100%"} height={"max-content"}>
								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"} height={"max-content"}>
									<img src={images.calendar} alt={"calendar"} width={25} height={25} />
									<Typography
										variant={"h3"}
										fontWeight={600}
										color={"#164c68"}
										textTransform={"capitalize"}
										sx={{ mx: `${isRtl ? "8px" : "0px"} !important` }}
									>
										{t("DueDate")}:
									</Typography>
								</Stack>
								<Stack justifyContent={"flex-start"} alignItems={"flex-start"} width={"100%"} spacing={1} height={"max-content"}>
									<CustomDatePicker
										title={t("selectDate")}
										setInvalidDate={setError.dueDate}
										setErrorText={setErrorText.dueDate}
										value={dueDate}
										setDate={setDueDate}
									/>
									{error.dueDate && (
										<Typography variant={"title1"} color={"red"} fontWeight={500}>
											{errorText.dueDate}
										</Typography>
									)}
								</Stack>
							</Stack>
							<Stack justifyContent={"flex-start"} alignItems={"flex-start"} spacing={2} width={"100%"} height={"max-content"}>
								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"} height={"max-content"}>
									<img src={images.priceTag} alt={"price"} width={25} height={25} />
									<Typography
										variant={"h3"}
										fontWeight={600}
										color={"#164c68"}
										textTransform={"capitalize"}
										sx={{ mx: `${isRtl ? "8px" : "0px"} !important` }}
									>
										{t("Price")}:
									</Typography>
								</Stack>
								<Stack justifyContent={"flex-start"} alignItems={"flex-start"} width={"100%"} spacing={1} height={"max-content"}>
									<CustomTextField
										type={"number"}
										name={"amount"}
										value={formData.amount}
										handleInputChange={(e) => {
											if (e.target.value <= 0) {
												setError({ ...error, amount: true });
												setErrorText({ ...errorText, amount: "Amount cannot be less than or equal to 0" });
												return;
											} else {
												setError({ ...error, amount: false });
												setErrorText({ ...errorText, amount: "" });
												setFormData({ ...formData, amount: e.target.value });
											}
										}}
										resetValue={() => setFormData({ ...formData, amount: "" })}
										error={error.amount}
										errorText={errorText.amount}
										required={true}
									/>
								</Stack>
							</Stack>
						</Stack>

						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"}>
							<Checkbox
								checked={formData.collected}
								size="medium"
								onChange={() => {
									setFormData({ ...formData, collected: !formData.collected });
								}}
								sx={{
									color: "#164c68",
									"&.Mui-checked": {
										color: "#164c68",
									},
								}}
							/>
							<Typography variant={"title1"} fontWeight={600} color={"#164c68"}>
								{t("Collected")}?
							</Typography>
						</Stack>
						{formData.collected && (
							<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={2} width={"60%"} height={"max-content"}>
								<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"} height={"max-content"}>
									<img src={images.calendar} alt={"calendar"} width={25} height={25} />
									<Typography
										variant={"h3"}
										fontWeight={600}
										color={"#164c68"}
										textTransform={"capitalize"}
										sx={{ mx: `${isRtl ? "8px" : "0px"} !important` }}
									>
										{t("DateCollected")}:
									</Typography>
								</Stack>
								<Stack justifyContent={"flex-start"} alignItems={"flex-start"} width={"100%"} spacing={1} height={"max-content"}>
									<CustomDatePicker
										title={"Collected"}
										setInvalidDate={setError.collectedDate}
										setErrorText={setErrorText.collectedDate}
										value={collectedDate}
										setDate={setCollectedDate}
									/>
									{error.collectedDate && (
										<Typography variant={"title1"} color={"red"} fontWeight={500}>
											{errorText.collectedDate}
										</Typography>
									)}
								</Stack>
							</Stack>
						)}
						<Stack justifyContent={"flex-start"} alignItems={"flex-start"} spacing={2} width={"100%"} height={"max-content"}>
							<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"} height={"max-content"}>
								<img src={images.notes} alt={"notes"} width={25} height={25} />
								<Typography
									variant={"h3"}
									fontWeight={600}
									color={"#164c68"}
									textTransform={"capitalize"}
									sx={{ mx: `${isRtl ? "8px" : "0px"} !important` }}
								>
									{t("Notes")}:
								</Typography>
							</Stack>
							<CustomTextField
								type={"text"}
								label={t("Notes")}
								value={formData.notes}
								resetValue={() => setFormData({ ...formData, notes: "" })}
								name={"note"}
								handleInputChange={(e) => {
									setFormData({ ...formData, notes: e.target.value });
								}}
								required={false}
								multiline={true}
							/>
						</Stack>
					</Stack>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: "#fff", py: 1 }}>
				<Button
					onClick={handleClose}
					sx={{
						color: "red",
						"&:hover": {
							color: "#fff",
							backgroundColor: "red",
						},
						transition: "all 1s ease",
					}}
				>
					{t("Cancel")}
				</Button>
				<LoadingButton
					loading={loading}
					variant="contained"
					size="medium"
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						mr: `${isRtl ? "8px" : "0px"} !important`,
					}}
					onClick={isEditingFee ? handleUpdateFee : handleCreateFee}
				>
					{isEditingFee ? t("Update") : t("Save")}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default FeeFormDialog;
