import { Box, Button, CircularProgress, FormControl, MenuItem, Select, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SectionHeader from "../../Common/SectionHeader";
import { GiGavel } from "react-icons/gi";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
import { useGetAllHearingsQuery } from "../../../redux/slices/api/hearing/hearingApi";
import HearingTableReport from "./HearingTableReport";
import DeleteHearingDialog from "../../Case/Hearing/DeleteHearingDialog";
import { IoClose } from "react-icons/io5";
import { images } from "../../Assets";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ScrollableContainer from "../../Common/ScrollableContainer";
import { useGetTableViewQuery, useUpdateTableViewMutation } from "../../../redux/slices/api/tableView/tableViewApi";
import { setSnackbar } from "../../../redux/slices/app";
import { LoadingButton } from "@mui/lab";
import ConfirmDeleteTableView from "../../Common/ConfirmDeleteTableView";

const predefinedRanges = [
	{
		label: "Today",
		value: [new Date(), new Date()],
		placement: "left",
	},
	{
		label: "Yesterday",
		value: [addDays(new Date(), -1), addDays(new Date(), -1)],
		placement: "left",
	},
	{
		label: "This week",
		value: [startOfWeek(new Date()), endOfWeek(new Date())],
		placement: "left",
	},
	{
		label: "Last 7 days",
		value: [subDays(new Date(), 6), new Date()],
		placement: "left",
	},
	{
		label: "Last 30 days",
		value: [subDays(new Date(), 29), new Date()],
		placement: "left",
	},
	{
		label: "This month",
		value: [startOfMonth(new Date()), new Date()],
		placement: "left",
	},
	{
		label: "Last month",
		value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
		placement: "left",
	},
	{
		label: "This year",
		value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
		placement: "left",
	},
	{
		label: "Last year",
		value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
		placement: "left",
	},
	{
		label: "All time",
		value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
		placement: "left",
	},
	{
		label: "Last week",
		closeOverlay: false,
		value: (value) => {
			const [start = new Date()] = value || [];
			return [addDays(startOfWeek(start, { weekStartsOn: 0 }), -7), addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)];
		},
		appearance: "default",
	},
	{
		label: "Next week",
		closeOverlay: false,
		value: (value) => {
			const [start = new Date()] = value || [];
			return [addDays(startOfWeek(start, { weekStartsOn: 0 }), 7), addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)];
		},
		appearance: "default",
	},
];

const HearingReportSection = () => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [dateRange, setDateRange] = useState([moment().toDate(), moment().toDate()]);

	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 50,
	});

	const [searchQuery, setSearchQuery] = useState("");
	const [colFilter, setColFilter] = useState([]);
	const [columnVisibility, setColumnVisibility] = useState({});
	const [columnSizing, setColumnSizing] = useState({});
	const [columnOrder, setColumnOrder] = useState([]);
	const [defaultTemplate, setDefaultTemplate] = useState(null);
	const [templates, setTemplates] = useState([]);
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [loading, setLoading] = useState(false);
	const [handleDeleteView, setHandleDeleteView] = useState(false);

	const colFilterObject = colFilter.reduce((acc, { id, value }) => {
		if (id === "caseFile.caseNumber") id = "caseFile";
		acc[id] = value;
		return acc;
	}, {});

	const hearingQuery = {
		...(searchQuery && { search: searchQuery }),
		...(colFilter.length > 0 && { ...colFilterObject }),
		...(typeof dateRange[0] !== "string" && { "date[gte]": moment(dateRange[0]).format("YYYY-MM-DD") }),
		...(typeof dateRange[1] !== "string" && { "date[lte]": moment(dateRange[1]).format("YYYY-MM-DD") }),
		skip: pagination.pageIndex * pagination.pageSize,
		limit: pagination.pageSize,
	};

	const { data, isLoading, isError } = useGetAllHearingsQuery(hearingQuery, { refetchOnMountOrArgChange: true });
	const [rows, setRows] = useState([]);
	const [totalCount, setTotalCount] = useState(data?.totalRecords || 0);

	const [openDeleteHearingDialog, setOpenDeleteHearingDialog] = useState(false);

	useEffect(() => {
		if (isError) return;
		if (!isLoading && !isError && data?.data) {
			const sortedData = [...data.data].sort((a, b) => {
				// Convert dates to Date objects for comparison
				const dateA = new Date(a.date);
				const dateB = new Date(b.date);

				return dateA - dateB;
			});

			setRows(sortedData);
			setTotalCount(data?.totalRecords);
		}
	}, [data, pagination.pageIndex, pagination.pageSize, dateRange, searchQuery, colFilter]);

	const tableView = useGetTableViewQuery({ type: "hearing" }, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (tableView.data?.data?.hearing) {
			const defaultView = tableView.data?.data?.hearing?.find((view) => view.default === true);
			if (defaultView) {
				setDefaultTemplate(defaultView);
				setSelectedTemplate(defaultView);
				setColumnVisibility(JSON.parse(defaultView?.column));
				setColumnOrder(JSON.parse(defaultView?.columnOrder));
			} else {
				const defaultTemp = tableView.data?.data?.hearing[0];
				console.log("default: ", defaultTemp);
				setSelectedTemplate(defaultTemp);
				setColumnVisibility(JSON.parse(defaultTemp?.column));
				setColumnOrder(JSON.parse(defaultTemp?.columnOrder));
			}
			setTemplates(tableView.data?.data?.hearing);
		} else {
			setSelectedTemplate(null);
			setColumnVisibility({});
			setColumnOrder([]);
		}
	}, [tableView.data, templates]);

	const [updateTableView] = useUpdateTableViewMutation();

	const handleSetAsDefault = async (e) => {
		e.preventDefault();
		setLoading(true);
		const data = {
			default: true,
		};
		try {
			await updateTableView({ templateId: selectedTemplate?.id, data: data }).unwrap();
			setDefaultTemplate(selectedTemplate);
			setSelectedTemplate(selectedTemplate);
			setLoading(false);
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setLoading(false);
		}
	};

	return (
		<Box
			width={"100%"}
			height={"max-content"}
			p={2}
			borderRadius={2}
			bgcolor={"#fff"}
			sx={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;" }}
		>
			<SectionHeader icon={<GiGavel color="#164c68" size={18} />} title={`${t("My")} ${t("Hearings")}`} section={"hearing"} />
			<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} my={2}>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={2}>
					<DateRangePicker
						format="MMM dd, yyyy"
						character=" - "
						ranges={predefinedRanges}
						value={dateRange}
						onChange={setDateRange}
						onClean={() => setDateRange([moment().toDate(), moment().toDate()])}
					/>
					<Typography variant={"h5"} color={"#164c68"}>
						{t("SelectedTemplate")}:
					</Typography>
					{templates.length > 0 ? (
						<FormControl sx={{ m: 1, minWidth: 100, mr: isRtl ? "8px !important" : 0 }} size="small">
							<Select
								id="select-template"
								value={selectedTemplate}
								onChange={(e) => {
									setSelectedTemplate(e.target.value);
									setColumnVisibility(JSON.parse(e?.target?.value?.column));
									setColumnOrder(JSON.parse(e?.target?.value?.columnOrder));
								}}
								sx={{ color: "#164c68" }}
								displayEmpty
							>
								{defaultTemplate && (
									<MenuItem value={defaultTemplate}>
										{defaultTemplate?.name} ({t("Default")})
									</MenuItem>
								)}
								{templates
									?.filter((template) => defaultTemplate?.id !== template.id)
									.map((template) => (
										<MenuItem key={template.id} value={template}>
											{template.name}
										</MenuItem>
									))}
							</Select>
						</FormControl>
					) : (
						<Typography variant={"h5"} color={"#164c68"}>
							{t("NoTableViewFound")}
						</Typography>
					)}
					{selectedTemplate?.id !== defaultTemplate?.id && (
						<LoadingButton
							loading={isLoading}
							variant="contained"
							size="small"
							onClick={handleSetAsDefault}
							sx={{
								bgcolor: "#164c68",
								color: "#fff",
								padding: 1,
								"&:hover": {
									bgcolor: "#00a3b1",
								},
								transition: "all 0.5s ease",
							}}
						>
							{t("SetAsDefault")}
						</LoadingButton>
					)}
				</Stack>
			</Stack>

			<Box height={"100%"} width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"} pb={2}>
				{isLoading && !isError ? (
					<CircularProgress />
				) : rows ? (
					<ScrollableContainer>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<HearingTableReport
								data={rows}
								deleteHearing={() => setOpenDeleteHearingDialog(true)}
								pagination={pagination}
								setPagination={setPagination}
								pageCount={Math.ceil(totalCount / pagination.pageSize)}
								searchQuery={searchQuery}
								setSearchQuery={setSearchQuery}
								colFilter={colFilter}
								setColFilter={setColFilter}
								columnVisibility={columnVisibility}
								setColumnVisibility={setColumnVisibility}
								columnSizing={columnSizing}
								setColumnSizing={setColumnSizing}
								columnOrder={columnOrder}
								setColumnOrder={setColumnOrder}
								setDefaultView={setDefaultTemplate}
								selectedTemplate={selectedTemplate}
								setSelectedTemplate={setSelectedTemplate}
								templates={templates}
								setHandleDeleteView={setHandleDeleteView}
							/>
						</LocalizationProvider>
					</ScrollableContainer>
				) : (
					<Box width={"100%"} height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} mt={10}>
						<img src={images.notFound} alt="Not Found" width={200} height={200} />
						<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ my: 2 }}>
							{t("NoHearingAvailable")}
						</Typography>
						<Button
							size="small"
							endIcon={!isRtl && <IoClose color="red" />}
							onClick={() => {
								setSearchQuery("");
								setColFilter([]);
							}}
							sx={{
								bgcolor: "#fff",
								color: "#164c68",
								padding: 1,
								border: "1px solid #164c68",
							}}
						>
							{t("ClearSearch")}
						</Button>
					</Box>
				)}
			</Box>
			{openDeleteHearingDialog && <DeleteHearingDialog open={openDeleteHearingDialog} handleClose={() => setOpenDeleteHearingDialog(false)} />}
			{handleDeleteView && (
				<ConfirmDeleteTableView
					open={handleDeleteView}
					handleClose={() => setHandleDeleteView(false)}
					templateId={selectedTemplate?.id}
					template={selectedTemplate}
					setTemplates={setTemplates}
					setSelectedTemplate={() => setSelectedTemplate(null)}
					type={"hearing"}
					setColumnVisibility={setColumnVisibility}
					setColumnOrder={setColumnOrder}
					setDefaultView={setDefaultTemplate}
				/>
			)}
		</Box>
	);
};

export default HearingReportSection;
