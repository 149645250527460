import { Box, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { images } from "../Assets";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTask } from "../../redux/slices/task";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const IndividualTask = ({ task }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const isRtl = useSelector((state) => state.app.isRtl);

	const today = moment();
	return (
		<Box
			width={"100%"}
			minWidth={"300px"}
			maxWidth={"300px"}
			minHeight={"200px"}
			maxHeight={"200px"}
			height={"auto"}
			display={"flex"}
			justifyContent={"flex-start"}
			alignItems={"start"}
			gap={1}
			flexDirection={"column"}
			p={2}
			borderRadius={1.5}
			borderRight={`5px solid ${
				task.status === "New" ? "#edaf41" : task.status === "In Progress" ? "#116ee9" : task.status === "Completed" ? "#74c193" : "#fc6b77"
			}`}
			bgcolor={"#fff"}
			sx={{
				boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
				"&:hover": {
					cursor: "pointer",
				},
			}}
			onClick={() => {
				dispatch(setSelectedTask(task));
				navigate("/dashboard/task/details");
			}}
		>
			<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2}>
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
						<img src={images.task} alt="task" width={25} height={25} />
						<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ mx: '"8px" !important' }}>
							{t("Title")}:
						</Typography>
					</Stack>
					<Typography variant={"h5"} fontWeight={700} color={"#164c68"}>
						{task.taskTitle ? (isRtl ? task.taskTitle.nameAr : task.taskTitle.nameEn ? task.taskTitle.nameEn : task.taskTitle.nameAr) : task.title}
					</Typography>
				</Stack>
				<Box p={1} display={"flex"} justifyContent={"center"} alignItems={"center"} bgcolor={"#e9e7e770"} borderRadius={1}>
					<Tooltip title={`${t("Priority")}: ${t(task.priority)}`} placement="top" arrow>
						{task.priority === "Low" ? (
							<img src={images.low} alt="low" width={20} height={20} />
						) : task.priority === "Medium" ? (
							<img src={images.medium} alt="medium" width={20} height={20} />
						) : (
							<img src={images.high} alt="high" width={20} height={20} />
						)}
					</Tooltip>
				</Box>
			</Stack>

			<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2} width={"100%"}>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<img src={images.notes} alt="notes" width={25} height={25} />
					<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ mx: '"8px" !important' }}>
						{t("Description")}:
					</Typography>
				</Stack>
				<Typography variant={"h5"} fontWeight={500} color={"#164c68"}>
					{task.description.length > 10 ? task.description.slice(0, 10) + "..." : task.description}
				</Typography>
			</Stack>

			<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2} width={"100%"}>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<img src={images.creator} alt="creator" width={25} height={25} />
					<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ mx: '"8px" !important' }}>
						{t("CreatedBy")}:
					</Typography>
				</Stack>
				<Typography variant={"h5"} fontWeight={500} color={"#164c68"}>
					{task?.createdBy?.firstName} {task?.createdBy?.lastName}
				</Typography>
			</Stack>

			<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2} width={"100%"}>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<img src={images.calendar} alt="calendar" width={25} height={25} />
					<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ mx: '"8px" !important' }}>
						{t("DueDate")}:
					</Typography>
				</Stack>
				<Typography
					variant={"h5"}
					fontWeight={500}
					color={moment(task.dueDate).diff(today, "days") < 1 ? "#fc6b77" : "#164c68"}
					sx={{
						fontFamily: '"Montserrat", "sans-serif"',
					}}
				>
					{moment(task.dueDate).format("MMMM Do YYYY")}
				</Typography>
			</Stack>
		</Box>
	);
};

export default IndividualTask;
