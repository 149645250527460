import React, { useEffect, useState } from "react";
import Animate from "../../Common/Animate";
import { Box, Grid, Stack, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../Common/Breadcrumbs";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import TopFilter from "./TopFilter";
import UserCard from "./UserCard";
import UserSettingMenu from "./UserSettingMenu";
import AddUser from "./AddUser";
import { useSelector } from "react-redux";
import { useGetAllUsersQuery, useGetByUserNameQuery } from "../../../redux/slices/api/user/userApi";
import CustomBackdrop from "../../Common/CustomBackdrop";
import { images } from "../../Assets";
import { useTranslation } from "react-i18next";

const UserSettings = () => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const { data, isLoading, isError } = useGetAllUsersQuery({}, { refetchOnMountOrArgChange: true });

	const userDetails = useSelector((state) => state.app.userDetails);

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	const [originalRows, setOriginalRows] = useState(data?.data); // Add this line
	const [rows, setRows] = useState(data?.data);
	const [filteredRows, setFilteredRows] = useState([]);

	const [searchValue, setSearchValue] = useState("");
	const [isSearching, setIsSearching] = useState(false);
	const [isFiltering, setIsFiltering] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [categoryValue, setCategoryValue] = useState("");
	const [openAddUser, setOpenAddUser] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const nameQuery = searchValue?.length >= 2 && searchValue !== "" ? { name: searchValue } : {};

	const searchByName = useGetByUserNameQuery(nameQuery, { refetchOnMountOrArgChange: true });

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		if (isError) {
			// dispatch(setSnackbar({ open: true, message: "Error fetching data", severity: "error" }));
			return;
		}
		if (data && !isError && !isLoading) {
			setRows(data?.data);
			setOriginalRows(data?.data);
		}
	}, [data, isLoading, isError]);

	useEffect(() => {
		if (searchValue?.length > 1 && searchValue !== "") {
			setIsSearching(true);
			setFilteredRows(searchByName?.data?.data);
		} else {
			setIsSearching(false);
		}
	}, [searchValue]);

	useEffect(() => {
		if (categoryValue !== "") {
			setIsFiltering(true);
			const filtered = originalRows.filter((row) => {
				// Change this line
				return row.role === categoryValue;
			});
			setRows(filtered);
		} else {
			setIsFiltering(false);
			setRows(originalRows); // Change this line
		}
	}, [categoryValue, originalRows]);

	const handleSearchInput = (e) => {
		setSearchValue(e.target.value);
		if (e.target.value === "") {
			setIsSearching(false);
		}
	};

	const clearSearch = () => {
		setSearchValue("");
		setIsSearching(false);
		setRows(data?.data);
	};

	return (
		<Animate type="fade" delay={0.5}>
			<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={2} px={6} pt={4}>
				<CustomBreadcrumbs section={t("UserSettings")} />
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<img src={images.team} alt={"team"} width={40} height={40} />
					<Typography variant={"h2"} fontWeight={600} color={"#164c68"} sx={{ mr: `${isRtl ? "8px" : "0px"} !important` }}>
						{t("UserSettings")}
					</Typography>
				</Stack>
			</Stack>

			<Box
				width={"100%"}
				height={"max-content"}
				display={"flex"}
				justifyContent={"flex-start"}
				alignItems={"start"}
				gap={3}
				sx={{ overflowY: "auto", overflowX: "hidden" }}
				px={6}
				py={3}
			>
				{/* <SettingsFilter
					isSmallScreen={isSmallScreen}
					chosenCategory={categoryValue}
					setChosenCategory={setCategoryValue}
					setEditingPermissions={setEditingPermissions}
				/> */}
				<Box width={"100%"} display={"flex"} justifyContent={"flex-start"} alignItems={"start"} flexDirection={"column"} gap={4}>
					<TopFilter
						isSmallScreen={isSmallScreen}
						searchValue={searchValue}
						handleSearchInput={handleSearchInput}
						clearSearch={clearSearch}
						isSearching={isSearching}
						categoryValue={categoryValue}
						setCategoryValue={setCategoryValue}
						setOpenAddUser={setOpenAddUser}
					/>
					<Grid container spacing={2}>
						{isSearching
							? filteredRows.map((user) => (
									<Grid item xs={12} sm={4} key={user.id}>
										<UserCard user={user} setAnchorEl={setAnchorEl} setSelectedUser={setSelectedUser} open={open} />
									</Grid>
							  ))
							: rows?.map((user) => (
									<Grid item xs={12} sm={4} key={user.id}>
										<UserCard user={user} setAnchorEl={setAnchorEl} setSelectedUser={setSelectedUser} open={open} />
									</Grid>
							  ))}
					</Grid>
				</Box>
			</Box>
			{open && (
				<UserSettingMenu
					open={open}
					anchorEl={anchorEl}
					setAnchorEl={setAnchorEl}
					handleClose={handleClose}
					user={selectedUser}
					setUser={setSelectedUser}
					rows={rows}
					setRows={setRows}
				/>
			)}
			{openAddUser && <AddUser open={openAddUser} handleClose={() => setOpenAddUser(false)} users={rows} setUsers={setRows} />}
			{isLoading && <CustomBackdrop open={isLoading} />}
		</Animate>
	);
};

export default UserSettings;
