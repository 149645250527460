import { queryBuilder } from "../../../../utils/queryBuilder";
import { apiSlice } from "../apiSlice";

export const feesApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getFeesByCriteria: builder.query({
			query: (query) => ({
				url: `/fee?total=true&${queryBuilder(query)}`,
				method: "GET",
			}),
			providesTags: ["Fees"],
		}),
		getById: builder.query({
			query: (id) => ({
				url: `/fee/${id}`,
				method: "GET",
			}),
		}),
		getMetadata: builder.query({
			query: () => ({
				url: "/fee/metadata",
				method: "GET",
			}),
		}),
		createFee: builder.mutation({
			query: ({ data }) => ({
				url: `/fee`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["Fees"],
		}),
		updateFee: builder.mutation({
			query: ({ id, data }) => ({
				url: `/fee/${id}`,
				method: "PATCH",
				body: data,
			}),
			invalidatesTags: ["Fees"],
		}),
		deleteFee: builder.mutation({
			query: (id) => ({
				url: `/fee/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Fees"],
		}),
	}),
});

export const { useGetFeesByCriteriaQuery, useGetByIdQuery, useGetMetadataQuery, useCreateFeeMutation, useUpdateFeeMutation, useDeleteFeeMutation } = feesApi;
