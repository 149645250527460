import { CssBaseline, Snackbar, ThemeProvider } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
import React, { useEffect, useMemo } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "./redux/slices/app";
import { Typography } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const vertical = "bottom";
const horizontal = "center";

function App() {
	const dispatch = useDispatch();
	const mode = useSelector((state) => state.app.mode);

	const { message, open, severity } = useSelector((state) => state.app.snackbar);
	const isRtl = useSelector((state) => state.app.isRtl);

	useEffect(() => {
		document.documentElement.dir = isRtl ? "rtl" : "ltr";
	}, [isRtl]);

	const theme = useMemo(() => createTheme(themeSettings(mode, isRtl ? "rtl" : "ltr")), [mode, isRtl]);
	return (
		<>
			<CssBaseline />
			<ThemeProvider theme={theme}>
				<RouterProvider router={router} />
			</ThemeProvider>
			{message && open && (
				<Snackbar
					anchorOrigin={{ vertical, horizontal }}
					open={open}
					autoHideDuration={4000}
					key={vertical + horizontal}
					onClose={() => {
						dispatch(setSnackbar({ open: false, message: "", severity: "" }));
					}}
				>
					<Alert
						onClose={() => {
							dispatch(setSnackbar({ open: false, message: "", severity: "" }));
						}}
						severity={severity}
						sx={{ width: "max-content" }}
					>
						<Typography sx={{px: `${isRtl ? '2rem' : '0px'} !important`}} variant="title1" color="white" fontWeight={500}>
							{message}
						</Typography>
					</Alert>
				</Snackbar>
			)}
		</>
	);
}

export default App;
