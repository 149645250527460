import React, { useEffect, useState } from "react";
import { fetchMedia } from "../../Common/utils";
import { images } from "../../Assets";
import { CircularProgress } from "@mui/material";

const ImageSelect = ({ avatar }) => {
	const [avatarData, setAvatarData] = useState(null);
	const [avatarLoading, setAvatarLoading] = useState(false);
	useEffect(() => {
		if (avatar) {
			const fetchData = async () => {
				setAvatarLoading(true);
				const data = await fetchMedia(avatar);
				setAvatarData(data?.dataUrl);
				setAvatarLoading(false);
			};
			fetchData();
		}
	}, [avatar]);
	return (
		<>
			{avatarLoading ? (
				<CircularProgress />
			) : (
				<img
					id="party-avatar"
					loading="lazy"
					width="40px"
					height="40px"
					//   srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
					srcSet={avatarData || images.avatar}
					src={avatarData || images.avatar}
					alt="avatar"
					style={{ objectFit: "cover", borderRadius: "50%" }}
				/>
			)}
		</>
	);
};

export default ImageSelect;
