import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetByCriteriaEmailTemplatesQuery } from "../../../redux/slices/api/email/templateApi";
import {  Stack, Typography } from "@mui/material";

const ReminderEmailTemplate = ({ templateId }) => {
	const { t } = useTranslation();

	const template = useGetByCriteriaEmailTemplatesQuery({}, { refetchOnMountOrArgChange: true });
	const [templateData, setTemplateData] = useState(null);
	const [templateOptions, setTemplateOptions] = useState([]);

	useEffect(() => {
		if (template.isError) return;

		if (template.data && !template.isLoading && !template.isError) {
			const templateDetails = template.data?.data?.find((template) => template.id === templateId);
			// const templateOptions = template.data?.data?.filter((template) => template.id !== templateId);
			setTemplateData(templateDetails.subject);
			// setTemplateOptions(templateOptions);
			setTemplateOptions(template.data?.data);
		}
	}, [templateId]);

	return (
		<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
			<Typography variant={"h6"} fontWeight={700} color={"#164c68"}>
				{t("Template")}:
			</Typography>
			<Typography variant={"h6"} fontWeight={500} color={"#164c68"}>
				{templateData}
			</Typography>
			{/* <FormControl size="small">
				<Select
					id="select-template"
					value={templateData}
					onChange={(e) => {
						setTemplateData(e.target.value);
					}}
					sx={{ color: "#164c68" }}
				>
					{templateOptions.map((template, index) => (
						<MenuItem key={index} value={template.subject}>
							{template.subject}
						</MenuItem>
					))}
				</Select> */}
			{/* </FormControl> */}
		</Stack>
	);
};

export default ReminderEmailTemplate;
