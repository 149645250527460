import { Box, Stack, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useState } from "react";
import CustomTextField from "../Common/CustomTextField";
import CustomCheckbox from "../Common/CustomCheckBox";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../redux/slices/api/auth/authApi";
import { useDispatch, useSelector } from "react-redux";
import { setIsAuthorized, setUserAccess } from "../../redux/slices/auth";
import { setSnackbar } from "../../redux/slices/app";
import { useTranslation } from "react-i18next";
import ForgotPasswordType from "./ForgotPasswordType";
import ResetPasswordConfirmation from "./ResetPasswordConfirmation";

const LoginForm = () => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [formData, setFormData] = useState({
		email: process.env.NODE_ENV === "development" ? "owner@company1.com" : "",
		password: process.env.NODE_ENV === "development" ? "P@$$w0rd" : "",
	});

	const [error, setError] = useState({
		email: false,
		password: false,
	});
	const [errorText, setErrorText] = useState({
		email: "",
		password: "",
	});

	const [showPassword, setShowPassword] = useState(false);
	const [loading, setLoading] = useState(false);
	const [checked, setChecked] = useState(false);
	const [forgotPassword, setForgotPassword] = useState(false);
	const [openOtpDialog, setOpenOtpDialog] = useState(false);

	const handleInputChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleResetValue = (name) => {
		setFormData({ ...formData, [name]: "" });
	};

	const [login] = useLoginMutation();

	const handleLogin = async (e) => {
		e.preventDefault();
		setLoading(true);
		const { email, password } = formData;

		if (email === "" || password === "") {
			setError({ email: email === "", password: password === "" });
			setErrorText({
				email: email === "" ? "Email is required" : "",
				password: password === "" ? "Password is required" : "",
			});
			setLoading(false);
			return;
		}

		try {
			const res = await login({ email, password }).unwrap();
			dispatch(setSnackbar({ open: true, message: t("LoginSuccessful"), severity: "success" }));
			dispatch(setUserAccess(res.data));
			dispatch(setIsAuthorized(true));
			setLoading(false);
			navigate("/dashboard");
		} catch (error) {
			const errorMessage = isRtl ? error?.data?.ar : error?.data?.en;
			dispatch(setSnackbar({ open: true, message: errorMessage, severity: "error" }));
			setLoading(false);
		}
	};

	return (
		<Box
			p={5}
			borderRadius={3}
			height={"auto"}
			width={"auto"}
			display={"flex"}
			justifyContent={"flex-start"}
			alignItems={"start"}
			flexDirection={"column"}
			sx={{ boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;" }}
		>
			<Stack width={"100%"} mb={2}>
				<Typography variant={"h5"} fontWeight={600}>
					{t("SignInText")}
				</Typography>
				<Typography variant={isRtl ? "title" : "body2"} fontWeight={400} color={"#51505099"} sx={{ mb: 3 }}>
					{t("SignInDialog")}
				</Typography>
			</Stack>
			<Stack spacing={3} width={"100%"} mb={2}>
				<CustomTextField
					type={"email"}
					name={"email"}
					value={formData.email}
					resetValue={handleResetValue}
					label={t("EmailAddress")}
					setData={setFormData}
					handleInputChange={handleInputChange}
					error={error.email}
					errorText={errorText.email}
					required={true}
				/>
				<CustomTextField
					type={"password"}
					name={"password"}
					value={formData.password}
					label={t("Password")}
					setData={setFormData}
					handleInputChange={handleInputChange}
					showPassword={showPassword}
					setShowPassword={setShowPassword}
					error={error.password}
					errorText={errorText.password}
					required={true}
				/>
				<Stack justifyContent={"flex-start"} alignItems={"start"} width={"100%"} spacing={1}>
					<Typography
						component={"button"}
						variant={"body2"}
						fontWeight={600}
						color={"#164c68"}
						sx={{ cursor: "pointer", background: "none" }}
						onClick={() => setForgotPassword(true)}
					>
						{t("ForgotPassword")}?
					</Typography>
					<CustomCheckbox desc={`${t("AgreeToTerms")}*`} checked={checked} toggle={() => setChecked(!checked)} />
				</Stack>
			</Stack>

			<LoadingButton
				loading={loading}
				variant="contained"
				size="small"
				fullWidth
				disabled={!checked}
				sx={{
					bgcolor: "#164c68",
					color: "#fff",
					"&:hover": {
						bgcolor: "#00a3b1",
					},
					transition: "all 0.5s ease",
				}}
				onClick={handleLogin}
			>
				{t("Login")}
			</LoadingButton>

			{forgotPassword && <ForgotPasswordType open={forgotPassword} handleClose={() => setForgotPassword(false)} openOtp={() => setOpenOtpDialog(true)} />}
			{openOtpDialog && <ResetPasswordConfirmation open={openOtpDialog} handleClose={() => setOpenOtpDialog(false)} />}
		</Box>
	);
};

export default LoginForm;
