import { Box, Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { images } from "../../../Assets/index";
import CustomTextField from "../../../Common/CustomTextField";
import CustomConstraintSelect from "../../../Common/CustomConstraintSelect";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IoAddCircle } from "react-icons/io5";

const SecondSection = ({ decision, setDecision, decisionDetails, setDecisionDetails, setAddConstraint, setConstraintReference }) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	return (
		<Box width={"100%"} height={"100%"} minHeight={"300px"} p={2} bgcolor={"#fff"}>
			<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
				<img src={images.decision} alt="decision" width={25} height={25} style={{ marginLeft: `${isRtl ? "8px" : "0px"}` }} />
				<Typography variant="h4" color="#164c68" fontWeight="500">
					{t("DecisionDetails")}:
				</Typography>
			</Stack>
			<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
			<Stack justifyContent={"space-between"} alignItems={"start"} spacing={5} width={"100%"} height={"100%"}>
				<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={2} width={"100%"} height={"100%"}>
					<Typography variant="h5" color="#164c68" fontWeight="500">
						{t("ChooseDecision")}:
					</Typography>
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
						<CustomConstraintSelect reference={"decision"} dataSet={decision} setData={setDecision} />
						<Tooltip title={`${t("Add")}  ${t("Decision")}`}>
							<IconButton
								onClick={() => {
									setAddConstraint(true);
									setConstraintReference("decision");
								}}
							>
								<IoAddCircle color={"#164c68"} size={25} />
							</IconButton>
						</Tooltip>
					</Stack>
					<Typography variant="h5" color="#164c68" fontWeight="500">
						{t("DecisionDetails")}:
					</Typography>
					<CustomTextField
						type={"text"}
						value={decisionDetails}
						resetValue={() => setDecisionDetails("")}
						name={"decisionDetails"}
						handleInputChange={(e) => {
							setDecisionDetails(e.target.value);
						}}
						required={true}
						multiline={true}
					/>
				</Stack>
			</Stack>
		</Box>
	);
};

export default SecondSection;
