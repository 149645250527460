import { apiSlice } from "../apiSlice";

export const permissionApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAllPermissions: builder.query({
			query: () => ({
				url: "/permissions-group?total=true",
				method: "GET",
			}),
			providesTags: ["Permissions"],
		}),
		getPermissionById: builder.query({
			query: (permissionId) => ({
				url: `/permissions-group/${permissionId}`,
				method: "GET",
			}),
		}),
		addPermission: builder.mutation({
			query: (data) => ({
				url: "/permissions-group",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["Permissions"],
		}),
		updatePermission: builder.mutation({
			query: ({ permissionId, data }) => ({
				url: `/permissions-group/${permissionId}`,
				method: "PATCH",
				body: data,
			}),
			invalidatesTags: ["Permissions"],
		}),
		deletePermission: builder.mutation({
			query: (permissionId) => ({
				url: `/permissions-group/${permissionId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Permissions"],
		}),
		getMetaData: builder.query({
			query: () => ({
				url: "/permissions-group/metadata",
				method: "GET",
			}),
		}),
	}),
});

export const {
	useGetAllPermissionsQuery,
	useGetPermissionByIdQuery,
	useAddPermissionMutation,
	useUpdatePermissionMutation,
	useDeletePermissionMutation,
	useGetMetaDataQuery,
} = permissionApi;
