import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetAllMeetingsQuery } from "../../../redux/slices/api/meetings/meetingsApi";
import moment from "moment";
import SectionHeader from "../../Common/SectionHeader";
import { CiCalendarDate } from "react-icons/ci";
import { setCurrentMeeting } from "../../../redux/slices/meetings";

const FileTypeStatBox = ({ isSmallScreen, status }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [upcomingMeetings, setUpcomingMeetings] = useState([]);

	const isRtl = useSelector((state) => state.app.isRtl);

	const { data, isLoading, isError } = useGetAllMeetingsQuery({}, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (isError) return;

		if (data) {
			const comingMeetings = data?.data
				?.filter((meeting) => {
					let meetingDate = moment(meeting?.date);
					return meetingDate.diff(moment(), "week") < 1;
				})
				.sort((a, b) => moment(a.date).diff(moment(b.date)))
				.reverse()
				.slice(0, 3);
			setUpcomingMeetings(comingMeetings);
		}
	}, [data]);

	return (
		<Box
			width={isSmallScreen ? "100%" : "25%"}
			height={"auto"}
			minHeight={"353px"}
			p={2}
			borderRadius={1.5}
			bgcolor={"#fff"}
			sx={{ boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;" }}
		>
			<SectionHeader icon={<CiCalendarDate color="#164c68" size={18} />} title={t("ComingMeetings")} />
			{upcomingMeetings.length > 0 ? (
				<>
					{upcomingMeetings.map((meeting, index) => (
						<Stack
							key={index}
							width={"100%"}
							direction={"row"}
							justifyContent={"space-between"}
							alignItems={"center"}
							p={2}
							borderBottom={"1px solid #aaaaaa6b"}
							mt={2}
							onClick={() => {
								if (meeting.caseFile) dispatch(setCurrentMeeting(meeting));
								dispatch(setCurrentMeeting(meeting));
								navigate("/dashboard/meeting-detail");
							}}
						>
							<Typography variant={"h6"} fontWeight={700} color={"#595858"}>
								{meeting.caseFile?.classification === "litigation"
									? `${meeting.caseFile?.fullCaseNumber} - ${
											isRtl
												? meeting.caseFile?.caseStage?.nameAr
												: meeting.caseFile?.caseStage?.nameEn
												? meeting.caseFile?.caseStage?.nameEn
												: meeting.caseFile?.caseStage?.nameAr
									  }`
									: meeting.code}
							</Typography>
							<Typography
								variant={"h6"}
								fontWeight={700}
								color={"#595858"}
								sx={{
									fontFamily: '"Montserrat", "sans-serif"',
								}}
							>
								{moment(meeting.date).format("DD/MM/YYYY")}
							</Typography>
						</Stack>
					))}
				</>
			) : (
				<></>
			)}
		</Box>
	);
};

export default FileTypeStatBox;
