import React, { useEffect, useState } from "react";
import { useGetByUserIdQuery } from "../../redux/slices/api/user/userApi";
import moment from "moment";
import { Box, Stack, Typography } from "@mui/material";
import { images } from "../Assets";
import { useTranslation } from "react-i18next";

const TaskStatuses = ({ status }) => {
	const { t } = useTranslation();
	const [creator, setCreator] = useState(null);

	const today = moment();

	const { data, isError } = useGetByUserIdQuery(status.user, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (isError) return;
		if (data) {
			setCreator(data.data);
		}
	}, [data]);

	return (
		<Box
			width={"100%"}
			p={2}
			height={"100%"}
			// minHeight={350}
			borderRadius={1.5}
			display={"flex"}
			justifyContent={"flex-start"}
			alignItems={"start"}
			flexDirection={"column"}
			gap={2}
			bgcolor={"#f3f7ff"}
			sx={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;" }}
		>
			<Stack direction={"row"} width={"100%"} justifyContent={"space-between"} alignItems={"center"}>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
					<img src={images.notes} alt="notes" width={25} height={25} />
					<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mb: 1, mx: "8px !important" }}>
						{t("UpdateNotes")}:
					</Typography>
					<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1 }}>
						{status.note}
					</Typography>
				</Stack>
				<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1}>
					<Box
						sx={{
							color: "#fff",
							borderRadius: 6,
							padding: 1,
							backgroundColor:
								status.status === "New"
									? "#edaf41"
									: status.status === "In Progress"
									? "#116ee9"
									: status.status === "Completed"
									? "#74c193"
									: "#fc6b77",
							textAlign: "center",
							textTransform: "capitalize",
							minWidth: "65px",
						}}
					>
						<Typography variant={"subtitle2"} fontWeight={600} color={"#fff"}>
							{status.status}
						</Typography>
					</Box>
				</Stack>
			</Stack>

			<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
				<img src={images.creator} alt="creator" width={25} height={25} />
				<Typography variant="h5" color={"#164c68"} fontWeight={700} sx={{ mb: 1, mx: "8px !important" }}>
					{t("UpdatedBy")}:
				</Typography>
				<Typography variant="h5" color={"#164c68"} fontWeight={500} sx={{ mb: 1 }}>
					{creator?.firstName} {creator?.lastName}
				</Typography>
			</Stack>

			<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2} width={"100%"}>
				<img src={images.calendar} alt="calendar" width={20} height={20} />
				<Typography variant={"h5"} fontWeight={700} color={"#164c68"} sx={{ mb: 1, mx: "8px !important" }}>
					{t("CreatedAt")}:
				</Typography>
				<Typography variant={"h5"} fontWeight={500} color={"#164c68"}>
					{moment(status.createdAt).format("MMMM Do YYYY")}
				</Typography>
			</Stack>
			<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2} width={"100%"}>
				<img src={images.calendar} alt="calendar" width={20} height={20} />
				<Typography variant={"h5"} fontWeight={700} color={"#164c68"} sx={{ mb: 1, mx: "8px !important" }}>
					{t("DueDate")}:
				</Typography>
				<Typography variant={"h5"} fontWeight={500} color={moment(status.dueDate).diff(today, "days") < 1 ? "#fc6b77" : "#164c68"}>
					{moment(status.dueDate).format("MMMM Do YYYY")}
				</Typography>
			</Stack>

			<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2} width={"100%"}>
				<img src={images.calendar} alt="calendar" width={20} height={20} />
				<Typography variant={"h5"} fontWeight={700} color={"#164c68"} sx={{ mb: 1, mx: "8px !important" }}>
					{t("UpdatedAt")}:
				</Typography>
				<Typography variant={"h5"} fontWeight={500} color={"#164c68"}>
					{moment(status.updatedAt).format("MMMM Do YYYY")}
				</Typography>
			</Stack>
		</Box>
	);
};

export default TaskStatuses;
