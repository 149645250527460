import React, { useState } from "react";
import { Dialog, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import SectionHeader from "./SectionHeader";
import { FaUserCheck } from "react-icons/fa";
import { useVerifyCodeMutation, useVerifyTypeMutation } from "../../redux/slices/api/auth/authApi";
import { setSnackbar, setUserDetails } from "../../redux/slices/app";
import { LoadingButton } from "@mui/lab";
import { useGetMineQuery } from "../../redux/slices/api/user/userApi";
import axiosInstance from "../../utils/axios";

const OtpDialog = ({ open, handleClose, closeVerificationDialog, verificationMethod, myData }) => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const isRtl = useSelector((state) => state.app.isRtl);

	const { data } = useGetMineQuery({}, { refetchOnMountOrArgChange: true });

	const [otp, setOtp] = useState("");

	const [isLoading, setIsLoading] = useState({
		sendAgain: false,
		verify: false,
	});

	const [verifyCode] = useVerifyCodeMutation();

	const [verifyType] = useVerifyTypeMutation();

	const handleVerificationCode = async (e) => {
		e.preventDefault();
		setIsLoading({ ...isLoading, verify: true });
		let data = {};

		if (verificationMethod === "email") {
			data["email"] = myData.email;
			data["emailCode"] = otp;
		} else {
			data["phone"] = myData.phone;
			data["phoneCode"] = otp;
		}

		try {
			await verifyCode(data).unwrap();
			const res = await axiosInstance.get("/user/mine");
			dispatch(setUserDetails(res?.data?.data));
			setIsLoading({ ...isLoading, verify: false });
			dispatch(setSnackbar({ open: true, message: t("VerifiedSuccessfully"), severity: "success" }));
			closeVerificationDialog();
			handleClose();
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setIsLoading({ ...isLoading, verify: false });
		}
	};

	const handleSendAgain = async (e) => {
		e.preventDefault();
		setIsLoading({ ...isLoading, sendAgain: true });

		let data = {};

		if (verificationMethod === "email") {
			data["email"] = true;
		} else {
			data["phone"] = true;
		}

		try {
			await verifyType(data).unwrap();
			setIsLoading({ ...isLoading, sendAgain: false });
			dispatch(setSnackbar({ open: true, message: t("CodeSentSuccessfully"), severity: "success" }));
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setIsLoading({ ...isLoading, sendAgain: false });
		}
	};

	return (
		<Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth={"sm"} fullWidth>
			<DialogTitle sx={{ pb: 2, borderBottom: "1px solid #aaaaaa6b", backgroundColor: "#fff" }}>
				<SectionHeader icon={<FaUserCheck color="#164c68" size={18} />} title={`${t("VerifyUser")}`} type={"dialog"} />
			</DialogTitle>
			<DialogContent sx={{ backgroundColor: "#fff" }}>
				<DialogContentText
					id="add-user-dialog-description"
					sx={{ p: 4, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 4 }}
				>
					<Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={1}>
						<Typography variant={"h5"} fontWeight={500} color={"#164c68"} sx={{ mb: 2 }}>
							{t("EnterOTP")}:
						</Typography>
						<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ mb: 2 }}>
							{verificationMethod === "email" ? myData.email : myData.phone}:
						</Typography>
					</Stack>
					<OtpInput
						value={otp}
						onChange={setOtp}
						numInputs={6}
						renderSeparator={<span style={{ color: "#164c68", fontSize: "1.5rem" }}>-</span>}
						renderInput={(props) => <input {...props} />}
						inputType="text"
						containerStyle={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "6px" }}
						inputStyle={{
							width: "50px",
							height: "50px",
							color: "#164c68",
							borderRadius: "6px",
							border: "1px solid #164c68",
							fontSize: "2rem",
							textAlign: "center",
						}}
					/>
					<Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={1}>
						<LoadingButton
							loading={isLoading.sendAgain}
							variant="contained"
							size="medium"
							onClick={handleSendAgain}
							sx={{
								bgcolor: "#164c68",
								color: "#fff",
								mr: `${isRtl ? "8px" : "0px"} !important`,
							}}
						>
							{t("SendAgain")}
						</LoadingButton>
						<LoadingButton
							loading={isLoading.verify}
							variant="contained"
							size="medium"
							onClick={handleVerificationCode}
							sx={{
								bgcolor: "#164c68",
								color: "#fff",
								mr: `${isRtl ? "8px" : "0px"} !important`,
							}}
						>
							{t("Verify")}
						</LoadingButton>
					</Stack>
				</DialogContentText>
			</DialogContent>
		</Dialog>
	);
};

export default OtpDialog;
