import { queryBuilder } from "../../../../utils/queryBuilder";
import { apiSlice } from "../apiSlice";

export const tasksApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getTasks: builder.query({
			query: (query) => ({
				url: `/task?total=true&${queryBuilder(query)}`,
				method: "GET",
			}),
			providesTags: ["Tasks"],
		}),
		getTaskById: builder.query({
			query: (taskId) => ({
				url: `/task/${taskId}`,
				method: "GET",
			}),
		}),
		addTask: builder.mutation({
			query: (data) => ({
				url: "/task",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["Tasks"],
		}),
		updateTask: builder.mutation({
			query: ({ taskId, data }) => ({
				url: `/task/${taskId}`,
				method: "PATCH",
				body: data,
			}),
			invalidatesTags: ["Tasks"],
		}),
		deleteTask: builder.mutation({
			query: (taskId) => ({
				url: `/task/${taskId}`,
				method: "DELETE",
			}),
			invalidatesTags: ["Tasks"],
		}),
	}),
});

export const { useGetTasksQuery, useGetTaskByIdQuery, useAddTaskMutation, useUpdateTaskMutation, useDeleteTaskMutation } = tasksApi;
