import React, { useState } from "react";
import DOMPurify from "dompurify";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import DeleteEmailTemplateDialog from "./DeleteEmailTemplateDialog";
import { useDispatch } from "react-redux";
import { setIsUsingTemplate } from "../../../../redux/slices/email";
import { TiDelete } from "react-icons/ti";

const TemplateCard = ({ template, setCreateEmail, section, selectedTemplate, setSelectedTemplate }) => {
	const dispatch = useDispatch();

	const [openDeleteEmailTemplateDialog, setOpenDeleteEmailTemplateDialog] = useState(false);

	const cleanHTML = DOMPurify.sanitize(template.html);
	const { t } = useTranslation();
	return (
		<Box
			width={"100%"}
			height={"100%"}
			maxHeight={"350px"}
			minHeight={"350px"}
			display={"flex"}
			flexDirection={"column"}
			justifyContent={"center"}
			alignItems={"center"}
			borderRadius={1}
			sx={{
				boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;",
				cursor: "pointer",
				position: "relative",
				"::before": {
					content: '""',
					display: selectedTemplate === template.id ? "block" : "none",
					position: "absolute",
					top: 0,
					right: 0,
					bottom: 0,
					left: 0,
					backgroundColor: "rgb(0 0 0 / 24%)",
				},
			}}
			bgcolor={"#d1d1d1"}
		>
			{selectedTemplate === template.id && section === "reminder" && (
				<Tooltip title={t("Unselect")} placement="top" arrow>
					<IconButton onClick={() => setSelectedTemplate(null)} disableRipple sx={{ position: "absolute", zIndex: 999 }}>
						<TiDelete color="red" size={55} />
					</IconButton>
				</Tooltip>
			)}
			<Box
				width={"80%"}
				height={"80%"}
				display={"flex"}
				flexDirection={"column"}
				justifyContent={"flex-start"}
				alignItems={"start"}
				p={2}
				gap={3}
				bgcolor={"#fff"}
				onClick={() => {
					if (section === "email") {
						dispatch(setIsUsingTemplate(true));
						dispatch(setSelectedTemplate(template));
						setCreateEmail(true);
					} else if (section === "reminder") {
						setSelectedTemplate(template.id);
					}
				}}
			>
				<Typography variant="h5" fontWeight={600} color={"#164c68"}>
					{t("Subject")}: {template.subject}
				</Typography>
				<Stack justifyContent="flex-start" alignItems={"start"} spacing={1}>
					<Typography variant="h5" fontWeight={600} color={"#164c68"}>
						{t("Body")}:
					</Typography>
					<Typography variant="h5" fontWeight={500} color={"#164c68"}>
						{template.html ? <div dangerouslySetInnerHTML={{ __html: cleanHTML }} /> : "N/A"}
					</Typography>
				</Stack>
			</Box>
			<Box width={"100%"} height={"20%"} display={"flex"} justifyContent={"space-between"} alignItems={"center"} bgcolor={"#f5f4f4"} p={2}>
				<Typography variant="h5" fontWeight={700} color={"#164c68"}>
					{template.name}
				</Typography>
				{section === "email" && (
					<Tooltip title={t("DeleteTemplate")} placement="top" arrow>
						<IconButton onClick={() => setOpenDeleteEmailTemplateDialog(true)}>
							<RiDeleteBin6Fill color="#fc6739" />
						</IconButton>
					</Tooltip>
				)}
			</Box>
			{openDeleteEmailTemplateDialog && (
				<DeleteEmailTemplateDialog
					open={openDeleteEmailTemplateDialog}
					handleClose={() => setOpenDeleteEmailTemplateDialog(false)}
					templateId={template.id}
				/>
			)}
		</Box>
	);
};

export default TemplateCard;
