import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { navItems } from "../Common/utils";
import { setActiveRoute, setIsDrawerOpen } from "../../redux/slices/app";
import Animate from "../Common/Animate";
import { images } from "../Assets";
import { useTranslation } from "react-i18next";
import { setCurrentCase, setCurrentHearing, setIsEditingCase, setIsViewingCase, setMeetings } from "../../redux/slices/case";
import { setIsCreatingMeeting, setIsEditingMeeting } from "../../redux/slices/meetings";
import { setCurrentContact, setIsAddingContact, setIsEditingContact, setIsViewingContact } from "../../redux/slices/contact";
import { setIsCreatingFee, setIsEditingFee, setSelectedFee } from "../../redux/slices/fees";
import { setCurrentMemo, setIsCreatingMemo, setIsEditingMemo } from "../../redux/slices/memo";
import { setIsCreatingTask, setIsEditingTask, setSelectedTask } from "../../redux/slices/task";
import { setIsUsingTemplate, setSelectedTemplate } from "../../redux/slices/email";

const DrawerMenu = ({ drawerWidth }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const isRtl = useSelector((state) => state.app.isRtl);
	const activeRoute = useSelector((state) => state.app.activeRoute);

	const dispatch = useDispatch();
	return (
		<Animate>
			<Box
				width={drawerWidth}
				height={"100%"}
				maxHeight={"100%"}
				display={"flex"}
				flexDirection={"column"}
				justifyContent={"flex-start"}
				alignItems={"start"}
				bgcolor={"#fff"}
				py={15}
				gap={4}
				sx={{ position: "sticky", backgroundColor: "#fff", boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;" }}
			>
				<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} px={2}>
					<img src={images.mainMenu} alt={"main menu"} width={35} height={35} />
					<Typography variant={"h2"} fontWeight={600} color={"#164c68"} px={2}>
						{t("MainMenu")}
					</Typography>
				</Stack>

				<Box display={"flex"} width={"100%"} flexDirection={"column"} justifyContent={"start"} alignItems={"start"} gap={4}>
					{navItems.map((item) => (
						<Stack
							key={item.id}
							direction={"row"}
							justifyContent={"flex-start"}
							alignItems={"center"}
							spacing={2}
							width={"100%"}
							sx={{
								cursor: "pointer",
								py: 2,
								px: 4,
								transition: "all .3s ease-in",
								bgcolor: activeRoute === item.name ? "#0d666629" : "transparent",
							}}
							onClick={() => {
								dispatch(setCurrentCase(null));
								dispatch(setIsEditingCase(false));
								dispatch(setIsViewingContact(false));
								dispatch(setIsViewingCase(false));
								dispatch(setIsCreatingMeeting(false));
								dispatch(setIsEditingMeeting(false));
								dispatch(setCurrentHearing(null));
								dispatch(setMeetings([]));
								dispatch(setCurrentContact(null));
								dispatch(setIsEditingContact(false));
								dispatch(setIsViewingContact(false));
								dispatch(setIsAddingContact(false));
								dispatch(setSelectedFee(null));
								dispatch(setIsEditingFee(null));
								dispatch(setIsCreatingFee(null));
								dispatch(setCurrentMemo(null));
								dispatch(setIsCreatingMemo(false));
								dispatch(setIsEditingMemo(false));
								dispatch(setSelectedTask(null));
								dispatch(setIsEditingTask(false));
								dispatch(setIsCreatingTask(false));
								dispatch(setSelectedTemplate(null));
								dispatch(setIsUsingTemplate(false));
								dispatch(setActiveRoute(item.name));
								navigate("/dashboard" + item.path);
								dispatch(setIsDrawerOpen(false));
							}}
						>
							<img
								src={
									item.name === "Dashboard"
										? images.dashboard
										: item.name === "Contacts"
										? images.contacts
										: item.name === "Cases"
										? images.onlineCase
										: item.name === "Tasks"
										? images.task
										: item.name === "Meetings"
										? images.calendar
										: item.name === "Emails"
										? images.email
										: item.name === "Reports"
										? images.reports
										: null
								}
								alt={item.name}
								width={25}
								height={25}
							/>
							<Typography
								variant={"h3"}
								color={"#164c68"}
								fontWeight={activeRoute === item.name ? 600 : 400}
								sx={{ mr: `${isRtl ? "8px" : "0px"} !important` }}
							>
								{t(item.name)}
							</Typography>
						</Stack>
					))}
				</Box>
			</Box>
		</Animate>
	);
};

export default DrawerMenu;
