import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import appReducer from "./slices/app";
import caseReducer from "./slices/case";
import authReducer from "./slices/auth";
import taskReducer from "./slices/task";
import memoReducer from "./slices/memo";
import meetingsReducer from "./slices/meetings";
import contactsReducer from "./slices/contact";
import feesReducer from "./slices/fees";
import constraintsReducer from "./slices/constraints";
import emailReducer from "./slices/email";
import { apiSlice } from "./slices/api/apiSlice";

const rootPersistConfig = {
	key: "root",
	storage,
	keyPrefix: "redux-",
};

const applicationReducer = combineReducers({
	app: appReducer,
	case: caseReducer,
	auth: authReducer,
	task: taskReducer,
	memo: memoReducer,
	meetings: meetingsReducer,
	contacts: contactsReducer,
	fees: feesReducer,
	constraints: constraintsReducer,
	email: emailReducer,
	[apiSlice.reducerPath]: apiSlice.reducer,
});

const rootReducer = (state, action) => {
	return applicationReducer(state, action);
};

export { rootReducer, rootPersistConfig };
