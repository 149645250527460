import { Button, FormControl, InputLabel, MenuItem, Select as MuiSelect, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import CustomTextField from "../../Common/CustomTextField";
import { IoMdAdd } from "react-icons/io";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Select = styled(MuiSelect)({
	color: "#164c68", // Change this to your desired color
	"& .MuiSelect-outlined": {
		// Change this to your desired color
		borderRadius: 5,
	},
	"&:active": {
		"& .MuiSelect-outlined": {
			boxShadow: "0 0 5px rgba(0,0,0,0.3)",
			// Example border color when focused
		},
	},
});

const TopFilter = ({ isSmallScreen, searchValue, handleSearchInput, clearSearch, isSearching, categoryValue, setCategoryValue, setOpenAddUser }) => {
	const userDetails = useSelector((state) => state.app.userDetails);
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);
	return (
		<Stack
			direction={"row"}
			justifyContent={"space-between"}
			alignItems={isSmallScreen ? "start" : "center"}
			width={"100%"}
			borderRadius={1}
			p={3.5}
			bgcolor={"#fff"}
			sx={{ boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;" }}
		>
			<Stack direction={isSmallScreen ? "column" : "row"} justifyContent={"flex-start"} alignItems={isSmallScreen ? "start" : "center"} spacing={2}>
				<CustomTextField
					label={t("Search")}
					name={"searchValue"}
					value={searchValue}
					type={"text"}
					handleInputChange={handleSearchInput}
					isSearching={isSearching}
					clearSearch={clearSearch}
					setSortValue={setCategoryValue}
					required={false}
				/>
				{isSmallScreen && (
					<FormControl size="small" sx={{ my: 2, width: "auto" }}>
						<InputLabel id="category">{t("Role")}</InputLabel>
						<Select labelId="category" id="status" value={categoryValue} label="category" onChange={(e) => setCategoryValue(e.target.value)}>
							<MenuItem value={"Owner"}>{t("Owner")}</MenuItem>
							<MenuItem value={"Admin"}>{t("Admin")}</MenuItem>
							<MenuItem value={"Employee"}>{t("Employee")}</MenuItem>
						</Select>
					</FormControl>
				)}
			</Stack>
			{userDetails?.role === "Owner" && (
				<Button
					variant="contained"
					startIcon={!isRtl && <IoMdAdd />}
					size="medium"
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						"&:hover": {
							bgcolor: "#00a3b1",
						},
						transition: "all 0.5s ease",
					}}
					onClick={() => {
						setOpenAddUser(true);
					}}
				>
					{t("AddUser")}
				</Button>
			)}
		</Stack>
	);
};

export default TopFilter;
