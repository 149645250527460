import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	selectedTemplate: null,
	isUsingTemplate: false,
};

export const emailSlice = createSlice({
	name: "email",
	initialState,
	reducers: {
		setSelectedTemplate: (state, action) => {
			state.selectedTemplate = action.payload;
		},
		setIsUsingTemplate: (state, action) => {
			state.isUsingTemplate = action.payload;
		},
	},
});

export const { setSelectedTemplate, setIsUsingTemplate } = emailSlice.actions;

export default emailSlice.reducer;
