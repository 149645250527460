import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useState } from "react";
import { FaTasks } from "react-icons/fa";
import SectionHeader from "../../Common/SectionHeader";
import PieChart from "../../Common/Charts/PieChart";
import { GoDotFill } from "react-icons/go";
import { useGetTasksQuery } from "../../../redux/slices/api/tasks/tasksApi";
import { useTranslation } from "react-i18next";
import { images } from "../../Assets";

const TaskOverview = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
	const [taskData, setTaskData] = useState([{}]);
	const [taskDataForList, setTaskDataForList] = useState([{}]);

	const newTask = useGetTasksQuery({ status: "New", data: false }, { refetchOnMountOrArgChange: true });
	const inProgressTask = useGetTasksQuery({ status: "In Progress", data: false }, { refetchOnMountOrArgChange: true });
	const completedTask = useGetTasksQuery({ status: "Completed", data: false }, { refetchOnMountOrArgChange: true });
	const cancelledTask = useGetTasksQuery({ status: "Canceled", data: false }, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (newTask && inProgressTask && completedTask && cancelledTask) {
			setTaskData(
				[
					{ id: "New", label: t("New"), value: newTask.data?.totalRecords, color: "#edaf41" },
					{ id: "In Progress", label: t("InProgress"), value: inProgressTask.data?.totalRecords, color: "#116ee9" },
					{ id: "Completed", label: t("Completed"), value: completedTask.data?.totalRecords, color: "#74c193" },
					{ id: "Canceled", label: t("Canceled"), value: cancelledTask.data?.totalRecords, color: "#fc6b77" },
				].filter((task) => task.value !== 0)
			);
			setTaskDataForList([
				{ id: "New", label: t("New"), value: newTask.data?.totalRecords, color: "#edaf41" },
				{ id: "In Progress", label: t("InProgress"), value: inProgressTask.data?.totalRecords, color: "#116ee9" },
				{ id: "Completed", label: t("Completed"), value: completedTask.data?.totalRecords, color: "#74c193" },
				{ id: "Canceled", label: t("Canceled"), value: cancelledTask.data?.totalRecords, color: "#fc6b77" },
			]);
		}
	}, [newTask, inProgressTask, completedTask, cancelledTask]);

	return (
		<>
			<SectionHeader icon={<FaTasks color="#164c68" size={18} />} title={t("TasksOverview")} />
			<Stack justifyContent={"center"} alignItems={"center"} height={"100%"}>
				{taskData.length === 0 ? (
					<Box width={"100%"} height={"100%"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} mt={10}>
						<img src={images.notFound} alt="Not Found" width={200} height={200} />
						<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ my: 2 }}>
							{t("NoTaskFound")}
						</Typography>
						<Typography variant={"subtitle1"} fontWeight={500} color={"#51505099"}>
							{t("AddTask")}
						</Typography>
					</Box>
				) : (
					<>
						<Box width={"100%"} height={"600px"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
							<PieChart data={taskData} />
						</Box>
						<Stack
							width={"100%"}
							direction={isSmallScreen ? "column" : "row"}
							justifyContent={isSmallScreen ? "flex-start" : "center"}
							alignItems={isSmallScreen ? "flex-start" : "center"}
							spacing={2}
						>
							{taskDataForList.map((item) => (
								<Box
									key={item.id}
									width={"30%"}
									height={"auto"}
									p={1}
									borderRadius={2}
									display={"flex"}
									justifyContent={"center"}
									alignItems={"center"}
									flexDirection={"column"}
									sx={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;" }}
								>
									<Stack
										width={"100%"}
										direction={isSmallScreen ? "column" : "row"}
										justifyContent={isSmallScreen ? "flex-start" : "center"}
										alignItems={isSmallScreen ? "flex-start" : "center"}
										spacing={1}
									>
										<Stack width={"100%"} direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={0.5}>
											<GoDotFill color={item.color} size={15} />
											<Typography variant={"caption"} color={"#164c68"} fontWeight={500}>
												{item.label}:
											</Typography>
										</Stack>
										<Typography
											variant={"caption"}
											color={"#164c68"}
											fontWeight={500}
											sx={{
												fontFamily: '"Montserrat", "sans-serif"',
											}}
										>
											{item.value}
										</Typography>
									</Stack>
								</Box>
							))}
						</Stack>
					</>
				)}
			</Stack>
		</>
	);
};

export default TaskOverview;
