import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Stack, Tooltip, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import SectionHeader from "../../Common/SectionHeader";
import { FaUserPlus } from "react-icons/fa";
import CustomTextField from "../../Common/CustomTextField";
import CustomCheckbox from "../../Common/CustomCheckBox";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const UserSelection = ({ open, handleClose, usersToAdd, setUsersToAdd, rows, setRows, originalRows }) => {
	const { t } = useTranslation();
	const isRtl = useSelector((state) => state.app.isRtl);

	const [searchValue, setSearchValue] = useState("");
	const [isSearching, setIsSearching] = useState(false);

	useEffect(() => {
		if (searchValue?.length >= 0 && searchValue !== "") {
			setIsSearching(true);
			const filtered = rows.filter((row) => {
				return Object.values(row).some((value) => value.toString().toLowerCase().includes(searchValue.toLowerCase()));
			});
			setRows(filtered);
		} else {
			setIsSearching(false);
			setRows(originalRows);
		}
	}, [searchValue]);

	const handleSearchInput = (e) => {
		setSearchValue(e.target.value);
		if (e.target.value === "") {
			setIsSearching(false);
		}
	};

	const clearSearch = () => {
		setSearchValue("");
		setIsSearching(false);
	};

	const handleToggle = (user) => {
		const currentIndex = usersToAdd.indexOf(user);
		const newUsersToAdd = [...usersToAdd];

		if (currentIndex === -1) {
			newUsersToAdd.push(user);
		}

		setUsersToAdd(newUsersToAdd);
	};

	const handleRemoveUser = (user) => {
		const newUsersToAdd = usersToAdd.filter((u) => u !== user);
		setUsersToAdd(newUsersToAdd);
	};

	return (
		<Dialog
			open={open}
			fullWidth
			maxWidth="sm"
			onClose={handleClose}
			TransitionComponent={Transition}
			keepMounted
			aria-describedby="add-users-dialog"
			sx={{ height: "100%" }}
		>
			<DialogTitle sx={{ pb: 2, borderBottom: "1px solid #aaaaaa6b", backgroundColor: "#fff" }}>
				<SectionHeader icon={<FaUserPlus color="#164c68" size={18} />} title={t("AddUser")} type={"dialog"} />
			</DialogTitle>
			<DialogContent sx={{ backgroundColor: "#fff", overflowY: "scroll" }}>
				<DialogContentText id="add-users-dialog-description" sx={{ p: 4 }}>
					<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} width={"100%"} spacing={2}>
						<Typography variant={"h5"} fontWeight={600} color={"#164c68"} sx={{ ml: `${isRtl ? "8px" : "0px"} !important` }}>
							{t("SelectedUsers")}:
						</Typography>
						{usersToAdd.length > 0 &&
							usersToAdd.map((user) => (
								<Box
									key={user.id}
									display={"flex"}
									justifyContent={"center"}
									alignItems={"center"}
									p={0.5}
									border={".5px solid #164c68"}
									borderRadius={1}
									onClick={() => {
										handleRemoveUser(user);
									}}
									sx={{
										"&:hover": {
											cursor: "pointer",
										},
									}}
								>
									<Tooltip title={`Remove ${user.firstName} ${user.lastName}?`} placement="top">
										<Typography variant={"caption"} fontWeight={600} color={"#164c68"}>
											{user.firstName} {user.lastName}
										</Typography>
									</Tooltip>
								</Box>
							))}
					</Stack>
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
						<Typography variant={"h4"} fontWeight={600} color={"#164c68"}>
							{t("Users")}
						</Typography>
						<CustomTextField
							label={t("Search")}
							name={"searchValue"}
							value={searchValue}
							type={"text"}
							handleInputChange={handleSearchInput}
							isSearching={isSearching}
							clearSearch={clearSearch}
							required={false}
						/>
					</Stack>
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<Stack justifyContent={"flex-start"} alignItems={"start"} spacing={2} py={2} mb={2}>
						{rows?.map((user) => (
							<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} key={user.id} px={3}>
								<Typography variant={"h4"} color={"#164c68"}>
									{user.firstName} {user.lastName}
								</Typography>
								<CustomCheckbox checked={usersToAdd.includes(user)} toggle={() => handleToggle(user)} />
							</Stack>
						))}
					</Stack>
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{ backgroundColor: "#fff" }}>
				<Button
					onClick={handleClose}
					sx={{
						bgcolor: "#164c68",
						color: "#fff",
						"&:hover": {
							bgcolor: "#00a3b1",
							color: "#fff",
						},
						transition: "all 1s ease",
					}}
				>
					{t("Add")}
				</Button>
				<Button
					onClick={() => {
						setRows(originalRows);
						handleClose();
					}}
					sx={{
						color: "red",
						"&:hover": {
							color: "#fff",
							backgroundColor: "red",
						},
						transition: "all 1s ease",
						mr: `${isRtl ? "8px" : "0px"} !important`,
					}}
				>
					{t("Close")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default UserSelection;
