import React, { useEffect, useState } from "react";
import Animate from "../Common/Animate";
import { Box, Button, Divider, FormControl, MenuItem, Select, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomBreadcrumbs from "../Common/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { CiCalendarDate } from "react-icons/ci";
import CustomTextField from "../Common/CustomTextField";
import { useGetCaseByIdQuery, useGetCasesQuery } from "../../redux/slices/api/cases/caseApi";
import CustomDateTimePicker from "../Common/DateTimePicker";
import moment from "moment";
import ContactSelect from "./ContactSelect";
import { images } from "../Assets";
import ContactSelectDialog from "./ContactSelectDialog";
import UserSelect from "./UserSelect";
import UserSelectDialog from "./UserSelectDialog";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { setSnackbar } from "../../redux/slices/app";
import { useAddMeetingMutation, useUpdateMeetingMutation } from "../../redux/slices/api/meetings/meetingsApi";
import CancellingDialog from "../Common/CancellingDialog";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../utils/axios";
import { setCurrentMeeting } from "../../redux/slices/meetings";

const MeetingForm = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const isRtl = useSelector((state) => state.app.isRtl);
	const isEditingMeeting = useSelector((state) => state.meetings.isEditingMeeting);
	const isCreatingMeeting = useSelector((state) => state.meetings.isCreatingMeeting);
	const currentMeeting = useSelector((state) => state.meetings.currentMeeting);
	const currentCase = useSelector((state) => state.case.currentCase);

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	const { data, isError } = useGetCasesQuery({}, { refetchOnMountOrArgChange: true });

	const [caseFiles, setCaseFiles] = useState(data?.data);

	useEffect(() => {
		if (isError) return;
		if (data) {
			setCaseFiles(data?.data);
		}
	}, [data, caseFiles]);

	// const [code, setCode] = useState(isEditingMeeting ? currentMeeting?.code : "");
	const [place, setPlace] = useState(isEditingMeeting ? currentMeeting?.place : "");
	const [meetingSubject, setMeetingSubject] = useState(isEditingMeeting ? currentMeeting?.subject : "");
	const [meetingNote, setMeetingNote] = useState(isEditingMeeting ? currentMeeting?.note : "");
	const [caseFile, setCaseFile] = useState(
		isEditingMeeting
			? currentMeeting?.caseFile
				? currentMeeting?.caseFile.id
				: null
			: !isEditingMeeting && isCreatingMeeting && currentCase
			? currentCase.id
			: null
	);
	const [startDate, setStartDate] = useState(isEditingMeeting ? moment(currentMeeting?.date) : moment().add(1, "hour"));
	const [invalidStartDate, setInvalidStartDate] = useState(false);
	const [startDateError, setStartDateError] = useState("");
	const [meetingUrl, setMeetingUrl] = useState(isEditingMeeting ? (currentMeeting?.url ? currentMeeting.url : "") : "");
	const [invalidMeetingUrl, setInvalidMeetingUrl] = useState(false);
	const [meetingUrlError, setMeetingUrlError] = useState("");

	const [contacts, setContacts] = useState(isEditingMeeting ? currentMeeting?.contacts : []);
	const [selectingContacts, setSelectingContacts] = useState(false);

	const [users, setUsers] = useState(isEditingMeeting ? currentMeeting?.users : []);
	const [selectingUsers, setSelectingUsers] = useState(false);

	const [loading, setLoading] = useState(false);
	const [cancelling, setCancelling] = useState(false);

	const caseDetail = useGetCaseByIdQuery(caseFile, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (currentCase && isCreatingMeeting && !isEditingMeeting) {
			const currentCaseFileContacts = currentCase.parties.map((party) => party.party);
			setContacts(currentCaseFileContacts);
			const currentCaseUsers = currentCase.users.map((user) => user);
			setUsers(currentCaseUsers);
		}
	}, [currentCase]);

	const [createMeeting] = useAddMeetingMutation();

	const [updateMeeting] = useUpdateMeetingMutation();

	const handleAddMeeting = async () => {
		setLoading(true);

		if (!startDate) {
			dispatch(setSnackbar({ open: true, message: t("MeetingStartDateRequired"), severity: "error" }));
			setLoading(false);
			return;
		}

		if (contacts.length < 1) {
			dispatch(setSnackbar({ open: true, message: t("ContactMinimum"), severity: "error" }));
			setLoading(false);
			return;
		}

		if (users.length < 1) {
			dispatch(setSnackbar({ open: true, message: t("UserMinimum"), severity: "error" }));
			setLoading(false);
			return;
		}

		const formattedUsers = users?.map((user) => user?.user?.id || user?.id);
		const formattedContacts = contacts?.map((contact) => contact.id);

		let meetingData = {
			caseFile: caseFile,
			date: startDate,
			contacts: formattedContacts,
			users: formattedUsers,
		};

		if (!isEditingMeeting) {
			if (place && place !== "") {
				meetingData = { ...meetingData, place: place };
			}
			if (meetingSubject && meetingSubject !== "") {
				meetingData = { ...meetingData, subject: meetingSubject };
			}
			if (meetingNote && meetingNote !== "") {
				meetingData = { ...meetingData, note: meetingNote };
			}

			if (meetingUrl && meetingUrl !== "") {
				const pattern = new RegExp(
					"^(https?:\\/\\/)?" + // protocol
						"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name and extension
						"((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
						"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
						"(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
						"(\\#[-a-z\\d_]*)?$",
					"i"
				);

				if (!pattern.test(meetingUrl)) {
					dispatch(setSnackbar({ open: true, message: t("MeetingUrlError"), severity: "error" }));
					setInvalidMeetingUrl(true);
					setMeetingUrlError(t("MeetingUrlError"));
					setLoading(false);
					return;
				} else {
					meetingData = { ...meetingData, url: meetingUrl };
				}
			}
			try {
				const res = await createMeeting(meetingData).unwrap();
				const newMeetingData = await axiosInstance.get(`/meeting/${res.id || res._id}`);
				dispatch(setCurrentMeeting(newMeetingData.data.data));
				dispatch(setSnackbar({ open: true, message: t("MeetingCreatedSuccessfully"), severity: "success" }));
				setLoading(false);
				navigate(`/dashboard/meeting-detail`);
				return;
			} catch (error) {
				dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
				setLoading(false);
			}
		}
	};

	const handleUpdateMeeting = async () => {
		setLoading(true);

		const formattedUsers = users?.map((user) => user.id);
		const formattedContacts = contacts?.map((contact) => contact.id);

		let meetingData = {
			date: startDate,
			contacts: formattedContacts,
			users: formattedUsers,
			place: place,
			subject: meetingSubject,
			note: meetingNote,
		};

		if (meetingUrl && meetingUrl !== "") {
			const pattern = new RegExp(
				"^(https?:\\/\\/)?" + // protocol
					"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name and extension
					"((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
					"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
					"(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
					"(\\#[-a-z\\d_]*)?$",
				"i"
			);

			if (!pattern.test(meetingUrl)) {
				dispatch(setSnackbar({ open: true, message: t("MeetingUrlError"), severity: "error" }));
				setInvalidMeetingUrl(true);
				setMeetingUrlError(t("MeetingUrlError"));
				setLoading(false);
				return;
			} else {
				meetingData = { ...meetingData, url: meetingUrl };
			}
		}

		try {
			await updateMeeting({ meetingId: currentMeeting?.id, data: meetingData }).unwrap();
			const newMeetingData = await axiosInstance.get(`/meeting/${currentMeeting?.id}`);
			dispatch(setCurrentMeeting(newMeetingData.data.data));
			dispatch(setSnackbar({ open: true, message: t("MeetingUpdatedSuccessfully"), severity: "success" }));
			setLoading(false);
			navigate(-1);
			return;
		} catch (error) {
			dispatch(setSnackbar({ open: true, message: isRtl ? error?.data?.ar : error?.data?.en, severity: "error" }));
			setLoading(false);
		}
	};

	return (
		<Animate type="fade" delay={0.5}>
			<Stack justifyContent={"flex-start"} alignItems={"self-start"} spacing={2} px={6} py={4}>
				<CustomBreadcrumbs section={t("Meetings")} />
				<Typography variant={"h2"} fontWeight={600} color={"#164c68"}>
					{isEditingMeeting ? t("Editing") : t("Creating")} {t("Meeting")}
				</Typography>
			</Stack>

			<Box
				width={"100%"}
				height={"max-content"}
				display={"flex"}
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
				gap={4}
				sx={{ overflowY: "auto", overflowX: "hidden" }}
				p={4}
			>
				<Box
					width={isSmallScreen ? "100%" : "90%"}
					height={"auto"}
					p={2}
					bgcolor={"#fff"}
					borderRadius={1.5}
					sx={{
						boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
					}}
				>
					<Stack width={"100%"} direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1}>
						<Box p={1} display={"flex"} justifyContent={"center"} alignItems={"center"} bgcolor={"#e9e7e770"} borderRadius={1}>
							<img src={images.calendar} alt="meeting" width={20} height={20} />
						</Box>
						<Typography variant={"h4"} fontWeight={700} color={"#595858"}>
							{isEditingMeeting ? t("Editing") : t("New")} {t("Meeting")}
						</Typography>
					</Stack>
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />

					<Stack justifyContent={"flex-start"} alignItems={"flex-start"} spacing={2} width={"100%"}>
						<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
							<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2} width={"100%"}>
								<Typography variant={"h3"} fontWeight={500} color={"#164c68"}>
									{t("MeetingDetails")}:
								</Typography>
							</Stack>
						</Stack>

						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"} mb={3}>
							<Typography
								variant={"h4"}
								fontWeight={600}
								color={"#164c68"}
								sx={{ mb: 3, ml: `${isRtl ? "8px" : "0px"} !important`, mr: "8px !important" }}
							>
								{t("CaseFileNumber")}:
							</Typography>
							{!isEditingMeeting && !currentCase ? (
								<FormControl sx={{ m: 1, minWidth: 100 }} size="small">
									<Select
										id="select-caseFile"
										value={caseFile}
										onChange={(e) => setCaseFile(e.target.value)}
										sx={{ color: "#164c68" }}
										displayEmpty
									>
										{caseFiles?.map((file) => (
											<MenuItem key={file?.id} value={file?.id}>
												{file?.fileNumber}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							) : (
								<Typography variant={"h4"} fontWeight={500} color={"#164c68"} sx={{ mb: 3, ml: `${isRtl ? "8px" : "0px"} !important` }}>
									{currentCase?.fileNumber || caseFile?.fileNumber}
								</Typography>
							)}
						</Stack>
						{caseFile && caseDetail?.data?.data?.classification === "litigation" ? (
							<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
								<Typography
									variant={"h4"}
									fontWeight={600}
									color={"#164c68"}
									sx={{ mb: 3, ml: `${isRtl ? "8px" : "0px"} !important`, mr: "8px !important" }}
								>
									{t("CaseFileFullName")}:
								</Typography>

								<Typography variant={"h4"} fontWeight={400} color={"#164c68"} sx={{ mb: 3, ml: `${isRtl ? "8px" : "0px"} !important` }}>
									{caseDetail?.data?.data?.caseNumber ? caseDetail?.data?.data?.caseNumber : "N/A"} /{" "}
									{caseDetail?.data?.data?.caseYear ? caseDetail?.data?.data?.caseYear : "N/A"} -{" "}
									{caseDetail?.data?.data?.caseStage
										? isRtl
											? caseDetail?.data?.data?.caseStage.nameAr
											: caseDetail?.data?.data?.caseStage.nameEn
											? caseDetail?.data?.data?.caseStage.nameEn
											: caseDetail?.data?.data?.caseStage.nameAr
										: "N/A"}{" "}
									-{" "}
									{caseDetail?.data?.data?.caseType
										? isRtl
											? caseDetail?.data?.data?.caseType.nameAr
											: caseDetail?.data?.data?.caseType.nameEn
											? caseDetail?.data?.data?.caseType.nameEn
											: caseDetail?.data?.data?.caseType.nameAr
										: "N/A"}
								</Typography>
							</Stack>
						) : (
							<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
								<Typography
									variant={"h4"}
									fontWeight={600}
									color={"#164c68"}
									sx={{ mb: 3, ml: `${isRtl ? "8px" : "0px"} !important`, mr: "8px !important" }}
								>
									{t("CaseFileFullName")}:
								</Typography>
								<Typography
									variant={"h4"}
									fontWeight={400}
									color={"#164c68"}
									sx={{ mb: 3, ml: `${isRtl ? "8px" : "0px"} !important`, mr: "8px !important" }}
								>
									N/A
								</Typography>
							</Stack>
						)}
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
							<Typography
								variant={"h4"}
								fontWeight={600}
								color={"#164c68"}
								sx={{ mb: 3, ml: `${isRtl ? "8px" : "0px"} !important`, mr: "8px !important" }}
							>
								{t("MeetingUrl")}:
							</Typography>
							<CustomTextField
								name={"url"}
								value={meetingUrl}
								resetValue={() => setMeetingUrl("")}
								type={"text"}
								handleInputChange={(e) => setMeetingUrl(e.target.value)}
								required={false}
								id={"meetingUrl"}
								error={invalidMeetingUrl}
								errorText={meetingUrlError}
								placeholder={"https://example.com"}
							/>
						</Stack>

						<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
							<Stack justifyContent={"flex-start"} alignItems={"flex-start"} spacing={2} width={isSmallScreen ? "100%" : "50%"}>
								<Typography variant={"h4"} fontWeight={600} color={"#164c68"} sx={{ ml: `${isRtl ? "8px" : "0px"} !important` }}>
									{t("MeetingSubject")}:
								</Typography>
								<CustomTextField
									label={t("MeetingSubject")}
									name={"meetingSubject"}
									value={meetingSubject}
									resetValue={() => setMeetingSubject("")}
									type={"text"}
									handleInputChange={(e) => setMeetingSubject(e.target.value)}
									required={false}
									multiline={true}
									fullWidth={false}
								/>
							</Stack>
							<Stack
								justifyContent={"flex-end"}
								alignItems={"flex-start"}
								spacing={2}
								width={isSmallScreen ? "100%" : "50%"}
								sx={{ ml: "8px !important" }}
							>
								<Typography variant={"h4"} fontWeight={600} color={"#164c68"} sx={{ ml: `${isRtl ? "8px" : "0px"} !important` }}>
									{t("MeetingNotes")}:
								</Typography>
								<CustomTextField
									label={t("MeetingNotes")}
									name={"meetingNote"}
									value={meetingNote}
									resetValue={() => setMeetingNote("")}
									type={"text"}
									handleInputChange={(e) => setMeetingNote(e.target.value)}
									required={false}
									multiline={true}
									fullWidth={false}
								/>
							</Stack>
						</Stack>
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2} width={"100%"}>
							<Typography variant={"h4"} fontWeight={600} color={"#164c68"} sx={{ ml: `${isRtl ? "8px" : "0px"} !important` }}>
								{t("MeetingPlace")}:
							</Typography>
							<CustomTextField
								label={t("MeetingPlace")}
								name={"place"}
								value={place}
								resetValue={() => setPlace("")}
								type={"text"}
								handleInputChange={(e) => setPlace(e.target.value)}
								required={false}
							/>
						</Stack>
						<Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={1} width={"100%"}>
							<Typography variant={"h4"} fontWeight={600} color={"#164c68"} sx={{ ml: `${isRtl ? "8px" : "0px"} !important` }}>
								{t("MeetingStartDate")}:
							</Typography>
							<CustomDateTimePicker title={t("selectDateAndTime")} value={startDate} setDate={setStartDate} section={"meeting"} />
							{invalidStartDate && (
								<Typography variant={"title1"} color={"red"} fontWeight={500}>
									{startDateError}
								</Typography>
							)}
						</Stack>
					</Stack>
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<ContactSelect contacts={contacts} setSelectingContacts={setSelectingContacts} />
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<UserSelect users={users} setSelectingUsers={setSelectingUsers} />
					<Divider sx={{ my: 2, bgcolor: "#164c6866" }} />
					<Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={1.5}>
						<LoadingButton
							loading={loading}
							variant="contained"
							loadingPosition="start"
							startIcon={!isRtl && <CiCalendarDate />}
							size="medium"
							sx={{
								bgcolor: "#164c68",
								color: "#fff",
							}}
							onClick={isEditingMeeting ? handleUpdateMeeting : handleAddMeeting}
						>
							{isEditingMeeting ? t("Update") : t("Save")}
						</LoadingButton>
						<Button
							onClick={() => setCancelling(true)}
							disabled={loading}
							sx={{
								color: "#fff",
								backgroundColor: "#fc6739",
								mr: `${isRtl ? "8px" : "0px"} !important`,
								"&:hover": {
									color: "#fff",
									backgroundColor: "red",
								},
								transition: "all 1s ease",
							}}
						>
							{t("Cancel")}
						</Button>
					</Stack>
				</Box>
			</Box>
			{selectingContacts && (
				<ContactSelectDialog contacts={contacts} setContacts={setContacts} open={selectingContacts} handleClose={() => setSelectingContacts(false)} />
			)}
			{selectingUsers && <UserSelectDialog users={users} setUsers={setUsers} open={selectingUsers} handleClose={() => setSelectingUsers(false)} />}
			{cancelling && <CancellingDialog open={cancelling} handleClose={() => setCancelling(false)} path={"meetings"} />}
		</Animate>
	);
};

export default MeetingForm;
